<template>
	<div class="rightobbox">

		<div class="title-bo2">
			<div class="left">
				<p>取款卡</p>
				<!-- <span>{{tabidx == 1 ? '最多支持绑定3张银行卡' : '最多支持绑定5条虚拟币地址'}} </span> -->
			</div>
		</div>

		<div class="tab">
			<ul class="tabs">
				<li :class="{'active': tabidx == 1}" @click="tabidx = 1">
					銀行卡
					
				</li>
				<li :class="{'active': tabidx == 2}" @click="tabidx = 2" v-if="config.drawing_usdt == 1"><span>USDT</span></li>
			</ul>
		</div>
		
		<a-spin tip="正在加载..." :spinning="spinning">
			<a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
		    <div>
				<div v-if="tabidx == 1">
					<div class="no_list" v-if="banks.length == 0">
						<my-img srcText="mycenter/icon1.png" styleText="width:365px;height:165px;" />
						<div class="no_list_div"><span>暫無綁定銀行卡</span> <br></div>
						<div class="no_list_div"><button class="Button333" style="width: 150px; height: 50px;" @click="addBankClick">添加銀行卡</button></div>
					</div>
					
					<div class="bank_list">
						<div class="bank_item_box" v-for="(item, index) in banks" :key="index">
							<div class="bank_item" >
								<!-- <img /> -->
								<p>{{item.bank_name}}  <span style="margin-left: 15px;">
									{{dealCard(item.bank_card)}}
								</span></p>
							</div>
							<div class="edit" @click="editBankClick(item)"><span><a-icon type="form" /> </span>
							編輯銀行卡</div>
						</div>
					</div>
				</div>
				
				
				<div v-if="tabidx == 2">
					<div class="no_list" v-if="usdts.length == 0">
						<my-img srcText="mycenter/icon1.png" styleText="width:365px;height:165px;" />
						<div class="no_list_div"><span>暫無綁定虛擬幣地址</span> <br></div>
						<div class="no_list_div"><button class="Button333" style="width: 150px; height: 50px;" @click="addBankXnClick">添加虛擬幣地址</button></div>
					</div>
					
					<div class="bank_list">
						<div class="bank_item_box" v-for="(item, index) in usdts" :key="index">
							<div class="bank_item" >
								<!-- <img /> -->
								<p>{{item.pact == 'erc' ? 'ERC' : 'TRC'}} <span style="margin-left: 15px;">
									{{dealUsdt(item.usdt_address)}}
								</span></p>
							</div>
							<div class="edit" @click="editBankXnClick(item)"><span><a-icon type="form" /> </span>
							編輯虛擬幣</div>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
		
		
		
		
		<add-bank ref="addBank" @getNewList="getList" />
		<add-bank-xn ref="addBankXn" @getNewList="getList"/>
		<edit-bank ref="editBank" @getNewList="getList"/>
		<edit-bank-xn ref="editBankXn" @getNewList="getList"/>
		<bind-phone ref="phone" />
	</div>
</template>

<script>
	import AddBank from '@/components/addBank/index.vue'
	import AddBankXn from '@/components/addBankXn/index.vue'
	import EditBank from '@/components/editBank/index.vue'
	import EditBankXn from '@/components/editBankXn/index.vue'
	import http from '@/http.js'
	import {
		mapGetters
	} from 'vuex';
	import BindPhone from '@/components/BindPhone.vue'
	
	export default {
		name: "Home",
		components: {
			AddBank,
			AddBankXn,
			EditBank,
			EditBankXn,
			BindPhone
		},
		data() {
			return {
				tabidx: 1,
				banks: [],
				usdts: [],
				
				spinning: true
			};
		},
		computed: {
			...mapGetters(['userInfo', 'config']),
		},
		mounted() {
			this.getList()
		},
		methods: {
			dealCard (account) {
				if (!account) {
					return ''
				}
				return "**** **** **** "+ account.substring(account.length-4);
			},
			addBankClick () {
				if (!this.userInfo.phone) {
					let self = this
					this.$confirm({
						title: '手機號未綁定，請先綁定手機號！',
						cancelText: '取消',
						okText: '綁定手機號',
						onOk() {
							self.$refs.phone.showModel()
						},
						onCancel() {
							
						},
					});
				} else {
					this.$refs.addBank.showModel(this.userInfo.phone)
				}
			},
			addBankXnClick () {
				if (!this.userInfo.phone) {
					let self = this
					this.$confirm({
						title: '手機號未綁定，請先綁定手機號！',
						cancelText: '取消',
						okText: '綁定手機號',
						onOk() {
							self.$refs.phone.showModel()
						},
						onCancel() {
							
						},
					});
				} else {
					this.$refs.addBankXn.showModel(this.userInfo.phone)
				}
			},
			editBankClick (data) {
				this.$refs.editBank.showModel(this.userInfo.phone, data.id, data)
			},
			editBankXnClick (data) {
				this.$refs.editBankXn.showModel(this.userInfo.phone, data.id, data)
			},
			dealUsdt(account) {
				let usdt = account.substring(0,6)
				for(let i = 0; i < 10; i++) {
					usdt += '*'
				}
				usdt += account.substring(account.length - 6);
				return usdt
			},
			getList () {
				http({
					method: 'post',
					url: '/member/drawingInfoList',
					data: {
						
					}
				}).then((res) => {
					if (res.code == 200) {
						if (res.data.bank.bank_card) {
							this.banks = [res.data.bank]
						} else {
							this.banks = []
						}
						
						if (res.data.usdt.usdt_address) {
							this.usdts = [res.data.usdt]
						} else {
							this.usdts = []
						}
					} else {
						this.$message.error(res.message);
					}
					this.spinning = false
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.bank_item_box {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}
	.bank_item_box .edit {
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.bank_item_box .edit span{
		    background: #3678fe;
		    border-radius: 100px;
		    width: 24px;
		    height: 24px;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    color: #fff;
		    font-size: 12px;
			margin-right: 5px;
	}
	.bank_list {
		padding-top: 30px;
	}
	.bank_item {
		margin-right: 20px;
		    position: relative;
		    padding: 0 20px!important;
		    text-align: left!important;
		    width: 440px;
		    height: 60px;
		    border-radius: 5px;
		    background: #fff;
		    border: 1px solid #d8dce7;
			display: flex;
			align-items: center;
			img {
				width: 18px;
				height: 18px;
				background-color: red;
				margin-right: 9px;
			}
			p {
				    line-height: 18px;
				    margin-left: 1px;
				    font-size: 18px;
				    font-family: PingFang SC;
				    height: 100%;
				    display: -webkit-box;
				    display: -webkit-flex;
				    display: -ms-flexbox;
				    display: flex;
				    -webkit-box-align: center;
				    -webkit-align-items: center;
				    -ms-flex-align: center;
				    align-items: center;
				    color: #383b43;
					font-weight: 700;
			}
	}
	.tab {
		margin-top: -20px;
		.fire {
			position: absolute;
			top: -3px;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			width: 85px;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			left: -12px;
			font-weight: normal !important;
	
			p {
				font-size: 12px;
				-webkit-transform: scale(0.83);
				transform: scale(0.83);
				color: red;
				line-height: 16px;
				text-align: left;
				font-weight: normal !important;
			}
		}
	
		.tabs {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			position: relative;
			-webkit-box-pack: start;
			-webkit-justify-content: flex-start;
			-ms-flex-pack: start;
			justify-content: flex-start;
			margin-top: 10px;
	
			&:after {
				background: #d8dce7;
				content: "";
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 1px;
			}
	
			li {
				height: 54px;
				line-height: 54px;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
				cursor: pointer;
				display: block;
				text-align: center;
				position: relative;
				margin-right: 30px;
	
				&.active {
					font-weight: bold;
	
					&:after {
						content: "";
						display: block;
						position: absolute;
						left: 0;
						bottom: 0;
						height: 1px;
						z-index: 1;
						width: 100%;
						background: #4080ff;
					}
				}
			}
		}
	}
	
	.no_list {
		    width: 100%;
		    height: 350px;
		    padding-top: 100px;
		    text-align: center;
	}
	.no_list  img {
		margin-bottom: 30px;
	}
	.no_list_div {
		margin-bottom: 30px;
	}
	.no_list_div span {
		    color: #94a6b7;
	}
	.no_list_div .Button333 {
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;
		cursor: pointer;
	}
	.no_list_div .Button333:hover {
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
		background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
	}
</style>
