<template>
	<div class="home">

		<div class="app_box">
			<img class="title_img" src="/assets/app/title.png" alt="">
			<div class="hqClient__gameBg__1zRYA hqClient__allGame__BTPD2">
				<div class="hqClient__gameBox__TplL5">
					<img
						src="/assets/app/all_game.png"
						width="649px" height="515px" style="">
					
					<div class="hqClient__halfPartNew__EwZm5">
						
						<!-- <img
							src="https://senbackkg.kz4702q.com/main-consumer-web/assets-oss/huanqiu/images/client/title_all.27a7f14fe95b21d66fe947ce3bf0690e.webp"
							width="191px" height="30px" style=""> -->
							
						<p class="hqClient__textNew__2KaIm">
							下載環球全站APP，這裏有最刺激的體育、真人、電競、棋牌、彩票、電子等各類高端遊戲。暢快淋漓的下註體驗，帶給您無與倫比的娛樂盛宴，盡在掌中體驗，掃碼下載即刻擁有！</p>
						<div class="hqClient__bottomNew__2S5XC">
							<div class="hqClient__clientBorderNew__31A8n"
								style="width: 217px; height: 260px;">
								<div class="hqClient__codeBoxNew__3Za-3">
									<img style="width:130px;height:130px" :src="config.app_img" />
									</div>
								<div class="hqClient__appNew__2MUAC">掃碼下載APP</div>
								<div class="hqClient__downloadappNew__1rC8V">支持iOS&amp;Android全部移動設備</div>
							</div>
							<div class="hqClient__clientBorderRightNew__3s2p6"
								style="width: 217px; height: 260px;">
								<div class="hqClient__imageNew__1JSmp"><img
										src="/assets/app/h5@2x.1a754566bc3a6df2a3ab502d65e9eb4d.png"
										width="160px" style=""></div>
								<div class="hqClient__urlNew__qtRjq">H5端直接訪問</div>
								<div class="hqClient__urlsNew__1HpOw">無需下載直接訪問</div>
								<!-- <a
									class="hqClient__textUrlNew__3IkAt" href="https://www.hqty983.com:34225"
									target="_blank">https://www.hqty983.com:34225</a> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import http from '../../http.js'
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: "Home",
		data() {
			return {

			};
		},
		computed: {
			...mapGetters(['config']),
		},
		mounted() {

		},
		methods: {

		}
	};
</script>

<style lang="less" scoped>
	.app_box {
		// background-image: url(../../assets/app/bg.png);
		background-image: url(/assets/app/bg.png);
		padding-top: 108px;
		padding-bottom: 60px;
		.title_img {
			margin:  auto;
			width: 802px;
			display: block;
		}
	}
	.hqClient__gameBg__1zRYA {
	    width: 100%;
	    min-width: 1200px;
	    background-size: cover;
	    text-align: center;
	    position: relative;
	}
	.hqClient__gameBox__TplL5 {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    width: 1200px;
	    height: inherit;
	    margin: 0 auto;
	    position: relative;
	}
	.hqClient__gameBox__TplL5 .hqClient__halfPartNew__EwZm5 {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -webkit-flex-direction: column;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -ms-flex-align: center;
	    align-items: center;
	    padding-top: 70px;
	    width: 551px;
	    position: relative;
	    left: 34px;
	}
	.hqClient__gameBox__TplL5 .hqClient__halfPartNew__EwZm5 .hqClient__textNew__2KaIm {
	    text-indent: 6px;
	    color: #333;
	    line-height: 1.75;
	    width: 437px;
	    font-size: 16px;
	    text-align: left;
	    padding-top: 54px;
	}
	.hqClient__gameBox__TplL5 .hqClient__halfPartNew__EwZm5 .hqClient__bottomNew__2S5XC {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    padding-top: 15px;
	}
	.hqClient__gameBox__TplL5 .hqClient__halfPartNew__EwZm5 .hqClient__bottomNew__2S5XC .hqClient__clientBorderNew__31A8n {
	    margin: 0;
	    background-size: 100% 100%;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -webkit-flex-direction: column;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -ms-flex-align: center;
	    align-items: center;
		// background-image: url(../../assets/app/border.png);
		background-image: url(/assets/app/border.png);
	}
	.hqClient__clientBorderRightNew__3s2p6 {
		margin: 0;
		background-size: 100% 100%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		// background-image: url(../../assets/app/border.png);
		background-image: url(/assets/app/border.png);
	}
	.hqClient__gameBox__TplL5 .hqClient__halfPartNew__EwZm5 .hqClient__bottomNew__2S5XC .hqClient__clientBorderNew__31A8n .hqClient__codeBoxNew__3Za-3 {
	    padding-top: 28px;
	}
	.hqClient__gameBox__TplL5 .hqClient__halfPartNew__EwZm5 .hqClient__bottomNew__2S5XC .hqClient__clientBorderNew__31A8n .hqClient__appNew__2MUAC {
	    padding-top: 10px;
	    font-size: 14px;
	    color: #454c67;
	}
	.hqClient__gameBox__TplL5 .hqClient__halfPartNew__EwZm5 .hqClient__bottomNew__2S5XC .hqClient__clientBorderNew__31A8n .hqClient__downloadappNew__1rC8V {
	    width: 110px;
	    padding-top: 5px;
	    line-height: 1.3;
	    font-size: 12px;
	    color: #95a0ca;
	}
	.hqClient__gameBox__TplL5 .hqClient__halfPartNew__EwZm5 .hqClient__bottomNew__2S5XC .hqClient__clientBorderRightNew__3s2p6 .hqClient__urlNew__qtRjq {
	    position: relative;
	    top: -18px;
	    padding-top: 10px;
	    font-size: 14px;
	    color: #454c67;
	}
	.hqClient__gameBox__TplL5 .hqClient__halfPartNew__EwZm5 .hqClient__bottomNew__2S5XC .hqClient__clientBorderRightNew__3s2p6 .hqClient__urlsNew__1HpOw {
	    position: relative;
	    top: -17px;
	    padding-top: 5px;
	    line-height: 1.3;
	    font-size: 12px;
	    color: #95a0ca;
	}
	.hqClient__gameBox__TplL5 .hqClient__halfPartNew__EwZm5 .hqClient__bottomNew__2S5XC .hqClient__clientBorderRightNew__3s2p6 .hqClient__textUrlNew__3IkAt {
	    word-break: break-all;
	    line-height: 1.2;
	    width: 123px;
	    position: relative;
	    top: -14px;
	    font-size: 10px;
	    color: #3597ff;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    -webkit-line-clamp: 2;
	    display: -webkit-box;
	    -webkit-box-orient: vertical;
	}
</style>
