<template>
	<div class="navCard_secondNavbarView " :class="{'navCard_showNavView': showNav}">
		<div class="navCard_secondNavView">
			<div class="egame_centerView">
				<div class="egame_itemView game_maintainBy" v-if="getGame('OBBY').on_line == 0" :class="{'game_maintain': getGame('OBBY').maintain == 1}" @click="link('OBBY')">
					<div class="egame_topGroup">
						<div class="logoBg_logoBgView">
							<my-img srcText="header/header-nav-7/icon1.png" classText="egame_logoIcon" />
						</div>
						<div class="egame_infoView">
							<div class="egame_venueCn__1ToxK">{{$t('main.game.by.ob.name')}}</div>
							<div class="egame_venueEn__3QRw0">SLOT GAMES</div>
						</div>
					</div>
					<div class="image_imageContainer image_cover egame_imgIcon" style="cursor: inherit;">
						<div class="ddes1 ddes" srcText="header/header-nav-6/icon2.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; filter: none; background-image: none;">

						</div>
					</div>
				</div>
				<div class="egame_itemView game_maintainBy" v-if="getGame('JDB').on_line == 0" :class="{'game_maintain': getGame('JDB').maintain == 1}" @click="link('JDB')">
					<div class="egame_topGroup">
						<div class="logoBg_logoBgView">
							<my-img srcText="header/header-nav-7/icon3.png" classText="egame_logoIcon" />
						</div>
						<div class="egame_infoView">
							<div class="egame_venueCn__1ToxK">JDB捕鱼</div>
							<div class="egame_venueEn__3QRw0">SLOT GAMES</div>
						</div>
					</div>
					<div class="image_imageContainer image_cover egame_imgIcon" style="cursor: inherit;">
						<div class="ddes2 ddes" srcText="header/header-nav-6/icon2.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; filter: none; background-image: none;">

						</div>
					</div>
				</div>
				<div class="egame_itemView game_maintainBy" v-if="getGame('HJ').on_line == 0" :class="{'game_maintain': getGame('HJ').maintain == 1}" @click="link('OBBY')">
					<div class="egame_topGroup">
						<div class="logoBg_logoBgView">
							<my-img srcText="header/header-nav-7/icon5.png" classText="egame_logoIcon" />
						</div>
						<div class="egame_infoView">
							<div class="egame_venueCn__1ToxK">皇金渔场</div>
							<div class="egame_venueEn__3QRw0">SLOT GAMES</div>
						</div>
					</div>
					<div class="image_imageContainer image_cover egame_imgIcon" style="cursor: inherit;">
						<div class="ddes3 ddes" srcText="header/header-nav-6/icon2.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; filter: none; background-image: none;">

						</div>
					</div>
				</div>
				<div class="egame_itemView game_maintainBy" v-if="getGame('MW').on_line == 0" :class="{'game_maintain': getGame('MW').maintain == 1}" @click="link('MW')">
					<div class="egame_topGroup">
						<div class="logoBg_logoBgView">
							<my-img srcText="header/header-nav-7/icon7.png" classText="egame_logoIcon" />
						</div>
						<div class="egame_infoView">
							<div class="egame_venueCn__1ToxK">{{$t('main.game.by.mw.name')}}</div>
							<div class="egame_venueEn__3QRw0">SLOT GAMES</div>
						</div>
					</div>
					<div class="image_imageContainer image_cover egame_imgIcon" style="cursor: inherit;">
						<div class="ddes4 ddes" srcText="header/header-nav-6/icon2.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; filter: none; background-image: none;">

						</div>
					</div>
				</div>
				<div class="egame_itemView game_maintainBy" v-if="getGame('JOKER').on_line == 0" :class="{'game_maintain': getGame('JOKER').maintain == 1}" @click="link('OBBY')">
					<div class="egame_topGroup">
						<div class="logoBg_logoBgView">
							<my-img srcText="header/header-nav-7/icon9.png" classText="egame_logoIcon" />
						</div>
						<div class="egame_infoView">
							<div class="egame_venueCn__1ToxK">JOKER捕鱼</div>
							<div class="egame_venueEn__3QRw0">SLOT GAMES</div>
						</div>
					</div>
					<div class="image_imageContainer image_cover egame_imgIcon" style="cursor: inherit;">
						<div class="ddes5 ddes" srcText="header/header-nav-6/icon2.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; filter: none; background-image: none;">

						</div>
					</div>
				</div>
				<div class="egame_itemView game_maintainBy" v-if="getGame('BBIN').on_line == 0" :class="{'game_maintain': getGame('BBIN').maintain == 1}" @click="link('OBBY')">
					<div class="egame_topGroup">
						<div class="logoBg_logoBgView">
							<my-img srcText="header/header-nav-7/icon11.png" classText="egame_logoIcon" />
						</div>
						<div class="egame_infoView">
							<div class="egame_venueCn__1ToxK">BBIN捕鱼达人</div>
							<div class="egame_venueEn__3QRw0">SLOT GAMES</div>
						</div>
					</div>
					<div class="image_imageContainer image_cover egame_imgIcon" style="cursor: inherit;">
						<div class="ddes6 ddes" srcText="header/header-nav-6/icon2.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; filter: none; background-image: none;">

						</div>
					</div>
				</div>
				<div class="egame_itemView game_maintainBy" v-if="getGame('BBIN').on_line == 0" :class="{'game_maintain': getGame('BBIN').maintain == 1}" @click="link('OBBY')">
					<div class="egame_topGroup">
						<div class="logoBg_logoBgView">
							<my-img srcText="header/header-nav-7/icon13.png" classText="egame_logoIcon" />
						</div>
						<div class="egame_infoView">
							<div class="egame_venueCn__1ToxK">BBIN捕鱼大师</div>
							<div class="egame_venueEn__3QRw0">SLOT GAMES</div>
						</div>
					</div>
					<div class="image_imageContainer image_cover egame_imgIcon" style="cursor: inherit;">
						<div class="ddes7 ddes" srcText="header/header-nav-6/icon2.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; filter: none; background-image: none;">

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	export default {
		props: ['showNav', 'NewArrs'],
		data() {
			return {
				
			}
		},
		methods: {
			getGame (type) {
				let data = {}
				this.NewArrs.forEach((v) => {
					if (v.plat_type == type) {
						data = v
					}
				})
				return data
			},
			link(type) {
				let self = this
				if (!localStorage.getItem('access_token')) {
					let wxts = this.$t('main.login_ts')
					this.$confirm({
						title: this.$t('main.wxts'),
						content: h => < div > {wxts} </div>,
						okText: this.$t('main.okText'),
						cancelText: this.$t('main.cancelText'),
						onOk() {
							self.$router.push({
							  path: "/login",
							});
						},
						onCancel() {
						},
						class: 'testbo',
					});
				} else {
					let data = {
						gameData: {}
					}
					this.NewArrs.forEach((v) => {
						if (v.plat_type == type) {
							data.gameData = v
						}
					})
					let href = window.location.origin +
						`/game?plat_type=${data.gameData.plat_type}&game_type=${data.gameData.game_type}&game_code=${data.gameData.game_code}`;
					// this.$store.dispatch('user/getNewGidBalanceTime')
					// window.open(href, "_blank");
					this.$store.dispatch('user/linkGamePage', {
						href: href,
						platType: data.gameData.plat_type,
						gameName: data.gameData.name
					})
				}
			}
		}
	};
</script>

<style scoped lang="less">
	.ddes {
		width: 126px;
		height: 160px;
		// background-image: url(../../../assets/header/header-nav-6/sprite4.png);
		background-image: url(/assets/header/header-nav-6/sprite4.png);
	}

	.ddes1 {
		background-size: 1078px 160px;
		background-repeat: no-repeat;
		background-position: 0 -10px;
	}

	.ddes2 {
		background-size: 1078px 160px;
		background-repeat: no-repeat;
		background-position: -696px -5px;
		// background-image: url(../../../assets/header/header-nav-6/buyu.png);
		background-image: url(/assets/header/header-nav-6/buyu.png);

	}

	.ddes3 {
		background-size: 1078px 160px;
		background-repeat: no-repeat;
		background-position: 0 -10px;
		// background-image: url(../../../assets/header/header-nav-6/buyu.png);
		background-image: url(/assets/header/header-nav-6/buyu.png);
	}

	.ddes4 {
		background-size: 1078px 160px;
		background-repeat: no-repeat;
		background-position: -952px -10px;
		// background-image: url(../../../assets/header/header-nav-6/buyu.png);
		background-image: url(/assets/header/header-nav-6/buyu.png);
	}

	.ddes5 {
		background-size: 1078px 160px;
		background-repeat: no-repeat;
		background-position: -568px -10px;
		// background-image: url(../../../assets/header/header-nav-6/buyu.png);
		background-image: url(/assets/header/header-nav-6/buyu.png);
	}

	.ddes6 {
		background-size: 1078px 160px;
		background-repeat: no-repeat;
		background-position: -824px -10px;
		// background-image: url(../../../assets/header/header-nav-6/buyu.png);
		background-image: url(/assets/header/header-nav-6/buyu.png);
	}

	.ddes7 {
		background-size: 1078px 160px;
		background-repeat: no-repeat;
		background-position: -128px -10px;
		// background-image: url(../../../assets/header/header-nav-6/buyu.png);
		background-image: url(/assets/header/header-nav-6/buyu.png);
	}

	.image_imageContainer {
		position: relative;
	}

	.navCard_secondNavbarView {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 390px;
		padding-top: 90px;
		background-color: #fff;
		overflow: hidden;
		-webkit-transition: opacity .8s ease, -webkit-transform .5s ease;
		transition: opacity .8s ease, -webkit-transform .5s ease;
		transition: transform .5s ease, opacity .8s ease;
		transition: transform .5s ease, opacity .8s ease, -webkit-transform .5s ease;
		z-index: 80;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		opacity: 0;
		// background-image: url(../../../assets/header/header-nav-1/icon10.png);
		background-image: url(/assets/header/header-nav-1/icon10.png);
		background-size: 100% 390px;
		background-position: top;
	}

	.navCard_showNavView {
		transform: translateY(0);
		opacity: 1;
		box-shadow: 0 6px 19px 0 hsla(0, 0%, 40%, .27);
	}

	.navCard_secondNavbarView.navCard_showNavView {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
		box-shadow: 0 6px 19px 0 hsla(0, 0%, 40%, .27);
	}

	.navCard_secondNavbarView .navCard_secondNavView {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		overflow: hidden;
	}

	.egame_centerView {
		width: 1050px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		position: relative;
		width: 100%;
	}

	.egame_centerView .egame_itemView {
		width: 210px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center;
		cursor: pointer;
		-webkit-transition: all .2s ease;
		transition: all .2s ease
	}

	.egame_centerView .egame_itemView .egame_officialIcon {
		position: absolute;
		top: 29px;
		left: 70px
	}

	.egame_centerView .egame_itemView .egame_imgIcon {
		width: 126px;
		height: 160px;
		margin-top: 6px;
		-webkit-transition: all .3s;
		transition: all .3s
	}

	.egame_centerView .egame_itemView:hover .egame_imgIcon {
		-webkit-transform: scale(1.1);
		transform: scale(1.1)
	}

	.egame_centerView .egame_itemView .egame_topGroup {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		margin-top: 44px
	}

	.egame_centerView .egame_itemView .egame_topGroup .egame_infoView {
		width: 100%
	}

	.egame_centerView .egame_itemView .egame_topGroup .egame_infoView .egame_venueCn__1ToxK {
		position: relative;
		line-height: 21px;
		margin-bottom: 2px;
		font-size: 16px;
		font-weight: 600;
		color: #6c7ba8
	}

	.egame_centerView .egame_itemView .egame_topGroup .egame_infoView .egame_venueEn__3QRw0 {
		line-height: 16px;
		font-size: 12px;
		color: #6c7ba8;
		white-space: nowrap
	}

	.egame_centerView .egame_itemView .egame_topGroup .egame_logoIcon {
		height: 44px;
		width: auto
	}

	.egame_rightView {
		width: 120px
	}

	.egame_rightView .egame_topIcon {
		width: 103px;
		height: auto;
		margin-top: 66px
	}

	.egame_rightView p {
		font-size: 14px;
		color: #67718f
	}

	.egame_rightView .egame_bottomIcon {
		width: 112px;
		height: auto
	}

	.egame_maskClass__11bmc {
		width: 147px !important
	}


	.judgeVenueStatus_isBanned__2cpSM {
		position: relative
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz h2 {
		color: #fff !important;
		font-size: 30px !important;
		line-height: 42px;
		font-family: PingFangSC;
		margin-bottom: 4px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO {
		position: absolute;
		width: 160px;
		height: 180px;
		border-radius: 8px;
		text-align: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-flow: column;
		flex-flow: column;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		z-index: 2
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE:after,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb:after,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W:after,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF:after,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO:after {
		content: "";
		background-color: #6c7ba8;
		width: 100%;
		height: 100%;
		opacity: .7;
		position: absolute;
		z-index: -1;
		border-radius: 8px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE h2,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb h2,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W h2,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF h2,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO h2 {
		color: #fff !important;
		font-size: 30px !important;
		line-height: 42px;
		font-family: PingFangSC;
		margin-bottom: 4px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE div,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb div,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W div,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF div,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO div {
		color: #fff !important
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE p,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb p,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W p,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF p,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO p {
		margin: 0 auto;
		color: #fff !important
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE .judgeVenueStatus_date__21MY9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb .judgeVenueStatus_date__21MY9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W .judgeVenueStatus_date__21MY9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF .judgeVenueStatus_date__21MY9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO .judgeVenueStatus_date__21MY9 {
		font-size: 14px;
		line-height: 16px;
		font-family: DINPro
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE .judgeVenueStatus_time__W_4q9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb .judgeVenueStatus_time__W_4q9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W .judgeVenueStatus_time__W_4q9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF .judgeVenueStatus_time__W_4q9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO .judgeVenueStatus_time__W_4q9 {
		font-size: 24px;
		line-height: 30px;
		font-weight: 700;
		font-family: DINPro
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE {
		width: 580px;
		height: 180px;
		top: 60px;
		left: 155px;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		padding: 30px 0
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE>section {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
		margin-top: 15px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE>section p {
		line-height: 20px;
		font-size: 18px;
		margin-left: 12px;
		margin-right: 12px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE .judgeVenueStatus_time__W_4q9 {
		font-size: 20px;
		line-height: 24px;
		height: 24px;
		margin-top: 5px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W {
		width: 235px;
		height: 180px;
		top: 69px;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		padding: 30px 0
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W>section {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
		margin-top: 15px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W>section p {
		line-height: 46px;
		font-size: 18px;
		margin-left: 12px;
		margin-right: 12px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W .judgeVenueStatus_time__W_4q9 {
		font-size: 20px;
		line-height: 24px;
		height: 24px;
		margin-top: 5px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF {
		width: 162px;
		height: 204px;
		top: 48px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF>section p {
		line-height: 20px;
		font-size: 14px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF .judgeVenueStatus_time__W_4q9 {
		font-size: 20px;
		line-height: 24px;
		height: 24px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO {
		width: 270px;
		height: 180px;
		top: 60px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO>section {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
		margin-top: 15px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO>section p {
		line-height: 20px;
		font-size: 18px;
		margin-left: 12px;
		margin-right: 12px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO .judgeVenueStatus_time__W_4q9 {
		font-size: 20px;
		line-height: 24px;
		height: 24px;
		margin-top: 5px
	}

	.logoBg_logoBgView {
		width: 50px;
		height: 50px;
		-webkit-flex: none;
		flex: none;
		margin-right: 8px;
		border-radius: 10.9px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		box-shadow: 0 3px 7px 0 rgba(112, 146, 215, .14);
		background-size: contain;
		// background-image: url(../../../assets/header/header-nav-2/icon60.png)
		background-image: url(/assets/header/header-nav-2/icon60.png)
	}

	.officialLogo_officialLogoView {
		width: 51px;
		height: 16px;
		background-size: contain;
		position: relative;
		// background-image: url(../../../assets/header/header-nav-2/icon15.png)
		background-image: url(/assets/header/header-nav-2/icon15.png)
	}

	.officialLogo_officialLogoView span {
		position: absolute;
		top: 50%;
		left: 23px;
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		color: #fff;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%)
	}
</style>
