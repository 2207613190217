<template>
	<div class="rightobbox">

		<div class="title-bo2">
			<div class="left">
				<p>轉賬</p>
				<span>场馆钱包和场馆钱包之间不可以互转</span>
			</div>
			<div class="right">
				<div class="tabsob">
					<div :class="{active: userInfo.is_auto == 1}" @click="selectType(1)">
						自動免轉
					</div>
					<div :class="{active: userInfo.is_auto == 0}" @click="selectType(0)">
						手動轉賬
					</div>
				</div>
			</div>
		</div>

		<div class="account">
			<div>
				<my-img srcText="mycenter/transfer/icon1.svg"/>
				<div>
					<div class="title">中心錢包</div>
					<div @click="getBalance"><span class="balance">{{balanceText}}</span> HKD<a class="refresh " :class="{'loading': loadingBalance}"></a></div>
				</div><button @click="oneTransferOut" class="Button Button3" style="width: 80px; height: 28px; border-radius: 14px; position: absolute; top: 15px; right: 18px; font-size: 14px;">{{oneTransferOutText}}</button>
			</div>
			<div>
				<my-img srcText="mycenter/transfer/icon2.svg"/>
				
				<div>
					<div class="title">遊戲錢包</div>
					<div ><span class="balance">{{gidBalanceText}}</span> HKD<a @click="getGidBalance('list')" :class="{'loading': loadingGidBalance}" data-analytics="button" data-label="转账-刷新" class="refresh "></a></div>
				</div>
			</div>
		</div>

		<div v-if="userInfo.is_auto == 0">
			<div class="tie">
				轉賬方向
			</div>
			<transfer-select @selectStartData="selectStartData" v-if="config.plat_type" ref="transferSelect" :list="config.plat_type" :key="config.plat_type.length" />
			<div class="tie">
				轉賬金額
			</div>
			<div class="inputbox">
				<div class="formControl inputnoright inputhe60">
					
					<a-input-number :key="moneyAll" v-model="money" autocomplete="off" class="ainputnumber" style="width: 440px;height: 60px;" :min="0" :max="100000000" placeholder="請輸入轉賬金額" size="large" />
					<!-- <input type="number" v- placeholder="请输入转账金额" class="Input" style="width: 440px; height: 60px; font-weight: 600; padding-right: 88px;"> -->
					<a class="getMaxMoney" @click="setAllMoney">最大金額</a>
				</div>
				<button class="Button Button333 " @click="gameTransfer" style="margin-bottom: 15px;">{{transferBtnText}}</button>
			</div>
		</div>
		<div>
			<div class="tie">
				場館錢包
			</div>
			<a-spin tip="正在獲取場館錢包..." :spinning="listLoading" style="min-height: 300px;">
				<ul  class="walletlist clearfix isShowMore ">
					<li class=" null null" v-for="(item, index) in apis" :key="index">
						<div class="top">
							<div><span class="clientName">{{item.api_title}}</span></div>
							<div class="actionBtnBox">
								<a-icon type="sync" @click="getNewGameBalance(item)" :spin="item.loading" />
							</div>
						</div>
						<div class="money"><i>{{item.money}}</i></div>
					</li>
				</ul>
				
				<div class="no_list" v-if="apis.length == 0">
					<my-img srcText="mycenter/icon1.png" styleText="width:365px;height:165px;" />
					<div class="no_list_div"><span>暫無場館列表</span> <br></div>
				</div>
			</a-spin>
		</div>
		
		<div class="loading-app" v-if="showLoading">
			<div class="loading-box">
				<img src="/assets/loading.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	import TransferSelect from './TransferSelect.vue'
	export default {
		name: "Home",
		
		data() {
			return {
				loadingBalance: false,
				apis: [],
				
				listLoading: false,
				
				loadingGidBalance: false,
				
				oneTransferOutText: '一鍵回收',
				
				money: null,
				moneyAll: 100000,
				showLoading: false,
				
				transferBtnText: '立即轉賬'
				
			};
		},
		components: {
			TransferSelect
		},
		computed: {
			...mapGetters(['balance', 'gidBalance', 'userInfo', 'config']),
			
			balanceText () {
				if (this.balance) {
					if (typeof this.balance === 'number') {
						return this.balance.toFixed(2)
					} else {
						return this.balance
					}
				} else {
					return '0.00'
				}
			},
			gidBalanceText () {
				if (this.gidBalance) {
					if (typeof this.gidBalance === 'number') {
						return this.gidBalance.toFixed(2)
					} else {
						return this.gidBalance
					}
				} else {
					return '0.00'
				}
			}
		},
		mounted() {
			this.getGidBalance()
			this.apiList()
			
			this.$nextTick(() => {
				setTimeout(() => {
					this.moneyAll = this.balance
				}, 1000)
			})
		},
		methods: {
			...mapActions('user', ['intervalGetBalance', 'setBalance', 'clearIntervalGetBalance', 'getNewGidBalance', 'getUserInfo']),
			
			// 切换转账貌似
			selectType (index) {
				if (this.userInfo.is_auto === 1 && index === 0) {
					this.$confirm({
					        title: '溫馨提示',
					        content: h => <div>關閉自動免轉後，您將需要手動操作各個遊戲錢包的金額，確定關閉自動免轉？</div>,
							okText: '確定',
							cancelText: '取消',
					        onOk: () => {
					          this.setType(0)
					        },
					        onCancel() {
					          
					        },
					});
				} else if (this.userInfo.is_auto === 0 && index === 1){
					this.setType(1)
				}
			},
			
			selectStartData () {
				if (!this.$refs.transferSelect) {
					this.moneyAll = 100000
					return
				}
				let apiName = this.$refs.transferSelect.startData.api_name
				if (apiName === 'center') {
					this.moneyAll = this.balance
				} else {
					this.getApiMoney(apiName, (money) => {
						this.moneyAll = money ? parseFloat(money) : 0
						// if (this.moneyAll === 0 || !this.moneyAll) {
						// 	this.money = null
						// }
					})
				}
			},
			
			setAllMoney () {
				let apiName = this.$refs.transferSelect.startData.api_name
				if (apiName === 'center') {
					this.moneyAll = this.balance
					this.money = this.moneyAll
				} else {
					this.money = this.moneyAll
				}
			},
			
			gameTransfer () {
				if (this.transferBtnText === '轉賬中...') {
					return
				}
				if (this.money === 0 || !this.money) {
					this.$message.error('請輸入轉入金額');
					return
				}
				if (this.money > this.moneyAll) {
					this.$message.error('當前余額不足！');
					return
				}
				this.showLoading = true
				this.transferBtnText = '轉賬中...'
				http({
					method: 'post',
					url: '/member/gameTransfer',
					data: {
						in_account: this.$refs.transferSelect.endData.api_name,
						out_account: this.$refs.transferSelect.startData.api_name,
						money: this.money
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getNewBalance()
						this.getGidBalance('list')
						setTimeout(() => {
							this.showLoading = false
							this.$message.success('操作成功');
							this.money = null
							this.selectStartData()
							this.transferBtnText = '立即轉賬'
						}, 1000)
					} else {
						this.$message.error(res.message);
						this.showLoading = false
						this.transferBtnText = '立即轉賬'
					}
				})
			},
			
			setType (type) {
				this.showLoading = true
				http({
					method: 'post',
					url: '/member/switchWallet',
					data: {
						is_auto: type
					}
				}).then((res) => {
					if (res.code == 200) {
						this.money = ''
						this.getUserInfo()
						setTimeout(() => {
							this.showLoading = false
							this.$message.success('操作成功');
						}, 500)
					} else {
						this.$message.error(res.message);
						this.showLoading = false
					}
				})
			},
			
			// 获取最新余额
			getNewBalance () {
				this.setBalance()
				this.intervalGetBalance()
			},
			oneTransferOut () {
				if (this.oneTransferOutText === '回收中...') {
					return
				}
				this.listLoading = true
				this.loadingBalance = true
				this.oneTransferOutText = '回收中...'
				http({
					method: 'post',
					url: '/member/oneTransferOut',
					data: {
						
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getGidBalance()
						this.getNewBalance()
						this.apiList()
						setTimeout(() => {
							this.$message.success('余額回收成功！');
						}, 500)
						if (this.userInfo.is_auto === 0) {
							this.money = null
							this.selectStartData()
						}
					} else {
						this.$message.error(res.message);
					}
					setTimeout(() => {
						this.loadingBalance = false
					}, 2000)
					setTimeout(() => {
						this.oneTransferOutText = '一鍵回收'
					}, 500)
				})
			},
			getBalance () {
				this.loadingBalance = true
				this.getNewBalance()
				setTimeout(() => {
					this.loadingBalance = false
				}, 2000)
			},
			getGidBalance (type) {
				if (this.loadingGidBalance === true) {
					return
				}
				this.loadingGidBalance = true
				this.getNewGidBalance()
				// 循环请求
				if (type === 'list') {
					this.apis.forEach((data) => {
						this.getNewGameBalance(data)
					})
				}
				setTimeout(() => {
					this.loadingGidBalance = false
				}, 2000)
			},
			apiList () {
				this.listLoading = true
				http({
					method: 'post',
					url: '/member/apiList',
					data: {
				
					}
				}).then((res) => {
					if (res.code == 200) {
						this.apis = res.data.map((v) => {
							return {
								...v,
								loading: false
							}
						})
						this.listLoading = false
					} else {
						this.$message.error(res.message);
					}
				})
			},
			getNewGameBalance (data) {
				data.loading = true
				http({
					method: 'post',
					url: '/member/gameBalance',
					data: {
						plat_type: data.api_name
					}
				}).then((res) => {
					if (res.code == 200) {
						data.loading = false
						data.money = res.data
					} else {
						this.$message.error(res.message);
					}
				})
			},
			getApiMoney (apiName, callback) {
				http({
					method: 'post',
					url: '/member/gameBalance',
					data: {
						plat_type: apiName
					}
				}).then((res) => {
					callback && callback(res.data)
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	
	.loading-app {
		background: rgba(0, 0, 0, .5);
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1300;
		overflow: hidden;
	}
	
	.loading-app div {
		height: 96px;
		width: 96px;
		position: relative;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	
	.loading-app div img {
		height: 96px;
		width: 96px;
		display: block;
	}
	
	.no_list {
		    width: 100%;
		    height: 350px;
		    padding-top: 100px;
		    text-align: center;
	}
	.no_list  img {
		margin-bottom: 30px;
	}
	.no_list_div {
		margin-bottom: 30px;
	}
	.no_list_div span {
		    color: #94a6b7;
	}
	.no_list_div .Button333 {
		border: none;
		
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;
		cursor: pointer;
	}
	.no_list_div .Button333:hover {
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
		background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
	}
	
	.Button333 {
		width: 440px;
		cursor: pointer;
		height: 60px;
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		    font-size: 16px;
		    border-radius: 6px;
			    margin-top: 44px;
			&:hover {
				    background-image: -webkit-gradient(linear,left top, right top,color-stop(1%, #4c87fe),to(#3678fe));
				    background-image: -webkit-linear-gradient(left,#4c87fe 1%,#3678fe);
				    background-image: linear-gradient(to right,#4c87fe 1%,#3678fe);
				    -webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
				    box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			}
	}
	
	
	.inputbox {
		border-radius: 10px;
		margin-top: 20px;

		.formControl {
			position: relative;
			width: 440px;
			position: relative;
			height: 60px;

			input {
				border-radius: 6px;
				font-size: 12px;
				    border-radius: 6px;
				    padding: 0 20px;
				    border: solid 1px #d8dce7;
				    color: #333;
				    background-color: #fff;
					    border-radius: 6px;
						&:hover {
							    transition: all .3s ease;
							    border: solid 1px #3678fe;
						}
						&:focus {
						    -webkit-transition: background-color .2s ease,border .2s ease;
						    transition: background-color .2s ease,border .2s ease;
						    border: solid 1px #3678fe;
						    -webkit-box-shadow: 0 10px 20px 0 #dae8f2;
						    box-shadow: 0 10px 20px 0 #dae8f2;
						}
			}

			a {
				position: absolute;
				right: 20px;
				top: 50%;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
				font-size: 14px;
				color: #4b5e6f;
			}
		}
	}

	.account {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin-top: 30px;



		&>div {
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			padding: 20px;
			border-radius: 10px;
			width: 435px;
			height: 90px;
			border-radius: 6px;
			-webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
			box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
			border: solid 1px #f0f3f7;
			color: #4b5e6f;
			background-color: #fff;

			img {
				margin-right: 10px;
				width: 40px;
				height: 40px;
			}

			.Button {
				border: none;
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
				background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
				color: #fff;
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				transition: all .3s ease;
				cursor: pointer;

				&:hover {
					background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
					background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
					background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
					-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
					box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
				}
			}

			.title {
				margin-bottom: 10px;
				font-size: 14px;
				color: #4b5e6f;
			}


			.balance {
				font-size: 24px;
				color: #333;
				font-weight: bold;
				display: inline-block;
				max-width: 226px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				vertical-align: bottom;
			}

			.refresh {
				display: inline-block;
				margin-left: 10px;
				width: 16px;
				height: 16px;
				// background-image: url(../../../assets/mycenter/transfer/icon4.svg);
				background-image: url(/assets/mycenter/transfer/icon4.svg);
				background-repeat: no-repeat;
				border: none;
				vertical-align: middle;
				cursor: pointer;
				transition: all 2s;
				&.loading {
					transform: rotateZ(360deg);
				}
			}
		}
	}
	/*里面的代码可以根据自己需求去进行更改*/
	/* 设置滚动条的样式 */
	.walletlist::-webkit-scrollbar {
	width:2px;
	}
	
	.walletlist {
		// max-height: 220px;
		-webkit-transition: max-hieght .4s linear;
		transition: max-hieght .4s linear;
		-webkit-transition: max-height .4s;
		-o-transition: max-height .4s;
		// overflow: auto;
		// max-height: 1000px;

		.normalItem {
			position: relative;
			height: 120px;
			background-size: 210px 120px;

			.top {
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-webkit-justify-content: space-between;
				-ms-flex-pack: justify;
				justify-content: space-between;

				.clientName {
					color: #333;
					font-size: 14px;
				}
			}

			.actionBtnBox {
				a {
					position: absolute;
					top: 14px;
					right: 10px;
					width: 65px;
					height: 28px;
					text-align: center;
					line-height: 28px;
					border-radius: 6px;
					font-size: 12px;
					-webkit-transition: none;
					transition: none;
					color: #4b5e6f;
				}
			}

			.money {
				margin-top: 14px;
			}
		}

		li {
			width: 209px;
			height: 90px;
			position: relative;
			border-radius: 6px;
			float: left;
			margin-right: 20px;
			margin-bottom: 20px;
			padding: 20px 20px 20px;
			background-size: 210px 90px;
			color: #4b5e6f;
			-webkit-box-shadow: 0 10px 10px 0 rgba(208, 217, 225, .22);
			box-shadow: 0 10px 10px 0 rgba(208, 217, 225, .22);
			border: solid 1px #dfeaf6;
			// background-image: url(../../../assets/mycenter/transfer/icon6.svg), linear-gradient(166deg, #ffffff 8%, #f6fafd 64%);
			background-image: url(/assets/mycenter/transfer/icon6.svg), linear-gradient(166deg, #ffffff 8%, #f6fafd 64%);

			&:nth-child(4n) {
				margin-right: 0;
			}

			.top {
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-webkit-justify-content: space-between;
				-ms-flex-pack: justify;
				justify-content: space-between;

				.clientName {
					color: #333;
					font-size: 14px;
				}

			}

			.actionBtnBox {
				cursor: pointer;
			}

			.actionBtnBox:hover a {
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
				background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
				color: #fff;
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			}

			.money {
				margin-top: 14px;

				i {
					display: block;
					color: #333;
					font-size: 18px;
					font-weight: bold;
				}
			}

			.transToggle span {
				position: absolute;
				font-size: 12px;
				width: 44px;
				height: 28px;
				text-align: center;
				line-height: 28px;
				border-radius: 6px;
				cursor: pointer;

				&:first-child {
					right: 58px;
					bottom: 7px;
				}

				&:last-child {
					right: 10px;
					bottom: 7px;
				}

				&:hover {
					background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
					background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
					background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
					color: #fff;
					-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
					box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				}
			}
		}

	}

	.tie {
		color: #333;
		margin: 30px 0 10px;
		font-size: 14px;
	}
</style>
