<template>
	
	<div class="notice_homepageMgWarp ">
		<div class="marguee_YBMarquee" style="height:40px;line-height:40px">
			<div class="marguee_marqueeIcon" style="width:61px">
				<div class="image_imageContainer image_contain "
					style="width:53px;height:63px;cursor:inherit">
					<div style="display:block;overflow:hidden;position:relative;box-sizing:border-box;margin:0">
						<div style="display:block;box-sizing:border-box;padding-top:118.86792452830188%"></div>
						<my-img srcText="home/bulletin/icon1.png" styleText="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%;object-fit:contain" />
					</div>
				</div>
			</div>
			<div class="marguee_wrapper">
				<bulletincom v-if="BulletinList.length" @clickItemIndex="clickItemIndex" :sendVal="BulletinList" />
				<!-- <div class="marguee_content">
					<div class="marguee_txt  marquee-container"
						style="--pause-on-hover:paused; --pause-on-click:paused;">
						<div class="marquee" 
							:style="{'--play': 'running', '--direction': 'normal', '--duration': (BulletinList.length * 25) + 's', '--delay': '2s', '--iteration-count': 'infinite'}">
							<div v-for="(item, index) in BulletinList"  @click="showContent('BulletinList', index, 'showAllBtn')">
								{{item.content}}
							</div>
						</div>
					</div>
				</div> -->
			</div>
			
		</div>
		<div class="notice_wrapHomepageMore__2Kz9r undefined">
			<div class="notice_homepageMore__UyqJX" @click="showModel">{{$t('main.all')}}</div>
		</div>
		
		<a-modal :zIndex="2000" :destroyOnClose="true" :title="getContent.title" :footer="null" width="680px" centered v-model="show"
			  @cancel="hideCountentModel">
			<div class="text-model">
				<div class="detail" style="padding: 0 25px;">
					<div class="date">{{getContent.time}}</div>
					<div class="trext" v-html="getContent.content"></div>
				</div>
				<div class="group" v-if="isShowAllBtn">
					<button class="Button1 " @click="hideCountentModel"
						style="width: 200px; height: 50px; margin-right: 30px;">{{$t('main.close')}}</button>
					<button class="Button1 " @click="showModel" style="width: 200px; height: 50px;background-image: linear-gradient(to right, #5fabff 1%, #3678fe);color: #fff;">查看更多</button>
				</div>
				<div class="group" v-else>
					<button class="Button1 " :class="{'disabled2': !isPerv}" @click="pervContent"
						style="width: 200px; height: 50px; margin-right: 30px;">{{$t('main.perv')}}</button>
					<button class="Button1 "  :class="{'disabled2': !isNext}" @click="nextContent" style="width: 200px; height: 50px;">{{$t('main.next')}}</button>
				</div>
			</div>
		</a-modal>
		
		<a-modal :destroyOnClose="true" v-model="visible" wrapClassName="bulletin-model" :width="700" :title="$t('main.bulletin')" :footer="null" >
		      <div class="table1-box">
				  <table class="table1 table1he">
				  	<tbody>
				  		<tr class="noRead" v-for="(item, index) in BulletinList" :key="index" @click="showContent('BulletinList', index)">
				  			<td>
				  				<div class="flex">
				  		    
				  					<div class="typeIcon undefined"><my-img srcText="mycenter/mail/icon1.png" />
				  					</div><span :title="item.title" v-html="item.title" class="ellipsis"></span>
				  				</div>
				  			</td>
				  			<td v-html="item.content" style="padding-right:15px"></td>
				  			<!-- <td>{{getContent.time}}</td> -->
				  		</tr>
				  	</tbody>
				  </table>
			  </div>
		    </a-modal>
			
		
	</div>
</template>


<script>
	import http from '@/http.js'
	import bulletincom from "./bulletincom";
	export default {
		name: "Bulletin",
		data() {
			return {
				BulletinList: [],
				
				visible: false,
				showContentKey: '',
				showContentIndex: 0,
				
				show: false,
				
				isShowAllBtn: false,
				
				newsList: [
				        {
				          name: "张三1",
				          detail:
				            "信息公告：2020年10月14限公司成立，我行用信金额111111元",
				        },
				        {
				          name: "张三2",
				          detail:
				            "信息公告：2020年9月30大幅度发大发的，我行用信金额222222元",
				        },
				        {
				          name: "张三3",
				          detail:
				            "信息公告：2020年12月有限公司成立，我行用信金额333333元",
				        },
				        {
				          name: "张三4",
				          detail:
				            "信息公告：2020年8月31有限公司成立，我行用信金额444444元",
				        },
				      ],
			};
		},
		components:{
			bulletincom
		},
		computed: {
			getContent () {
				if (this[this.showContentKey]) {
					if (this[this.showContentKey][this.showContentIndex]) {
						return this[this.showContentKey][this.showContentIndex]
					}
				}
				return ''
			},
			isNext () {
				if (this[this.showContentKey]) {
					if (this[this.showContentKey].length == (this.showContentIndex + 1)) {
						return false
					}
				}
				return true
			},
			isPerv () {
				if (this.showContentIndex == 0) {
					return false
				}
				return true
			}
		},
		methods: {
			clickItemIndex (index) {
				this.showContent('BulletinList', index, 'showAllBtn')
			},
			showAllModel () {
				this.show = false
				this.visible = true
			},
			hideCountentModel () {
				this.show = false
			},
			pervContent () {
				if (this.isPerv) {
					this.showContentIndex = this.showContentIndex - 1
				}
			},
			nextContent () {
				if (this.isNext) {
					this.showContentIndex = this.showContentIndex + 1
				}
			},
			showContent (key, index, type) {
				if (type === 'showAllBtn') {
					this.isShowAllBtn = true
				} else {
					this.isShowAllBtn = false
				}
				this.showContentKey = key
				this.showContentIndex  = index
				this.show = true
			},
			showModel () {
				// this.visible = true
        console.log(!localStorage.getItem('access_token'))
				if (!localStorage.getItem('access_token')) {
					this.show = false
					let self = this
					this.$confirm({
						title: '温馨提示',
						content: h => {
							return `請登錄後查看！`
						},
						okText: this.$t('main.okText'),
						cancelText: this.$t('main.cancelText'),
						onOk() {
							self.$router.push({
							  path: "/login?link=mail_type_3",
							});

						},
						onCancel() {
							
						},
						class: 'testbo',
					});
				} else {
          this.show = false
					this.$router.push({
					  path: "/my-center/mail?type=3",
					});
				}
			},
			link (url) {
				if (!localStorage.getItem('access_token')) {
					let self = this
					this.$confirm({
						title: '温馨提示',
						content: h => {
							return `< div > ${this.$t('main.perv')} < /div>`
						},
						okText: this.$t('main.okText'),
						cancelText: this.$t('main.cancelText'),
						onOk() {
							self.$router.push({
							  path: "/login?link=mail_type_3",
							});
						},
						onCancel() {
							
						},
						class: 'testbo',
					});
				} else {
					this.$router.push({
						path: '/my-center/mail?type=3'
					})
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				}
			}
		},
		mounted() {
			http({
				method: 'get',
				url: '/sysNotice',
				data: {
					
				}
			}).then((res) => {
				this.BulletinList = res.data
			})
		},
		destroyed() {

		},
	};
</script>

<style lang="less" scoped>
.table1-box {
	max-height: 560px;
	    overflow: auto;
}
.table1-box::-webkit-scrollbar{
  width: 6px;
  background-color: #f0f6ff;
}
.table1-box::-webkit-scrollbar-thumb{
  background-color: #00aff8;
}
.table1he {
	height: 500px;
}
.table1 {
		border-top: none;
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;

		tbody tr:nth-of-type(odd) {
			background-color: #fff;
		}

		tr {
			height: 56px;
		}

		td:nth-child(1) {
			width: 328px;
			padding-left: 20px;
			padding-right: 40px;
		}

		td:nth-child(2) {
			width: 346px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 346px;
			color: #94a6b7;
		}

		td:nth-child(3) {
			color: #94a6b7;
			text-align: right;
			padding-right: 20px;
		}

		tr td {
			font-size: 14px;
			padding: 0;
			line-height: 56px;
		}

		td {
			cursor: pointer;
		}

		tr {
			height: 56px;
		}

		tr:nth-child(2n) {
			background-color: rgba(243, 246, 249, .5);
		}

		tbody tr:nth-of-type(odd) {
			background-color: #fff;
		}

		.flex {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;


			.quanquan {
				margin-right: 12px;
				position: relative;
				width: 16px;

				img {
					vertical-align: middle;
				}
			}

			.typeIcon {
				margin-right: 12px;
				position: relative;

				img {
					width: 20px;
					height: 20px;
					vertical-align: middle;
				}

				&.active {
					&:after {
						content: " ";
						display: table;
						width: 6px;
						height: 6px;
						background-color: red;
						position: absolute;
						right: -3px;
						top: 13px;
						border-radius: 50%;
					}
				}

			}

			.ellipsis {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 200px;
				color: #333;
			}
		}
	}
	
	.marguee_YBMarquee {
		width: 100%;
		height: 100%;
		position: relative
	}

	.marguee_YBMarquee .marguee_wrapper {
		width: 1030px;
		margin-left: 56px;
		height: 100%;
		position: absolute;
		top: -2px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center
	}

	.marguee_YBMarquee .marguee_marqueeIcon {
		height: 100%;
		position: relative;
		z-index: 1;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center
	}

	.marguee_YBMarquee .marguee_marqueeIcon img {
		margin-top: 5px !important
	}

	.marguee_YBMarquee .marguee_content {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden
	}

	.marguee_YBMarquee .marguee_item__1irq- {
		float: left;
		height: 100%;
		line-height: 40px;
		white-space: nowrap
	}

	.marguee_YBMarquee .marguee_txt {
		padding-right: 150px;
		font-family: FZLTHK-GBK1-0;
		white-space: nowrap;
		display: inline-block;
		height: 100%;
		letter-spacing: -.35px;
		color: #9da5bb;
		cursor: pointer;
		font-size: 16px;
		letter-spacing: -.4px !important;
		color: #6c7ba8 !important;
		    overflow-x: hidden !important;
		    display: flex !important;
		    flex-direction: row !important;
		    position: relative;
		    width: 100%;
	}

	.marguee_YBMarquee .marguee_txt div {
		padding-right: 150px
	}

	.notice_homepageMgWarp {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		width: 100%;
		height: 40px;
		padding: 0 10px 0 18px;
		margin-top: 31px;
		border-radius: 20px;
		background-image: -webkit-linear-gradient(top, #fff 14%, #ecefff 74%, #fefeff 98%);
		background-image: linear-gradient(180deg, #fff 14%, #ecefff 74%, #fefeff 98%);
		box-shadow: 0 6px 12px 0 rgba(216, 226, 255, .9)
	}

	.notice_homepageMgWarp .notice_noticeIcon__38l6G {
		position: relative;
		width: 53px;
		height: 63px;
		top: 4px
	}

	.notice_homepageMgWarp .notice_maskBarBox__2JTuo {
		width: 100%;
		box-sizing: border-box;
		margin: auto;
		text-align: left;
		display: -webkit-flex;
		display: flex
	}

	.notice_homepageMgWarp .notice_maskBarBox__2JTuo>img {
		width: 16px;
		height: 16px;
		margin: auto 14px auto 0
	}

	.notice_homepageMgWarp .notice_maskBarBox__2JTuo .notice_inner__14_eb {
		width: 100%;
		overflow: hidden
	}

	.notice_homepageMgWarp .notice_maskBarBox__2JTuo .notice_inner__14_eb:hover p {
		-webkit-animation-play-state: paused;
		animation-play-state: paused
	}

	.notice_homepageMgWarp .notice_maskBarBox__2JTuo .notice_inner__14_eb>p {
		width: 100%;
		display: inline-block;
		-webkit-animation-name: notice_marquee__cegcT;
		animation-name: notice_marquee__cegcT;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		height: 40px;
		white-space: nowrap
	}

	@-webkit-keyframes notice_marquee__cegcT {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		to {
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%)
		}
	}

	@keyframes notice_marquee__cegcT {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		to {
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%)
		}
	}

	.notice_homepageMgWarp .notice_maskBarBox__2JTuo .notice_inner__14_eb>p span {
		color: #9da5bb;
		font-size: 14px;
		margin-right: 100px;
		-webkit-user-select: none;
		user-select: none;
		cursor: pointer
	}

	.notice_homepageMgWarp .notice_wrapHomepageMore__2Kz9r .notice_homepageMore__UyqJX {
		width: 75px;
		height: 26px;
		line-height: 26px;
		margin-left: 10px;
		font-size: 14px;
		text-align: center;
		border: 1px solid #4176fa;
		border-radius: 13px;
		color: #4176fa;
		cursor: pointer;
		-webkit-transition: all .2s;
		transition: all .2s
	}

	.notice_homepageMgWarp .notice_wrapHomepageMore__2Kz9r .notice_homepageMore__UyqJX:hover {
		background: #4176fa;
		color: #fff
	}

	
	
	.marquee {
	    flex: 0 0 auto;
	    min-width: 100%;
	    z-index: 1;
	    display: flex;
	    flex-direction: row;
	    align-items: center;
	    animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
	    animation-play-state: var(--play);
	    animation-delay: var(--delay);
	    animation-direction: var(--direction);
	}
	
	.marquee-container {
	  overflow-x: hidden !important;
	  display: flex !important;
	  flex-direction: row !important;
	  position: relative;
	  width: 100%;
	}
	.marquee-container:hover div {
	  animation-play-state: var(--pause-on-hover);
	}
	.marquee-container:active div {
	  animation-play-state: var(--pause-on-click);
	}
	
	.overlay {
	  position: absolute;
	  width: 100%;
	  height: 100%;
	}
	.overlay::before, .overlay::after {
	  background: linear-gradient(to right, var(--gradient-color));
	  content: "";
	  height: 100%;
	  position: absolute;
	  width: var(--gradient-width);
	  z-index: 2;
	}
	.overlay::after {
	  right: 0;
	  top: 0;
	  transform: rotateZ(180deg);
	}
	.overlay::before {
	  left: 0;
	  top: 0;
	}
	
	.marquee {
	  flex: 0 0 auto;
	  min-width: 100%;
	  z-index: 1;
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
	  animation-play-state: var(--play);
	  animation-delay: var(--delay);
	  animation-direction: var(--direction);
	}
	@keyframes scroll {
	  0% {
	    transform: translateX(0%);
	  }
	  100% {
	    transform: translateX(-100%);
	  }
	}
</style>
