<template>
 <div class="num-block">
  <div class="num-block_show">
   <div class="num-block_numbers" :class="{'ellipsis': !isNum(item)}" v-for="(item, key) in numbers" :key="key">
    <!-- <ul class="num-block_ul" :style="ulStyles(noNumbers(item, key))" v-if="isNum(item)"> -->
    <ul class="num-block_ul" :style="ulStyles(item, key)" v-if="isNum(item)">
     <!-- <li v-for="(opt, index) in noNumbers(item, key)" :key="index">
      {{ opt }}
     </li> -->
     <li>0</li>
     <li>1</li>
     <li>2</li>
     <li>3</li>
     <li>4</li>
     <li>5</li>
     <li>6</li>
     <li>7</li>
     <li>8</li>
     <li>9</li>
    </ul>
    <div class="num-block_ellipsis" v-else>,</div>
   </div>
  </div>
  <!-- <div style="position:absolute;top:250px;left:0">
   {{ this.numbers }}
  </div> -->
 </div>
</template>

<script>
 export default {
  name: "ws-num-block",
  props: ['data'],
  data() {
   return {
    currentData: '10',
    lastData: '10'
   }
  },
  computed: {
   numbers() {
    let data = this.data;
    if(!data) {
     return
    }
    data = this.dealData(data);
    return data;
   }
  },
  watch: {
   data(val){
    let lastData = this.currentData;
    this.currentData = this.dealData(val);
    this.lastData = lastData;
   }
  },
  methods: {
   noNumbers(item, key) {
    let lastData = this.lastData;
    let lastItem = this.lastData[key];
    let result = [];
    lastItem = lastItem ? lastItem : '0';
    if(lastItem != item) {
     if(lastItem == ',' || item == ',') {
      result.push(item);
      return result;
     }else {
      let meal = 0;
      if(Number(item) < Number(lastItem)) {
       meal = Number(item) + 10 - Number(lastItem);
      }else {
       meal = Number(item) - Number(lastItem);
      }
      for(let i=0;i<meal+1;i++) {
       if(Number(lastItem) > 9) {
        lastItem = 0;
       }
       result.push(lastItem.toString());
       lastItem++;
      }
      return result;
     }
    }else {
     result.push(item);
     return result;
    }
   },
   // ulStyles(arr) {
   ulStyles(item, key) {
    // if(!arr) {
    //  return
    // }
    let top = 0;
    // let currentTop = -26 * Number(arr.length-1);
    let currentTop = -110 * Number(item);
    top = currentTop + 'px';
    // top = 0;
    return {
     'position': 'absolute',
     'left': 0,
     'top': top,
     'width': '12px',
     'list-style': 'none',
     'padding': '0',
     'margin': '0',
     'transition': '1s'
    }
   },
   isNum(val) {
    return val == ',' ? false : true
   },
   dealData(val) {
    let vals = val.toString().split('').reverse();
    let dealData = [];
    for(let i=0;i<vals.length;i++) {
     if( i > 0 && i%3 == 0 ) {
      dealData.push(',');
     }
     dealData.push(vals[i]);
    }
    dealData = dealData.reverse().join('');
    return dealData;
   }
  }
 };
</script>
<style lang="less" scoped>
 .num-block {
	 width: 100%;
	 height: auto;
 }
  
  .num-block_show {
	  display: flex;
	 height: 110px;
	  overflow: hidden;
  }
   
 .num-block_numbers {
	 position: relative;
	 width: 14px;
	 position: relative;
	 // background-image: url(../../assets/pg/icon3.png);
	 background-image: url(/assets/slot/icon3.png);
	 background-size: 915px 110px;
	 background-position: -312px 0;
	 width: 75px;
	 height: 110px;
	 display: -webkit-flex;
	 display: flex;
	 -webkit-align-items: center;
	 align-items: center;
	 -webkit-justify-content: center;
	 justify-content: center
 }
 
  .num-block_numbers.ellipsis {
	    width: 36px;
  }
  .ellipsis {
	  background: none  !important;
  }
 
  .num-block_ul>li {
	    height: 110px;
	    width: 75px;
	    line-height: 91px;
	    font-size: 74px;
	    text-align: center;
	    padding-bottom: 19px;
	    padding-left: 9px;
	    font-weight: 700;
	    color: #566278;
  }
   
  .num-block_ellipsis {
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 36px;
	  line-height: 91px;
	  font-size: 74px;
	  text-align: center;
	  padding-bottom: 19px;
	  padding-left: 9px;
	  font-weight: 700;
	  color: #566278;
  }
   
</style>