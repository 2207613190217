<template>
	<div class="rightobbox">

		<div class="title-bo2">
			<div class="left">
				<p>修改密碼</p>
			</div>
		</div>

		<div class="tab">
			<ul class="tabs">
				<li :class="{'active': tabidx == 1}" @click="setTab(1)">
					修改登錄密碼
				</li>
				<li :class="{'active': tabidx == 2}" @click="setTab(2)"><span>修改取款密碼</span></li>
			</ul>
		</div>


		<div v-if="tabidx == 1">
			<div class="form forminut">
				<div class="bind_bank_item ">
					<div class="bind_bank_item_left">舊密碼：</div>
					<div class="bind_bank_item_right">
						<input type="text" placeholder="輸入舊密碼" maxlength="24" class="Input"
							v-model="form1Data.old_password">
						<span
							style="display:  block;margin-bottom: -5px;line-height:  12px;margin-top: 5px;color:  red;font-size:  12px;"
							v-show="form1Data.old_password_show">請輸入6-12位字母+數字的組合</span>
					</div>
				</div>
				<div class="bind_bank_item ">
					<div class="bind_bank_item_left">新密码：</div>
					<div class="bind_bank_item_right">
						<input type="text" placeholder="輸入新密碼 (6-20位字母+數字的組合)" maxlength="24" class="Input"
							v-model="form1Data.password">
						<span
							style="display:  block;margin-bottom: -5px;line-height:  12px;margin-top: 5px;color:  red;font-size:  12px;"
							v-show="form1Data.password_show">請輸入6-12位字母+數字的組合</span>
					</div>
				</div>
				<div class="bind_bank_item ">
					<div class="bind_bank_item_left">確認新密碼：</div>
					<div class="bind_bank_item_right">
						<input type="text" placeholder="確認新密碼 (6-20位字母+數字的組合)" maxlength="24" class="Input"
							v-model="form1Data.password_confirmation">
						<span
							style="display:  block;margin-bottom: -5px;line-height:  12px;margin-top: 5px;color:  red;font-size:  12px;"
							v-show="form1Data.password_confirmation_show">兩次密碼不一致</span>
					</div>
				</div>
				<button class="Button333 "
					style="width: 300px; height: 50px; margin-left: 150px;margin-top:50px; display: block;cursor: pointer;" @click="save">確認</button>
			</div>
		</div>
		<div v-if="tabidx == 2">
			<div class="form forminut">
				<div class="bind_bank_item ">
					<div class="bind_bank_item_left">舊密碼：</div>
					<div class="bind_bank_item_right">
						<!-- <input type="text" placeholder="输入旧密码" maxlength="24" class="Input"
							v-model="form1Data.old_password"> -->
						<input-password v-model="form1Data.old_password" placeholderText="輸入取款舊密碼" styleText="width:486px;height:40px;padding-left:20px"></input-password>
						<span
							style="display:  block;margin-bottom: -5px;line-height:  12px;margin-top: 5px;color:  red;font-size:  12px;"
							v-show="form1Data.old_password_show">請輸入4位數取款密碼</span>
					</div>
				</div>
				<div class="bind_bank_item ">
					<div class="bind_bank_item_left">新密碼：</div>
					<div class="bind_bank_item_right">
						<!-- <input type="text" placeholder="输入新密码 (4位数取款密码)" maxlength="24" class="Input"
							v-model="form1Data.password"> -->
						<input-password v-model="form1Data.password" placeholderText="輸入取款新密碼" styleText="width:486px;height:40px;padding-left:20px"></input-password>
						<span
							style="display:  block;margin-bottom: -5px;line-height:  12px;margin-top: 5px;color:  red;font-size:  12px;"
							v-show="form1Data.password_show">請輸入4位數取款密碼</span>
					</div>
				</div>
				<div class="bind_bank_item ">
					<div class="bind_bank_item_left">確認新密碼：</div>
					<div class="bind_bank_item_right">
						<input-password v-model="form1Data.password_confirmation" placeholderText="確認取款新密碼" styleText="width:486px;height:40px;padding-left:20px"></input-password>
						<!-- <input type="text" placeholder="确认新密码 (4位数取款密码)" maxlength="24" class="Input"
							v-model="form1Data.password_confirmation"> -->
						<span
							style="display:  block;margin-bottom: -5px;line-height:  12px;margin-top: 5px;color:  red;font-size:  12px;"
							v-show="form1Data.password_confirmation_show">兩次密碼不一致</span>
					</div>
				</div>
				<button class="Button333 "
					style="width: 300px; height: 50px; margin-left: 150px;margin-top:50px; display: block;cursor: pointer;" @click="save">確認</button>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	import InputPassword from '@/components/InputPassword.vue'
	export default {
		name: "Home",
		components: {},
		data() {
			return {
				tabidx: 1,
				form1Data: {
					old_password: '',
					password: '',
					password_confirmation: '',

					old_password_show: false,
					password_show: false,
					password_confirmation_show: false
				}
			};
		},
		components: {
			InputPassword
		},
		methods: {
			setTab(index) {
				this.form1Data = {
					old_password: '',
					password: '',
					password_confirmation: '',
					old_password_show: false,
					password_show: false,
					password_confirmation_show: false
				}
				this.tabidx = index
			},
			save () {
				var regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
				if (this.tabidx == 1) {
					if (!regex.test(this.form1Data.old_password)) {
						this.form1Data.old_password_show = true
					} else {
						this.form1Data.old_password_show = false
					}
					if (!regex.test(this.form1Data.password)) {
						this.form1Data.password_show = true
					} else {
						this.form1Data.password_show = false
					}
				}
				if (this.tabidx == 2) {
					if (this.form1Data.old_password.length != 4) {
						this.form1Data.old_password_show = true
					} else {
						this.form1Data.old_password_show = false
					}
					if (this.form1Data.password.length != 4) {
						this.form1Data.password_show = true
					} else {
						this.form1Data.password_show = false
					}
				}
				if (this.form1Data.password != this.form1Data.password_confirmation) {
					this.form1Data.password_confirmation_show = true
				} else {
					this.form1Data.password_confirmation_show = false
				}
				if (this.form1Data.password_confirmation_show == false &&  this.form1Data.password_show  == false && this.form1Data.old_password_show == false) {
					http({
						method: 'post',
						url: this.tabidx == 1 ? '/member/resetLoginPwd' : '/member/resetQkPwd',
						data: {
							old_password: this.form1Data.old_password,
							password: this.form1Data.password,
							password_confirmation: this.form1Data.password_confirmation,
						}
					}).then((res) => {
						if (res.code == 200) {
							this.$message.success('修改密碼成功');
							this.form1Data = {
								old_password: '',
								password: '',
								password_confirmation: '',
								old_password_show: false,
								password_show: false,
								password_confirmation_show: false
							}
						} else {
							this.$message.error(res.message);
						}
					})
				} else {
					return
				}
			}
		}
	};
</script>

<style lang="less" scoped>
	.tab {
		margin-top: -20px;

		.fire {
			position: absolute;
			top: -3px;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			width: 85px;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			left: -12px;
			font-weight: normal !important;

			p {
				font-size: 12px;
				-webkit-transform: scale(0.83);
				transform: scale(0.83);
				color: red;
				line-height: 16px;
				text-align: left;
				font-weight: normal !important;
			}
		}

		.tabs {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			position: relative;
			-webkit-box-pack: start;
			-webkit-justify-content: flex-start;
			-ms-flex-pack: start;
			justify-content: flex-start;
			margin-top: 10px;

			&:after {
				background: #d8dce7;
				content: "";
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 1px;
			}

			li {
				height: 54px;
				line-height: 54px;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
				cursor: pointer;
				display: block;
				text-align: center;
				position: relative;
				margin-right: 30px;

				&.active {
					font-weight: bold;

					&:after {
						content: "";
						display: block;
						position: absolute;
						left: 0;
						bottom: 0;
						height: 1px;
						z-index: 1;
						width: 100%;
						background: #4080ff;
					}
				}
			}
		}
	}

	.no_list {
		width: 100%;
		height: 350px;
		padding-top: 100px;
		text-align: center;
	}

	.no_list img {
		margin-bottom: 30px;
	}

	.no_list_div {
		margin-bottom: 30px;
	}

	.no_list_div span {
		color: #94a6b7;
	}

	.no_list_div .Button333 {
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;
		cursor: pointer;
	}

	.no_list_div .Button333:hover {
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
		background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
	}

	.form {

		padding: 0 26px;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-top: 40px;

		.Button333 {
			border: none;
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
			background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
			color: #fff;
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			transition: all .3s ease;
			font-size: 16px;
			border-radius: 6px;

			&:hover {
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
				background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			}

			&.disabled {
				opacity: .4;
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
				background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
			}
		}

		.checktips {
			color: #999;
			font-size: 12px;
			margin: 30px auto 0;
			text-align: center;
			display: block;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;

			span {
				color: #3678fe;
			}
		}

		.bind_bank_item {
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			line-height: 38px;
			margin-bottom: 20px;

			&:after {
				content: "";
				display: block;
				clear: both;
				overflow: hidden;
			}

			.bind_bank_item_left {
				font-size: 14px;
				color: #4b5e6f;
				min-width: 90px;
				text-align: right;
			}

			.bind_bank_item_right {
				text-align: left;
				margin-left: 10px;
				position: relative;

				.bindCount {
					position: absolute;
					right: 14px;
					top: 0;
					color: #94a6b7;

					&>button {
						border: none;
						outline: none;
						background: transparent;
					}
				}

				&>input {
					font-size: 12px;
					width: 486px;
					height: 40px;
					border-radius: 6px;
					padding: 0 20px;
					border: solid 1px #d8dce7;
					color: #333;
					background-color: #fff;

					&.disabled {
						background-color: rgba(216, 220, 231, .2);
						pointer-events: none;
					}

					&:focus {
						-webkit-transition: background-color .2s ease, border .2s ease;
						transition: background-color .2s ease, border .2s ease;
						border: solid 1px #3678fe;
						-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
						box-shadow: 0 10px 20px 0 #dae8f2;
					}


				}

				.tips {
					color: #94a6b7;
					margin-bottom: 20px;
					font-size: 12px;
					margin-top: 7px;
					display: block;
					line-height: 1;
				}
			}
		}
	}
</style>
