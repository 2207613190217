<template>
	<div class="my-center-page">
		<div class="content-box">
			<div class="w1200bp">
				<div class="leftbox1">
					<div class="usercount" style="">
						<div class="private private_avatar">
							<img v-if="avatarImg" :src="avatarImg" alt="用户头像" />
						</div>
						<div class="user_name">
							<span class="name_text">{{userInfo.name}}</span>
						</div>
						<div class="safe_icon">
							<section class="gray" @click="showPhone">
								<div class="icon myicon2" :class="{'active': userInfo.phone}"></div>
								<div class="tip" v-if="!userInfo.phone">提示：為了享受更便捷的安全、消息服務，請先<span>&nbsp;綁定手機！</span></div>
							</section>
							<section class="gray"  @click="linkCardPage('/my-center/banks')">
								<div class="icon myicon3" :class="{'active': bankList.length != 0}"></div>
								<div class="tip" v-if="bankList.length == 0">提示：為了享受更便捷的提款服務，請先<span>&nbsp;綁定真實姓名！</span></div>
							</section>
							<section class="gray" @click="linkCardPage('/my-center/banks')">
								<div class="icon myicon4" :class="{'active': bankList.length != 0}"></div>
								<div class="tip" v-if="bankList.length == 0">提示：為了享受更便捷的提款服務，請先<span>&nbsp;綁定取款卡！</span></div>
							</section>
						</div>
						<div class="money_box">
							<p>中心錢包</p>
							<div class="money">
								<p><b :class="[balanceTextClass]">${{balanceText}}</b></p>
								<span @click="oneTransferOut"><a class="refresh "
										v-show="loadingBalance"></a>一鍵回收</span>
							</div>
						</div>
					</div>
					<div class="wallet_menu">
						<div class="user_menu">
							<ul class="part_one">
								<li :class="{'active': $route.name == 'deposit'}" @click="link('/my-center/deposit')">
									<div class="icon"></div>
									<p>存款</p>
								</li>
								<li :class="{'active': $route.name == 'transfer'}" @click="link('/my-center/transfer')">
									<div class="icon"></div>
									<p>轉賬</p>
								</li>
								<li :class="{'active': $route.name == 'withdrawals'}"
									@click="link('/my-center/withdrawals')">
									<div class="icon"></div>
									<p>取款</p>
								</li>
							</ul>
							<ul class="part_two">
								<li :class="{'link_active': $route.name == 'transRecord'}"
									@click="link('/my-center/transRecord')">
									<div class="icon"></div>
									<p>交易記錄</p>
								</li>
								<li :class="{'link_active': $route.name == 'betRecord'}"
									@click="link('/my-center/betRecord')">
									<div class="icon"></div>
									<p>投註記錄</p>
								</li>
								<li :class="{'link_active': $route.name == 'banks'}" @click="link('/my-center/banks')">
									<div class="icon"></div>
									<p>取款卡</p>
								</li>
							</ul>
							<ul class="part_three">
								<li :class="{'link_active': $route.name == 'info'}" @click="link('/my-center/info')">
									<div class="icon"></div>
									<p>個人信息</p>
								</li>
								<!-- <li :class="{'link_active': $route.name == 'myVip'}" @click="link('/my-center/myVip')">
									<div class="icon"></div>
									<p>VIP详情</p>
								</li> -->
								<li :class="{'link_active': $route.name == 'turnover'}"
									@click="link('/my-center/turnover')">
									<div class="icon"></div>
									<p>自助洗碼</p>
								</li>
								<li :class="{'link_active': $route.name == 'activity'}"
									@click="link('/my-center/activity')">
									<div class="icon"></div>
									<p>活動申請</p>
								</li>
								<li :class="{'link_active': $route.name == 'password'}"
									@click="link('/my-center/password')">
									<div class="icon"></div>
									<p>修改密碼</p>
								</li>
								<li :class="{'link_active': $route.name == 'mail'}" @click="link('/my-center/mail')">
									<div class="icon"></div>
									<p>消息中心 <span v-if="unreadText">{{unreadText}}</span> </p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="rgihtbox1">
					<transition name="t1">
						<router-view></router-view>
					</transition>
				</div>
			</div>
		</div>
		
		<bind-phone ref="phone" />
	</div>
</template>

<script>
	import http from '@/http.js'
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	import BindPhone from '@/components/BindPhone.vue'
	export default {
		name: "Home",
		data() {
			return {
				transitionName: 'slide-right',
				loadingBalance: false,

				imageUrl: '',
				
				bankList: []
			};
		},
		watch: {
			$route(to, from) {
				let is = this.$router.isBack;
				if (is) {
					this.transitionName = 'ddd'
				} else {
					this.transitionName = 'sss'
				}
				this.$router.isBack = false
			}
		},
		computed: {
			...mapGetters(['balance', 'avatarImg', 'userInfo', 'unread']),
			
			balanceText () {
				if (this.balance) {
					if (typeof this.balance === 'number') {
						return this.balance.toFixed(2)
					} else {
						return this.balance
					}
				} else {
					return '0.00'
				}
			},
			balanceTextClass () {
				if (this.balance >= 1000000) {
					return 'fonst14'
				} else if (this.balance >= 100000) {
					return 'fonst16'
				} else if (this.balance >= 10000) {
					return 'fonst18'
				}
				return 'fonst20'
			},
			unreadText () {
				if (this.unread >= 99) {
					return '99+'
				}
				return this.unread
			}
		},
		components: {
			BindPhone
		},
		mounted() {
			// http({
			// 	method: 'post',
			// 	url: '/member/setAvatar',
			// 	data: {
			// 		avatar: '/uploads/avatar/avatar-11.png'
			// 	}
			// }).then((res) => {
				
			// })
			this.bankList = []
			let self = this
			if (!localStorage.getItem('access_token')) {
				let wxts = this.$t('main.login_ts')
				this.$confirm({
					title: this.$t('main.wxts'),
					content: h => < div > {wxts} </div>,
					okText: this.$t('main.okText'),
					cancelText: this.$t('main.cancelText'),
					onOk() {
						self.$router.push({
						  path: "/login",
						});
					},
					onCancel() {
						self.$router.push({
							path: "/",
						});
					},
					class: 'testbo',
				});
			} else {
				// http({
				// 	method: 'post',
				// 	url: '/member/info',
				// 	data: {

				// 	}
				// }).then((res) => {
				// 	if (res.code == 200) {
				// 		this.info = res.data
				// 	} else {
				// 		this.$message.error(res.message);
				// 	}
				// })
				this.getBankList()
			}
		},
		activated() {
			this.bankList = []
			let self = this
			if (!localStorage.getItem('access_token')) {
				let wxts = this.$t('main.login_ts')
				this.$confirm({
					title: this.$t('main.wxts'),
					content: h => < div > {wxts} </div>,
					okText: this.$t('main.okText'),
					cancelText: this.$t('main.cancelText'),
					onOk() {
						self.$router.push({
						  path: "/login",
						});
					},
					onCancel() {
						self.$router.push({
							path: "/",
						});
					},
					class: 'testbo',
				});
			} else {
				this.getUnread()
				this.getBankList()
			}
		},
		methods: {
			...mapActions('user', ['intervalGetBalance', 'setBalance', 'clearIntervalGetBalance', 'getNewGidBalance', 'getUnread']),
			
			showPhone () {
				if (!this.userInfo.phone) {
					this.$refs.phone.showModel()
				}
			},
			linkCardPage(url) {
				if (!this.userInfo.phone) {
					let self = this
					this.$confirm({
						title: '手机號未綁定，請先綁定手機號！',
						cancelText: '取消',
						okText: '綁定手機號',
						onOk() {
							self.$refs.phone.showModel()
						},
						onCancel() {
							
						},
					});
				} else if (this.bankList.length) {
					
				} else {
					this.$router.push({
						path: url
					});
				}
			},
			getBankList () {
				http({
					method: 'post',
					url: '/member/drawingInfoList',
					data: {
						
					}
				}).then((res) => {
					if (res.code == 200) {
						if (res.data.bank && res.data.bank.bank_card) {
							this.bankList = [res.data.bank]
						}
						if (res.data.usdt && res.data.usdt.usdt_address) {
							this.bankList = [...this.bankList, res.data.usdt]
						}
					} else {
						this.$message.error(res.message);
					}
				})
			},
			
			// 获取最新余额
			getNewBalance() {
				this.setBalance()
				this.intervalGetBalance()
			},
			downloadFilesCustomRequest(data) {
				http({
					method: 'post',
					url: '/member/setAvatar',
					data: {
						avatar: '/uploads/avatar/avatar-11.png'
					}
				}).then((res) => {
					
				})
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('请选择jpg、png格式图片');
				}
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('选择图片不能大于2m大小');
				}
				return isJpgOrPng && isLt2M;
			},
			link(link) {
				this.$router.push({
					path: link
				})
			},
			oneTransferOut() {
				if (this.loadingBalance === true) {
					return
				}
				this.loadingBalance = true
				http({
					method: 'post',
					url: '/member/oneTransferOut',
					data: {

					}
				}).then((res) => {
					if (res.code == 200) {
						this.getNewGidBalance()
						this.getNewBalance()
						setTimeout(() => {
							this.$message.success('余额回收成功！');
						}, 500)
						this.loadingBalance = false
					} else {
						this.$message.error(res.message);
						this.loadingBalance = false
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.avatar-uploader {
		width: 90px;
		height: 90px;
	}

	.loadingspan {}

	.refresh {
		display: inline-block;
		margin-right: 4px;
		width: 16px;
		height: 16px;
		// background-image: url(../../assets/mycenter/transfer/icon4.svg);
		background-image: url(/assets/mycenter/transfer/icon4.svg);
		background-repeat: no-repeat;
		border: none;
		margin-bottom: 3px;
		vertical-align: middle;
		cursor: pointer;
		transition: all 2s;
		animation: loadingcss 2s infinite;

		.loading {}
	}

	@keyframes loadingcss {
		0% {
			transform: rotateZ(0deg);
		}

		100% {
			transform: rotateZ(360deg);
		}
	}

	.money_box {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		margin: 0 20px;
		margin-top: 20px;
		padding-top: 4px;
	}

	.money_box>p {
		color: #fff;
		text-align: left;
		line-height: 34px;
		font-size: 12px;
	}

	.money_box .money {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.money_box .money p {
		font-size: 20px;
		font-weight: 700;
		color: #fff;
		line-height: 20px;
		
		b {
			font-size: 20px;
			font-weight: 700;
			&.fonst20 {
				font-size: 20px;
			}
			&.fonst18 {
				font-size: 18px;
			}
			&.fonst16 {
				font-size: 16px;
			}
			&.fonst14 {
				font-size: 14px;
			}
		}
	}

	.money_box .money span {
		border-radius: 20px;
		background-color: #fff;
		height: 24px;
		width: 80px;
		font-size: 12px;
		text-align: center;
		cursor: pointer;
		line-height: 24px;
		box-shadow: 0 10px 20px 0 rgba(255, 255, 255, 0.2);
		transition: all 0.3s ease;
	}

	.myicon2 {
		// background-image: url(../../assets/mycenter/sidebar/icon1.png);
		
		background-image: url(/assets/mycenter/sidebar/icon1.png);
		background-position: center center;
		background-size: 26px 26px;
		background-repeat: no-repeat;
		&.active {
			background-size: 30px 30px;
			background-image: url(/assets/mycenter/sidebar/icond1.png);
		}
	}

	.myicon3 {
		// background-image: url(../../assets/mycenter/sidebar/icon2.png);
		background-image: url(/assets/mycenter/sidebar/icon2.png);
		background-position: center center;
		background-size: 26px 26px;
		background-repeat: no-repeat;
		&.active {
			background-size: 30px 30px;
			background-image: url(/assets/mycenter/sidebar/icond2.png);
		}
	}

	.myicon4 {
		// background-image: url(../../assets/mycenter/sidebar/icon3.png);
		background-image: url(/assets/mycenter/sidebar/icon3.png);
		background-position: center center;
		background-size: 26px 26px;
		background-repeat: no-repeat;
		&.active {
			background-size: 30px 30px;
			background-image: url(/assets/mycenter/sidebar/icond3.png);
		}
	}

	.content-box {
		// background-image: url(../../assets/mycenter/icon21.png);
		background-image: url(/assets/mycenter/icon21.png);
		padding-top: 110px;
		background-size: 100% calc(673px / var(--Cardinality));
		width: 100%;
		height: auto;
		background-color: #e9edf8;
		background-position: 0 0;
		background-repeat: no-repeat;

		.w1200bp {
			position: relative;
			width: 1200px;
			height: auto;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			margin: 0 auto;
			min-height: 690px;
		}

		.leftbox1 {
			width: 220px;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			padding: 0 0 20px;
			float: none;
			position: relative;

			.usercount {
				height: 286px;
				border-radius: 10px;
				-webkit-box-shadow: 0 5px 10px 0 rgba(23, 58, 119, .1);
				box-shadow: 0 5px 10px 0 rgba(23, 58, 119, .1);
				background: none;
				background-image: -webkit-linear-gradient(313deg, #70b5ff 2%, #3678fe 98%);
				background-image: linear-gradient(137deg, #70b5ff 2%, #3678fe 98%);
				margin-bottom: 10px;
				padding: 20px 0 0 0;
				text-align: center;
				box-shadow: 0 5px 10px 0 rgba(23, 58, 119, .1);
				position: relative;

				.safe_icon {
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					justify-content: center;
					position: relative;
					z-index: 2;

					.gray {
						width: 30px;
						height: 30px;
						margin: 0 8px;
						position: relative;

						.icon {
							margin: 0 auto;
							width: 30px;
							height: 30px;
							cursor: pointer;
						}

						&:hover {
							.tip {
								display: block;
							}
						}

						.tip {
							display: none;
							padding-top: 21px;
							padding-left: 15px;
							padding-right: 15px;
							color: #888;
							position: absolute;
							width: 236px;
							height: 70px;
							font-size: 12px;
							line-height: 20px;
							top: 18px;
							left: -182px;
							// background-image: url(../../assets/mycenter/icon20.png);
							background-image: url(/assets/mycenter/icon20.png);
							background-repeat: no-repeat;
							background-size: auto auto;
							background-size: initial;
							z-index: 10;

							span {
								color: #00aeff;
								text-decoration: underline;
								cursor: pointer;
							}
						}
					}
				}

				.user_name {
					position: relative;
					z-index: 2;
					margin: 10px auto 20px;

					.name_text {
						display: block;
						font-size: 14px;
						font-weight: bold;
						line-height: 1;
						color: #fff;
					}

					.title_des {
						display: block;
						opacity: .9;
						font-size: 12px;
						line-height: 1;
						color: #fff;
						margin-top: 6px;
					}
				}

				.safe_level {
					opacity: .9;
					font-size: 12px;
					line-height: 1;
					margin-bottom: 10px;
					color: #fff;
				}

				.private {
					margin: 0 auto;
					width: 90px;
					height: 90px;
					border-radius: 50%;
					position: relative;
					z-index: 2;
					img {
						width: 90px;
						height: 90px;
						border-radius: 50%;
					}
					i {

						display: block;
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}
				}

				// &::before {
				// 	content: " ";
				// 	display: block;
				// 	width: 129px;
				// 	height: 93px;
				// 	opacity: .1;
				// 	background-image: -webkit-radial-gradient(85% 83%, circle, #ffffff, rgba(255, 255, 255, 0) 85%);
				// 	background-image: radial-gradient(circle at 85% 83%, #ffffff, rgba(255, 255, 255, 0) 85%);
				// 	position: absolute;
				// 	left: 0;
				// 	top: 0;
				// 	z-index: 1;
				// 	pointer-events: none;
				// }

				&:after {
					position: absolute;
					right: 0;
					top: 0;
					content: " ";
					display: block;
					width: 100px;
					height: 236px;
					opacity: .25;
					background-image: -webkit-linear-gradient(228deg, rgba(255, 254, 254, 0) 68%, #599dfe 42%);
					background-image: linear-gradient(222deg, rgba(255, 254, 254, 0) 68%, #599dfe 42%);
					z-index: 1;
					pointer-events: none;
				}
			}


			.wallet_menu {
				-webkit-box-flex: 1;
				-webkit-flex: 1;
				-ms-flex: 1;
				flex: 1;
				border-radius: 10px;
				-webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
				box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
				background-color: #fff;

				.userWallet {
					padding: 20px 0 0 22px;
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;
					-webkit-box-pack: start;
					-webkit-justify-content: flex-start;
					-ms-flex-pack: start;
					justify-content: flex-start;

					p {
						margin: 0;
						font-size: 14px;
						color: #4b5e6f;
						line-height: 1;
					}

					b {

						font-size: 14px;
						font-weight: bold;
						line-height: 1;
						color: #3678fe;
						max-width: 104px;
						margin-right: 6px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					span {
						cursor: pointer;
						width: 16px;
						height: 16px;
						// background-image: url(../../assets/mycenter/sidebar/icon4.png);
						background-image: url(/assets/mycenter/sidebar/icon4.png);
						background-size: cover;
						-webkit-transition: all .7s ease;
						transition: all .7s ease;
					}
				}
			}

			.user_menu {

				.part_two {
					padding: 5px 0 6px;
					position: relative;

					&:after {
						content: "";
						position: absolute;
						left: 30px;
						bottom: 0;
						width: 160px;
						height: 1px;
						background-color: #d8dce7;
					}

					li {
						height: 46px;
						display: -webkit-box;
						display: -webkit-flex;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-webkit-align-items: center;
						-ms-flex-align: center;
						align-items: center;
						cursor: pointer;
						font-size: 14px;
						color: #8590a6;
						padding: ;
						padding: 0 0 0 50px;
						-webkit-transition: background-color .5s;
						transition: background-color .5s;
						overflow: hidden;
						position: relative;

						&.link_active {
							background-color: #f3fafd;

							&:after {
								-webkit-transition: all .4s;
								transition: all .4s;
								-webkit-transform: translateY(0);
								transform: translateY(0);
							}
						}

						&.link_active p {
							font-weight: 700;
							color: #3678fe;
						}

						&:nth-child(1).link_active {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon12.png);
								background-image: url(/assets/mycenter/sidebar/icon12.png);
								background-size: cover;
							}
						}

						&:nth-child(2).link_active {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon14.png);
								background-image: url(/assets/mycenter/sidebar/icon14.png);
								background-size: cover;
							}
						}

						&:nth-child(3).link_active {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon16.png);
								background-image: url(/assets/mycenter/sidebar/icon16.png);
								background-size: cover;
							}
						}

						&:hover {
							&:nth-child(1) {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon12.png);
									background-image: url(/assets/mycenter/sidebar/icon12.png);
									background-size: cover;
								}
							}

							&:nth-child(2) {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon14.png);
									background-image: url(/assets/mycenter/sidebar/icon14.png);
									background-size: cover;
								}
							}

							&:nth-child(3) {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon16.png);
									background-image: url(/assets/mycenter/sidebar/icon16.png);
									background-size: cover;
								}
							}

							p {
								font-weight: 700;
								color: #3678fe;
							}
						}

						&:nth-child(1) {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon11.png);
								background-image: url(/assets/mycenter/sidebar/icon11.png);
								background-size: cover;
							}
						}

						&:nth-child(2) {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon13.png);
								background-image: url(/assets/mycenter/sidebar/icon13.png);
								background-size: cover;
							}
						}

						&:nth-child(3) {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon15.png);
								background-image: url(/assets/mycenter/sidebar/icon15.png);
								background-size: cover;
							}
						}

						.icon {
							width: 20px;
							height: 20px;
							margin-right: 10px;

						}

						p {
							line-height: 46px;
							margin: 0;
							transition: all .4s;
							font-weight: 400;
						}

						&::after {
							content: "border-left";
							font-size: 0;
							position: absolute;
							left: 0;
							top: 0;
							background-color: #3678fe;
							height: 46px;
							width: 2px;
							-webkit-transform: translateY(-46px);
							transform: translateY(-46px);
						}
					}
				}

				.part_three {
					padding: 5px 0 6px;
					position: relative;



					li {
						height: 46px;
						display: -webkit-box;
						display: -webkit-flex;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-webkit-align-items: center;
						-ms-flex-align: center;
						align-items: center;
						cursor: pointer;
						font-size: 14px;
						color: #8590a6;
						padding: ;
						padding: 0 0 0 50px;
						-webkit-transition: background-color .5s;
						transition: background-color .5s;
						overflow: hidden;
						position: relative;

						&.link_active {
							background-color: #f3fafd;

							&:after {
								-webkit-transition: all .4s;
								transition: all .4s;
								-webkit-transform: translateY(0);
								transform: translateY(0);
							}
						}

						&.link_active p {
							font-weight: 700;
							color: #3678fe;
						}

						&:nth-child(1).link_active {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon18.png);
								background-image: url(/assets/mycenter/sidebar/icon18.png);
								background-size: cover;
							}
						}


						&:nth-child(2).link_active {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon20.png);
								background-image: url(/assets/mycenter/sidebar/icon20.png);
								background-size: cover;
							}
						}

						&:nth-child(3).link_active {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon22.png);
								background-image: url(/assets/mycenter/sidebar/icon26.png);
								background-size: cover;
							}
						}

						&:nth-child(4).link_active {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon24.png);
								background-image: url(/assets/mycenter/sidebar/icon22.png);
								background-size: cover;
							}
						}

						&:nth-child(5).link_active {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon26.png);
								background-image: url(/assets/mycenter/sidebar/icon26.png);
								background-size: cover;
							}
						}

						&:hover {
							&:nth-child(1) {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon18.png);
									background-image: url(/assets/mycenter/sidebar/icon18.png);
									background-size: cover;
								}
							}

							&:nth-child(2) {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon20.png);
									background-image: url(/assets/mycenter/sidebar/icon20.png);
									background-size: cover;
								}
							}

							&:nth-child(3) {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon22.png);
									background-image: url(/assets/mycenter/sidebar/icon26.png);
									background-size: cover;
								}
							}

							&:nth-child(4) {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon24.png);
									
									background-image: url(/assets/mycenter/sidebar/icon22.png);
									background-size: cover;
								}
							}

							&:nth-child(5) {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon26.png);
									background-image: url(/assets/mycenter/sidebar/icon26.png);
									background-size: cover;
								}
							}

							p {
								font-weight: 700;
								color: #3678fe;
							}
						}

						&:nth-child(1) {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon17.png);
								background-image: url(/assets/mycenter/sidebar/icon17.png);
								background-size: cover;
							}
						}

						&:nth-child(2) {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon19.png);
								background-image: url(/assets/mycenter/sidebar/icon19.png);
								background-size: cover;
							}
						}

						&:nth-child(3) {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon21.png);
								background-image: url(/assets/mycenter/sidebar/icon25.png);
								background-size: cover;
							}
						}

						&:nth-child(4) {
							.icon {
								background-image: url(/assets/mycenter/sidebar/icon21.png);
								background-size: cover;
								// background-image: url(../../assets/mycenter/sidebar/icon23.png);
								
							}
						}

						&:nth-child(5) {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon25.png);
								background-image: url(/assets/mycenter/sidebar/icon25.png);
								background-size: cover;
							}
						}


						.icon {
							width: 20px;
							height: 20px;
							margin-right: 10px;

						}

						p {
							line-height: 46px;
							margin: 0;
							transition: all .4s;
							font-weight: 400;

							span {
								display: inline-block;
								height: 16px;
								margin-left: 6px;
								border-radius: 10px;
								font-size: 12px;
								line-height: 16.5px;
								background-color: red;
								-webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, .3);
								box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, .3);
								padding: 0 6px;
								color: #fff;
								font-weight: normal;
							}
						}

						&::after {
							content: "border-left";
							font-size: 0;
							position: absolute;
							left: 0;
							top: 0;
							background-color: #3678fe;
							height: 46px;
							width: 2px;
							-webkit-transform: translateY(-46px);
							transform: translateY(-46px);
						}
					}
				}

				.part_one {
					padding: 12px 22px 5px;
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: justify;
					-webkit-justify-content: space-between;
					-ms-flex-pack: justify;
					justify-content: space-between;

					li {
						display: -webkit-box;
						display: -webkit-flex;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-webkit-align-items: center;
						-ms-flex-align: center;
						align-items: center;
						cursor: pointer;
						font-size: 14px;
						color: #8590a6;
						-webkit-box-orient: vertical;
						-webkit-box-direction: normal;
						-webkit-flex-direction: column;
						-ms-flex-direction: column;
						flex-direction: column;

						&:nth-child(1) {
							&.active {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon6.png);
									background-image: url(/assets/mycenter/sidebar/icon6.png);
									background-size: cover;
								}
							}
						}

						&:nth-child(2) {
							&.active {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon8.png);
									background-image: url(/assets/mycenter/sidebar/icon8.png);
									background-size: cover;
								}
							}
						}

						&:nth-child(3) {
							&.active {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon10.png);
									background-image: url(/assets/mycenter/sidebar/icon10.png);
									background-size: cover;
								}
							}
						}

						&:nth-child(1) {
							&:hover {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon6.png);
									background-image: url(/assets/mycenter/sidebar/icon6.png);
									background-size: cover;
								}
							}
						}

						&:nth-child(2) {
							&:hover {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon8.png);
									background-image: url(/assets/mycenter/sidebar/icon8.png);
									background-size: cover;
								}
							}
						}

						&:nth-child(3) {
							&:hover {
								.icon {
									// background-image: url(../../assets/mycenter/sidebar/icon10.png);
									background-image: url(/assets/mycenter/sidebar/icon10.png);
									background-size: cover;
								}
							}
						}

						&:hover {
							p {
								color: #000;
							}

						}

						&:nth-child(1) {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon5.png);
								background-image: url(/assets/mycenter/sidebar/icon5.png);
								background-size: cover;
							}
						}

						&:nth-child(2) {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon7.png);
								background-image: url(/assets/mycenter/sidebar/icon7.png);
								background-size: cover;
							}
						}

						&:nth-child(3) {
							.icon {
								// background-image: url(../../assets/mycenter/sidebar/icon9.png);
								background-image: url(/assets/mycenter/sidebar/icon9.png);
								background-size: cover;
							}
						}

						.icon {
							width: 40px;
							height: 40px;
							margin-bottom: 6px;



						}

						p {
							transition: all .4s;
							font-weight: 400;
							line-height: 1;
						}
					}
				}
			}
		}

		.rgihtbox1 {
			width: auto;
			height: auto;
			float: none;
		}
	}
</style>
