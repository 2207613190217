<template>
	<div class="game-content">
		<div class="game-logo"></div>
		<div class="bar-box">
			<div class="bar">
				<span :style="{'width': width + '%'}"></span>
			</div>
			<p>{{width}}%</p>
		</div>
		<div class="ts-text">正在為您跳轉遊戲界面，請稍候…</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	export default {
		name: "app",
		data() {
			return {
				width: 0,
				ti: null
			};
		},
		activated() {
			this.width = 0
			this.ti = setInterval(() => {
				this.width = this.width + 1
			}, 100)
			http({
				method: 'post',
				url: '/member/gameLogin',
				data: {
					plat_type: this.$route.query.plat_type == 'null' ? '' : this.$route.query.plat_type,
					game_type: this.$route.query.game_type == 'null' ? '' : this.$route.query.game_type,
					game_code: this.$route.query.game_code == 'null' ? '' : this.$route.query.game_code
				}
			}).then((res) => {
				if (res.code == 200) {
					window.location.href = res.data
				} else {
					this.$message.error(res.message);
				}
				this.width = 100
				clearInterval(this.ti)
			})
		},
		beforeDestroy () {
			this.width = 0
			clearInterval(this.ti)
		}
	};
</script>

<style lang="less" scoped>
	.game-logo {
		width: 200px;
		height: 200px;
		margin: 0 auto;
		display: block;
		margin-bottom: 50px;
		background-image: url(/assets/game/logo.png);
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
	.game-content {
		height: 100%;
		padding-top: 10.5%;
		background: #16181f;
		&>img {
			    width: 168px;
			    height: 187px;
				margin: 0 auto;
				display: block;
				margin-bottom: 70px;
		}
		.bar-box {
			    margin-bottom: 27px;
			    width: 566px;
				margin: 0 auto;
				margin-bottom: 27px;
				&>p {
					font-size: 38px;
					color: rgb(166, 166, 166);
					text-align: center;
				}
				.bar {
					    margin-bottom: 27px;
					    width: 100%;
					    height: 19.6px;
					    background-color: #101113;
					    border-radius: 100px;
					    position: relative;
					    overflow: hidden;
						 border-radius: 100px;
						 overflow: hidden;
						span {
							display: block;
							 border-radius: 100px;
							    height: 19.6px;
							    background-image: -webkit-gradient(linear,left top,right top,color-stop(13%,#a0511a),color-stop(60%,#ff7f27));
							    background-image: linear-gradient(90deg,#a0511a 13%,#ff7f27 60%);
						}
				}
		}
		.ts-text {
			    font-size: 21px;
				color: rgb(166, 166, 166);
			    letter-spacing: 2px;
				text-align: center;
		}
	}
</style>