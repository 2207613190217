<template>
	<div class="rightobbox">
		<div class="title-bo2">
			<div class="left">
				<p>消息中心
				</p>
			</div>
		</div>
		<div class="content_title">
			<a :class="{active: tabidx == 1}" @click="setTab(1)">通知<span style="display: block;" v-if="tabUnread1">{{tabUnread1}}</span></a>
			<a :class="{active: tabidx == 2}" @click="setTab(2)">私信<span style="display: block;" v-if="tabUnread2">{{tabUnread2}}</span></a>
			<a :class="{active: tabidx == 3}" @click="setTab(3)">公告</a>
		</div>
		
		 <a-spin :spinning="spinning">
		    <div v-if="tabidx == 1" style="margin-top: 20px;">
				<a-spin :spinning="pageLoading">
					<table class="table1" >
					<div class="no_list" v-if="userMsgList.length == 0 && spinning == false">
						<my-img srcText="mycenter/icon1.png" styleText="width:365px;height:165px;" />
						<div class="no_list_div"><span>暫無通知消息</span> <br></div>
					</div>
		    		<tbody>
		    			<tr class="" :class="[index % 2 == 0 ? 'noRead' : 'read']" v-for="(item, index) in userMsgList" @click="showContent('userMsgList', index)" :key="index"  >
		    				<td>
		    					<div class="flex">
		    						<div v-if="showall" class="quanquan" @click.stop="selectItemCheck(index)">
		    							<my-img v-if="!item.check" srcText="mycenter/mail/icon6.svg" />
										<my-img v-if="item.check" srcText="mycenter/mail/icon61.svg" />
		    						</div>
		    						<div class="typeIcon" :class="{'active': item.du == 0}">
		    							<my-img srcText="mycenter/mail/icon2.png" />
		    						</div><span  :title="item.title" v-html="item.title">
		    							
		    						</span>
		    					</div>
		    				</td>
		    				<td  v-html="item.content"></td>
		    				<td>{{item.created_at}}</td>
		    			</tr>
		    			<tr v-if="userMsgList.length">
		    				<td colspan="3" style="padding-right: 0px;">
		    					<div class="bottom_box" style="display: flex;justify-content: space-between;">
		    						<div class="pagination" style="display: block; border-left: 0px; border-right: 0px;">
		    							<div class="headerBox">
		    								<a v-if="showall" @click="selctAll">
		    									<my-img v-if="!isSelectAll" srcText="mycenter/mail/icon7.svg" />
		    									<my-img v-if="isSelectAll" srcText="mycenter/mail/icon61.svg" />
		    									<span>全選</span>
		    								</a>
		    								<a v-if="!showall" @click="showall = true">
		    									<my-img srcText="mycenter/mail/icon3.svg" />
		    									<span>編輯</span>
		    								</a>
		    								<a v-if="showall" @click="msgRead">
		    									<my-img srcText="mycenter/mail/icon8.svg" />
		    									<span class="biaoji">批量已讀</span></a>
		    								<a v-if="showall" @click="msgDel">
		    									<my-img srcText="mycenter/mail/icon9.svg" />
		    									<span class="biaoji">批量刪除</span></a>
		    								<a v-if="showall" @click="noShowAll"><span>取消</span></a>
		    
		    								<a v-if="!showall" @click="allMsgRead"><my-img srcText="mycenter/mail/icon4.svg" /><span>全部已讀</span></a>
		    								<a v-if="!showall" @click="allRemove"><my-img srcText="mycenter/mail/icon5.svg" /><span>全部刪除</span></a>
		    							</div>
		    							<div class="mycenterPaginations" style="text-align: right; margin-top: 20px;"></div>
		    						</div>
									<div style="margin-top: 10px;">
										<a-pagination :default-current="4" @change="changePagination" v-model="pagination.current_page" :total="pagination.total" />
									</div>
		    					</div>
		    				</td>
		    			</tr>
		    		</tbody>
		    	</table>
				</a-spin>
			</div>
		    <div v-if=" tabidx == 2" style="margin-top: 20px;">
				<a-spin :spinning="pageLoading">
		    	<table class="table1">
					<div class="no_list" v-if="userMsgList.length == 0 && spinning == false">
						<my-img srcText="mycenter/icon1.png" styleText="width:365px;height:165px;" />
						<div class="no_list_div"><span>暫無通知消息</span> <br></div>
					</div>
		    		<tbody>
		    			<tr class="" :class="[index % 2 == 0 ? 'noRead' : 'read']" v-for="(item, index) in userMsgList"  @click="showContent('userMsgList', index)" :key="index" >
		    				<td>
		    					<div class="flex">
		    						<div v-if="showall" class="quanquan" @click.stop="selectItemCheck(index)">
		    							<my-img v-if="!item.check" srcText="mycenter/mail/icon6.svg" />
		    							<my-img v-if="item.check" srcText="mycenter/mail/icon61.svg" />
		    						</div>
		    						<div class="typeIcon" :class="{'active': item.du == 0}">
		    							<my-img srcText="mycenter/mail/icon2.png" />
		    						</div><span   :title="item.title" v-html="item.title">
		    							
		    						</span>
		    					</div>
		    				</td>
		    				<td  v-html="item.content"></td>
		    				<td>{{item.created_at}}</td>
		    			</tr>
		    			<tr v-if="userMsgList.length">
		    				<td colspan="3" style="padding-right: 0px;">
		    					<div class="bottom_box" style="display: flex;justify-content: space-between;">
		    						<div class="pagination" style="display: block; border-left: 0px; border-right: 0px;">
		    							<div class="headerBox">
		    								<a v-if="showall" @click="selctAll">
		    									<my-img v-if="!isSelectAll" srcText="mycenter/mail/icon7.svg" />
												<my-img v-if="isSelectAll" srcText="mycenter/mail/icon61.svg" />
		    									<span>全選</span>
		    								</a>
		    								<a v-if="!showall" @click="showall = true">
		    									<my-img srcText="mycenter/mail/icon3.svg" />
		    									<span>編輯</span>
		    								</a>
		    								<a v-if="showall" @click="msgRead">
		    									<my-img srcText="mycenter/mail/icon8.svg" />
		    									<span class="biaoji">批量已讀</span></a>
		    								<a v-if="showall" @click="msgDel">
		    									<my-img srcText="mycenter/mail/icon9.svg" />
		    									<span class="biaoji">批量删除</span></a>
		    								<a v-if="showall" @click="noShowAll"><span>取消</span></a>
		    					    
		    								<a v-if="!showall" @click="allMsgRead"><my-img srcText="mycenter/mail/icon4.svg" /><span>全部已讀</span></a>
		    								<a v-if="!showall" @click="allRemove"><my-img srcText="mycenter/mail/icon5.svg" /><span>全部刪除</span></a>
		    							</div>
		    							<div class="mycenterPaginations" style="text-align: right; margin-top: 20px;"></div>
		    						</div>
									<div style="margin-top: 10px;">
										<a-pagination :default-current="4" @change="changePagination" v-model="pagination.current_page" :total="pagination.total" />
									</div>
		    					</div>
		    				</td>
		    			</tr>
		    		</tbody>
		    	</table>
				</a-spin>
			</div>
		    <div v-if=" tabidx == 3" style="margin-top: 20px;">
		    	<table class="table1">
		    		<tbody>
		    			<tr class="noRead" v-for="(item, index) in sysNoticeList" :key="index" @click="showContent('sysNoticeList', index)">
		    				<td>
		    					<div class="flex">
		    
		    						<div class="typeIcon undefined"><my-img srcText="mycenter/mail/icon1.png" />
		    						</div><span :title="item.title" v-html="item.title" class="ellipsis"></span>
		    					</div>
		    				</td>
		    				<td v-html="item.content"></td>
		    				<td>{{getContent.time}}</td>
		    			</tr>
		    			<!-- <tr class="read" @click="show = true">
		    				<td>
		    					<div class="flex">
		    
		    						<div class="typeIcon undefined"><my-img srcText="mycenter/mail/icon2.png" />
		    						</div><span title="【欢迎加入KOK】" class="ellipsis">【欢迎加入KOK】</span>
		    					</div>
		    				</td>
		    				<td>尊敬的iswangli198：恭喜您已经成为KOK正式会员。KOK拥有欧洲马耳他MGA、英国GC监督委员会（GamblingCommission）、菲律宾政府（PAGCOR）颁发的合法执照。注册于英属维尔京群岛，是受国际行业协会认可的合法公司，选择KOK将为您的账号和资金安全保驾护航。我们将为您提供全球最顶级的体育赛事、真人娱乐、棋牌游戏、电子游戏等多种产品。我们还为您提供了超便捷的存款方式和提款渠道，存款只需60秒，提款只需要90秒即可快速到账。
		    				</td>
		    				<td>2021-07-10 04:32:15</td>
		    			</tr> -->
		    		</tbody>
		    	</table>
		    </div>
		    
		</a-spin>
		
		<a-modal :title="'【' + getContent.title + '】'" :footer="null" width="680px" centered v-model="show"
			  @cancel="show = false">
			<div class="text-model">
				<div class="detail" style="padding: 0 25px;">
					<div class="date">{{getContent.time}}</div>
					<div class="trext" v-html="getContent.content"></div>
				</div>
				<div class="group">
					<button class="Button1 " :class="{'disabled2': !isPerv}" @click="pervContent"
						style="width: 200px; height: 50px; margin-right: 30px;">上一條</button>
					<button class="Button1 "  :class="{'disabled2': !isNext}" @click="nextContent" style="width: 200px; height: 50px;">下一條</button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http.js'
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	export default {
		name: "Home",
		data() {
			return {
				tabidx: 1,
				tabUnread1: 0,
				tabUnread2: 0,
				showall: false,
				isSelectAll: false,
				show: false,
				
				sysNoticeList: [],
				showContentKey: '',
				showContentIndex: 0,
				userMsgList: [],
				
				spinning: true,
				
				pagination: {
					total: 0,
					current_page: 1
				},
				
				pageLoading: false
			};
		},
		computed: {
			getContent () {
				if (this.tabidx < 3 && this.showContentKey != '' && this[this.showContentKey] && this[this.showContentKey][this.showContentIndex]) {
					this[this.showContentKey][this.showContentIndex].du = 1
					http({
						method: 'post',
						url: '/member/msgInfo',
						data: {
							id: this[this.showContentKey][this.showContentIndex].id
						}
					}).then((res) => {
						if (res.code == 200) {
							this.getUnreadAll()
						} else {
							this.$message.error(res.message);
						}
					})
				}
				if (this[this.showContentKey]) {
					if (this[this.showContentKey][this.showContentIndex]) {
						return this[this.showContentKey][this.showContentIndex]
					}
				}
				return ''
			},
			isNext () {
				if (this[this.showContentKey]) {
					if (this[this.showContentKey].length == (this.showContentIndex + 1)) {
						return false
					}
				}
				return true
			},
			isPerv () {
				if (this.showContentIndex == 0) {
					return false
				}
				return true
			}
		}, 
		activated () {
			if (this.$route.query.type && this.$route.query.type == 3) {
				this.tabidx = 3
			}
		},
		mounted () {
			if (this.$route.query.type && this.$route.query.type == 3) {
				this.tabidx = 3
			}
			this.getUnreadAll()
			this.getSysNotice()
			this.getUserMsg()
		},
		methods: {
			...mapActions('user', ['getUnreadNumber']),
			selectItemCheck (index) {
				this.userMsgList = this.userMsgList.map((v, idx) => {
					if (idx == index) {
						v.check = !v.check
					}
					return v
				})
				let isAllCheck = true
				this.userMsgList.forEach((v) => {
					if (!v.check) {
						isAllCheck = false
					}
				})
				this.isSelectAll = isAllCheck
			},
			allRemove () {
				
				this.$confirm({
					title:  '温馨提示',
					content:  '刪除後將無法恢複, 確定要刪除全部公告嗎?',
				          okText: '同意',
				          cancelText: '取消',
				          type: 'warning',
						  onOk:()=>{
							this.pageLoading = true
							http({
								method: 'post',
								url: '/member/msgDel',
								data: {
									id: this.userMsgList.map((v) => {
										return v.id
									}).join()
								}
							}).then((res) => {
								if (res.code == 200) {
									this.getUnreadAll()
									this.getUserMsg()
									this.$message.success('操作成功');
									this.isSelectAll = false
								} else {
									this.$message.error(res.message);
								}
							})
						  }
				        })
				
			},
			allMsgRead () {
				this.pageLoading = true
				http({
					method: 'post',
					url: '/member/msgRead',
					data: {
						id: this.userMsgList.map((v) => {
							return v.id
						}).join()
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getUnreadAll()
						this.getUserMsg()
						this.$message.success('操作成功');
						this.isSelectAll = false
					} else {
						this.$message.error(res.message);
					}
				})
			},
			noShowAll () {
				this.showall = false
				this.userMsgList = this.userMsgList.map((v) => {
					return {
						...v,
						check: false
					}
				})
			},
			selctAll () {
				this.isSelectAll = !this.isSelectAll
				this.userMsgList = this.userMsgList.map((v) => {
					return {
						...v,
						check: this.isSelectAll
					}
				})
			},
			msgRead () {
				let ids = []
				this.userMsgList.filter((v) => {
					if (v.check) {
						ids.push(v.id)
					}
				})
				if (ids.length == 0) {
					this.$message.warning('請先選擇消息');
					return
				}
				this.pageLoading = true
				http({
					method: 'post',
					url: '/member/msgRead',
					data: {
						id: ids.join()
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getUserMsg()
						this.getUnread(this.tabidx)
						this.$message.success('操作成功');
						this.isSelectAll = false
					} else {
						this.$message.error(res.message);
					}
				})
			},
			msgDel  () {
				let ids = []
				this.userMsgList.filter((v) => {
					if (v.check) {
						ids.push(v.id)
					}
				})
				if (ids.length == 0) {
					this.$message.warning('請先選擇消息');
					return
				}
				this.$confirm({
						  title:  '温馨提示',
						  content:  '刪除後將無法恢複, 確定要刪除嗎?',
						        okText: '確定刪除',
						        cancelText: '取消',
						        type: 'warning',
						  	  onOk:()=>{
								  this.pageLoading = true
								  http({
								  	method: 'post',
								  	url: '/member/msgDel',
								  	data: {
								  		id: ids.join()
								  	}
								  }).then((res) => {
								  	if (res.code == 200) {
								  		this.getUserMsg()
								  		this.getUnread(this.tabidx)
								  		this.$message.success('操作成功');
								  		this.isSelectAll = false
								  	} else {
								  		this.$message.error(res.message);
								  	}
								  })
							  }
				        })
			},
			setTab (type) {
				this.pagination.current_page = 1
				this.pagination.total = 0
				this.userMsgList = []
				this.spinning = true
				this.tabidx = type
				this.showall = false
				this.isSelectAll = false
				this.pageLoading = false
				if (type == 3) {
					this.getSysNotice()
				} else {
					this.getUserMsg()
				}
			}, 
			pervContent () {
				if (this.isPerv) {
					this.showContentIndex = this.showContentIndex - 1
				}
			},
			nextContent () {
				if (this.isNext) {
					this.showContentIndex = this.showContentIndex + 1
				}
			},
			showContent (key, index) {
				this.showContentKey = key
				this.showContentIndex  = index
				this.show = true
			},
			getUnreadAll () {
				this.getUnread(1)
				this.getUnread(2)
			},
			getUnread (type) {
				this.getUnreadNumber()
				http({
					method: 'post',
					url: '/member/unread',
					data: {
						type: type
					}
				}).then((res) => {
					if (res.code == 200) {
						this['tabUnread' + type] = typeof res.data == 'number' ? res.data : 0
					} else {
						this.$message.error(res.message);
					}
				})
			},
			changePagination (index) {
				// this.spinning = true
				this.isSelectAll = false
				this.pagination.current_page = index
				this.pageLoading = true
				this.getUserMsg()
			},
			getUserMsg () {
				http({
					method: 'post',
					url: '/member/msg',
					data: {
						page: this.pagination.current_page,
						type: this.tabidx
					}
				}).then((res) => {
					if (res.code == 200) {
						this.userMsgList = res.data.list.map((v) => {
							return {
								...v,
								check: false
							}
						})
						this.pagination = {
							total: res.data.meta.pagination.total,
							current_page: this.pagination.current_page
						}
					} else {
						this.$message.error(res.message);
					}
					this.pageLoading = false
					this.spinning = false
				})
			},
			getSysNotice () {
				http({
					method: 'get',
					url: '/sysNotice',
					data: {
						
					}
				}).then((res) => {
					if (res.code == 200) {
						this.sysNoticeList = res.data
					} else {
						this.$message.error(res.message);
					}
					this.spinning = false
				})
			}
		},
		
	};
</script>

<style lang="less" scoped>
	.bottom_box {
		position: relative;
		height: 56px;
		width: 100%;

		.headerBox {
			left: 0;
			top: 10px;

			a {
				display: block;
				float: left;
				margin-right: 23px;
				text-align: center;
				line-height: 40px;
				height: 40px;

				img {
					width: 18px;
					height: 18px;
					vertical-align: text-bottom;
					display: inline-block;
					margin-right: 2px;
				}

				span {
					color: #4b5e6f;

					&.biaoji {
						color: #94a6b7;
					}
				}
			}
		}
	}

	.table1 {
		border-top: none;
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;

		tbody tr:nth-of-type(odd) {
			background-color: #fff;
		}

		tr {
			height: 56px;
		}

		td:nth-child(1) {
			width: 328px;
			padding-left: 20px;
			padding-right: 40px;
		}

		td:nth-child(2) {
			width: 346px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 346px;
			color: #94a6b7;
		}

		td:nth-child(3) {
			color: #94a6b7;
			text-align: right;
			padding-right: 20px;
		}

		tr td {
			font-size: 14px;
			padding: 0;
			line-height: 56px;
		}

		td {
			cursor: pointer;
		}

		tr {
			height: 56px;
		}

		tr:nth-child(2n) {
			background-color: rgba(243, 246, 249, .5);
		}

		tbody tr:nth-of-type(odd) {
			background-color: #fff;
		}

		.flex {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;


			.quanquan {
				margin-right: 12px;
				position: relative;
				width: 16px;

				img {
					vertical-align: middle;
				}
			}

			.typeIcon {
				margin-right: 12px;
				position: relative;

				img {
					width: 20px;
					height: 20px;
					vertical-align: middle;
				}

				&.active {
					&:after {
						content: " ";
						display: table;
						width: 6px;
						height: 6px;
						background-color: red;
						position: absolute;
						right: -3px;
						top: 13px;
						border-radius: 50%;
					}
				}

			}

			.ellipsis {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 200px;
				color: #333;
			}
		}
	}

	.content_title {
		margin-top: 20px;
		border-radius: 10px;
		display: flex;

		a {
			width: 150px;
			height: 50px;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			text-align: center;
			color: #4b5e6f;
			position: relative;
			border: solid 1px #efeff4;

			&.active {
				color: #333;
				font-weight: bold;
				border-bottom: 2px solid #3678fe;
			}

			span {
				width: auto;
				border-radius: 15px;
				margin-left: 5px;
				font-size: 12px;
				font-weight: normal;
				padding: 0 6px;
				min-width: 24px;
				height: 18px;
				line-height: 18px;
				top: 10px;
				left: 90px;
				position: absolute;
				background:red;
				-webkit-box-shadow: 0 10px 20px rgba(60, 142, 254, .2);
				box-shadow: 0 10px 20px rgba(60, 142, 254, .2);
				color: #fff;
			}

			&:nth-child(2) {
				border-left: none;
			}

			&:nth-child(3) {
				border-left: none;
			}
		}
	}
	
	
	.no_list {
		width: 100%;
		height: 350px;
		padding-top: 100px;
		text-align: center;
	}
	
	.no_list img {
		margin-bottom: 30px;
	}
	
	.no_list_div {
		margin-bottom: 30px;
	}
	
	.no_list_div span {
		color: #94a6b7;
	}
	
	.no_list_div .Button333 {
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;
		cursor: pointer;
	}
	
	.no_list_div .Button333:hover {
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
		background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
	}
</style>
