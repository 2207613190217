<template>
	<div class="layout_venue sport_bg">
		<div class="layout_container sport_container">
			<div class="sport_leftWrap  nopagafter" :class="{'sport_fadeOut__3D40R' : show == true}">
				<div class="image_imageContainer image_contain sport_top"
					style="width: 520px; height: 300px; cursor: inherit;">
					<div
						style="display: block; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
						<div style="display: block; box-sizing: border-box; padding-top: 57.6923%;"></div>
						<my-img srcText="gaming/icon10.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer image_contain sport_bottom"
					v-if="getGame('TFG').on_line == 0" 
					style="width: 750px; height: 640px; cursor: inherit;"  :style="{'visibility': currentIndexValue == 0 ? 'inherit' : 'hidden'}">
					<div
						style="display: block; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
						<div style="display: block; box-sizing: border-box; padding-top: 85.3333%;"></div>
						
						<my-img srcText="gaming/page1/icon1.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer image_contain sport_bottom"
					
					style="width: 750px; height: 640px; cursor: inherit; "  :style="{'visibility': currentIndexValue == 1 ? 'inherit' : 'hidden'}">
					<div
						style="display: block; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
						<div style="display: block; box-sizing: border-box; padding-top: 85.3333%;"></div>
						
						<my-img srcText="gaming/page2/icon1.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer image_contain sport_bottom"
					v-if="getGame('OBDJ').on_line == 0" 
					style="width: 750px; height: 640px; cursor: inherit; " :style="{'visibility': currentIndexValue == 2 ? 'inherit' : 'hidden'}">
					<div
						style="display: block; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
						<div style="display: block; box-sizing: border-box; padding-top: 85.3333%;"></div>
						
						<my-img srcText="gaming/page3/icon1.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
			</div>
			<div class="layout_rightWrap sport_rightWrap">
				<div class="layout_section " :class="{'layout_fadeOut' : show == true}">
					<div class="image_imageContainer image_contain layout_title "
						style="width: 500px; height: 148px; cursor: inherit;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;">
								<img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAwIiBoZWlnaHQ9IjE0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4="
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>
							<my-img :srcText="list[currentIndexValue].title" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
						</div>
					</div>
					<p class="layout_desc ">{{list[currentIndexValue].content}}</p>
					<div class="image_imageContainer image_contain  "
						style="width: auto; height: 42px; cursor: inherit;">
						
							<my-img :srcText="list[currentIndexValue].urlList" style="width: auto; height: 42px;"/>
							</div>
				</div>
				<div class="layout_tabs">
					<ul class="tabs_icons">
            <li class="game_maintain_icon" v-if="getGame('TFG').on_line == 0" :class="{'tabs_active' : currentIndex == 0 , 'game_maintain': getGame('TFG').maintain == 1}" @click="setTab(0)">
              <div class="tabs_icon">
                <div class="tabs_logo tabs_logo_2"></div>
              </div>
              <p>{{$t('main.game.dj.lh.name')}}</p>
            </li>
						<li class="game_maintain_icon" v-if="getGame('OBDJ').on_line == 0" :class="{'tabs_active' : currentIndex == 2 && getGame('OBDJ').maintain != 1, 'game_maintain': getGame('OBDJ').maintain == 1}" @click="setTab(2)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_1" ></div>
							</div>
							<p>{{$t('main.game.dj.ob.name')}}</p>
						</li>

					</ul>
				</div>
				<div class="layout_entryBtn " v-show="isOpen" @click="link(list[currentIndex].plat_type)">进入游戏</div>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: "appbox",
		data() {
			return {
				list: [{
						image: 'gaming/page1/icon1.png',
						title: 'gaming/page1/icon2.png',
						content: this.$t('main.game.dj.lh.des'),
						urlList: 'gaming/page1/icon3.png',
						plat_type: "TFG"
					},
					{
							image: 'gaming/page1/icon1.png',
							title: 'gaming/page1/icon2.png',
							content: this.$t('main.game.dj.lh.des'),
							urlList: 'gaming/page1/icon3.png',
							plat_type: "TFG"
					},
					{
						image: 'gaming/page3/icon1.png',
						title: 'gaming/page1/icon2.png',
						content: this.$t('main.game.dj.ob.des'),
						urlList: 'gaming/page3/icon3.png',
						plat_type: "OBDJ"
					}
				],
				currentIndex: 2,
				currentIndexValue: 2,
				show: false,
				
				NewArrs: []
			};
		},
		watch: {
			gameConfig () {
				this.NewArrs = this.gameConfig['电竞']
        if (this.NewArrs.length == 0 ) return
        if(this.NewArrs[0].plat_type == 'TFG' ){
          this.currentIndex = 0
          this.currentIndexValue = 0
        }else if(this.NewArrs[0].plat_type == 'OBDJ' ){
          this.currentIndex = 2
          this.currentIndexValue = 2
        }
        return;

				if (this.getGame('OBDJ').on_line == 1) {
					this.currentIndex = 0
					this.currentIndexValue = 0
				}
				if (this.getGame('OBDJ').on_line == 1 && this.getGame('TFG').on_line == 1) {
					this.currentIndex = -1
					this.currentIndexValue = -1
				}
			}
		},
		computed: {
			isOpen: function () {
				let is = true
				if (this.currentIndex == 2 && (this.getGame('OBDJ').on_line == 1 || this.getGame('OBDJ').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 0 && (this.getGame('TFG').on_line == 1 || this.getGame('TFG').maintain == 1)) {
					is = false
				}
				return is
			},
			...mapGetters(['gameConfig']),
		},
		methods: {
			getGame (type) {
				let data = {}
				this.NewArrs.forEach((v) => {
					if (v.plat_type == type) {
						data = v
					}
				})
				return data
			},
			setTab (idx) {
				this.show = false
				this.currentIndex = idx
				this.show = true
				
				setTimeout(() => {
					this.currentIndexValue = idx
				}, 400)
				
				setTimeout(() => {
					this.show = false
				}, 400)
			},
			link(type) {
				let self = this
				if (!localStorage.getItem('access_token')) {
					let wxts = this.$t('main.login_ts')
					this.$confirm({
						title: this.$t('main.wxts'),
						content: h => < div > {wxts} </div>,
						okText: this.$t('main.okText'),
						cancelText: this.$t('main.cancelText'),
						onOk() {
							self.$router.push({
							  path: "/login",
							});
						},
						onCancel() {
						},
						class: 'testbo',
					});
				} else {
					let data = {
						gameData: {}
					}
					this.NewArrs.forEach((v) => {
						if (v.plat_type == type) {
							data.gameData = v
						}
					})
					let href = window.location.origin + `/game?plat_type=${data.gameData.plat_type}&game_type=${data.gameData.game_type}&game_code=${data.gameData.game_code}`
					// window.open(href, "_blank");
					this.$store.dispatch('user/linkGamePage', {
						href: href,
						platType: data.gameData.plat_type,
						gameName: data.gameData.name
					})
				}
			}
		},
		activated() {
			this.NewArrs = this.gameConfig['电竞']
		},
		destroyed() {
	
		},
	};
</script>

<style scoped lang="less">
	.tabs_icons li:hover {
		.tabs_logo_1 {
			// background-image: url(../../assets/gaming/icon4.png);
			background-image: url(/assets/gaming/icon4.png);
		}
		.tabs_logo_2 {
			// background-image: url(../../assets/gaming/icon6.png);
			background-image: url(/assets/gaming/icon6.png);
		}
		.tabs_logo_3 {
			// background-image: url(../../assets/gaming/icon8.png);
			background-image: url(/assets/gaming/icon8.png);
		}
	}
	.tabs_active {
		.tabs_logo_1 {
			// background-image: url(../../assets/gaming/icon4.png);
			background-image: url(/assets/gaming/icon4.png);
		}
		.tabs_logo_2 {
			// background-image: url(../../assets/gaming/icon6.png);
			background-image: url(/assets/gaming/icon6.png);
		}
		.tabs_logo_3 {
			// background-image: url(../../assets/gaming/icon8.png);
			background-image: url(/assets/gaming/icon8.png);
		}
	}
	.tabs_logo_1 {
		// background-image: url(../../assets/gaming/icon5.png);
		background-image: url(/assets/gaming/icon5.png);
	}
	.tabs_logo_2 {
		// background-image: url(../../assets/gaming/icon7.png);
		background-image: url(/assets/gaming/icon7.png);
	}
	.tabs_logo_3 {
		// background-image: url(../../assets/gaming/icon9.png);
		background-image: url(/assets/gaming/icon9.png);
	}
	.tabs_icons {
		display: -webkit-flex;
		display: flex
	}

	.tabs_icons>li {
		position: relative;
		text-align: center;
		cursor: pointer;
		margin-right: 16px
	}

	.tabs_icons>li .tabs_icon {
		width: 76px;
		height: 76px;
		margin-bottom: 16px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-content: center;
		align-content: center;
		position: relative;
		z-index: 1;
		box-shadow: 0 5px 10px 0 rgba(112, 146, 215, .14), inset 0 -4px 0 0 #fff;
		border-radius: 14px
	}

	.tabs_icons>li .tabs_icon:before {
		content: "";
		z-index: -1;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		// background-image: url(../../assets/gaming/icon1.png);
		background-image: url(/assets/gaming/icon1.png);
		background-size: 100% 100%
	}

	.tabs_icons>li .tabs_icon .tabs_logo {
		width: 50px;
		height: 50px;
		margin-top: 13px;
		display: inline-block;
		background-size: 50px 50px
	}

	.tabs_icons>li p {
		color: #6c7ba8;
		font-size: 16px
	}

	.tabs_icons>li:hover .tabs_icon {
		-webkit-animation: tabs_in__2YKAy .5s ease forwards;
		animation: tabs_in__2YKAy .5s ease forwards
	}

	.tabs_icons>li:hover .tabs_icon:before {
		// background-image: url(../../assets/gaming/icon2.png)
		background-image: url(/assets/gaming/icon2.png)
	}

	@-webkit-keyframes tabs_in__2YKAy {
		0% {
			-webkit-transform: scale(1.1);
			transform: scale(1.1)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	@keyframes tabs_in__2YKAy {
		0% {
			-webkit-transform: scale(1.1);
			transform: scale(1.1)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	.tabs_icons>li.tabs_active .tabs_icon,
	.tabs_icons>li:hover .tabs_icon {
		box-shadow: 0 7px 13px 0 #b6bfdb
	}

	.tabs_icons>li.tabs_active .tabs_icon:before,
	.tabs_icons>li:hover .tabs_icon:before {
		// background-image: url(../../assets/gaming/icon2.png)
		background-image: url(/assets/gaming/icon2.png)
	}

	.layout_venue {
		position: relative;
		margin: 0 auto;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		// -webkit-align-items: center;
		// align-items: center;
		background-size: 100% 100%;
		min-height: 960px;
		height: calc(100vh - 210px);
		padding-top: 170px
	}

	.layout_venue .layout_container {
		width: 1200px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-content: center;
		align-content: center;
		box-sizing: border-box;
		margin: 0 auto;
		position: relative
	}

	.layout_venue .layout_container .layout_rightWrap {
		width: 530px;
		margin-right: 33px;
		letter-spacing: .5px;
		text-align: justify
	}

	.layout_venue .layout_container .layout_rightWrap .layout_section {
		min-height: 393px;
		-webkit-animation: layout_animationRightIn__DIUpA 1s forwards;
		animation: layout_animationRightIn__DIUpA 1s forwards
	}

	.layout_venue .layout_container .layout_rightWrap .layout_section.layout_fadeOut {
		-webkit-animation: layout_animationRightOut__xFftF 0.3s forwards;
		animation: layout_animationRightOut__xFftF 0.3s forwards
	}

	.layout_venue .layout_container .layout_rightWrap .layout_title {
		max-width: 100%;
		height: 148px
	}

	.layout_venue .layout_container .layout_rightWrap .layout_desc {
		line-height: 24px;
		margin: 20px 0 !important;
		font-size: 16px;
		color: #6c7ba8;
		letter-spacing: .5px;
		text-align: justify
	}

	@-webkit-keyframes layout_animationRightIn__DIUpA {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(50px, 0, 0);
			transform: translate3d(50px, 0, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@keyframes layout_animationRightIn__DIUpA {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(50px, 0, 0);
			transform: translate3d(50px, 0, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@-webkit-keyframes layout_animationRightOut__xFftF {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	@keyframes layout_animationRightOut__xFftF {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	.layout_venue .layout_container .layout_rightWrap .layout_tabs {
		width: 100%
	}

	.layout_venue .layout_container .layout_rightWrap .layout_entryBtn {
		width: 160px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		margin-top: 47px;
		color: #fff;
		font-size: 18px;
		background-image: none !important;
		position: relative;
		z-index: 1;
		cursor: pointer;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		box-shadow: 0 2px 4px 0 #5da7eb;
		border-radius: 25px
	}

	.layout_venue .layout_container .layout_rightWrap .layout_entryBtn:before {
		content: "";
		z-index: -1;
		top: 0;
		display: block;
		width: 160px;
		height: 50px;
		position: absolute;
		left: 50%;
		-webkit-transform: translate3d(-50%, 0, 0);
		transform: translate3d(-50%, 0, 0);
		// background-image: url(../../assets/gaming/icon3.png);
		background-image: url(/assets/gaming/icon3.png);
		background-size: 100% 100%
	}

	.layout_venue .layout_container .layout_rightWrap .layout_entryBtn:hover {
		-webkit-transform: scale(1.05);
		transform: scale(1.05)
	}

	.sport_bg {
		background: #f0f6ff
	}

	.sport_container {
		height: 650px
	}

	.sport_leftWrap {
		min-width: 750px;
		height: 100%;
		position: relative;
		left: -100px
	}

	.sport_leftWrap .sport_top {
		-webkit-animation: sport_animationSportsIn__zDAU- .7s forwards;
		animation: sport_animationSportsIn__zDAU- .7s forwards;
		margin: 13px auto 0
	}

	.sport_leftWrap .sport_bottom {
		-webkit-animation: sport_animationPostersIn__I9pCr 1s forwards;
		animation: sport_animationPostersIn__I9pCr 1s forwards;
		position: absolute;
		left: 0;
		top: 0
	}

	.sport_leftWrap.sport_fadeOut__3D40R .sport_top {
		-webkit-animation: sport_animationSportsOut__3V8Sn .4s forwards;
		animation: sport_animationSportsOut__3V8Sn .4s forwards
	}

	.sport_leftWrap.sport_fadeOut__3D40R .sport_bottom {
		-webkit-animation: sport_animationPostersOut__3f5Q3 1s forwards;
		animation: sport_animationPostersOut__3f5Q3 1s forwards
	}

	@-webkit-keyframes sport_animationSportsIn__zDAU- {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(0, -50px, 0);
			transform: translate3d(0, -50px, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@keyframes sport_animationSportsIn__zDAU- {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(0, -50px, 0);
			transform: translate3d(0, -50px, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@-webkit-keyframes sport_animationSportsOut__3V8Sn {
		0% {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		to {
			opacity: 0;
			-webkit-transform: translate3d(0, -50px, 0);
			transform: translate3d(0, -50px, 0)
		}
	}

	@keyframes sport_animationSportsOut__3V8Sn {
		0% {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		to {
			opacity: 0;
			-webkit-transform: translate3d(0, -50px, 0);
			transform: translate3d(0, -50px, 0)
		}
	}

	@-webkit-keyframes sport_animationPostersIn__I9pCr {
		0% {
			opacity: 0;
			-webkit-transform: rotateX(-20deg);
			transform: rotateX(-20deg)
		}

		to {
			opacity: 1;
			-webkit-transform: rotateX(0);
			transform: rotateX(0)
		}
	}

	@keyframes sport_animationPostersIn__I9pCr {
		0% {
			opacity: 0;
			-webkit-transform: rotateX(-20deg);
			transform: rotateX(-20deg)
		}

		to {
			opacity: 1;
			-webkit-transform: rotateX(0);
			transform: rotateX(0)
		}
	}

	@-webkit-keyframes sport_animationPostersOut__3f5Q3 {
		0% {
			opacity: 1;
			-webkit-transform: rotateX(0);
			transform: rotateX(0)
		}

		to {
			opacity: 0;
			-webkit-transform: rotateX(-20deg);
			transform: rotateX(-20deg)
		}
	}

	@keyframes sport_animationPostersOut__3f5Q3 {
		0% {
			opacity: 1;
			-webkit-transform: rotateX(0);
			transform: rotateX(0)
		}

		to {
			opacity: 0;
			-webkit-transform: rotateX(-20deg);
			transform: rotateX(-20deg)
		}
	}

	.sport_rightWrap {
		position: relative;
		padding-top: 10px;
		left: -84px
	}
</style>
