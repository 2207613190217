<template>
	<a-modal title="修改銀行卡" :footer="null" width="680px" :visible="show" 
		@cancel="hideModel">
		<div class="form forminut">
			<div>
				<!-- v-show="showContent" -->
				<div >
					<div class="bind_bank_item " :class="{'error': formError.bank_name}">
						<div class="bind_bank_item_left">開戶銀行：</div>
						<div class="bind_bank_item_right">
							<a-select v-model="form.bank_name" placeholder="請選擇開戶銀行" style="width: 486px;height:40px;margin-bottom: 20px;"
								@change="bankNameChange">
								<a-select-option :value="item" v-for="(item, index) in banks" :key="index">
									{{item}}
								</a-select-option>
							</a-select>
						</div>
					</div>
					<div class="bind_bank_item " :class="{'error': formError.bank_username}">
						<div class="bind_bank_item_left">持卡人姓名：</div>
						<div class="bind_bank_item_right">
							<input type="text" placeholder="請輸入持卡人姓名" maxlength="24" class="Input"
								v-model="form.bank_username" @input="isAddBank('bank_username')">
							<span class="tips">為了您的資金能夠迅速到賬，請確保填寫的姓名與銀行卡的開戶姓名一致</span>
							<span class="tips-err">請輸入至少長度為2的姓名</span>
						</div>
					</div>
					<div class="bind_bank_item" :class="{'error': formError.bank_card}">
						<div class="bind_bank_item_left">銀行卡號：</div>
						<div class="bind_bank_item_right">
							<input type='number' placeholder="請輸入銀行卡號" maxlength="23" class="Input" @input="isAddBank('bank_card')" v-model.number="form.bank_card">
							<span class="tips">請認真校對銀行卡號，卡號錯誤資金將無法到賬</span>
							<span class="tips-err">請輸入合法的銀行卡號</span>
						</div>
					</div>
					
					
				</div>
				<div class="bind_bank_item " :class="{'error': formError.phone}">
						<div class="bind_bank_item_left">手機號碼：</div>
						<div class="bind_bank_item_right">
							<p class="inputvalule">{{form.phone}}</p>
							<!-- <input type='number' :disabled="isCzPhone" placeholder="请输入手机号码" class="Input" @input="isAddBank('phone')" v-model.number="form.phone"> -->
							<span class="tips">手機號碼將有助於您找回賬號，同時也是客服人員確認您身份的重要依據</span>
							<span class="tips-err">請輸入合法的手機號碼</span>
						</div>
				</div>
				<div class="bind_bank_item " :class="{'error': formError.captcha}">
					<div class="bind_bank_item_left">驗證碼：</div>
					<div class="bind_bank_item_right">
						<input :disabled="codeDisabled" @input="isCode" type='number' placeholder="驗證碼" maxlength="6" class="Input " 
							v-model.number="form.captcha">
						<div class="bindCount">
							<button class="" @click="getPhoneCode"><span class="countDown__verCode__2--mX ">{{codeText}}</span></button>
						</div>
					</div>
				</div>
				<div class="checktips" @click="linkCs">
					<my-img srcText="mycenter/withdrawals/icon6.svg" />
					有任何疑問請聯系<span style="cursor: pointer; margin-left: 5px;">在線客服</span>
				</div>
				<button class="Button333" @click="addBank" :class="{'disabled': addBtnDisabled}"
					style="width: 300px; height: 50px; margin: 30px auto 0px; display: block;">{{btnText}}</button>
			</div>
		</div>
	</a-modal>
</template>

<script>
	import http from '@/http.js'
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {
				show: false,
				form: {
					bank_username: '',
					bank_card: '',
					bank_name: undefined,
					phone: '',
					captcha: '',
					id: 0
				},
				formError: {
					bank_username: false,
					bank_card: false,
					bank_name: false,
					phone: false,
					captcha: false,
				},
				codeDisabled: true,
				addBtnDisabled: true,
				codeText: '獲取驗證碼',
				isCzPhone: false,
				
				showContent: false,
				
				btnText: '修改銀行卡'
			};
		},
		computed: {
			...mapGetters(['banks', 'config']),
		},
		methods: {
			linkCs () {
				window.open(this.config.service_link)
			},
			showModel(phone, bankId, data) {
				this.show = true
				this.hideModel('no')
				if (phone) {
					this.isCzPhone = true
					this.form.phone = phone
					this.codeDisabled = false
				}
				this.form = {
					bank_username: data.bank_username,
					bank_card: data.bank_card,
					bank_name: data.bank_name,
					phone: this.form.phone,
					captcha: '',
					id: bankId
				}
			},
			hideModel (type) {
				this.form = {
					bank_username: '',
					bank_card: '',
					bank_name: undefined,
					phone: '',
					captcha: ''
				}
				this.formError = {
					bank_username: false,
					bank_card: false,
					bank_name: false,
					phone: false,
					captcha: false,
				}
				this.codeDisabled = true
				this.addBtnDisabled = true
				this.showContent = false
				this.btnText = '修改銀行卡'
				this.codeText = '獲取驗證碼'
				if (type != 'no') {
					this.show = false
				}
			},
			
			// 
			bankNameChange () {
				
			},
			
			// 添加银行卡
			addBank () {
				if (this.addBtnDisabled) {
					return
				}
				this.isAddBank('all')
				http({
					method: 'post',
					url: '/member/editBindBank',
					data: this.form
				}).then((res) => {
					if (res.code == 200) {
						this.$emit('getNewList')
						this.$message.success(res.message);
						this.hideModel()
						this.show = false
					} else {
						this.$message.error(res.message);
					}
				})
			},
			
			// 验证手机短信
			isCode () {
				if (this.form.captcha.length == 6) {
					// this.showContent = true
					this.btnText = '修改銀行卡'
				}
				this.isAddBank('all')
			},
			
			// 验证是否可以添加
			isAddBank (type) {
				let isForm = true
				let isformError = true
				
				Object.values(this.form).forEach((v)=> {
					if (v == '') {isForm = false}
				})
				
				Object.values(this.formError).forEach((v)=> {
					if (v == true) {isformError = false}
				})
				if (type == 'bank_username' || type == 'all') {
					if (this.form.bank_username.length < 2) {
						this.formError.bank_username = true
					}else {
						this.formError.bank_username = false
					}
				}
				if (type == 'bank_card' || type == 'all') {
					if (!this.form.bank_card) {
						this.formError.bank_card = true
					} else {
						this.formError.bank_card = false
					}
				}
				if (type == 'phone' || type == 'all') {
					var regex = /^(\+852\s)?[123456789]{1}\d{7}$/;
					if (!regex.test(this.form.phone)) {
						this.formError.phone = true
					} else {
						this.formError.phone = false 
					}
				}
				
				if (isForm && isformError) {
					this.addBtnDisabled = false
				} else {
					this.addBtnDisabled = true
				}
			},
			phoneCensor() {
				var regex = /^(\+852\s)?[123456789]{1}\d{7}$/;
				this.phoneIsError = !regex.test(this.phone);
				this.phoneIsSuccess = !this.phoneIsError;
			},
			// // 验证手机号是否合法
			// phoneBlur() {
			// 	var patt = /^[1][3-9][\d]{9}/
			// 	if (patt.test(this.form.phone)) {
			// 		this.codeDisabled = false
			// 		this.formError.phone = false
			// 	} else {
			// 		this.formError.phone = true
			// 	}
			// },
			
			// 获取验证码
			getPhoneCode () {
				if (this.codeDisabled) {
					return
				}
				if (this.codeText != '獲取驗證碼') {
					return
				}
				let num = 60
				this.getCodeText = num + 's'
				let tim = setInterval((v) => {
					num = num - 1
					if (num == 0) {
						clearInterval(tim)
						this.codeText = '獲取驗證碼'
					} else {
						this.codeText = num  + 's'
					}
				}, 1000)
				http({
					method: 'post',
					url: '/member/sendSms',
					data: {
						phone: this.form.phone,
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message.success(res.message);
					} else {
						clearInterval(tim)
						this.codeText = '獲取驗證碼'
						this.$message.error(res.message);
					}
				})
			},
		}
	};
</script>

<style lang="less" scoped>
	
	.form {

		padding: 0 26px;
		padding-top: 20px;
		padding-bottom: 20px;
		
		.Input[disabled]{
			background: #f2f4f5 !important;
		}
		
		.countDown__verCode__2--mX {
			cursor: pointer;
		}

		.Button333 {
			cursor: pointer;
			border: none;
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
			background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
			color: #fff;
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			transition: all .3s ease;
			font-size: 16px;
			border-radius: 6px;

			&:hover {
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
				background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			}

			&.disabled {
				opacity: .4;
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
				background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
			}
		}

		.checktips {
			color: #999;
			font-size: 12px;
			margin: 30px auto 0;
			text-align: center;
			display: block;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;

			span {
				color: #3678fe;
			}
		}

		.bind_bank_item {
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			line-height: 38px;

			&.error {
				.tips {
					display: none !important;
				}

				.tips-err {
					display: block !important;
				}

				.bind_bank_item_right>input {
					border-color: red;

					&:focus {
						-webkit-transition: background-color .2s ease, border .2s ease;
						transition: background-color .2s ease, border .2s ease;
						border: solid 1px red;
						-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
						box-shadow: 0 10px 20px 0 #dae8f2;
					}
				}
			}

			&:after {
				content: "";
				display: block;
				clear: both;
				overflow: hidden;
			}

			.bind_bank_item_left {
				font-size: 14px;
				color: #4b5e6f;
				min-width: 90px;
				text-align: right;
			}

			.bind_bank_item_right {
				text-align: left;
				margin-left: 10px;
				position: relative;
				
				.inputvalule {
					font-size: 12px;
					width: 486px;
					height: 40px;
					border-radius: 6px;
					padding: 0 20px;
					border: solid 1px #d8dce7;
					color: #333;
					background-color: #fff;
					padding-left: 11px;
					background-color: rgba(216, 220, 231, .2);
					pointer-events: none;
				}

				.bindCount {
					position: absolute;
					right: 14px;
					top: 0;
					color: #94a6b7;

					&>button {
						border: none;
						outline: none;
						background: transparent;
					}
				}

				&>input {
					font-size: 12px;
					width: 486px;
					height: 40px;
					border-radius: 6px;
					padding: 0 20px;
					border: solid 1px #d8dce7;
					color: #333;
					background-color: #fff;
					padding-left: 11px;

					&.disabled {
						background-color: rgba(216, 220, 231, .2);
						pointer-events: none;
					}

					&:focus {
						-webkit-transition: background-color .2s ease, border .2s ease;
						transition: background-color .2s ease, border .2s ease;
						border: solid 1px #3678fe;
						-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
						box-shadow: 0 10px 20px 0 #dae8f2;
					}


				}

				.tips {
					color: #94a6b7;
					margin-bottom: 20px;
					font-size: 12px;
					margin-top: 7px;
					display: block;
					line-height: 1;
				}

				.tips-err {
					color: red;
					display: none;
					margin-bottom: 20px;
					font-size: 12px;
					margin-top: 7px;
					line-height: 1;
				}
			}
		}
	}
</style>
