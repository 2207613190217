/** echarts图表antv风格主题 */
export default {
    "color": [
        "#3aa1ff",
        "#4ecb73",
        "#36cbcb",
        "#fbd437",
        "#f2637b",
        "#975fe5",
        "#5254cf",
        "#435188"
    ],
    "backgroundColor": "rgba(252,252,252,0)",
    "textStyle": {},
    "title": {
        "textStyle": {
            "color": "#333333"
        },
        "subtextStyle": {
            "color": "#888888"
        }
    },
    "line": {
        "itemStyle": {
            "normal": {
                "borderWidth": "2"
            }
        },
        "lineStyle": {
            "normal": {
                "width": "3"
            }
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false
    },
    "radar": {
        "itemStyle": {
            "normal": {
                "borderWidth": "2"
            }
        },
        "lineStyle": {
            "normal": {
                "width": "3"
            }
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false
    },
    "bar": {
        "barCategoryGap": '50%',
        "itemStyle": {
            "normal": {
                "barBorderWidth": "0",
                "barBorderColor": "#ccc"
            },
            "emphasis": {
                "barBorderWidth": "0",
                "barBorderColor": "#ccc"
            }
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 2,
            "borderColor": "#fff"
        }
    },
    "scatter": {
        "itemStyle": {
            "normal": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            },
            "emphasis": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            }
        }
    },
    "boxplot": {
        "itemStyle": {
            "normal": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            },
            "emphasis": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            }
        }
    },
    "parallel": {
        "itemStyle": {
            "normal": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            },
            "emphasis": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            }
        }
    },
    "sankey": {
        "itemStyle": {
            "normal": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            },
            "emphasis": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            }
        }
    },
    "funnel": {
        "itemStyle": {
            "normal": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            },
            "emphasis": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            }
        }
    },
    "gauge": {
        "itemStyle": {
            "normal": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            },
            "emphasis": {
                "borderWidth": "0",
                "borderColor": "#cccccc"
            }
        }
    },
    "candlestick": {
        "itemStyle": {
            "normal": {
                "color": "#f04864",
                "color0": "#2fc25b",
                "borderColor": "#f04864",
                "borderColor0": "#2fc25b",
                "borderWidth": "2"
            }
        }
    },
    "graph": {
        "itemStyle": {
            "normal": {
                "borderWidth": "0",
                "borderColor": "#ccc"
            }
        },
        "lineStyle": {
            "normal": {
                "width": "1",
                "color": "#cccccc"
            }
        },
        "symbolSize": "8",
        "symbol": "emptyCircle",
        "smooth": false,
        "color": [
            "#3aa1ff",
            "#4ecb73",
            "#36cbcb",
            "#fbd437",
            "#f2637b",
            "#975fe5",
            "#5254cf",
            "#435188"
        ],
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#ffffff"
                }
            }
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "rgba(204,204,204,.1)",
            "borderColor": "rgba(204,204,204,.5)",
            "borderWidth": 1
        },
        "label": {
            "color": "#555",
            "textBorderColor": "#ffffff",
            "textBorderWidth": "1"
        }
    },
    "geo": {
        "itemStyle": {
            "normal": {
                "areaColor": "rgba(241,244,245,.3)",
                "borderColor": "#e3e5e8",
                "borderWidth": 1
            },
            "emphasis": {
                "areaColor": "rgba(63,177,227,0.25)",
                "borderColor": "#3fb1e3",
                "borderWidth": 1
            }
        },
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#ffffff"
                }
            },
            "emphasis": {
                "textStyle": {
                    "color": "#3fb1e3"
                }
            }
        }
    },
    "grid": {
        "top": 30,
        "right": 20,
        "left": 60,
        "bottom": 40
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            },
            "alignWithLabel": true
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "#555555"
            }
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "type": "dashed",
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": false,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "#555555"
            }
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "type": "dashed",
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "#555555"
            }
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#cccccc"
            }
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "#555555"
            }
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "normal": {
                "borderColor": "#888888"
            },
            "emphasis": {
                "borderColor": "#555555"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#888888"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#cccccc",
                "width": 1
            },
            "crossStyle": {
                "color": "#cccccc",
                "width": 1
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#cccccc",
            "width": "1"
        },
        "itemStyle": {
            "normal": {
                "color": "#cccccc",
                "borderWidth": "1"
            },
            "emphasis": {
                "color": "#cccccc"
            }
        },
        "controlStyle": {
            "normal": {
                "color": "#cccccc",
                "borderColor": "#cccccc",
                "borderWidth": 0.5
            },
            "emphasis": {
                "color": "#cccccc",
                "borderColor": "#cccccc",
                "borderWidth": 0.5
            }
        },
        "checkpointStyle": {
            "color": "#3aa1ff",
            "borderColor": "rgba(58,161,255,0.15)"
        },
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#888888"
                }
            },
            "emphasis": {
                "textStyle": {
                    "color": "#888888"
                }
            }
        }
    },
    "visualMap": {
        "itemHeight": 80,
        "itemWidth": 15,
        "color": [
            "#0050b3",
            "#4C84FF",
            "#bae7ff"
        ]
    },
    "dataZoom": {
        "backgroundColor": "rgba(255,255,255,0)",
        "dataBackgroundColor": "rgba(222,222,222,1)",
        "fillerColor": "rgba(58,161,255,0.15)",
        "handleColor": "#cccccc",
        "handleSize": "100%",
        "textStyle": {
            "color": "#888888"
        }
    },
    "markPoint": {
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#ffffff"
                }
            },
            "emphasis": {
                "textStyle": {
                    "color": "#ffffff"
                }
            }
        }
    }
}