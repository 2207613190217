<template>
  <div class="helpbox">
    <!-- 头部  -->
    <header-page :background1="true" />

    <div class="content3">
      <div class="help_box_left">
        <ul class="parentUl">
          <div @click="getContent(item.id)" :class="{'active': activeIndex == item.id}" v-for="(item,index) in list">
            <li>
              <div class="block" style="display: block"></div>
              <div class="titleImg">
				 <my-img :srcText="item.icon" />
              </div>
              <span class="title">{{item.name}}</span>
            </li>
          </div>
        </ul>
      </div>
      <div class="help_box_right">
		<a-spin :spinning="spinning">
			<div v-html="content"></div>
		</a-spin>
	  </div>
    </div>

    <!-- // footer -->
    <footer-page />
  </div>
</template>

<script>
// 头部
import HeaderPage from "@/components/HeaderPage";
// footer
import FooterPage from "@/components/FooterPage";
import http from '../../http.js'
export default {
  name: "help",
  components: {
    HeaderPage,
    FooterPage,
  },
  data() {
    return {
		spinning: true,
		activeIndex: 6,
		list: [{
			id: 6,
			name: this.$t('main.help.ti1'),
			icon: 'ico/icon7.png'
		},{
			id: 2,
			name: this.$t('main.help.ti2'),
			icon: 'ico/icon4.png'
		},{
			id: 3,
			name: this.$t('main.help.ti3'),
			icon: 'ico/icon5.png'
		},{
			id: 4,
			name: this.$t('main.help.ti4'),
			icon: 'ico/icon8.png'
		},{
			id: 5,
			name: this.$t('main.help.ti5'),
			icon: 'ico/icon6.png'
		},{
			id: 7,
			name: this.$t('main.help.ti6'),
			icon: 'ico/icon1.png'
		},{
			id: 8,
			name: this.$t('main.help.ti7'),
			icon: 'ico/icon3.png'
		}],
		content: ''
	};
  },
  activated() {
  	this.getContent(this.$route.query.id ? this.$route.query.id : 6)
  },
  methods: {
	  getContent (id) {
		  this.spinning = true
		  this.activeIndex = id
		  http({
		  	method: 'get',
		  	url: '/helps?id=' + this.activeIndex,
		  	data: {
		  	
		  	}
		  }).then((res) => {
			this.content = res.data.content
			this.spinning = false
		  })
	  }
  }
};
</script>

<style lang="less" scoped>
.helpbox {
  padding-top: 120px;
  background-color: #fff;
}
.content3 {
  width: 1200px;
  margin: 30px auto 60px;
  margin-top: 0;
  min-height: 616px;
  height: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  background-color: #fff;
  &:before {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
    overflow: hidden;
    content: "";
    display: table;
  }
  .help_box_right {
    padding: 30px;
    width: 100%;
    height: auto;
    overflow-y: auto;
    border-left: solid 1px #efefef;
    float: left;
    line-height: 2em;
    position: relative;
  }
  .help_box_left {
    width: 240px;
    float: left;
    .parentUl {
      padding: 20px 0;
      & > div.active {
        .title {
          color: #5eccff;
        }
      }
      li {
        width: 240px;
        height: 50px;
        color: #364a51;
        margin-bottom: 5px;
        line-height: 50px;
        position: relative;
        cursor: pointer;
        &:hover {
          .title {
            color: #5eccff;
          }
        }
        .block {
          height: 80px;
          width: 3px;
          position: absolute;
          left: 0;
          top: 0;
        }
        .titleImg img {
          margin-left: 40px;
          position: absolute;
          top: 16px;
          width: 20px;
		  min-width: 20px;
		  height: 20px;
        }
        .title {
          font-size: 14px;
          font-weight: bold;
          margin-left: 68px;
        }
      }
    }
  }
}
</style>
