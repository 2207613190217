<template>
	<div class="home">
		<div style="height:700px" :class="{'nocss': noAdcss}">
			<swiper :key="bannerKey" :options="bannerSwiperOption" v-if="bannerList.length != 0" ref="mySwiper" class="swiperdom" >
				<swiper-slide class="wwitew" v-for="(item,index) in bannerList" :key="bannerKey + '_' + index">
					<img class="banner-img" @load='imgonLoad(item, index)' style="display:none" :src="item.src" alt="" @click="openPage(item)">
					<!-- <img class="banner-img" :src="item.small_pic" v-show="!item.loadImg" alt="" @click="openPage(item)"> -->
					<div class="img-box" :style="{'background-image': 'url(' + ((bannerKey == 2 || item.loadImg) ? item.src : item.small_pic) + ')'}"></div>
				</swiper-slide>
				<div class="swiper-button-prev swiper-button-prev1" slot="button-prev"></div>
				<div class="swiper-button-next swiper-button-next1" slot="button-next"></div>
				<div class="swiper-pagination"  slot="pagination"></div>
			</swiper>
		</div>
		<div class="content-box">
			<bulletin />
			<player />
			<app-box />
			<popular />
			<service />
		</div>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import {
		mapGetters
	} from 'vuex';
	import "swiper/swiper.min.css";
	// 公告
	import Bulletin from '@/components/Page/Home/Bulletin/index.vue'
	// 球员
	import Player from '@/components/Page/Home/Player/index.vue'
	// app
	import AppBox from '@/components/Page/Home/App/index.vue'
	// 热门游戏
	import Popular from '@/components/Page/Home/Popular/index.vue'
	// 服务
	import Service from '@/components/Page/Home/Service/index.vue'
	
	import http from '../../http.js'
	
	export default {
		name: "Home",
		components: {
			Bulletin,
			Player,
			AppBox,
			Popular,
			Service,
			swiper,
			swiperSlide,
		},
		computed: {
			...mapGetters(['bannerList']),
		},
		data() {
			return {
				bannerSwiperOption: {
					speed: 600,
					loop: false,
					autoplay: {
						loop: false,
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					// 设置点击箭头
					navigation: {
						nextEl: ".swiper-button-next1",
						prevEl: ".swiper-button-prev1"
					},
					noSwiping : true,
					pagination: {
					    el: ".swiper-pagination",
						clickable: true,
					},
					effect: "fade",
				},
				noAdcss: true,
				
				bannerKey: 1
			};
		},
		methods: {
			
		},
		watch: {
			$route () {
				if (this.$route.name != "/Home") {
					this.noAdcss = true
				}
			}
		},
		activated() {
			setTimeout(() => {
				this.noAdcss = false
			}, 3000)
		},
		mounted() {
			// http({
			// 	method: 'get',
			// 	url: '/banner',
			// 	data: {
			// 		type: 1
			// 	}
			// }).then((res) => {
			// 	if (this.homeBannerList.length == 0) {
			// 		this.homeBannerList = res.data
			// 		// this.noAdcss = false
			// 	}
			// })
			if (this.$route.query.i){
				localStorage.setItem('access_token_i', this.$route.query.i)
			}
		},
		methods: {
			imgonLoad (data, index) {
				if (index == 0) {
					setTimeout(() => {
						data.loadImg = true
						if (this.bannerSwiperOption.loop === false) {
							this.bannerSwiperOption.loop = true
							this.bannerSwiperOption.autoplay.loop = true
							this.bannerKey = 2
						}
					}, 500)
				} else {
					data.loadImg = true
				}
			}
		},
		destroyed() {

		},
	};
</script>

<style lang="less" scoped>
	.wwitew {
		background: #f0f6ff;
	}
	.home {
		background: #f0f6ff;
	}
	.img-box {
		width: 100%;
		display: block;
		height: 700px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: top center;
	}
	.content-box {
		position: relative;
		width: 1200px;
		margin: 0 auto;
	}

	.cooperation-box {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin-top: 30px;
	}

	.swiperdom {
		height: 700px;
		img {
			width: 100%;
			display: block;
			height: 700px;
		}
	}
</style>
