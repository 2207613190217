<template>


	<div class="egame_egame__2Ws4m">
		<div class="egame_banner__3DJby" style="margin-bottom: 95px;">
			<div class="image_imageContainer__2dKjX image_cover__3B5D- egame_left__2e7ph" style="cursor: inherit;">
				<div>
					<my-img srcText="slot/icon1.png"
						styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; filter: none; background-image: none;" />
				</div>
			</div>
			<div class="image_imageContainer__2dKjX image_cover__3B5D- egame_right__35u_D" style="cursor: inherit;">
				<div>
					<my-img srcText="slot/icon2.png"
						styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; filter: none; background-image: none;" />
				</div>
			</div>
			<div class="pools_pool__3D5zM">
				<sz :data="endVal"></sz>
			</div>
			<!-- <div class="pools_pool__3D5zM" style="top:460px">
				<div class="pools_item__B0u0E"><span style="background-position-y: -736px;"></span></div>
				<div class="pools_item__B0u0E"><span style="background-position-y: -736px;"></span></div>
				<div class="pools_comma__2zpYI"></div>
				<div class="pools_item__B0u0E"><span style="background-position-y: -460px;"></span></div>
				<div class="pools_item__B0u0E"><span style="background-position-y: -552px;"></span></div>
				<div class="pools_item__B0u0E"><span style="background-position-y: -552px;"></span></div>
				<div class="pools_comma__2zpYI"></div>
				<div class="pools_item__B0u0E"><span style="background-position-y: -460px;"></span></div>
				<div class="pools_item__B0u0E"><span style="background-position-y: -736px;"></span></div>
				<div class="pools_item__B0u0E"><span style="background-position-y: -644px;"></span></div>
				<div class="pools_dot__3EsVS"></div>
				<div class="pools_item__B0u0E"><span style="background-position-y: -368px;"></span></div>
				<div class="pools_item__B0u0E"><span style="background-position-y: -644px;"></span></div>
			</div> -->
		</div>
		<div class="egame_content" >
			<div class="content ">
				<div class="headerWrapper ">
					<ul>
						<li id="VENUE_PGDZ" :class="{active: tabIdx == 0}" @click="tabIdx = 0">
							<i>
								<my-img srcText="slot/icon10.svg" v-if="tabIdx != 0" />
								<my-img srcText="slot/all.png" v-if="tabIdx == 0" />
							</i>
							<span>{{$t('main.dzyx')}}</span>
						</li>

					</ul>
				</div>
				<div class="pager">
					<ul>
						<li v-if="getGame('OBDY').on_line == 0" class="game_maintain_dz" :class="{'game_maintain': getGame('OBDY').maintain == 1}">
							<section class="game_section">
								<div class="game">
									<my-img srcText="slot/ob.png?t=1" styleText="height:160px;    width: 100%;" />
								</div>
								<div class="game_text"><span>{{$t('main.game.dz.ob.name')}}</span></div>
								<div class="game_cover"><button class="play_button  " @click="link('OBDY')">立即游戏</button></div>
							</section>
						</li>
						<li v-if="getGame('PNG').on_line == 0" class="game_maintain_dz" :class="{'game_maintain': getGame('PNG').maintain == 1}">
							<section class="game_section">
								<div class="game">
									<my-img srcText="slot/png.png?t=1" styleText="height:160px;    width: 100%;" />
								</div>
								<div class="game_text"><span>{{$t('main.game.dz.png.name')}}</span></div>
								<div class="game_cover"><button class="play_button  " @click="link('PNG')">立即游戏</button></div>
							</section>
						</li>
						<li v-if="getGame('JDB').on_line == 0" class="game_maintain_dz" :class="{'game_maintain': getGame('JDB').maintain == 1}">
							<section class="game_section">
								<div class="game">
									<my-img srcText="slot/jdb.png?t=1" styleText="height:160px;    width: 100%;" />
								</div>
								<div class="game_text"><span>{{$t('main.game.dz.jdb.name')}}</span></div>
								<div class="game_cover"><button class="play_button  " @click="link('JDB')">立即游戏</button></div>
							</section>
						</li>
						<li v-if="getGame('PGS').on_line == 0" class="game_maintain_dz" :class="{'game_maintain': getGame('PGS').maintain == 1}">
							<section class="game_section">
								<div class="game">
									<my-img srcText="slot/pgs.png?t=1" styleText="height:160px;    width: 100%;" />
								</div>
								<div class="game_text"><span>{{$t('main.game.dz.pgs.name')}}</span></div>
								<div class="game_cover"><button class="play_button  " @click="link('PGS')">立即游戏</button></div>
							</section>
						</li>
						<li v-if="getGame('LIVE22').on_line == 0" class="game_maintain_dz" :class="{'game_maintain': getGame('LIVE22').maintain == 1}">
							<section class="game_section">
								<div class="game">
									<my-img srcText="slot/live22.png?t=1" styleText="height:160px;    width: 100%;" />
								</div>
								<div class="game_text"><span>{{$t('main.game.dz.live.name')}}</span></div>
								<div class="game_cover"><button class="play_button  " @click="link('LIVE22')">立即游戏</button></div>
							</section>
						</li>
						<li v-if="getGame('MW').on_line == 0" class="game_maintain_dz" :class="{'game_maintain': getGame('MW').maintain == 1}">
							<section class="game_section">
								<div class="game">
									<my-img srcText="slot/mw.png?t=1" styleText="height:160px;    width: 100%;" />
								</div>
								<div class="game_text"><span>{{$t('main.game.dz.mw.name')}}</span></div>
								<div class="game_cover"><button class="play_button  " @click="link('MW')">立即游戏</button></div>
							</section>
						</li>
						<li v-if="getGame('PP').on_line == 0" class="game_maintain_dz" :class="{'game_maintain': getGame('PP').maintain == 1}">
							<section class="game_section">
								<div class="game">
									<my-img srcText="slot/pp.png?t=1" styleText="height:160px;    width: 100%;" />
								</div>
								<div class="game_text"><span>{{$t('main.game.dz.pp.name')}}</span></div>
								<div class="game_cover"><button class="play_button  " @click="link('PP')">立即游戏</button></div>
							</section>
						</li>
						<li v-if="getGame('CQ9').on_line == 0" class="game_maintain_dz" :class="{'game_maintain': getGame('CQ9').maintain == 1}">
							<section class="game_section">
								<div class="game">
									<my-img srcText="slot/cq9.png?t=1" styleText="height:160px;    width: 100%;" />
								</div>
								<div class="game_text"><span>{{$t('main.game.dz.cq.name')}}</span></div>
								<div class="game_cover"><button class="play_button  " @click="link('CQ9')">立即游戏</button></div>
							</section>
						</li>
						<li v-if="getGame('JOKER').on_line == 0" class="game_maintain_dz" :class="{'game_maintain': getGame('JOKER').maintain == 1}">
							<section class="game_section">
								<div class="game">
									<my-img srcText="slot/joker.png?t=1" styleText="height:160px;    width: 100%;" />
								</div>
								<div class="game_text"><span>{{$t('main.game.dz.joker.name')}}</span></div>
								<div class="game_cover"><button class="play_button  " @click="link('JOKER')">立即游戏</button></div>
							</section>
						</li>
            <li v-if="getGame('NS').on_line == 0" class="game_maintain_dz" :class="{'game_maintain': getGame('NS').maintain == 1}">
              <section class="game_section">
                <div class="game">
                  <my-img srcText="slot/ns.png?t=1" styleText="height:160px;    width: 100%;" />
                </div>
                <div class="game_text"><span>{{$t('main.game.dz.ns.name')}}</span></div>
                <div class="game_cover"><button class="play_button  " @click="link('NS')">立即游戏</button></div>
              </section>
            </li>
            <li v-if="getGame('BBIN').on_line == 0" class="game_maintain_dz" :class="{'game_maintain': getGame('BBIN').maintain == 1}">
              <section class="game_section">
                <div class="game">
                  <my-img srcText="slot/bbin.png?t=1" styleText="height:160px;    width: 100%;" />
                </div>
                <div class="game_text"><span>{{$t('main.game.dz.bbin.name')}}</span></div>
                <div class="game_cover"><button class="play_button  " @click="link('BBIN')">立即游戏</button></div>
              </section>
            </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	import {
		mapGetters
	} from 'vuex';
	import Sz from "./sz.vue"
	export default {
		name: "Home",
		data() {
			return {
				tabIdx: 0,

				NewArrs: [],
				startVal: 0,
				endVal: 14201252,
				duration: 300
			};
		},
		components: {
			Sz
		},
		watch: {
			gameConfig () {
				this.NewArrs = this.gameConfig['电子']
			}
		},
		computed: {
			...mapGetters(['gameConfig']),
		},
		mounted () {
			setInterval(() => {
				this.endVal = this.endVal + parseInt(Math.random() * 1000)
			}, 2000)
		},
		methods: {
			getGame (type) {
				let data = {}
				this.NewArrs.forEach((v) => {
					if (v.plat_type == type) {
						data = v
					}
				})
				return data
			},
			link(type) {
				let self = this
				if (!localStorage.getItem('access_token')) {
					let wxts = this.$t('main.login_ts')
					this.$confirm({
						title: this.$t('main.wxts'),
						content: h => < div > {wxts} </div>,
						okText: this.$t('main.okText'),
						cancelText: this.$t('main.cancelText'),
						onOk() {
							self.$router.push({
							  path: "/login",
							});
						},
						onCancel() {
						},
						class: 'testbo',
					});
				} else {
					let data = {
						gameData: {}
					}
					this.NewArrs.forEach((v) => {
						if (v.plat_type == type) {
							data.gameData = v
						}
					})
					let href = window.location.origin +
						`/game?plat_type=${data.gameData.plat_type}&game_type=${data.gameData.game_type}&game_code=${data.gameData.game_code}`
					// window.open(href, "_blank");
					this.$store.dispatch('user/linkGamePage', {
						href: href,
						platType: data.gameData.plat_type,
						gameName: data.gameData.name
					})
				}
			}
		},
		activated() {
			this.NewArrs = this.gameConfig['电子']
		}
	};
</script>

<style lang="less" scoped>
	.egame_content {
		margin: 0 auto;
		position: relative;
		z-index: 10;
		width: 1300px;
		border-radius: 10px;
		margin-bottom: 20px;

		.content {
			position: relative;
			box-sizing: border-box;
			z-index: 99;
			margin: -97px auto 0;
			width: 1300px;
			    padding: 28px 38px;
			    background: #f5f9ff;
			    border: 2px solid #fff;
			    -webkit-box-shadow: 0 6px 20px 3px rgba(201,211,237,.5), inset 0 0 10px rgba(155,180,216,.6);
			    box-shadow: 0 6px 20px 3px rgba(201,211,237,.5), inset 0 0 10px rgba(155,180,216,.6);
			    border-radius: 24px;

			.headerWrapper {
				width: 100%;
				position: relative;
				padding-bottom: 24px;
				// border-bottom: 1px solid transparent;

				// &::after {
				// 	content: " ";
				// 	display: table;
				// 	width: 1300px;
				// 	height: 1px;
				// 	position: absolute;
				// 	bottom: 0;
				// 	left: -50px;
				// 	background-color: #dee9f3;
				// }

				ul {



					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-webkit-flex-flow: row nowrap;
					-ms-flex-flow: row nowrap;
					flex-flow: row nowrap;
					-webkit-box-align: center;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;

				}

				li {
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-webkit-flex-flow: row nowrap;
					-ms-flex-flow: row nowrap;
					flex-flow: row nowrap;
					-webkit-box-align: center;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;
					-webkit-box-pack: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					justify-content: center;
					height: 60px;
					width: 164px;
					border-radius: 30px;
					cursor: pointer;
					-webkit-box-shadow: 0 10px 20px 0 rgba(14, 26, 31, .11);
					box-shadow: 0 10px 20px 0 rgba(14, 26, 31, .11);
					margin-right: 20px;
					width: 170px;
					height: 50px;
					-webkit-box-shadow: 0 5px 20px 0 rgba(79, 107, 139, .1);
					box-shadow: 0 5px 20px 0 rgba(79, 107, 139, .1);
					border: solid 1px #dee9f3;
					span {
						margin-left: 7px;
						font-size: 16px;
						color: #3c4265;
					}

					&.active {
						background: -webkit-gradient(linear, left top, right top, from(#9dd6ff), to(#197ff0));
						background: -webkit-linear-gradient(left, #9dd6ff, #197ff0);
						background: linear-gradient(to right, #9dd6ff, #197ff0);
						-webkit-box-shadow: 0 10px 20px 6px rgba(0, 180, 255, .2);
						box-shadow: 0 10px 20px 6px rgba(0, 180, 255, .2);
						-webkit-box-shadow: 0 5px 20px 6px rgba(145, 207, 233, .2);
						box-shadow: 0 5px 20px 6px rgba(145, 207, 233, .2);
						background-image: -webkit-gradient(linear, right top, left top, from(#197ff0), to(#9dd6ff));
						background-image: -webkit-linear-gradient(right, #197ff0, #9dd6ff);
						background-image: linear-gradient(to left, #197ff0, #9dd6ff);

						span {
							color: #fff;
						}
					}
				}
			}

			.pager {
				min-height: 600px;
				-webkit-box-orient: vertical;
				-webkit-flex-flow: column nowrap;
				-ms-flex-flow: column nowrap;
				flex-flow: column nowrap;
				-webkit-box-pack: justify;
				-webkit-justify-content: space-between;
				-ms-flex-pack: justify;
				justify-content: space-between;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				position: relative;
				margin-top: 25px;

				ul {
					width: 100%;
					-webkit-box-orient: horizontal;
					-webkit-flex-flow: row wrap;
					-ms-flex-flow: row wrap;
					flex-flow: row wrap;
					-webkit-box-pack: start;
					-webkit-justify-content: flex-start;
					-ms-flex-pack: start;
					justify-content: flex-start;
					-webkit-box-align: start;
					-webkit-align-items: flex-start;
					-ms-flex-align: start;
					align-items: flex-start;
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;

					li {
						height: 208px;
						overflow: hidden;
						width: 24%;
						margin-right: 1.32%;
						margin-bottom: 24px;
						border-radius: 12px;
						cursor: pointer;
						overflow: hidden;
						position: relative;
						box-shadow: 0 4px 8px rgba(136,149,205,.2);
						&:nth-child(4n) {
							margin-right: 0%;
						}

						.game_section {
							position: relative;

							.game {
								width: 100%;
								overflow: hidden;
								display: -webkit-box;
								display: -webkit-flex;
								display: -ms-flexbox;
								display: flex;
								-webkit-box-align: center;
								-webkit-align-items: center;
								-ms-flex-align: center;
								align-items: center;
								-webkit-box-pack: center;
								-webkit-justify-content: center;
								-ms-flex-pack: center;
								justify-content: center;
								background: #f5f5f5;
								height: 160px;
							}

							.game_text {
								display: flex;
								flex-flow: row nowrap;
								justify-content: space-between;
								align-items: center;
								height: 45px;
								padding: 0 14px;
								box-sizing: border-box;
								color: #4b5e6f;
								height: 48px;
								background: #fff;
								padding: 0 10px;
								color: #303442;
								width: 100%;
							}

							.game_cover {
								position: absolute;
								text-align: center;
								width: 100%;
								z-index: 10;
								line-height: 160px;
								top: -142px;
							}

							.play_button {
								border: none;
								text-align: center;
								line-height: 30px;
								color: #fff;
								width: 107px;
								height: 30px;
								font-size: 12px;
								background: #4080ff;
								border-radius: 4px;
								cursor: pointer;
							}

							&:hover {
								.game_cover {
									top: 0;
									background-color: rgba(0, 0, 0, .5);
									text-align: center;
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}


	.header {
		position: relative;
		min-width: 1200px;
		height: 553px;
		z-index: 10;

		.banner {
			width: 100%;
			height: 100%;

			.banner_img {
				margin: auto;
				background-position: center top;
				background-repeat: no-repeat;
				background-size: cover;
				height: 570px;
				width: 100%;
				background-position: center -10px;
				position: relative;

				.numGroup {
					position: absolute;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					left: 0;
					right: 0;
					margin: auto;
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					z-index: 2;
					width: 776px;
					height: 172px;
					top: 280px;
					padding: 29px 39px 39px 39px;

					.numItem {
						position: relative;
						width: 50px;
						height: 75px;
						margin-right: 15px;
						width: 58px;
						height: 90px;

						&:nth-child(2) {
							left: -4px;
						}

						&:nth-child(3) {
							left: 9px;
						}

						&:nth-child(4) {
							left: 5px;
						}

						&:nth-child(5) {
							left: 1px;
						}

						&:nth-child(6) {
							left: 12px;
						}

						&:nth-child(7) {
							left: 8px;
						}

						&:nth-child(8) {
							left: 5px;
						}

						&:nth-child(9) {
							left: 17px;
						}

						&:nth-child(10) {
							left: 14px;
						}

						.numitemNum {
							overflow: hidden;
							position: absolute;
							top: 50%;
							left: 50%;
							-webkit-transform: translate(-50%, -50%);
							transform: translate(-50%, -50%);
							width: 40px;
							height: 50px;
							-webkit-transition: background 1s;
							transition: background 1s;
							background-size: 41px 540px;
							background-position-y: 0px;
						}
					}
				}
			}
		}
	}

	.pagination_paginationView__3Sq2J .ant-pagination-item-container {
		margin-top: 8px
	}

	.pagination_paginationView__3Sq2J .ant-pagination {
		text-align: right;
		padding-top: 30px;
		display: block
	}

	.pagination_paginationView__3Sq2J .ant-pagination-jump-next,
	.pagination_paginationView__3Sq2J .ant-pagination-jump-prev {
		border: 1px solid #ebedf0;
		width: 32px;
		height: 32px;
		line-height: 32px;
		border-radius: 4px;
		margin: 0 4px
	}

	.pagination_paginationView__3Sq2J .ant-pagination-jump-next svg,
	.pagination_paginationView__3Sq2J .ant-pagination-jump-prev svg {
		color: #414655
	}

	.pagination_paginationView__3Sq2J .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
	.pagination_paginationView__3Sq2J .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
		color: #414655;
		-webkit-transform: scale(.5);
		transform: scale(.5)
	}

	.pagination_paginationView__3Sq2J .ant-pagination-next,
	.pagination_paginationView__3Sq2J .ant-pagination-prev {
		width: 32px;
		height: 32px;
		margin: 0 4px;
		line-height: 32px
	}

	.pagination_paginationView__3Sq2J .ant-pagination-next a,
	.pagination_paginationView__3Sq2J .ant-pagination-prev a {
		border: 1px solid #ebedf0;
		background-color: #fff;
		border-radius: 4px
	}

	.pagination_paginationView__3Sq2J .ant-pagination-next a .anticon,
	.pagination_paginationView__3Sq2J .ant-pagination-prev a .anticon {
		color: #414655
	}

	.pagination_paginationView__3Sq2J .ant-pagination-next:hover a,
	.pagination_paginationView__3Sq2J .ant-pagination-prev:hover a {
		border-color: transparent;
		background-color: #4176fa
	}

	.pagination_paginationView__3Sq2J .ant-pagination-next:hover a .anticon,
	.pagination_paginationView__3Sq2J .ant-pagination-prev:hover a .anticon {
		color: #fff
	}

	.pagination_paginationView__3Sq2J .ant-pagination-item {
		min-width: 32px;
		height: 32px;
		margin: 0 4px;
		border-radius: 4px;
		line-height: 32px;
		border: 1px solid #ebedf0;
		background-color: #fff
	}

	.pagination_paginationView__3Sq2J .ant-pagination-item a {
		color: #414655;
		border: none;
		display: inline-block
	}

	.pagination_paginationView__3Sq2J .ant-pagination-item:hover {
		border-color: transparent;
		background-color: #4176fa
	}

	.pagination_paginationView__3Sq2J .ant-pagination-item:hover a {
		color: #fff
	}

	.pagination_paginationView__3Sq2J .ant-pagination-item.ant-pagination-item-active {
		background-color: #4176fa;
		border-color: transparent
	}

	.pagination_paginationView__3Sq2J .ant-pagination-item.ant-pagination-item-active a {
		color: #fff
	}

	.pools_pool__3D5zM {
		position: absolute;
		width: 884px;
		display: -webkit-flex;
		display: flex;
		top: 366px;
		left: 0;
		    height: 100px;
		    overflow: hidden;
	}

	.pools_pool__3D5zM .pools_item__B0u0E {
		position: relative;
		// background-image: url(../../assets/slot/icon3.png);
		background-image: url(/assets/slot/icon3.png?t=1);
		background-size: 915px 110px;
		background-position: -312px 0;
		width: 75px;
		height: 110px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center
	}

	.pools_pool__3D5zM .pools_item__B0u0E span {
		overflow: hidden;
		position: absolute;
		// background-image: url(../../assets/slot/icon5.png);
		background-image: url(/assets/slot/icon5.png?t=1);
		background-size: 60px 918px;
		background-repeat: repeat;
		background-position: 0 0;
		-webkit-transition: background 1s;
		transition: background 1s;
		top: 3px;
		left: 12px;
		width: 60px;
		height: 90px
	}

	.pools_pool__3D5zM .pools_comma__2zpYI {
		// background-image: url(../../assets/slot/icon3.png);
		background-image: url(/assets/slot/icon3.png?t=1);
		background-position: -419px 0
	}

	.pools_pool__3D5zM .pools_comma__2zpYI,
	.pools_pool__3D5zM .pools_dot__3EsVS {
		display: inline-block;
		height: 85px;
		width: 20px;
		background-size: 915px 110px;
		margin-top: 7px
	}

	.pools_pool__3D5zM .pools_dot__3EsVS {
		// background-image: url(../../assets/slot/icon3.png);
		background-image: url(/assets/slot/icon3.png?t=1);
		background-position: -397px 0
	}

	.tab_header__2rNiv {
		padding-bottom: 30px;
		border-bottom: 1px solid #f2f2f2
	}

	.tab_header__2rNiv,
	.tab_header__2rNiv>li {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-webkit-align-items: center;
		align-items: center
	}

	.tab_header__2rNiv>li {
		position: relative;
		overflow: hidden;
		-webkit-justify-content: center;
		justify-content: center;
		height: 50px;
		border-radius: 30px;
		cursor: pointer;
		width: 180px;
		background-image: none;
		width: 144px;
		// background-image: url(/_next/static/media/btn.b70e26749a331b0ad780da21f135fd40.png);
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
		color: #444c64;
		border: .5px solid #cddeff;
		box-shadow: 0 4px 8px 0 rgba(194, 211, 222, .67), inset 0 1px 7px 0 #fff, inset 0 -3px 4px 0 rgba(175, 194, 230, .16);
		margin-right: 18px
	}

	.tab_header__2rNiv>li .tab_icon__27cdE {
		width: 50px;
		height: 50px;
		-webkit-transition: .3s;
		transition: .3s;
		background-size: contain
	}

	.tab_header__2rNiv>li .tab_icon__27cdE:first-of-type {
		display: block;
		opacity: 1
	}

	.tab_header__2rNiv>li .tab_icon__27cdE:last-of-type {
		display: none;
		opacity: 0
	}

	.tab_header__2rNiv>li:last-child {
		margin-right: 0
	}

	.tab_header__2rNiv>li .tab_icon__27cdE {
		width: 40px;
		height: 40px
	}

	.tab_header__2rNiv>li>span {
		margin-left: 7px;
		font-size: 16px
	}

	.tab_header__2rNiv>li.tab_active__15Qcr,
	.tab_header__2rNiv>li:hover {
		border: none;
		background: -webkit-linear-gradient(left, #f5d9ba, #caab8a);
		background: linear-gradient(90deg, #f5d9ba, #caab8a);
		box-shadow: 0 2px 4px 0 #5da7eb;
		// background: url(/_next/static/media/btn-hover.44c474352ed5a7fe8dddbd7e925a0630.png);
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat
	}

	.tab_header__2rNiv>li.tab_active__15Qcr .tab_iconActive__kgfqG:first-of-type,
	.tab_header__2rNiv>li:hover .tab_iconActive__kgfqG:first-of-type {
		display: none;
		opacity: 0
	}

	.tab_header__2rNiv>li.tab_active__15Qcr .tab_iconActive__kgfqG:last-of-type,
	.tab_header__2rNiv>li:hover .tab_iconActive__kgfqG:last-of-type {
		display: block;
		opacity: 1
	}

	.tab_header__2rNiv>li.tab_active__15Qcr span,
	.tab_header__2rNiv>li:hover span {
		color: #fff
	}

	.tab_line__2fFRI {
		height: 1px;
		background-color: #d3d9f0;
		position: relative
	}

	.tab_line__2fFRI:after {
		content: "";
		position: absolute;
		width: 100%;
		top: 1px;
		display: block;
		background-color: #fff;
		height: 1px
	}

	.filter_filter__X4kNV {
		width: 100%;
		margin: 24px 0 !important
	}

	.filter_filter__X4kNV,
	.filter_filter__X4kNV>li {
		position: relative;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center
	}

	.filter_filter__X4kNV>li {
		height: 20px;
		padding-left: 30px;
		cursor: pointer;
		margin-right: 31px
	}

	.filter_filter__X4kNV>li span {
		-webkit-transition: .2s;
		transition: .2s;
		font-size: 16px;
		color: #666
	}

	.filter_filter__X4kNV>li>img {
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px
	}

	.filter_filter__X4kNV>li>img:first-of-type {
		display: unset
	}

	.filter_filter__X4kNV>li.filter_active__3OZOG>img:first-of-type,
	.filter_filter__X4kNV>li:hover>img:first-of-type,
	.filter_filter__X4kNV>li>img:last-of-type {
		display: none
	}

	.filter_filter__X4kNV>li.filter_active__3OZOG>img:last-of-type,
	.filter_filter__X4kNV>li:hover>img:last-of-type {
		display: unset
	}

	.filter_filter__X4kNV>li.filter_active__3OZOG span,
	.filter_filter__X4kNV>li:hover span {
		color: #4176fa
	}

	.filter_filter__X4kNV .filter_search__2Q7cp {
		width: 285px;
		height: 36px;
		position: absolute;
		right: 0
	}

	.filter_filter__X4kNV .filter_search__2Q7cp input {
		position: absolute;
		left: 0;
		top: 0;
		width: 285px;
		height: 36px;
		line-height: 36px;
		padding: 0 40px 0 18px;
		box-sizing: border-box;
		border-radius: 4px;
		font-size: 14px;
		color: #6c7ba8;
		border: 1px solid #c0c4d0
	}

	.filter_filter__X4kNV .filter_search__2Q7cp input::-webkit-input-placeholder {
		color: #a5aabc
	}

	.filter_filter__X4kNV .filter_search__2Q7cp input::placeholder {
		color: #a5aabc
	}

	.filter_filter__X4kNV .filter_search__2Q7cp input:focus {
		border-color: #a5aabc
	}

	.filter_filter__X4kNV .filter_search__2Q7cp i {
		width: 24px;
		height: 24px;
		position: absolute;
		top: 6px;
		right: 12px;
		cursor: pointer;
		// background-image: url(/_next/static/media/sprite.2523a1c7e4d2aecced83df4c651abfaa.png);
		background-size: 915px 110px;
		background-position: -503px 0;
		z-index: 1
	}

	.filter_filter__X4kNV .filter_search__2Q7cp i:hover {
		background-position: -477px 0
	}

	.filter_filter__X4kNV .filter_search__2Q7cp .filter_hotGame__1PU0i {
		position: absolute;
		top: 34px;
		left: 0;
		z-index: 11;
		width: 100%;
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;
		overflow: hidden;
		border: 1px solid #c0c4d0;
		background: #fff
	}

	.filter_filter__X4kNV .filter_search__2Q7cp .filter_hotGame__1PU0i p {
		margin: 13px 0 6px 11px;
		height: 19px;
		line-height: 19px;
		font-size: 14px;
		color: #a5aabc;
		color: #cbced8
	}

	.filter_filter__X4kNV .filter_search__2Q7cp .filter_hotGame__1PU0i ul {
		margin-top: 5px
	}

	.filter_filter__X4kNV .filter_search__2Q7cp .filter_hotGame__1PU0i ul>li {
		width: 100%;
		padding-left: 13px;
		height: 50px;
		line-height: 50px;
		font-family: MicrosoftYaHei;
		font-size: 14px;
		color: #6a7391;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center
	}

	.filter_filter__X4kNV .filter_search__2Q7cp .filter_hotGame__1PU0i ul>li:hover {
		background-color: #ede2d5;
		background-color: rgba(61, 114, 250, .2);
		cursor: pointer
	}

	.filter_filter__X4kNV .filter_search__2Q7cp .filter_hotGame__1PU0i ul>li .filter_itemImg__PXXDE {
		display: inline-block;
		width: 48px;
		height: 30px;
		margin-right: 10px
	}

	.filter_filter__X4kNV .filter_search__2Q7cp .filter_hotGame__1PU0i ul>li .filter_placeholderImg__1dwxI {
		display: inline-block;
		width: 48px;
		height: 30px;
		margin-right: 10px;
		background-color: #cbced8;
		// background-image: url(/_next/static/media/game_no_picture.49d7e21e348367ad3a622ec40529ea24.png);
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: contain
	}

	.filter_filter__X4kNV .filter_search__2Q7cp .filter_hotGame__1PU0i ul>li>span {
		max-width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}

	.list_list__3gV_G {
		margin-top: 20px;
		min-height: 600px;
		-webkit-flex-flow: column nowrap;
		flex-flow: column nowrap;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
		position: relative
	}

	.list_list__3gV_G ul {
		width: 100%;
		-webkit-flex-flow: row wrap;
		flex-flow: row wrap;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
		-webkit-align-items: flex-start;
		align-items: flex-start;
		display: -webkit-flex;
		display: flex
	}

	.list_list__3gV_G ul>li {
		overflow: hidden;
		margin-right: 12px;
		border: 1px solid #ededed;
		border-radius: 6px;
		margin-bottom: 24px;
		width: 214px;
		position: relative
	}

	.list_list__3gV_G ul>li:nth-child(5n) {
		margin-right: 0
	}

	.list_list__3gV_G ul>li:hover img {
		text-align: center;
		-webkit-transform: scale(1.1);
		transform: scale(1.1)
	}

	.list_list__3gV_G ul>li:hover .list_cover__2yAJg {
		top: 0;
		background-color: rgba(0, 0, 0, .5);
		-webkit-transition: all .5s;
		transition: all .5s;
		text-align: center;
		display: block
	}

	.list_list__3gV_G ul>li .list_image__13D3N,
	.list_list__3gV_G ul>li .list_noImage__qImwt {
		height: 130px;
		width: 100%;
		overflow: hidden;
		background: #cbced8
	}

	.list_list__3gV_G ul>li .list_image__13D3N>img,
	.list_list__3gV_G ul>li .list_noImage__qImwt>img {
		width: 100%;
		height: 100%;
		-webkit-transition: all .3s;
		transition: all .3s
	}

	.list_list__3gV_G ul>li .list_noImage__qImwt {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center
	}

	.list_list__3gV_G ul>li .list_noImage__qImwt .list_defaultImage__PWxhP {
		width: 44px;
		height: 44px;
		-webkit-transition: all .3s;
		transition: all .3s
	}

	.list_list__3gV_G ul>li .list_text__3Fe8D {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
		padding: 0 14px;
		box-sizing: border-box;
		position: relative;
		height: 47px
	}

	.list_list__3gV_G ul>li .list_text__3Fe8D span {
		width: 155px;
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		/*! autoprefixer: off */
		-webkit-box-orient: vertical
	}

	.list_list__3gV_G ul>li .list_text__3Fe8D i {
		cursor: pointer;
		width: 16px;
		height: 16px;
		line-height: 45px;
		// background-image: url(/_next/static/media/sprite.2523a1c7e4d2aecced83df4c651abfaa.png);
		background-size: 915px 110px;
		background-position: -440px 0
	}

	.list_list__3gV_G ul>li .list_text__3Fe8D i.list_active__2ffsk,
	.list_list__3gV_G ul>li .list_text__3Fe8D i:hover {
		background-position: -458px 0
	}

	.list_list__3gV_G ul>li .list_cover__2yAJg {
		position: absolute;
		text-align: center;
		width: 100%;
		z-index: 10;
		line-height: 130px;
		top: -132px
	}

	.list_list__3gV_G ul>li .list_cover__2yAJg button {
		cursor: pointer;
		border: none;
		text-align: center;
		line-height: 30px;
		color: #fff;
		width: 107px;
		height: 30px;
		font-size: 12px;
		background: #4176fa;
		border-radius: 4px
	}

	.list_list__3gV_G .list_noGameContainer__zvbR2 {
		margin: 90px auto 0
	}

	.list_list__3gV_G .list_noGameContainer__zvbR2 .list_noGame__1KsTB {
		text-align: center;
		width: 300px;
		height: 240px;
		margin: 0 auto 10px;
		// background-image: url(/_next/static/media/noGame.0dc006ab5d30e2e865f6b74b6e6971ed.png);
		background-size: cover
	}

	.list_list__3gV_G .list_noGameContainer__zvbR2 .list_noGameTxt__1zhYe {
		height: 20px;
		line-height: 20px;
		font-family: PingFangSC;
		font-size: 14px;
		text-align: center
	}

	.egame_egame__2Ws4m {
		padding-top: 90px;
		background-color: #f0f2ff;
		padding-bottom: 83px;
		background-color: #f0f6ff
	}

	.egame_egame__2Ws4m .egame_banner__3DJby {
		position: relative;
		width: 1200px;
		margin: 0 auto;
		height: 517px
	}

	.egame_egame__2Ws4m .egame_banner__3DJby .egame_center__ZawhD {
		position: absolute;
		width: 1371px;
		height: 480px;
		top: 56px;
		left: -95px
	}

	.egame_egame__2Ws4m .egame_banner__3DJby .egame_left__2e7ph {
		position: absolute;
		top: 82px;
		left: 0;
		width: 511px;
		height: 263px
	}

	.egame_egame__2Ws4m .egame_banner__3DJby .egame_right__35u_D {
		position: absolute;
		top: 33px;
		right: -90px;
		width: 768px;
		height: 485px
	}

	.egame_egame__2Ws4m .egame_main__L2r-e {
		width: 1200px;
		margin: 0 auto;
		position: relative;
		box-sizing: border-box;
		z-index: 19;
		padding: 30px 39px 20px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
		background-color: #fff;
		box-shadow: 0 2px 14px 0 rgba(203, 206, 216, .2)
	}

	.egame_egame__2Ws4m .egame_main__L2r-e .egame_pagination__1krhL {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center
	}
</style>
