<template>
	<a-popconfirm overlayClassName="password-pop" placement="bottomLeft" v-model="passwordModel">
		<template slot="title">
			<div class="password-box">
				<div class="password-header">
					<div v-for="(item, index) in 4">
						<p>{{qk_pwd[index]}}</p>
					</div>
				</div>
				<div class="password-item">
					<p v-for="(item, index) in items" :key="index" @click="selectItem(item)">
						{{item}}
					</p>
					<p style="width: 50%;" @click="czpas">重置</p>
				</div>
			</div>
		</template>
		<div class="input-password-box" :style="styleText">
			<p v-if="!isShowPasswordText && qk_pwd.length != 0" style="font-size: 20px;">{{pasText}}</p>
			<p v-if="isShowPasswordText && qk_pwd.length != 0">{{qk_pwd}}</p>
			<span style="color: #b0b0b0;font-size: 14px;" v-if="qk_pwd.length == 0">{{placeholderText}}</span>
			<a-icon class="icon" @click.stop="isShowPasswordText = true" v-if="!isShowPasswordText" type="eye-invisible" />
			<a-icon class="icon" @click.stop="isShowPasswordText = false" v-else type="eye" />
		</div>
	</a-popconfirm>

</template>

<script>
	export default {
		name: "input-password",
		model: {
		    prop: 'qk_pwd',
		    event: 'update:qk_pwd'
		},
		props: {
		    qk_pwd: {
		      type: String,
		      required: true
		    },
			styleText: {
		      type: String,
		      required: true
		    },
			placeholderText: {
				type: String,
				required: false,
				default: '請輸入取款密碼'
			}
		},
		data() {
			return {
				isShowPasswordText: false,
				passwordModel: false,
				
				items: [6, 7, 8, 9, 2, 3, 4, 5, 0, 1]
			};
		},
		computed: {
			pasText () {
				if (this.qk_pwd.length == 0) {
					return ''
				}
				if (typeof this.qk_pwd === 'number') {
					return toString(this.qk_pwd).replace(/[0123456789]/g,"*")
				}
				return this.qk_pwd.replace(/[0123456789]/g,"*")
			}
		},
		mounted() {
			
		},
		methods: {
			czpas () {
				this.$emit('update:qk_pwd', '')
			},
			selectItem (number) {
				console.log(this.qk_pwd.length, 'this.qk_pwd.length')
				if (this.qk_pwd.length >= 3) {
					if (this.qk_pwd.length == 3) {
						this.$emit('update:qk_pwd', this.qk_pwd + number)
					}
					this.passwordModel = false
					return
				}
				this.$emit('update:qk_pwd', this.qk_pwd + number)
			}
		}
	};
</script>

<style lang="less" scoped>
	
	.input-password-box {
		font-size: 12px;
		    width: 486px;
		    height: 40px;
		    border-radius: 6px;
		    padding: 0 20px;
		    border: solid 1px #d8dce7;
		    color: #333;
		    background-color: #fff;
		    padding-left: 11px;
		    background-color: rgba(216, 220, 231, 0.2);
			display: flex;
			align-items: center;
			position: relative;
			.icon {
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translate(0, -50%);
				z-index: 111;
				font-size: 14px;
				cursor: pointer;
			}
		p {
			letter-spacing: 2px;
			font-size: 16px;
			width: 80%;
		}
	}
	.password-header {
		height: 50px;
		display: flex;
		align-items: center;
		background-image: linear-gradient(90deg, #1675c1 1%, #8aa2d2);
		div {
			width: 25%;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			&>p {
				width:30px;
				height: 30px;
				background:#fff;
				border-radius: 100px;
				font-size: 18px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 700;
			}
		}
	}
	.password-item {
		width:200px;
		display: flex;
		flex-wrap: wrap;
		border-left: 1px solid #ebe9e9;
		p {
			height: 50px;
			width: 25%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			font-weight: 700;
			border-bottom: 1px solid #ebe9e9;
			border-right: 1px solid #ebe9e9;
			cursor: pointer;
		}
	}
</style>
