<template>
	<div class="layout_venue realbet_bg">
		<div class="layout_container realbet_container">
			<div class="realbet_leftWrap nopagafter"
				:class="{[classList[currentIndexValue]]: true, 'realbet_fadeRightIn': show == true}">
				<div class="image_imageContainer image_cover realbet_poster" style="cursor: inherit;"
					v-if="getGame('OBZR').on_line == 0"
					:style="{'visibility': currentIndexValue == 0 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="casino/page1/icon5.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer image_cover realbet_poster" style="cursor: inherit; "
					:style="{'visibility': currentIndexValue == 1 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="casino/page2/icon5.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer image_cover realbet_poster" style="cursor: inherit;"
					:style="{'visibility': currentIndexValue == 2 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="casino/page3/icon5.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer image_cover realbet_poster" style="cursor: inherit; "
					:style="{'visibility': currentIndexValue == 3 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="casino/page4/icon5.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer image_cover realbet_poster" style="cursor: inherit; "
					:style="{'visibility': currentIndexValue == 4 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="casino/page5/icon5.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer image_cover realbet_poster" style="cursor: inherit;"
					:style="{'visibility': currentIndexValue == 5 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="casino/page6/icon5.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer image_cover realbet_poster" style="cursor: inherit;"
					:style="{'visibility': currentIndexValue == 6 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="casino/page1/icon5.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				
				<div v-if="getGame('OBZR').on_line == 0 && getGame('OBZR').maintain == 0">
					<my-img srcText="casino/page1/icon1.png" class="realbet_float1" v-show="currentIndexValue == 0" />
					<my-img srcText="casino/page1/icon2.png" class="realbet_float2" v-show="currentIndexValue == 0" />
					<my-img srcText="casino/page1/icon3.png" class="realbet_float3" v-show="currentIndexValue == 0" />
					<my-img srcText="casino/page1/icon4.png" class="realbet_float4" v-show="currentIndexValue == 0" />
				</div>

				<div style="position: relative;left: 100px;">
					<my-img srcText="casino/page2/icon1.png" class="realbet_float1" v-show="currentIndexValue == 1" />
					<my-img srcText="casino/page2/icon2.png" class="realbet_float2" v-show="currentIndexValue == 1" />
					<my-img srcText="casino/page2/icon3.png" class="realbet_float3" v-show="currentIndexValue == 1" />
					<my-img srcText="casino/page2/icon4.png" class="realbet_float4" v-show="currentIndexValue == 1" />
				</div>

				<div style="position: relative;left: 100px;">
					<my-img srcText="casino/page3/icon1.png" class="realbet_float1" v-show="currentIndexValue == 2" />
					<my-img srcText="casino/page3/icon2.png" class="realbet_float2" v-show="currentIndexValue == 2" />
					<my-img srcText="casino/page3/icon3.png" class="realbet_float3" v-show="currentIndexValue == 2" />
					<my-img srcText="casino/page3/icon4.png" class="realbet_float4" v-show="currentIndexValue == 2" />
				</div>

				<div style="position: relative;left: 100px;">
					<my-img srcText="casino/page4/icon1.png" class="realbet_float1" v-show="currentIndexValue == 3" />
					<my-img srcText="casino/page4/icon2.png" class="realbet_float2" v-show="currentIndexValue == 3" />
					<my-img srcText="casino/page4/icon3.png" class="realbet_float3" v-show="currentIndexValue == 3" />
					<my-img srcText="casino/page4/icon4.png" class="realbet_float4" v-show="currentIndexValue == 3" />
				</div>

				<div style="position: relative;left: 100px;">
					<my-img srcText="casino/page5/icon1.png" class="realbet_float1" v-show="currentIndexValue == 4" />
					<my-img srcText="casino/page5/icon2.png" class="realbet_float2" v-show="currentIndexValue == 4" />
					<my-img srcText="casino/page5/icon3.png" class="realbet_float3" v-show="currentIndexValue == 4" />
					<my-img srcText="casino/page5/icon4.png" class="realbet_float4" v-show="currentIndexValue == 4" />
				</div>

				<div style="position: relative;left: 100px;">
					<my-img srcText="casino/page6/icon1.png" class="realbet_float1" v-show="currentIndexValue == 5" />
					<my-img srcText="casino/page6/icon2.png" class="realbet_float2" v-show="currentIndexValue == 5" />
					<my-img srcText="casino/page6/icon3.png" class="realbet_float3" v-show="currentIndexValue == 5" />
					<my-img srcText="casino/page6/icon4.png" class="realbet_float4" v-show="currentIndexValue == 5" />
				</div>

				<div>
					<my-img srcText="casino/page1/icon1.png" class="realbet_float1" v-show="currentIndexValue == 6" />
					<my-img srcText="casino/page1/icon2.png" class="realbet_float2" v-show="currentIndexValue == 6" />
					<my-img srcText="casino/page1/icon3.png" class="realbet_float3" v-show="currentIndexValue == 6" />
					<my-img srcText="casino/page1/icon4.png" class="realbet_float4" v-show="currentIndexValue == 6" />
				</div>
			</div>
			<div class="layout_rightWrap sport_rightWrap">
				<div class="layout_section " :class="{'layout_fadeOut' : show == true}">
					<div class="image_imageContainer image_contain layout_title "
						style="width: 500px; height: 148px; cursor: inherit;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAwIiBoZWlnaHQ9IjE0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4="
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>

							<my-img :srcText="list[currentIndexValue1].title"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
						</div>
					</div>
					<p class="layout_desc__2U81i ">{{list[currentIndexValue].content}}</p>
					<div class="image_imageContainer image_contain  "
						style="width: auto; height: 40px; cursor: inherit;">
						<my-img :srcText="list[currentIndexValue].urlList"
							styleText="width: auto; height: 40px; filter: none; background-image: none;" />
					</div>
				</div>
				<div class="layout_tabs">
					<ul class="tabs_icons">
            <li class="game_maintain_icon" v-if="getGame('DG').on_line == 0" :class="{'tabs_active' : currentIndex == 1, 'game_maintain': getGame('DG').maintain == 1}" @click="setTab(1)">
              <div class="tabs_icon">
                <div class="tabs_logo tabs_logo_2"></div>
              </div>
              <p>{{this.$t('main.game.zr.dg.name')}}</p>
            </li>

            <li class="game_maintain_icon" v-if="getGame('OBZR').on_line == 0" :class="{'tabs_active' : currentIndex == 0 , 'game_maintain': getGame('OBZR').maintain == 1}" @click="setTab(0)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_1"></div>
							</div>
							<p>{{this.$t('main.game.zr.ob.name')}}</p>
						</li>

						<li class="game_maintain_icon" v-if="getGame('PP').on_line == 0" :class="{'tabs_active' : currentIndex == 2, 'game_maintain': getGame('PP').maintain == 1}" @click="setTab(2)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_3"></div>
							</div>
							<p>{{this.$t('main.game.zr.pp.name')}}</p>
						</li>
						<li class="game_maintain_icon" v-if="getGame('EVO').on_line == 0" :class="{'tabs_active' : currentIndex == 3, 'game_maintain': getGame('EVO').maintain == 1}" @click="setTab(3)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_4"></div>
							</div>
							<p>{{this.$t('main.game.zr.evo.name')}}</p>
						</li>
						<li class="game_maintain_icon" v-if="getGame('WM').on_line == 0" :class="{'tabs_active' : currentIndex == 4, 'game_maintain': getGame('WM').maintain == 1}" @click="setTab(4)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_5"></div>
							</div>
							<p>{{this.$t('main.game.zr.wm.name')}}</p>
						</li>
						<li class="game_maintain_icon" v-if="getGame('SEXY').on_line == 0" :class="{'tabs_active' : currentIndex == 5, 'game_maintain': getGame('SEXY').maintain == 1}" @click="setTab(5)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_6"></div>
							</div>
							<p>{{this.$t('main.game.zr.sexy.name')}}</p>
						</li>
						<li class="game_maintain_icon" v-if="getGame('BBIN').on_line == 0" :class="{'tabs_active' : currentIndex == 6, 'game_maintain': getGame('BBIN').maintain == 1}" @click="setTab(6)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_7"></div>
							</div>
							<p>{{this.$t('main.game.zr.bbin.name')}}</p>
						</li>
					</ul>
				</div>
				<div class="layout_entryBtn realbet_entryBtn" v-show="isOpen" @click="link(list[currentIndex].plat_type)">进入游戏</div>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: "appbox",
		data() {
			return {
				list: [{
						image: 'home/app/icon2.png',
						title: "casino/page1/icon6.png",
						content: this.$t('main.game.zr.ob.des'),
						urlList: "casino/page1/icon7.png",
						plat_type: "OBZR"
					},
					{
						image: 'home/app/icon2.png',
						title: "casino/page1/icon6.png",
						content: this.$t('main.game.zr.dg.des'),
						urlList: "casino/page2/icon7.png",
						plat_type: "DG"
					},
					{
						image: 'home/app/icon2.png',
						title: "casino/page1/icon6.png",
						content: this.$t('main.game.zr.pp.des'),
						urlList: "casino/page3/icon7.png",
						plat_type: "PP"
					},
					{
						image: 'home/app/icon2.png',
						title: "casino/page1/icon6.png",
						content: this.$t('main.game.zr.evo.des'),
						urlList: "casino/page4/icon7.png",
						plat_type: "EVO"
					},
					{
						image: 'home/app/icon3.png',
						title: "casino/page1/icon6.png",
						content: this.$t('main.game.zr.wm.des'),
						urlList: "casino/page5/icon7.png",
						plat_type: "WM"
					},
					{
						image: 'home/app/icon4.png',
						title: "casino/page1/icon6.png",
						content: this.$t('main.game.zr.sexy.des'),
						urlList: "casino/page6/icon7.png",
						plat_type: "SEXY"
					},
					{
						image: 'home/app/icon2.png',
						title: "casino/page1/icon6.png",
						content: this.$t('main.game.zr.bbin.des'),
						urlList: "casino/page1/icon7.png",
						plat_type: "BBIN"
					},
				],
				currentIndex: 0,
				currentIndexValue: 0,
				currentIndexValue1: 0,
				show: false,
				classList: ['realbet_yb__3zPHl', 'realbet_bg', 'realbet_wm__1FwMM', 'realbet_ag__3PYEY',
					'realbet_ebet__1AFNc', 'realbet_og__3a7j7','realbet_yb__3zPHl',
				],
				
				NewArrs: []
			};
		},
		watch: {
			gameConfig () {
				this.NewArrs = this.gameConfig['真人']
        if (this.NewArrs.length == 0 ) return
        if(this.NewArrs[0].plat_type == 'OBZR' ){
          this.currentIndex = 0
          this.currentIndexValue = 0
        }else if(this.NewArrs[0].plat_type == 'DG' ){
          this.currentIndex = 1
          this.currentIndexValue = 1
        }else if(this.NewArrs[0].plat_type == 'PP' ){
          this.currentIndex = 2
          this.currentIndexValue = 2
        }else if(this.NewArrs[0].plat_type == 'EVO' ){
          this.currentIndex = 3
          this.currentIndexValue = 3
        }else if(this.NewArrs[0].plat_type == 'WM' ){
          this.currentIndex = 4
          this.currentIndexValue = 4
        }else if(this.NewArrs[0].plat_type == 'SEXY' ){
          this.currentIndex = 5
          this.currentIndexValue = 5
        }else if(this.NewArrs[0].plat_type == 'BBIN' ){
          this.currentIndex = 6
          this.currentIndexValue = 6
        }
        return;




				if (this.getGame('OBZR').on_line == 1) {
					this.currentIndex = 1
					this.currentIndexValue = 1
					this.currentIndexValue1 = 1
				}
				if (this.getGame('OBZR').on_line == 1 && this.getGame('DG').on_line == 1) {
					this.currentIndex = 2
					this.currentIndexValue = 2
					this.currentIndexValue1 = 2
				}
				if (this.getGame('OBZR').on_line == 1 && this.getGame('DG').on_line == 1 && this.getGame('PP').on_line == 1) {
					this.currentIndex = 3
					this.currentIndexValue = 3
					this.currentIndexValue1 = 3
				}
				if (this.getGame('OBZR').on_line == 1 && this.getGame('DG').on_line == 1 && this.getGame('PP').on_line == 1 && this.getGame('EVO').on_line == 1) {
					this.currentIndex = 4
					this.currentIndexValue = 4
					this.currentIndexValue1 = 4
				}
				if (this.getGame('OBZR').on_line == 1 && this.getGame('DG').on_line == 1 && this.getGame('PP').on_line == 1 && this.getGame('EVO').on_line == 1 && this.getGame('WM').on_line == 1) {
					this.currentIndex = 5
					this.currentIndexValue = 5
					this.currentIndexValue1 = 5
				}
				if (this.getGame('OBZR').on_line == 1 && this.getGame('DG').on_line == 1 && this.getGame('PP').on_line == 1 && this.getGame('EVO').on_line == 1 && this.getGame('WM').on_line == 1 && this.getGame('SEXY').on_line == 1) {
					this.currentIndex = 6
					this.currentIndexValue = 6
					this.currentIndexValue1 = 6
				}
				if (this.getGame('OBZR').on_line == 1 && this.getGame('DG').on_line == 1 && this.getGame('PP').on_line == 1 && this.getGame('EVO').on_line == 1 && this.getGame('WM').on_line == 1 && this.getGame('SEXY').on_line == 1 && this.getGame('BBIN').on_line == 1) {
					this.currentIndex = -1
					this.currentIndexValue = -1
					this.currentIndexValue1 = -1
				}
			}
		},
		computed: {
			isOpen: function () {
				let is = true
				if (this.currentIndex == 0 && (this.getGame('OBZR').on_line == 1 || this.getGame('OBZR').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 1 && (this.getGame('DG').on_line == 1 || this.getGame('DG').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 2 && (this.getGame('PP').on_line == 1 || this.getGame('PP').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 3 && (this.getGame('EVO').on_line == 1 || this.getGame('EVO').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 4 && (this.getGame('WM').on_line == 1 || this.getGame('WM').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 5 && (this.getGame('SEXY').on_line == 1 || this.getGame('SEXY').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 6 && (this.getGame('BBIN').on_line == 1 || this.getGame('BBIN').maintain == 1)) {
					is = false
				}
				return is
			},
			...mapGetters(['gameConfig']),
		},
		methods: {
			getGame (type) {
				let data = {}
				this.NewArrs.forEach((v) => {
					if (v.plat_type == type) {
						data = v
					}
				})
				return data
			},
			setTab(idx) {
				this.show = false
				this.currentIndex = idx
				this.show = true

				setTimeout(() => {
					this.currentIndexValue = idx
				}, 300)

				setTimeout(() => {
					this.currentIndexValue1 = idx
				}, 200)

				setTimeout(() => {
					this.show = false
				}, 400)
			},
			link(type) {
				let self = this
				if (!localStorage.getItem('access_token')) {
					let wxts = this.$t('main.login_ts')
					this.$confirm({
						title: this.$t('main.wxts'),
						content: h => < div > {wxts} </div>,
						okText: this.$t('main.okText'),
						cancelText: this.$t('main.cancelText'),
						onOk() {
							self.$router.push({
							  path: "/login",
							});
						},
						onCancel() {
						},
						class: 'testbo',
					});
				} else {
					let data = {
						gameData: {}
					}
					this.NewArrs.forEach((v) => {
						if (v.plat_type == type) {
							data.gameData = v
						}
					})
					let href = window.location.origin + `/game?plat_type=${data.gameData.plat_type}&game_type=${data.gameData.game_type}&game_code=${data.gameData.game_code}`
					// window.open(href, "_blank");
					this.$store.dispatch('user/linkGamePage', {
						href: href,
						platType: data.gameData.plat_type,
						gameName: data.gameData.name
					})
				}
			}
		},
		activated() {
			this.NewArrs = this.gameConfig['真人']
		},
		destroyed() {

		},
	};
</script>

<style scoped lang="less">
	.tabs_icons li:hover {
		.tabs_logo_1 {
			// background-image: url(../../assets/casino/icon4.png);
			background-image: url(/assets/casino/icon4.png);
		}

		.tabs_logo_2 {
			// background-image: url(../../assets/casino/icon6.png);
			background-image: url(/assets/casino/icon6.png);
		}

		.tabs_logo_3 {
			// background-image: url(../../assets/casino/icon8.png);
			background-image: url(/assets/casino/icon8.png);
		}

		.tabs_logo_4 {
			// background-image: url(../../assets/casino/icon10.png);
			background-image: url(/assets/casino/icon10.png);
		}

		.tabs_logo_5 {
			// background-image: url(../../assets/casino/icon12.png);
			background-image: url(/assets/casino/icon12.png);
		}

		.tabs_logo_6 {
			// background-image: url(../../assets/casino/icon14.png);
			background-image: url(/assets/casino/icon14.png);
		}

		.tabs_logo_7 {
			// background-image: url(../../assets/casino/icon16.png);
			background-image: url(/assets/casino/icon16.png);
		}
	}

	.tabs_active {
		.tabs_logo_1 {
			// background-image: url(../../assets/casino/icon4.png);
			background-image: url(/assets/casino/icon4.png);
		}

		.tabs_logo_2 {
			// background-image: url(../../assets/casino/icon6.png);
			background-image: url(/assets/casino/icon6.png);
		}

		.tabs_logo_3 {
			// background-image: url(../../assets/casino/icon8.png);
			background-image: url(/assets/casino/icon8.png);
		}

		.tabs_logo_4 {
			// background-image: url(../../assets/casino/icon10.png);
			background-image: url(/assets/casino/icon10.png);
		}

		.tabs_logo_5 {
			// background-image: url(../../assets/casino/icon12.png);
			background-image: url(/assets/casino/icon12.png);
		}

		.tabs_logo_6 {
			// background-image: url(../../assets/casino/icon14.png);
			background-image: url(/assets/casino/icon14.png);
		}

		.tabs_logo_7 {
			// background-image: url(../../assets/casino/icon16.png);
			background-image: url(/assets/casino/icon16.png);
		}
	}

	.tabs_logo_1 {
		// background-image: url(../../assets/casino/icon5.png);
		background-image: url(/assets/casino/icon5.png);
	}

	.tabs_logo_2 {
		// background-image: url(../../assets/casino/icon7.png);
		background-image: url(/assets/casino/icon7.png);
	}

	.tabs_logo_3 {
		// background-image: url(../../assets/casino/icon9.png);
		background-image: url(/assets/casino/icon9.png);
	}

	.tabs_logo_4 {
		// background-image: url(../../assets/casino/icon11.png);
		background-image: url(/assets/casino/icon11.png);
	}

	.tabs_logo_5 {
		// background-image: url(../../assets/casino/icon13.png);
		background-image: url(/assets/casino/icon13.png);
	}

	.tabs_logo_6 {
		// background-image: url(../../assets/casino/icon15.png);
		background-image: url(/assets/casino/icon15.png);
	}

	.tabs_logo_7 {
		// background-image: url(../../assets/casino/icon17.png);
		background-image: url(/assets/casino/icon17.png);
	}


	.tabs_icons {
		display: -webkit-flex;
		display: flex
	}

	.tabs_icons>li {
		position: relative;
		text-align: center;
		cursor: pointer;
		margin-right: 16px
	}

	.tabs_icons>li .tabs_icon {
		width: 76px;
		height: 76px;
		margin-bottom: 16px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-content: center;
		align-content: center;
		position: relative;
		z-index: 1;
		box-shadow: 0 5px 10px 0 rgba(112, 146, 215, .14), inset 0 -4px 0 0 #fff;
		border-radius: 14px
	}

	.tabs_icons>li .tabs_icon:before {
		content: "";
		z-index: -1;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		// background-image: url(../../assets/casino/icon1.png);
		background-image: url(/assets/casino/icon1.png);
		background-size: 100% 100%
	}

	.tabs_icons>li .tabs_icon .tabs_logo {
		width: 50px;
		height: 50px;
		margin-top: 13px;
		display: inline-block;
		background-size: 50px 50px
	}

	.tabs_icons>li p {
		color: #6c7ba8;
		font-size: 16px
	}

	.tabs_icons>li:hover .tabs_icon {
		-webkit-animation: tabs_in__2YKAy .5s ease forwards;
		animation: tabs_in__2YKAy .5s ease forwards
	}

	.tabs_icons>li:hover .tabs_icon:before {
		// background-image: url(../../assets/casino/icon2.png)
		background-image: url(/assets/casino/icon2.png)
	}

	@-webkit-keyframes tabs_in__2YKAy {
		0% {
			-webkit-transform: scale(1.1);
			transform: scale(1.1)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	@keyframes tabs_in__2YKAy {
		0% {
			-webkit-transform: scale(1.1);
			transform: scale(1.1)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	.tabs_icons>li.tabs_active .tabs_icon,
	.tabs_icons>li:hover .tabs_icon {
		box-shadow: 0 7px 13px 0 #b6bfdb
	}

	.tabs_icons>li.tabs_active .tabs_icon:before,
	.tabs_icons>li:hover .tabs_icon:before {
		// background-image: url(../../assets/casino/icon2.png)
		background-image: url(/assets/casino/icon2.png)
	}

	.layout_venue {
		position: relative;
		margin: 0 auto;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		// -webkit-align-items: center;
		// align-items: center;
		background-size: 100% 100%;
		min-height: 960px;
		height: calc(100vh - 210px);
		// padding-top: 90px
		padding-top: 170px;
	}

	.layout_venue .layout_container {
		width: 1200px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-content: center;
		align-content: center;
		box-sizing: border-box;
		margin: 0 auto;
		position: relative
	}

	.layout_venue .layout_container .layout_rightWrap {
		width: 530px;
		margin-right: 33px;
		letter-spacing: .5px;
		text-align: justify;
		position: relative;
		    padding-top: 10px;
		    left: -84px;
	}

	.layout_venue .layout_container .layout_rightWrap .layout_section {
		min-height: 393px;
		-webkit-animation: layout_animationRightIn__DIUpA 1s forwards;
		animation: layout_animationRightIn__DIUpA 1s forwards
	}

	.layout_venue .layout_container .layout_rightWrap .layout_section.layout_fadeOut {
		-webkit-animation: layout_animationRightOut__xFftF 0.3s forwards;
		animation: layout_animationRightOut__xFftF 0.3s forwards
	}

	.layout_venue .layout_container .layout_rightWrap .layout_title {
		max-width: 100%;
		height: 148px
	}

	.layout_venue .layout_container .layout_rightWrap .layout_desc__2U81i {
		line-height: 24px;
		margin: 20px 0 !important;
		font-size: 16px;
		color: #6c7ba8;
		letter-spacing: .5px;
		text-align: justify
	}

	@-webkit-keyframes layout_animationRightIn__DIUpA {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(50px, 0, 0);
			transform: translate3d(50px, 0, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@keyframes layout_animationRightIn__DIUpA {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(50px, 0, 0);
			transform: translate3d(50px, 0, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@-webkit-keyframes layout_animationRightOut__xFftF {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	@keyframes layout_animationRightOut__xFftF {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	.layout_venue .layout_container .layout_rightWrap .layout_tabs {
		width: 100%
	}

	.layout_venue .layout_container .layout_rightWrap .layout_entryBtn {
		width: 160px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		margin-top: 47px;
		color: #fff;
		font-size: 18px;
		background-image: none !important;
		position: relative;
		z-index: 1;
		cursor: pointer;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		box-shadow: 0 2px 4px 0 #5da7eb;
		border-radius: 25px
	}

	.layout_venue .layout_container .layout_rightWrap .layout_entryBtn:before {
		content: "";
		z-index: -1;
		top: 0;
		display: block;
		width: 160px;
		height: 50px;
		position: absolute;
		left: 50%;
		-webkit-transform: translate3d(-50%, 0, 0);
		transform: translate3d(-50%, 0, 0);
		// background-image: url(../../assets/casino/icon3.png);
		background-image: url(/assets/casino/icon3.png);
		background-size: 100% 100%
	}

	.layout_venue .layout_container .layout_rightWrap .layout_entryBtn:hover {
		-webkit-transform: scale(1.05);
		transform: scale(1.05)
	}

	.realbet_bg {
		background: #f0f6ff
	}

	.realbet_container {
		height: 700px
	}

	.realbet_entryBtn {
		margin-top: 21px
	}

	.realbet_leftWrap {
		// position: relative;
		// margin-left: 24px;
		// width: 550px;
		// height: 700px
		    min-width: 750px;
		    height: 100%;
		    position: relative;
		    left: -70px;
			-webkit-perspective: 600px;
			perspective: 600px;
			-webkit-animation: chess_animationPostersIn__1JbCE 1s forwards;
			animation: chess_animationPostersIn__1JbCE 1s forwards
	}

	.realbet_leftWrap.realbet_fadeRightIn {
		-webkit-animation: realbet_postersRightIn__UDbE7 0.3s ease-in-out forwards;
		animation: realbet_postersRightIn__UDbE7 0.3s ease-in-out forwards
	}

	.realbet_leftWrap.realbet_fadeLeftIn__3kVj- {
		-webkit-animation: realbet_postersLeftIn__2CkK5 0.3s ease-in-out forwards;
		animation: realbet_postersLeftIn__2CkK5 0.3s ease-in-out forwards
	}

	.realbet_leftWrap.realbet_fadeRightOut__3WyCg {
		-webkit-animation: realbet_postersRightOut__3qGqF 1s ease-in-out forwards;
		animation: realbet_postersRightOut__3qGqF 1s ease-in-out forwards
	}

	.realbet_leftWrap.realbet_fadeLeftOut__2lmDS {
		-webkit-animation: realbet_postersLeftOut__2c9E3 1s ease-in-out forwards;
		animation: realbet_postersLeftOut__2c9E3 1s ease-in-out forwards
	}
@-webkit-keyframes chess_animationPostersIn__1JbCE {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-100px);
			transform: translateX(-100px)
		}

		to {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@keyframes chess_animationPostersIn__1JbCE {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-100px);
			transform: translateX(-100px)
		}

		to {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@-webkit-keyframes realbet_postersRightIn__UDbE7 {
		0% {
			opacity: 1
		}
		
		to {
			opacity: 0
		}
	}

	@keyframes realbet_postersRightIn__UDbE7 {
		0% {
			opacity: 1
		}
		
		to {
			opacity: 0
		}
	}

	@-webkit-keyframes realbet_postersLeftIn__2CkK5 {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-50px);
			transform: translateX(-50px)
		}

		to {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@keyframes realbet_postersLeftIn__2CkK5 {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-50px);
			transform: translateX(-50px)
		}

		to {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@-webkit-keyframes realbet_postersRightOut__3qGqF {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		to {
			opacity: 0;
			-webkit-transform: translateX(50px);
			transform: translateX(50px)
		}
	}

	@keyframes realbet_postersRightOut__3qGqF {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		to {
			opacity: 0;
			-webkit-transform: translateX(50px);
			transform: translateX(50px)
		}
	}

	@-webkit-keyframes realbet_postersLeftOut__2c9E3 {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		to {
			opacity: 0;
			-webkit-transform: translateX(-50px);
			transform: translateX(-50px)
		}
	}

	@keyframes realbet_postersLeftOut__2c9E3 {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		to {
			opacity: 0;
			-webkit-transform: translateX(-50px);
			transform: translateX(-50px)
		}
	}

	.realbet_leftWrap .realbet_poster {
		position: absolute;
		left: 100px;
		top: 0;
		width: 550px;
		height: 720px
	}

	.realbet_leftWrap .realbet_float1 {
		position: absolute;
		z-index: 1;
		width: 121px;
		height: 81px;
		-webkit-animation: realbet_translate_top_parker__3Wkt2 5s infinite;
		animation: realbet_translate_top_parker__3Wkt2 5s infinite
	}

	.realbet_leftWrap .realbet_float2 {
		position: absolute;
		z-index: 1;
		width: 35px
	}

	.realbet_leftWrap .realbet_float3 {
		position: absolute;
		z-index: 1;
		width: 71px
	}

	.realbet_leftWrap .realbet_float4 {
		position: absolute;
		z-index: 1;
		width: 67px;
		height: 63px;
		-webkit-animation: realbet_translate_up_right_parker__3tzXe 5s infinite;
		animation: realbet_translate_up_right_parker__3tzXe 5s infinite
	}

	.realbet_leftWrap.realbet_yb__3zPHl .realbet_float1 {
		width: 121px;
		top: 213px;
		left: 20px
	}

	.realbet_leftWrap.realbet_yb__3zPHl .realbet_float2 {
		top: 52px;
		right: 150px;
		width: 39px;
		height: 30px;
		-webkit-animation: realbet_translate_up_right_parker__3tzXe 5s infinite;
		animation: realbet_translate_up_right_parker__3tzXe 5s infinite
	}

	.realbet_leftWrap.realbet_yb__3zPHl .realbet_float3 {
		top: 55px;
		right: 63px;
		width: 56px;
		height: 54px;
		-webkit-animation: realbet_translate_down_left_parker__1ksUG 5s infinite;
		animation: realbet_translate_down_left_parker__1ksUG 5s infinite
	}

	.realbet_leftWrap.realbet_yb__3zPHl .realbet_float4 {
		top: 388px;
		left: 41px;
		width: 66px
	}

	.realbet_leftWrap.realbet_bg .realbet_float1 {
		top: 230px;
		left: -90px
	}

	.realbet_leftWrap.realbet_bg .realbet_float2 {
		top: 26px;
		left: 392px;
		width: 37px;
		height: 30px;
		-webkit-animation: realbet_translate_down_left_parker__1ksUG 5s infinite;
		animation: realbet_translate_down_left_parker__1ksUG 5s infinite
	}

	.realbet_leftWrap.realbet_bg .realbet_float3 {
		top: 26px;
		left: 121px;
		width: 71px;
		height: 57px;
		-webkit-animation: realbet_translate_down_right_parker__3bCel 5s infinite;
		animation: realbet_translate_down_right_parker__3bCel 5s infinite
	}

	.realbet_leftWrap.realbet_bg .realbet_float4 {
		top: 405px;
		left: -41px
	}

	.realbet_leftWrap.realbet_ag__3PYEY .realbet_float1 {
		top: 247px;
		left: -81px
	}

	.realbet_leftWrap.realbet_ag__3PYEY .realbet_float2 {
		top: 111px;
		left: 416px;
		width: 35px;
		height: 29px;
		-webkit-animation: realbet_translate_down_left_parker__1ksUG 5s infinite;
		animation: realbet_translate_down_left_parker__1ksUG 5s infinite
	}

	.realbet_leftWrap.realbet_ag__3PYEY .realbet_float3 {
		top: 213px;
		left: 446px;
		width: 71px;
		height: 56px;
		-webkit-animation: realbet_translate_left_parker__3rD4K 5s infinite;
		animation: realbet_translate_left_parker__3rD4K 5s infinite
	}

	.realbet_leftWrap.realbet_ag__3PYEY .realbet_float4 {
		top: 422px;
		left: -32px
	}

	.realbet_leftWrap.realbet_ebet__1AFNc .realbet_float1 {
		top: 247px;
		left: -81px
	}

	.realbet_leftWrap.realbet_ebet__1AFNc .realbet_float2 {
		top: 465px;
		left: 387px;
		width: 35px;
		height: 29px;
		-webkit-animation: realbet_translate_up_left_parker__rSxxz 5s infinite;
		animation: realbet_translate_up_left_parker__rSxxz 5s infinite
	}

	.realbet_leftWrap.realbet_ebet__1AFNc .realbet_float3 {
		top: 40px;
		left: 380px;
		width: 71px;
		height: 56px;
		-webkit-animation: realbet_translate_down_left_parker__1ksUG 5s infinite;
		animation: realbet_translate_down_left_parker__1ksUG 5s infinite
	}

	.realbet_leftWrap.realbet_ebet__1AFNc .realbet_float4 {
		top: 422px;
		left: -32px
	}

	.realbet_leftWrap.realbet_og__3a7j7 .realbet_float1 {
		top: 247px;
		left: -81px
	}

	.realbet_leftWrap.realbet_og__3a7j7 .realbet_float2 {
		top: 305px;
		left: 464px;
		width: 35px;
		height: 29px;
		-webkit-animation: realbet_translate_up_left_parker__rSxxz 5s infinite;
		animation: realbet_translate_up_left_parker__rSxxz 5s infinite
	}

	.realbet_leftWrap.realbet_og__3a7j7 .realbet_float3 {
		top: 14px;
		left: 377px;
		width: 71px;
		height: 56px;
		-webkit-animation: realbet_translate_down_left_parker__1ksUG 5s infinite;
		animation: realbet_translate_down_left_parker__1ksUG 5s infinite
	}

	.realbet_leftWrap.realbet_og__3a7j7 .realbet_float4 {
		top: 421px;
		left: -33px
	}

	.realbet_leftWrap.realbet_wm__1FwMM .realbet_float1 {
		width: 121px;
		height: 81px;
		top: 247px;
		left: -38px
	}

	.realbet_leftWrap.realbet_wm__1FwMM .realbet_float2 {
		width: 35px;
		height: 29px;
		top: 300px;
		left: 500px;
		-webkit-animation: realbet_translate_up_left_parker__rSxxz 5s infinite;
		animation: realbet_translate_up_left_parker__rSxxz 5s infinite
	}

	.realbet_leftWrap.realbet_wm__1FwMM .realbet_float3 {
		width: 71px;
		height: 56px;
		top: 55px;
		left: 452px;
		-webkit-animation: realbet_translate_down_left_parker__1ksUG 5s infinite;
		animation: realbet_translate_down_left_parker__1ksUG 5s infinite
	}

	.realbet_leftWrap.realbet_wm__1FwMM .realbet_float4 {
		top: 430px;
		left: 4px
	}

	@-webkit-keyframes realbet_translate_right_parker__wYW8M {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		50% {
			-webkit-transform: translateX(10px);
			transform: translateX(10px)
		}

		to {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@keyframes realbet_translate_right_parker__wYW8M {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		50% {
			-webkit-transform: translateX(10px);
			transform: translateX(10px)
		}

		to {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@-webkit-keyframes realbet_translate_left_parker__3rD4K {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		50% {
			-webkit-transform: translateX(-10px);
			transform: translateX(-10px)
		}

		to {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@keyframes realbet_translate_left_parker__3rD4K {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		50% {
			-webkit-transform: translateX(-10px);
			transform: translateX(-10px)
		}

		to {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@-webkit-keyframes realbet_translate_down_left_parker__1ksUG {
		0% {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		50% {
			-webkit-transform: translate3D(-10px, 10px, 0);
			transform: translate3D(-10px, 10px, 0)
		}

		to {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@keyframes realbet_translate_down_left_parker__1ksUG {
		0% {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		50% {
			-webkit-transform: translate3D(-10px, 10px, 0);
			transform: translate3D(-10px, 10px, 0)
		}

		to {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@-webkit-keyframes realbet_translate_up_left_parker__rSxxz {
		0% {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		50% {
			-webkit-transform: translate3D(-10px, -10px, 0);
			transform: translate3D(-10px, -10px, 0)
		}

		to {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@keyframes realbet_translate_up_left_parker__rSxxz {
		0% {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		50% {
			-webkit-transform: translate3D(-10px, -10px, 0);
			transform: translate3D(-10px, -10px, 0)
		}

		to {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@-webkit-keyframes realbet_translate_up_right_parker__3tzXe {
		0% {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		50% {
			-webkit-transform: translate3D(10px, -10px, 0);
			transform: translate3D(10px, -10px, 0)
		}

		to {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@keyframes realbet_translate_up_right_parker__3tzXe {
		0% {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		50% {
			-webkit-transform: translate3D(10px, -10px, 0);
			transform: translate3D(10px, -10px, 0)
		}

		to {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@-webkit-keyframes realbet_translate_down_right_parker__3bCel {
		0% {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		50% {
			-webkit-transform: translate3D(10px, 10px, 0);
			transform: translate3D(10px, 10px, 0)
		}

		to {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@keyframes realbet_translate_down_right_parker__3bCel {
		0% {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		50% {
			-webkit-transform: translate3D(10px, 10px, 0);
			transform: translate3D(10px, 10px, 0)
		}

		to {
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@-webkit-keyframes realbet_translate_top_parker__3Wkt2 {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		50% {
			-webkit-transform: translateY(-10px);
			transform: translateY(-10px)
		}

		to {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@keyframes realbet_translate_top_parker__3Wkt2 {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		50% {
			-webkit-transform: translateY(-10px);
			transform: translateY(-10px)
		}

		to {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	.realbet_rightWrap {
		position: relative;
		padding-top: 10px
	}
</style>
