<template>
	<div class="rightobbox inputnoright">

		<div class="title-bo2">
			<div class="left">
				<p>取款</p>
				<span>通常您的提款只需3 - 15 分鐘即可到賬, 若超過30分鐘仍未到賬， 請聯系在線客服核查</span>
			</div>
		</div>

		<div class="account">
			<div>
				<my-img srcText="mycenter/withdrawals/icon1.svg" />
				<div>
					<div class="title">中心錢包</div>
					<div @click="getBalance">
						<span class="balance">{{balanceText}}</span> HKD
						<a class="refresh " :class="{'loading': loadingBalance}" ></a>
					</div>
				</div>
				<button @click="oneTransferOut" class="Button Button3"
					style="width: 80px; height: 28px; border-radius: 14px; position: absolute; top: 15px; right: 18px; font-size: 14px;">{{oneTransferOutText}}</button>
			</div>
			<div>
				<my-img srcText="mycenter/withdrawals/icon3.svg" />
				<div>
					<div class="title">遊戲錢包</div>
					<div @click="getGidBalance">
						<span  class="balance">{{gidBalanceText}}</span> HKD
						<a data-analytics="button" :class="{'loading': loadingGidBalance}" data-label="转账-刷新" class="refresh refresh1"></a>
					</div>
				</div>
			</div>
		</div>
		<a-spin tip="正在加载..." :spinning="spinning">
			<a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
			<div>
				<div class="tab">
					<ul class="tabs">
						<li :class="{'active': tabidx == 1}" @click="setTabidx(1)">
							極速取款
						</li>
						<li :class="{'active': tabidx == 3}" @click="setTabidx(3)" v-if="config.drawing_usdt == 1"><span>USDT取款</span></li>
					</ul>
				</div>
				<div class="tocont" v-if="tabidx == 1 || tabidx == 2">
					<p class="tit5">取款至銀行卡</p>
					<div v-if="banks.length == 0">
						<ul class="bankList usdt ">
							<li class="addBankButton" @click="addBankClick">
								<my-img srcText="mycenter/withdrawals/icon4.svg" />添加銀行卡
							</li>
						</ul>
						<div class="tisiNoInfo" style="margin-bottom: 33px;">
							<my-img srcText="mycenter/withdrawals/icon5.svg" />您還沒綁定銀行卡，為了您的資金安全，綁定銀行卡時，需要填寫您的個人信
						</div>
					</div>
					<div class="bank_list">
						<div class="bank_item_box" v-for="(item, index) in banks" :key="index">
							<div class="bank_item" >
								<!-- <img /> -->
								<p>{{item.bank_name}}  <span style="margin-left: 15px;">
									{{dealCard(item.bank_card)}}
								</span></p>
							</div>
						</div>
					</div>
					<div v-if="banks.length != 0">
						<div class="inputbo2">
							<div class="title-f5">取款金額</div>
							<!-- :max="balance" -->
							<a-input-number class="ainputnumber" style="width: 440px;height: 50px;" :min="100" placeholder="請輸入取款金額, 至少取款100" v-model.number="money" size="large" />
						</div>
						<div class="inputbo2" style="margin-top: 20px;">
							<div class="title-f5">取款密碼</div>
							
							<input-password v-model="qk_pwd" styleText="width:240px;height:50px"></input-password>
							
							<!-- <a-input-password class="ainputnumber" size="large"  style="width: 240px;" placeholder="请输入取款密码" v-model="qk_pwd"/> -->
						</div>
						<button class="Button333"
							style="width: 440px; height: 50px; margin: 50px auto; display: block;" @click="clickShowPasswordModel">{{qkBtnText}}</button>
					</div>
				</div>
				<div class="tocont" v-if="tabidx == 3">
					<p class="tit5">取款至虛擬幣地址</p>
					<div v-if="usdts.length == 0">
						<ul class="bankList usdt ">
							<li class="addBankButton" @click="addBankXnClick">
								<my-img srcText="mycenter/withdrawals/icon4.svg" />添加虛擬幣地址
							</li>
						</ul>
						<div class="tisiNoInfo" style="margin-bottom: 33px;">
							<my-img srcText="mycenter/withdrawals/icon5.svg" />您還沒綁定虛擬幣取款地址，為了你的資金安全，請先綁定虛擬幣取款地址
						</div>
					</div>
					<div class="bank_list" >
						<div class="bank_item_box" v-for="(item, index) in usdts" :key="index">
							<div class="bank_item" >
								<!-- <img /> -->
								<p>{{item.pact == 'erc' ? 'ERC' : 'TRC'}}  <span style="margin-left: 15px;">
									{{dealUsdt(item.usdt_address)}}
								</span></p>
							</div>
						</div>
					</div>
					<div v-if="usdts.length != 0">
						<div class="inputbo2">
							<div class="title-f5">取款金額</div>
							<div class="inputden">
								 <!-- :max="balance" -->
								<a-input-number autocomplete="off" class="ainputnumber" style="width: 240px;height: 50px;" :min="100" placeholder="请输入取款金额, 至少取款100" v-model.number="money" size="large" />
								<p>合计：<span>{{getUsdt}} USDT</span></p>
							</div>
						</div>
						<div class="inputbo2" style="margin-top: 20px;">
							<div class="title-f5">取款密碼</div>
							
							<input-password v-model="qk_pwd" styleText="width:240px;height:50px"></input-password>
							
							<!-- <a-input-password class="ainputnumber" size="large"  style="width: 240px;" placeholder="请输入取款密码" v-model="qk_pwd"/> -->
						</div>
						<button class="Button333"
							style="width: 440px; height: 50px; margin: 50px auto; display: block;" @click="clickShowPasswordModel">{{qkBtnText}}</button>
					</div>
				</div>
			</div>
		</a-spin>
		<add-bank ref="addBank" @getNewList="getList"  />
		<add-bank-xn ref="addBankXn" @getNewList="getList"  />
		
		<a-modal v-model="showPasswordModel" title="取款" cancelText="取消" okText="确认取款" @ok="handleOk">
		    <div>
				<div class="inputbo22">
					<div class="title-f5">取款金额</div>
					<div class="money">
						￥{{money}}
					</div>
				</div>
				
				<div class="inputbo22">
					<div class="title-f5">取款密码</div>
					<a-input-password style="width:300px" placeholder="请输入取款密码" v-model="qk_pwd"/>
				</div>
			</div>
		</a-modal>
		
		<bind-phone ref="phone" />
	</div>
</template>

<script>
	import AddBank from '@/components/addBank/index.vue'
	import AddBankXn from '@/components/addBankXn/index.vue'
	import http from '@/http.js'
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	import BindPhone from '@/components/BindPhone.vue'
	import InputPassword from '@/components/InputPassword.vue'
	export default {
		name: "Home",
		components: {
			AddBank,
			AddBankXn,
			BindPhone,
			InputPassword
		},
		data() {
			return {
				tabidx: 1,

				banks: [],
				usdts: [],
				spinning: true,
				money: '',
				qk_pwd: '',
				showPasswordModel: false,
				loadingBalance: false,
				
				loadingGidBalance: false,
				
				out_usdt: 0,
				oneTransferOutText: '一鍵回收',
				
				qkBtnText: '立即取款'
			};
		},
		computed: {
			...mapGetters(['balance', 'gidBalance', 'userInfo', 'config']),
			
			getUsdt () {
				if (!this.money) {
					return '0.00'
				}
				return (this.money / this.out_usdt).toFixed(4)
			},
			
			balanceText () {
				if (this.balance) {
					if (typeof this.balance === 'number') {
						return this.balance.toFixed(2)
					} else {
						return this.balance
					}
				} else {
					return '0.00'
				}
			},
			gidBalanceText () {
				if (this.gidBalance) {
					if (typeof this.gidBalance === 'number') {
						return this.gidBalance.toFixed(2)
					} else {
						return this.gidBalance
					}
				} else {
					return '0.00'
				}
			}
		},
		mounted() {
			http({
				method: 'get',
				url: '/config',
				data: {
			
				}
			}).then((res) => {
				this.out_usdt = res.data.out_usdt
			})
			this.getGidBalance()
			this.getList()
		},
		methods: {
			...mapActions('user', ['intervalGetBalance', 'setBalance', 'clearIntervalGetBalance', 'getNewGidBalance']),
			setTabidx (index) {
				this.tabidx = index
				this.qkBtnText = '立即取款'
			},
			dealUsdt(account) {
				let usdt = account.substring(0,6)
				for(let i = 0; i < 10; i++) {
					usdt += '*'
				}
				usdt += account.substring(account.length - 6);
				return usdt
			},
			getList () {
				http({
					method: 'post',
					url: '/member/drawingInfoList',
					data: {
				
					}
				}).then((res) => {
					if (res.code == 200) {
						if (res.data.bank.bank_card) {
							this.banks = [res.data.bank]
						} else {
							this.banks = []
						}
						
						if (res.data.usdt.usdt_address) {
							this.usdts = [res.data.usdt]
						} else {
							this.usdts = []
						}
						
						// this.banks = res.data.bank
						// this.usdts = res.data.usdt
					} else {
						this.$message.error(res.message);
					}
					this.spinning = false
				})
			},
			// 获取最新余额
			getNewBalance () {
				this.setBalance()
				this.intervalGetBalance()
			},
			oneTransferOut () {
				if (this.oneTransferOutText === '回收中...') {
					return
				}
				this.loadingBalance = true
				this.oneTransferOutText = '回收中...'
				http({
					method: 'post',
					url: '/member/oneTransferOut',
					data: {
					
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getGidBalance()
						this.getNewBalance()
						setTimeout(() => {
							this.$message.success('余額回收成功！');
						}, 500)
						setTimeout(() => {
							this.loadingBalance = false
						}, 2000)
					} else {
						this.$message.error(res.message);
					}
					setTimeout(() => {
						this.oneTransferOutText = '一鍵回收'
					}, 500)
				})
			},
			getBalance () {
				this.loadingBalance = true
				this.getNewBalance()
				setTimeout(() => {
					this.loadingBalance = false
				}, 2000)
			},
			getGidBalance () {
				if (this.loadingGidBalance === true) {
					return
				}
				this.loadingGidBalance = true
				this.getNewGidBalance()
				setTimeout(() => {
					this.loadingGidBalance = false
				}, 3000)
			},
			dealCard (account) {
				if (!account) {
					return ''
				}
				return "**** **** **** "+account.substring(account.length-4);
			},
			addBankClick() {
				if (!this.userInfo.phone) {
					let self = this
					this.$confirm({
						title: '手機號未綁定，請先綁定手機號！',
						cancelText: '取消',
						okText: '綁定手機號',
						onOk() {
							self.$refs.phone.showModel()
						},
						onCancel() {
							
						},
					});
				} else {
					this.$refs.addBank.showModel(this.userInfo.phone)
				}
			},
			addBankXnClick() {
				if (!this.userInfo.phone) {
					let self = this
					this.$confirm({
						title: '手機號未綁定，請先綁定手機號！',
						cancelText: '取消',
						okText: '綁定手機號',
						onOk() {
							self.$refs.phone.showModel()
						},
						onCancel() {
							
						},
					});
				} else {
					this.$refs.addBankXn.showModel(this.userInfo.phone)
				}
			},
			
			clickShowPasswordModel () {
				if (!this.money) {
					 this.$message.error('請先輸入取款金額');
					 return
				}
				// this.showPasswordModel = true
				this.handleOk()
			},
			handleOk () {
				if (!this.qk_pwd) {
					 this.$message.error('請先輸入取款密碼');
					 return
				}
				if (this.qkBtnText === '提交取款中...') {
					return
				}
				if (this.money > this.balance) {
					this.$message.error('當前余額不足！');
					return
				}
				this.qkBtnText = '提交取款中...'
				let id = 0
				if (this.tabidx == 3) {
					id = this.usdts[0].id
				} else {
					id = this.banks[0].id
				}
				let moneyData = {}
				if (this.tabidx == 3) {
					moneyData['digital_money'] = this.getUsdt
				}
				http({
					method: 'post',
					url: '/member/drawing',
					data: {
						money: this.money,
						qk_pwd: this.qk_pwd,
						type: this.tabidx == 3 ? 'usdt': 'bank',
						id: id,
						...moneyData
					}
				}).then((res) => {
					if (res.code == 200) {
						this.qkBtnText = '立即取款'
						this.$message.success(res.message);
						this.$router.push({
							path: "/my-center/transRecord?type=2",
						});
					} else {
						this.$message.error(res.message);
						this.qkBtnText = '立即取款'
					}
					// this.showPasswordModel = false
					// this.qk_pwd = ''
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.inputden {
		display: flex;
		.ainputnumber {
			border-right: 0px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
		}
		&>p {
			    border: 1px solid #d9d9d9;
			    border-radius: 4px;
			    border-top-left-radius: 0px;
			    border-bottom-left-radius: 0px;
			    width: 200px;
			    /* line-height: 40px; */
			    font-size: 16px;
			    height: 50px;
			    display: flex;
			    align-items: center;
			    /* justify-content: center; */
			    padding-left: 8px;
				span {
					font-weight: 700;
				}
		}
	}
	.inputbo22 {
		margin-bottom: 20px;
		.title-f5 {
			padding-bottom: 10px;
			font-size: 16px;
		}
		.money {
			padding-bottom: 10px;
			font-size: 20px;
			font-weight: 700;
		}
		margin-top: 10px;
	
		input {
			width: 286px;
			height: 50px;
			border-radius: 6px;
			padding: 0 20px;
			border: solid 1px #d8dce7;
			color: #333;
			background-color: #fff;
			font-size: 12px;
	
			&:focus {
				-webkit-transition: background-color .2s ease, border .2s ease;
				transition: background-color .2s ease, border .2s ease;
				border: solid 1px #3678fe;
				-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
				box-shadow: 0 10px 20px 0 #dae8f2;
			}
		}
	
		.tk_ts {
	
			margin-left: 20px;
			line-height: 40px;
			display: inline-block;
			font-size: 14px;
			color: #94a6b7;
			vertical-align: bottom;
	
			img {
				margin-right: 4px;
				width: 18px;
				vertical-align: sub;
			}
		}
	}
	.inputbo2 {
		.title-f5 {
			padding-bottom: 10px;
		}
		margin-top: 10px;
	
		input {
			width: 440px;
			height: 50px;
			border-radius: 6px;
			padding: 0 20px;
			border: solid 1px #d8dce7;
			color: #333;
			background-color: #fff;
			font-size: 12px;
	
			&:focus {
				-webkit-transition: background-color .2s ease, border .2s ease;
				transition: background-color .2s ease, border .2s ease;
				border: solid 1px #3678fe;
				-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
				box-shadow: 0 10px 20px 0 #dae8f2;
			}
		}
	
		.tk_ts {
	
			margin-left: 20px;
			line-height: 40px;
			display: inline-block;
			font-size: 14px;
			color: #94a6b7;
			vertical-align: bottom;
	
			img {
				margin-right: 4px;
				width: 18px;
				vertical-align: sub;
			}
		}
	}
	
	.bank_item_box {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}
	.bank_item_box .edit {
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.bank_item_box .edit span{
		    background: #3678fe;
		    border-radius: 100px;
		    width: 24px;
		    height: 24px;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    color: #fff;
		    font-size: 12px;
			margin-right: 5px;
	}
	.bank_list {
	}
	.bank_item {
		margin-right: 20px;
		    position: relative;
		    padding: 0 20px!important;
		    text-align: left!important;
		    width: 440px;
		    height: 60px;
		    border-radius: 5px;
		    background: #fff;
		    border: 1px solid #d8dce7;
			display: flex;
			align-items: center;
			img {
				width: 18px;
				height: 18px;
				background-color: red;
				margin-right: 9px;
			}
			p {
				    line-height: 18px;
				    margin-left: 1px;
				    font-size: 18px;
				    font-family: PingFang SC;
				    height: 100%;
				    display: -webkit-box;
				    display: -webkit-flex;
				    display: -ms-flexbox;
				    display: flex;
				    -webkit-box-align: center;
				    -webkit-align-items: center;
				    -ms-flex-align: center;
				    align-items: center;
				    color: #383b43;
					font-weight: 700;
			}
	}
	.Button333 {
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;
		cursor: pointer;

		&:hover {
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
			background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		}
	}

	.slesc {
		display: flex;
		align-items: center;
	}

	.icont3 {
		float: left;
		cursor: pointer;
		width: 20px;
		height: 20px;
		margin: 9px 10px;
		// background-image: url(https://senbackkg.salinent.com/main-consumer-web/assets-oss/kok/images/myCenter/sdqiaobao@2x.bb9310be4471ed0e8c1278f875324957.png?x-oss-process=image/quality,Q_70/format,webp);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.inputbox {
		border-radius: 10px;
		margin-top: 20px;

		.formControl {
			position: relative;
			width: 480px;
			float: left;
			position: relative;
			height: 40px;

			input {
				border-radius: 6px;
				font-size: 12px;
				border-radius: 6px;
				padding: 0 20px;
				border: solid 1px #d8dce7;
				color: #333;
				background-color: #fff;
				border-radius: 6px;

				&:hover {
					transition: all .3s ease;
					border: solid 1px #3678fe;
				}

				&:focus {
					-webkit-transition: background-color .2s ease, border .2s ease;
					transition: background-color .2s ease, border .2s ease;
					border: solid 1px #3678fe;
					-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
					box-shadow: 0 10px 20px 0 #dae8f2;
				}
			}

			a {
				position: absolute;
				right: 20px;
				top: 50%;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
				font-size: 14px;
				color: #4b5e6f;
			}
		}
	}

	.account {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin-top: 30px;



		&>div {
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			padding: 20px;
			border-radius: 10px;
			width: 435px;
			height: 90px;
			border-radius: 6px;
			-webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
			box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
			border: solid 1px #f0f3f7;
			color: #4b5e6f;
			background-color: #fff;

			img {
				margin-right: 10px;
				width: 40px;
				height: 40px;
			}

			.Button {
				border: none;
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
				background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
				color: #fff;
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				transition: all .3s ease;
				cursor: pointer;

				&:hover {
					background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
					background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
					background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
					-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
					box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
				}
			}

			.title {
				margin-bottom: 10px;
				font-size: 14px;
				color: #4b5e6f;
			}


			.balance {
				font-size: 24px;
				color: #333;
				font-weight: bold;
				display: inline-block;
				max-width: 226px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				vertical-align: bottom;
			}

			.refresh {
				display: inline-block;
				margin-left: 10px;
				width: 16px;
				height: 16px;
				// background-image: url(../../../assets/mycenter/withdrawals/icon2.svg);
				background-image: url(/assets/mycenter/withdrawals/icon2.svg);
				background-repeat: no-repeat;
				border: none;
				vertical-align: middle;
				cursor: pointer;
				transition: all 2s;
				&.loading {
					transform: rotateZ(360deg);
				}
				&.refresh1 {
					transition: all 3s;
				}
			}
			
		}
	}

	.walletlist {
		max-height: 220px;
		-webkit-transition: max-hieght .4s linear;
		transition: max-hieght .4s linear;
		-webkit-transition: max-height .4s;
		-o-transition: max-height .4s;
		overflow: hidden;
		max-height: 1000px;

		.normalItem {
			position: relative;
			height: 120px;
			background-size: 210px 120px;

			.top {
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-webkit-justify-content: space-between;
				-ms-flex-pack: justify;
				justify-content: space-between;

				.clientName {
					color: #333;
					font-size: 12px;
				}
			}

			.actionBtnBox {
				a {
					position: absolute;
					top: 14px;
					right: 10px;
					width: 65px;
					height: 28px;
					text-align: center;
					line-height: 28px;
					border-radius: 6px;
					font-size: 12px;
					-webkit-transition: none;
					transition: none;
					color: #4b5e6f;
				}
			}

			.money {
				margin-top: 14px;
			}
		}

		li {
			width: 210px;
			height: 90px;
			position: relative;
			border-radius: 6px;
			float: left;
			margin-right: 20px;
			margin-bottom: 20px;
			padding: 20px 20px 20px;
			background-size: 210px 90px;
			color: #4b5e6f;
			-webkit-box-shadow: 0 10px 10px 0 rgba(208, 217, 225, .22);
			box-shadow: 0 10px 10px 0 rgba(208, 217, 225, .22);
			border: solid 1px #dfeaf6;
			background-image: url(https://senbackkg.salinent.com/main-consumer-web/assets-oss/kok/images/myCenter/ka-2.a1a5e4968dff0471a7cf017e29561749.svg), linear-gradient(166deg, #ffffff 8%, #f6fafd 64%);

			&:nth-child(4n) {
				margin-right: 0;
			}

			.top {
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-webkit-justify-content: space-between;
				-ms-flex-pack: justify;
				justify-content: space-between;

				.clientName {
					color: #333;
					font-size: 12px;
				}

			}

			.actionBtnBox {
				cursor: pointer;
			}

			.actionBtnBox:hover a {
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
				background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
				color: #fff;
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			}

			.money {
				margin-top: 14px;

				i {
					display: block;
					color: #333;
					font-size: 18px;
					font-weight: bold;
				}
			}

			.transToggle span {
				position: absolute;
				font-size: 12px;
				width: 44px;
				height: 28px;
				text-align: center;
				line-height: 28px;
				border-radius: 6px;
				cursor: pointer;

				&:first-child {
					right: 58px;
					bottom: 7px;
				}

				&:last-child {
					right: 10px;
					bottom: 7px;
				}

				&:hover {
					background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
					background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
					background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
					color: #fff;
					-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
					box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				}
			}
		}

	}

	.tie {
		color: #333;
		margin: 30px 0 10px;
		font-size: 14px;
	}

	.tab {
		.fire {
			position: absolute;
			top: -3px;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			width: 85px;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			left: -12px;
			font-weight: normal !important;

			p {
				font-size: 12px;
				-webkit-transform: scale(0.83);
				transform: scale(0.83);
				color: red;
				line-height: 16px;
				text-align: left;
				font-weight: normal !important;
			}
		}

		.tabs {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			position: relative;
			-webkit-box-pack: start;
			-webkit-justify-content: flex-start;
			-ms-flex-pack: start;
			justify-content: flex-start;
			margin-top: 30px;

			&:after {
				background: #d8dce7;
				content: "";
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 1px;
			}

			li {
				height: 54px;
				line-height: 54px;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
				cursor: pointer;
				display: block;
				text-align: center;
				position: relative;
				margin-right: 30px;

				&.active {
					font-weight: bold;

					&:after {
						content: "";
						display: block;
						position: absolute;
						left: 0;
						bottom: 0;
						height: 1px;
						z-index: 1;
						width: 100%;
						background: #4080ff;
					}
				}
			}
		}
	}

	.tocont {

		.tit5 {
			margin: 20px 0 10px 0;
		}

		.bankList {
			width: 100%;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;

			.addBankButton {
				width: 286px;
				height: 40px;
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: center;
				-webkit-justify-content: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				border-radius: 6px;
				border: solid 1px #d8dce7;
				cursor: pointer;
				color: #4080ff;

				img {
					margin-right: 5px;
					height: 24px;
				}
			}
		}

		.tisiNoInfo {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			margin-top: 30px;
			color: #333;
			font-size: 14px;
			color: #94a6b7;
			margin-bottom: 33px;
		}

		.Button333 {
			cursor: pointer;
		}
	}
</style>
