<template>
  <div class="home">

    <div class="content2">
      <div class="title"></div>

      <div class="list-box2">
        <ul class="tab" style="top: 0px">
          <li class="active">
           <my-img srcText="activity/icon1.png" style="width:30px;height:30px;min-width: 30px;" />{{$t('main.yhhd')}}
          </li>
        </ul>
        <div class="wrap">
          <ul class="acyivityList clearfix">
            <li
              data-analytics="button"
			  v-for="(item, index) in list" :key="index" @click="linkDetail(item)"
            >
              <my-img srcText="activity/icon3.png"  classText="activityTag" />
              <div class="imgBox undefined">
                <p>
                  <img
                    :src="item.title_img"
                    width="360px"
                    height="224px"
                    style=""
                  />
                </p>
                <div class="itemText">
                  <p class="title1" :title="item.title">
                    {{item.title}}
                  </p>
                  <p class="time">
                    <span>{{item.desc}}</span>
                  </p>
                </div>
              </div>
            </li>
           
          </ul>
        </div>


        <!-- <div class="pagination">
          <ul class="ant-pagination">
            <li
              title="上一页"
              class="ant-pagination-prev ant-pagination-disabled"
              aria-disabled="true"
            >
              <a class="ant-pagination-item-link" disabled=""
                ><span role="img" aria-label="left" class="anticon anticon-left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    class=""
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path></svg></span
              ></a>
            </li>
            <li
              title="1"
              class="
                ant-pagination-item
                ant-pagination-item-active
              "
              tabindex="0"
            >
              <a>1</a>
            </li>
            <li
              title="2"
              class="ant-pagination-item"
              tabindex="0"
            >
              <a>2</a>
            </li>
            <li
              title="3"
              class="ant-pagination-item "
              tabindex="0"
            >
              <a>3</a>
            </li>
            <li
              title="4"
              class="ant-pagination-item "
              tabindex="0"
            >
              <a>4</a>
            </li>
            <li
              title="下一页"
              tabindex="0"
              class="ant-pagination-next"
              aria-disabled="false"
            >
              <a class="ant-pagination-item-link"
                ><span
                  role="img"
                  aria-label="right"
                  class="anticon anticon-right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    class=""
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path></svg></span
              ></a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>

  </div>
</template>

<script>
	import http from '../../http.js'
export default {
  name: "Home",
  data() {
    return {
		pageIndex: 1,
		list: []
	};
  },
  mounted() {
  	this.getList()
  },
  methods: {
	  getList () {
		  http({
		  	method: 'get',
		  	url: '/activity',
		  	data: {
		  	
		  	}
		  }).then((res) => {
		  	this.list = res.data.list
		  })
	  },
	  linkDetail (data) {
		  this.$router.push({
		    path: "/activity_detail?id=" + data.id,
		  });
	  }
   }
};
</script>

<style lang="less" scoped>
.content2 {
  min-width: 1200px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-position: center top;
  background-repeat: no-repeat;
  padding-top: 600px;
  background-size: 100% 500px;
  // background-image: url(../../assets/bg.bd0f8867de0e35100f609e630257a652.png);
  background-image: url(/assets/bg.bd0f8867de0e35100f609e630257a652.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .wrap {
    background: #fff;
    position: relative;
    z-index: 10;
    margin-top: 0;
    &:after {
      content: "";
      display: block;
      clear: both;
      overflow: hidden;
    }
    &::before {
      content: "";
      width: 100%;
      height: 18px;
    }
    .acyivityList {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      &:after {
        content: "";
        display: block;
        clear: both;
        overflow: hidden;
      }
      li {
        position: relative;
        -webkit-box-shadow: 0 0 0 1px #f1f1f1;
        box-shadow: 0 0 0 1px #f1f1f1;
        background-color: #fff;
        margin-top: 30px;
        cursor: pointer;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out,
          -webkit-transform 0.5s ease-in-out;
        margin-right: 28px;
        width: 364px;
        border-radius: 6px;
        border: solid 0.8px rgba(210, 210, 245, 0.5);
        background-image: -webkit-linear-gradient(top, #fff, #fff),
          -webkit-linear-gradient(321deg, #d2d2f5 1%, #d2d6e2 24%, #c3d3ed 44%, #cad0e3
                65%, #c6d2ed 99%);
        background-image: linear-gradient(to bottom, #fff, #fff),
          linear-gradient(
            129deg,
            #d2d2f5 1%,
            #d2d6e2 24%,
            #c3d3ed 44%,
            #cad0e3 65%,
            #c6d2ed 99%
          );
        -webkit-box-shadow: none;
        box-shadow: none;
        &:nth-of-type(3) {
        }
        &:nth-of-type(3n) {
          margin-right: 0;
        }
        &:hover {
          -webkit-transition: -webkit-box-shadow 0.25s;
          transition: -webkit-box-shadow 0.25s;
          transition: box-shadow 0.25s;
          transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
          -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.21);
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.21);
          .imgBox img {
            -webkit-transform: translateZ(0) scale(1.05);
            transform: translateZ(0) scale(1.05);
          }
        }
        .itemText {
          position: absolute;
          padding: 15px 11px 0;
          height: 90px;
          font-size: 14px;
          top: 225px;
          .title1 {
            width: 330px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            white-space: pre;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.65);
          }
          .time {
            margin-top: 3px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .imgBox {
          width: 100%;
          height: 291px;
          overflow: hidden;
          position: relative;
          border-radius: 6px 6px 0 0;
          p {
            overflow: hidden;
          }
          img {
            height: 224px;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            width: 364px;
          }
        }
        .activityTag {
          position: absolute;
          z-index: 2;
          will-change: translate, opacity;
          right: 0;
          bottom: 0;
          width: 52px;
          height: 52px;
        }
      }
    }
  }
  .list-box2 {
    border-radius: 10px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    width: 1238px;
    padding: 30px 48px 62px 42px;
    margin: 0px auto 70px;
    .tab {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: auto;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      li {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        width: 130px;
        height: 50px;
        border-radius: 10px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-box-shadow: 0 3px 6px 0 rgba(194, 211, 222, 0.67);
        box-shadow: 0 3px 6px 0 rgba(194, 211, 222, 0.67);
        text-shadow: 1px 1px 1px rgba(134, 161, 197, 0.4), -1px -1px 1px #fff;
        color: #90a2b3;
        // background-image: url(../../assets/btn.8986f99459532c8eaad78cb5a20f829e.png);
		background-image: url(/assets/btn.8986f99459532c8eaad78cb5a20f829e.png);
        &.active {
          // background-image: url(../../assets/btn-selected.cd8588d9e1311df3c7493a617b3a24bb.png);
		  background-image: url(/assets/btn-selected.cd8588d9e1311df3c7493a617b3a24bb.png);
          color: #fff;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 10px;
          -webkit-box-shadow: 0 2px 3px 0 #5da7eb;
          box-shadow: 0 2px 3px 0 #5da7eb;
          text-shadow: none;
        }
        img {
          width: 30px;
          margin-right: 4px;
          margin-left: -10px;
        }
      }
    }
  }

  .pagination {
          text-align: center;
    position: relative;
    margin: 40px 0 0 0;
      .ant-pagination {
              -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        border-color: #e2e2e2;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px !important;
    overflow: hidden;
    &:hover {
         font-weight: 500;
    background: #fff;
    border-color: #1890ff;
    box-shadow: 0 10px 20px 0 #dae8f2;
    border: solid 1px #3678fe;
    background-color: #fff;
    a {
        color: #3f8bff;
    }
    }
    a {
            color: #666;
    border: none;
    background-color: transparent;
    .anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
    }
    }
          .ant-pagination-prev {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    color: rgba(0,0,0,.65);
    font-family: Arial;
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    margin-right: 8px;
          }
          .ant-pagination-next{
    display: inline-block;
    min-width: 32px;
    height: 32px;
    color: rgba(0,0,0,.65);
    font-family: Arial;
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
          }
          .ant-pagination-item-link {
display: block;
    height: 100%;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border-radius: 2px;
    outline: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
        color: rgba(0,0,0,.65);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
          }
          .ant-pagination-item, .ant-pagination-total-text {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle;
}
          .ant-pagination-item {
min-width: 32px;
    font-family: Arial;
    text-align: center;
    list-style: none;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    a {
            display: block;
    padding: 0 6px;
    color: rgba(0,0,0,.65);
    -webkit-transition: none;
    transition: none;
    }
          }
          .ant-pagination-item-active {
              font-weight: 500;
    background: #fff;
    border-color: #1890ff;
    box-shadow: 0 10px 20px 0 #dae8f2;
    border: solid 1px #3678fe;
    background-color: #fff;
    a {
        color: #3f8bff;
    }
          }
      }
  }
}
.title {
  // background-image: url(../../assets/activity/icon2.png);
  background-image: url(/assets/activity/icon2.png);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  top: 70px;
  background-repeat: no-repeat;
  background-size: 1920px 500px;
  background-position: center top;
  height: 500px;
  width: 1920px;
}
</style>
