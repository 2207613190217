<template>
	<div class="home">
		<!-- 头部  -->
		<header-page />
		<keep-alive>
			<router-view />
		</keep-alive>
		<!-- // footer -->
		<footer-page />

		<a-modal width="400px" @cancel="modelCancel" :destroyOnClose="true" :dialogStyle="{'top': '200px'}" :keyboard="false"  dialogClass="trme-model" :visible="transferModelShow" :footer="null" :title="`您需要為【${transferModelInfoGameName}】轉入余額嗎？`">
			<div class="trme-content inputnoright">
				<div class="trme-content-left">
					<p>
						中心余額：{{balanceText}}
					</p>
					<!-- :max="balance" -->
					<a-input-number v-model="money" autocomplete="off" class="ainputnumber" style="width: 200px;height: 50px;" :min="0"  :placeholder="inputPlaceholder" size="large" />
				</div>
				<div class="trme-content-right">
					<div class="trme-content-right-btn1" @click="oneTransferOut">{{oneTransferOutText}}</div>
					<div class="trme-content-right-btn2" @click="setMoneyAll">全部</div>
				</div>
			</div>
			<div v-if="transferModelInfo.platType === 'JOKER' && transferModelInfo.gameName === 'JOKER电子' && multipleList.length > 1">
				<div class="bsclass">
					<p :class="{'active': multipleIndex === index}" @click="selectMultiple(index)" v-for="(item, index) in multipleList">{{item.muName}}X</p>
				</div>
				<!-- v-if="multipleList.length > 1" -->
				<div class="multip"  v-html="multipleTip"></div>
			</div>
			
			<div class="trme-footer">
				<div class="trme-footer-btn1" @click="link5sGame">直接進入</div>
				<div class="trme-footer-btn2" @click="transferLinkGame">{{transferText}}</div>
			</div>
		</a-modal>
		
		<a-modal width="400px" @cancel="modelJOKERCancel" :destroyOnClose="true" :dialogStyle="{'top': '200px'}" :keyboard="false"  dialogClass="trme-model" :visible="jokerModelShow" :footer="null" :title="`您需要為【${transferModelInfoGameName}】選擇倍數`">
			<div v-if="multipleList.length > 1">
				<div class="bsclass mano" >
					<p :class="{'active': multipleIndex === index}" @click="selectMultiple(index)" v-for="(item, index) in multipleList">{{item.muName}}X</p>
				</div>
				<!-- v-if="multipleList.length  > 1" -->
				<div class="multip"  v-html="multipleTip"></div>
			</div>
			
			<div class="trme-footer">
				<div class="trme-footer-btn2" @click="link5sGame">直接進入</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	// 头部 
	import HeaderPage from "@/components/HeaderPage";
	// footer
	import FooterPage from "@/components/FooterPage";
	import http from '@/http.js'
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	export default {
		name: "Home",
		components: {
			HeaderPage,
			FooterPage
		},
		data() {
			return {
				money: null,
				oneTransferOutText: '資金回收',
				transferText: '立即轉入',
				
				multipleIndex: 0
			};
		},
		computed: {
			...mapGetters(['transferModelShow', 'transferModelInfo', 'balance', 'jokerModelShow', 'gameConfig']),
			
			balanceText () {
				if (this.balance) {
					if (typeof this.balance === 'number') {
						return this.balance.toFixed(2)
					} else {
						return this.balance
					}
				} else {
					return '0.00'
				}
			},
			
			multipleList () {
				let jokerList = []
				this.gameConfig['电子'].forEach((v) => {
					console.log(v, '')
					if (v.name.indexOf('JOKER') >= 0 && v.maintain === 0 && v.name != 'JOKER电子') {
						jokerList.push({
							...v,
							muName: v.name.slice(5)
						})
					}
				})
				console.log(jokerList, 'jokerList')
				return [{
					game_code: null,
					game_type: 2,
					id: 55533,
					maintain: 0,
					muName: "1",
					name: "JOKER",
					on_line: 0,
					plat_type: "JOKER",
					sort: 10,
				}, ...jokerList]
			},
			multipleTip  () {
				return window.multipleTipWindows[this.multipleIndex]
			},
			transferModelInfoGameName () {
				if (this.multipleList.length > 1) {
					if (this.multipleIndex === 0) {
						return this.transferModelInfo.gameName
					} else {
						return this.multipleList[this.multipleIndex].name
					}
				} else {
					return this.transferModelInfo.gameName
				}
			},
			
			inputPlaceholder () {
				if (this.transferModelInfo.gameName === 'JOKER电子') {
					if (this.multipleList.length > 1) {
						return window.multipleInputPlaceholder[this.multipleIndex]
					} else {
					    return '請輸入金額'
				    }
				}
				else {
					return '請輸入金額'
				}
			}
		},
		watch: {
			transferModelShow () {
				this.oneTransferOutText = '資金回收',
				this.transferText = '立即轉入'
				this.money = null
			}
		},
		methods: {
			...mapActions('user', ['intervalGetBalance', 'setBalance', 'setTransferModelShow', 'setJokerModelShow']),
			
			setMoneyAll () {
				this.money = this.balance
			},
			// 获取最新余额
			getNewBalance () {
				this.setBalance()
				this.intervalGetBalance()
			},
			oneTransferOut () {
				if (this.oneTransferOutText === '回收中...') {
					return
				}
				this.oneTransferOutText = '回收中...'
				http({
					method: 'post',
					url: '/member/oneTransferOut',
					data: {
						
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getNewBalance()
						setTimeout(() => {
							this.$message.success('余額回收成功！');
						}, 500)
					} else {
						this.$message.error(res.message);
					}
					setTimeout(() => {
						this.oneTransferOutText = '資金回收'
					}, 500)
				})
			},
			transferLinkGame () {
				if (this.transferText === '轉入余額中...') {
					return
				}
				if (this.money === 0 || !this.money) {
					this.$message.error('請輸入轉入金額');
					return
				}
				
				if (this.transferModelInfo.gameName === 'JOKER电子' && this.multipleList.length > 1) {
					if (this.multipleIndex === 0 && this.money < 1) {
						this.$message.error("JOKER1最低1元");
						return 
					}
				}
				
				this.transferText = '轉入余額中...'
				let in_account = this.transferModelInfo.platType
				if (this.transferModelInfo.gameName === 'JOKER电子' && this.multipleList.length > 0) {
					let muData = this.multipleList[this.multipleIndex]
					in_account = muData.plat_type
				}
				
				if (this.money > this.balance) {
					this.$message.error('當前余額不足！');
					this.transferText = '立即轉入'
					return
				}
				
				http({
					method: 'post',
					url: '/member/gameTransfer',
					data: {
						in_account: in_account,
						out_account: 'center',
						money: this.money
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getNewBalance()
						this.$message.success('操作成功');
						this.link5sGame()
					} else {
						this.$message.error(res.message);
						this.showLoading = false
						this.transferText = '立即轉入'
					}
				})
			},
			link5sGame () {
				this.setTransferModelShow(false)
				setTimeout(() => {
					this.linkGame()
				}, 500)
			},
			linkGame () {
				if (this.transferModelInfo.gameName === 'JOKER电子') {
					if (this.multipleList.length > 1) {
						let muData = this.multipleList[this.multipleIndex]
						let href =  this.transferModelInfo.href.replace('JOKER', muData.plat_type)
						window.open(href, "_blank");
					} else {
						window.open(this.transferModelInfo.href, "_blank");
					}
				} else {
					window.open(this.transferModelInfo.href, "_blank");
				}
			},
			modelCancel () {
				this.setTransferModelShow(false)
			},
			modelJOKERCancel () {
				this.setJokerModelShow(false)
			},
			selectMultiple (index) {
				this.multipleIndex = index
			}
		}
	};
</script>

<style lang="less">
	
	.trme-model {
		.multip {
			font-weight: 700;
			color: #333;
		}
		.mano {
				margin-top: 0px !important;
		}
		.bsclass {
			display: flex;
			flex-wrap: wrap;
			margin-top: 30px;
			p {
				width: 30%;
				height: 40px;
				    background-image: linear-gradient(to bottom, #96989a 50%, #949697 100%);
				    height: 40px;
				    color: #fff;
				    line-height: 40px;
				    cursor: pointer;
				    border: 0;
				    font-size: 20px;
					font-weight: 700;
				    border-radius: 5px;
				    text-align: center;
				    letter-spacing: 2px;
				    // box-shadow: 0 10px 20px 0 rgb(150 ,152 ,154 , 0.1);
				    transition: all 0.3s ease;
					margin-right: 5%;
					color: #333;
					margin-bottom: 15px;
					&.active {
						background-image: linear-gradient(to bottom, #5fabff 10%, #3678fe 100%);
						box-shadow: 0 10px 20px 0 rgba(60, 142, 254, 0.2);
						color: #fff;
					}
					&:nth-child(3n) {
						margin-right: 0px;
					}
			}
		}
		.ant-modal-header {
			background-image: linear-gradient(180deg,#62abfb 10%,#2958b9);
			    color: #fff;
			    text-align: center;
				
				.ant-modal-title {
					 color: #fff;
					 letter-spacing: 1px;
					 font-weight: 400 !important;
				}
		}
		.ainputnumber input{
			font-size: 18px;
			letter-spacing: 1px;
		}
		.ant-modal-close-x {
			color: #fff;
		}
		.trme-content {
			display: flex;
			justify-content: space-between;
			padding-top: 10px;
			.trme-content-left {
				&>p {
					color: #000;
					font-weight: 500;
					font-size: 18px;
					margin-bottom: 20px;
				}
			}
			.trme-content-right {
				.trme-content-right-btn1 {
					background-image: linear-gradient(to bottom, #8aa2d2 1%, #1675c1);
					width: 120px;
					    height: 40px;
						color: #fff;
						line-height: 40px;
						text-align: center;
						    cursor: pointer;
						    border: 0;
						    border-radius:5px;
							margin-bottom: 17px;
							font-size: 16px;
							letter-spacing: 2px;
							    box-shadow: 0 10px 20px 0 rgba(60,142,254, 0.2);
								transition: all .3s ease;
								&:hover {
									background-image: linear-gradient(to bottom, #1675c1 1%, #8aa2d2);
								}
				}
				.trme-content-right-btn2 {
					background-image: linear-gradient(to bottom, #5fabff 10%, #3678fe 100%);
					width: 120px;
					    height: 40px;
						color: #fff;
						line-height: 40px;
						    cursor: pointer;
						    border: 0;
							font-size: 18px;
						    border-radius: 5px;
							text-align: center;
							letter-spacing: 2px;
							box-shadow: 0 10px 20px 0 rgba(60,142,254, 0.2);
							transition: all .3s ease;
							&:hover {
								background-image: linear-gradient(to bottom, #3678fe 10%, #5fabff 100%);
							}
				}
			}
		}
		.trme-footer {
			display: flex;
			justify-content: space-around;
			margin-top: 40px;
			padding-bottom: 10px;
			.trme-footer-btn1 {
				background-image: linear-gradient(to bottom, #b1b4b8 50%, #949697 100%);
				width: 150px;
				    height: 50px;
					color: #fff;
					line-height: 50px;
					    cursor: pointer;
					    border: 0;
						font-size: 18px;
					    border-radius: 5px;
						text-align: center;
						letter-spacing: 1px;
						box-shadow: 0 10px 20px 0 rgba(177,180,184, 0.2);
						transition: all .3s ease;
						&:hover {
							background-image: linear-gradient(to bottom, #949697 50%, #b1b4b8 100%);
						}
			}
			.trme-footer-btn2 {
				background-image: linear-gradient(180deg,#62abfb 10%,#2958b9);
				width: 150px;
				    height: 50px;
					color: #fff;
					line-height: 50px;
					    cursor: pointer;
					    border: 0;
						font-size: 18px;
					    border-radius: 5px;
						text-align: center;
						letter-spacing: 1px;
						box-shadow: 0 10px 20px 0 rgba(60,142,254, 0.2);
						transition: all .3s ease;
						&:hover {
							background-image: linear-gradient(180deg,#2958b9 10%,#62abfb);
						}
			}
		}
	}
</style>
