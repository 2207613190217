<template>
	<div class="slesc">
		<a-dropdown overlayClassName="meunlistcss">
		    <div class="select-box select-box-css">
		    	<span>從</span>
		    	<p>{{startData.api_title}}</p>
				<a-icon type="down" />
		    </div>
		    <a-menu slot="overlay">
			  <a-menu-item v-if="startData.api_title === '中心錢包'">
			    <a style="padding:0 20px;line-height: 30px;">中心錢包</a>
			  </a-menu-item>
		      <a-menu-item @click="selectStartData(item)" v-for="(item, index) in list" :key="index">
		        <a style="padding:0 20px;line-height: 30px;">{{item.api_title}}</a>
		      </a-menu-item>
		    </a-menu>
		</a-dropdown>
		
		<div class="icont3" @click="transfer"></div>
		
		<a-dropdown overlayClassName="meunlistcss">
		    <div class="select-box select-box-css">
		    	<span>到</span>
		    	<p>{{endData.api_title}}</p>
				<a-icon type="down" />
		    </div>
		   <a-menu slot="overlay">
			 <a-menu-item @click="selectEndData('中心錢包')" v-if="endData.api_title === '中心錢包'">
			   <a style="padding:0 20px;line-height: 30px;">中心錢包</a>
			 </a-menu-item>
		     <a-menu-item @click="selectEndData(item)" v-for="(item, index) in list" :key="index">
		       <a style="padding:0 20px;line-height: 30px;">{{item.api_title}}</a>
		     </a-menu-item>
		   </a-menu>
		</a-dropdown>
		<span style="margin-left: 20px;" class="tk_ts">
			<my-img srcText="mycenter/transfer/icon3.svg" />場館錢包和場館錢包之間不可以互轉
		</span>
	</div>
</template>

<script>
	import http from '@/http.js'
	export default {
		props: ['list'],
		data() {
			return {
				selectList1: [],
				selectList2: [],
				
				startData: {
					api_title: '中心錢包',
					api_name: 'center'
				},
				endData: {
					api_title: '',
					api_name: ''
				}
			};
		},
		computed: {

		},
		mounted() {
			if (this.list.length) {
				this.endData = {
					api_title: this.list[0].api_title,
					api_name: this.list[0].api_name,
				}
			}
		},
		methods: {
			selectStartData (data) {
				if (data === '中心錢包') {
					
				} else {
					this.startData = {
						api_title: data.api_title,
						api_name: data.api_name,
					}
					this.endData = {
						api_title: '中心錢包',
						api_name: 'center'
					}
				}
				this.$emit('selectStartData')
			},
			selectEndData (data) {
				if (data === '中心錢包') {
					
				} else {
					this.startData = {
						api_title: '中心錢包',
						api_name: 'center'
					}
					this.endData = {
						api_title: data.api_title,
						api_name: data.api_name,
					}
				}
			},
			transfer () {
				if (this.endData.api_title == '中心錢包') {
					this.endData = {
						api_title: this.startData.api_title,
						api_name: this.startData.api_name,
					}
					this.startData = {
						api_title: '中心錢包',
						api_name: 'center'
					}
				} else if (this.startData.api_title == '中心錢包') {
					this.startData = {
						api_title: this.endData.api_title,
						api_name: this.endData.api_name,
					}
					this.endData = {
						api_title: '中心錢包',
						api_name: 'center'
					}
				}
				this.$emit('selectStartData')
			}
		}
	};
</script>

<style lang="less" scoped>
	.select-box {
		width: 210px;
		height: 60px;
		border: solid 1px #d8dce7;
		color: #94a6b7;
		border-radius: 6px;
		padding: 0 20px;
		padding-right: 0px;
		display: flex;
		align-items: center;
		position: relative;
		cursor: pointer;
		&>span {
			margin-right: 4px;
			font-family: PingFang SC;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 60px;
			color: #626571;
			opacity: .5;
			position: relative;
			top: 1px;
		}
		&>p {
			margin-left: 0px;
			font-size: 18px;
			    color: #383b43;
				outline: none;
		}
		&>i {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translate(0%, -50%) !important;
			color: #333;
			opacity: 0.7;
		}
	}

	.slesc {
		display: flex;
		align-items: center;
	}

	.icont3 {
		float: left;
		cursor: pointer;
		width: 20px;
		height: 20px;
		margin: 9px 10px;
		// background-image: url(../../../assets/mycenter/transfer/icon5.png);
		background-image: url(/assets/mycenter/transfer/icon5.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
</style>
