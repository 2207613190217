<template>
	<div class="footer_footerView">
		<div class="footer_contentBox">
			<div class="footer_logbox">
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon1.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon2.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon3.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon4.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon5.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon6.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon7.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon8.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon9.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon10.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon11.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon12.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon13.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon14.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon15.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon16.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon17.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon18.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon24.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon26.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon19.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon20.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				<div class="footer_agent_item">
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon21.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
					<div class="image_imageContainer image_cover footer_agentIcon"
						style="cursor: inherit;">
						<div>
							<my-img srcText="footer/icon22.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;" />
						</div>
					</div>
				</div>
				
			</div>
			<div class="footer_explanation">
				<p>{{config.site_name}} {{$t('main.footer.ti1')}}
					<br>
					{{$t('main.footer.ti2')}}
				</p>
			</div>
			<div class="footer_license_warp">
				<ul class="footer_license_list">
					<li class="footer_license_item">
						<div class="footer_icon_group" style="width: 165px; height: 34px;">
							<div class="footer_iconDefault"></div>
							<div class="footer_iconHover"></div>
						</div><span class="footer_license_name">{{$t('main.footer.ti3')}}</span>
					</li>
					<li class="footer_license_item">
						<div class="footer_icon_group" style="width: 171px; height: 41px;">
							<div class="footer_iconDefault"></div>
							<div class="footer_iconHover"></div>
						</div><span class="footer_license_name">{{$t('main.footer.ti4')}}</span>
					</li>
					<li class="footer_license_item">
						<div class="footer_icon_group" style="width: 138px; height: 41px;">
							<div class="footer_iconDefault"></div>
							<div class="footer_iconHover"></div>
						</div><span class="footer_license_name">{{$t('main.footer.ti5')}}</span>
					</li>
				</ul>
			</div>
			<div class="footer_helper_warp">
				<ul class="footer_helper_list">
					<li class="footer_helper_item" @click="link('/help?id=6')">{{$t('main.help.ti1')}}</li>
					<li class="footer_helper_item" @click="link('/help?id=2')">{{$t('main.help.ti2')}}</li>
					<li class="footer_helper_item" @click="link('/help?id=3')">{{$t('main.help.ti3')}}</li>
					<li class="footer_helper_item" @click="link('/help?id=4')">{{$t('main.help.ti4')}}</li>
					<li class="footer_helper_item" @click="link('/help?id=5')">{{$t('main.help.ti5')}}</li>
					<li class="footer_helper_item" @click="link('/help?id=7')">{{$t('main.help.ti6')}}</li>
					<li class="footer_helper_item" @click="link('/help?id=8')">{{$t('main.help.ti7')}}</li>
				</ul>
			</div>
			<div class="footer_copyright">版權所有 ©2019-2021 {{config.site_name}} 保留所有權</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: "footer-page",
		computed: {
			...mapGetters(['config']),
		},
		methods: {
			link (url) {
				this.$router.push({
					path: url
				})
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			}
		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.footer_footerView {
		background: #414655;
		background: #414755;
		color: #878ea6;
		width: 100%;
		text-align: center;
		position: relative;
		height: 324px;
		line-height: 1
	}

	.footer_footerView .footer_contentBox {
		width: 1200px;
		margin: 0 auto;
		padding-top: 81px;
		position: relative
	}

	.footer_footerView .footer_contentBox .footer_logbox {
		min-width: 1200px;
		position: absolute;
		top: 0;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		height: 81px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
		border-bottom: 1px solid #2f3842
	}

	.footer_footerView .footer_contentBox .footer_logbox:before {
		content: "";
		position: absolute;
		bottom: -2px;
		left: 0;
		width: 100%;
		height: 1px;
		background: rgba(113, 128, 153, .2)
	}

	.footer_footerView .footer_contentBox .footer_logbox .footer_agent_item {
		height: 50px;
		width: auto;
		position: relative;
		cursor: pointer
	}

	.footer_footerView .footer_contentBox .footer_logbox .footer_agent_item .footer_agentIcon {
		height: 50px
	}

	.footer_footerView .footer_contentBox .footer_logbox .footer_agent_item .footer_agentIcon img {
		position: relative !important;
		width: auto !important;
		height: 50px !important;
		max-width: inherit !important
	}

	.footer_footerView .footer_contentBox .footer_logbox .footer_agent_item .footer_agentIcon:nth-child(2) {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		opacity: 0;
		-webkit-transition: .4s;
		transition: .4s;
		-webkit-transform: translateZ(0);
		transform: translateZ(0)
	}

	.footer_footerView .footer_contentBox .footer_logbox .footer_agent_item:hover>.footer_agentIcon:nth-child(2) {
		opacity: 1
	}

	.footer_footerView .footer_contentBox .footer_license_warp {
		position: relative;
		padding: 0 130px;
		margin-top: 20px;
		z-index: 0
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-around;
		justify-content: space-around;
		-webkit-align-items: center;
		align-items: center;
		height: 100%
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item {
		height: 60px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
		cursor: pointer
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item .footer_license_name {
		font-size: 14px;
		color: #878ea6;
		-webkit-transition: all .4s ease;
		transition: all .4s ease
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item .footer_icon_group {
		position: relative
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item .footer_icon_group>div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background-image: url(../../assets/footer/icon23.png);
		background-image: url(/assets/footer/icon23.png);
		-webkit-transition: all .4s ease;
		transition: all .4s ease;
		background-size: 958px 41px
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item .footer_icon_group .footer_iconDefault {
		opacity: 1;
		z-index: 1
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item:first-child .footer_iconDefault {
		background-position: 0 0
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item:first-child .footer_iconHover {
		background-position: -167px 0
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item:nth-child(2) .footer_iconDefault {
		background-position: -334px 0
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item:nth-child(2) .footer_iconHover {
		background-position: -507px 0
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item:nth-child(3) .footer_iconDefault {
		background-position: -680px 0
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item:nth-child(3) .footer_iconHover {
		background-position: -820px 0
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item:hover .footer_license_name {
		color: #fff
	}

	.footer_footerView .footer_contentBox .footer_license_warp .footer_license_list .footer_license_item:hover .footer_iconDefault {
		opacity: 0
	}

	.footer_footerView .footer_contentBox .footer_helper_warp {
		margin-top: 25px;
		font-family: DINPro
	}

	.footer_footerView .footer_contentBox .footer_helper_warp .footer_helper_list {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		color: #c0c4d0;
		font-size: 14px
	}

	.footer_footerView .footer_contentBox .footer_helper_warp .footer_helper_list .footer_helper_item {
		position: relative;
		padding: 0 20px;
		cursor: pointer;
		-webkit-transition: all .4s ease;
		transition: all .4s ease
	}

	.footer_footerView .footer_contentBox .footer_helper_warp .footer_helper_list .footer_helper_item:not(:last-child):after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 1px;
		height: 15px;
		background: #d6e2f3
	}

	.footer_footerView .footer_contentBox .footer_helper_warp .footer_helper_list .footer_helper_item:hover {
		color: #fff
	}

	.footer_footerView .footer_contentBox .footer_helper_warp .footer_helper_list .footer_helper_item a {
		color: #c0c4d0
	}

	.footer_footerView .footer_contentBox .footer_helper_warp .footer_helper_list .footer_helper_item a:hover {
		color: #fff
	}

	.footer_footerView .footer_contentBox .footer_explanation {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		line-height: 1.4;
		text-align: center;
		padding: 0 130px;
		margin-top: 18px;
		font-size: 14px;
		color: #878ea6
	}

	.footer_footerView .footer_contentBox .footer_copyright {
		text-align: center;
		margin-top: 27px;
		font-size: 12px;
		color: #7c8797
	}
</style>
