<template>
	<div class="rightobbox">
		<a-spin :spinning="pageLoading" tip="加載中...">
			<a-spin :spinning="spinning" tip="洗碼結算中...">
		<div class="title-bo2">
			<div class="left">
				<p>一鍵洗碼結算</p>
				<span></span>
			</div>
			<div class="right">
				
			</div>
		</div>

		<div class="account">
				<div class="c2">
						<my-img srcText="mycenter/turnover/icon1.svg" />
						<div>
							<div class="title">可洗碼結算總金額</div>
							<div><span class="balance">{{allMoney}}</span> 元<a class="refresh "></a></div>
						</div>
						<button @click="sendFs" class="Button Button3" style="width: 120px; height: 28px; border-radius: 14px; position: absolute; top: 15px; right: 18px; font-size: 14px;" >全部洗碼結算</button>
						<div class="tiws">自主洗碼，實時結算，請投註達標後再試！</div>
				</div>
		</div>
		
			
				<a-table rowKey="gameType" style="margin-top: 30px;" :pagination="false" :columns="columns" :locale="locale" :data-source="data">
					<div slot="rate" slot-scope="rate">{{rate}}%</div>
				</a-table>
			</a-spin>
		</a-spin>
	</div>
</template>

<script>
	const columns = [{
			title: '遊戲',
			dataIndex: 'game_type_name',
			key: 'game_type_name',
		},
		{
			title: '有效投註筆數',
			dataIndex: 'count',
			key: 'count',
		},
		{
			title: '總有效投資額',
			dataIndex: 'validBetAmount',
			key: 'validBetAmount',
			ellipsis: true,
		},
		{
			title: '比例',
			dataIndex: 'rate',
			key: 'rate',
			ellipsis: true,
			scopedSlots: {
				customRender: 'rate'
			},
		},
		{
			title: '可結算金額',
			dataIndex: 'fs_money',
			key: 'fs_money',
			ellipsis: true,
			
		}
	];
	
	import http from '@/http.js'
	import Empty from '@/components/Empty.vue'
	export default {
		name: "Home",
	
		data() {
			return {
				value: 'b',
				data: [],
				columns,
				locale: {
					emptyText: < Empty 
					msg1 = "暫無投註記錄" / >
				},
				allMoney: '0',
				spinning: false,
				
				pageLoading: true
			};
		},
		mounted() {
			this.pageLoading = true
			this.getList()
		},
		methods: {
			getList () {
				http({
					method: 'post',
					url: '/member/fsList',
					data: {
						
					}
				}).then((res) => {
					if (res.code == 200) {
						let arr = []
						for(let key in res.data) {
							if (key != 'all_money') {
								arr.push(res.data[key])
							} else {
								this.allMoney = res.data[key]
							}
						}
						this.data = arr
					} else {
						this.$message.error(res.message);
					}
					this.pageLoading = false
				})
			},
			sendFs () {
				if (this.spinning === true) {
					return
				}
				this.spinning = true
				http({
					method: 'post',
					url: '/member/sendFs',
					data: {
						
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getList()
						this.$message.success('全部洗碼結算成功');
					} else {
						this.$message.error(res.message);
					}
					this.spinning = false
				})	
			}
		}
	};
</script>

<style lang="less" scoped>
.Button3 {
	border:none;
	outline: none;
}
	.Button333 {
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;

		&:hover {
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
			background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		}
	}

	.account {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin-top: 30px;



		div.c2 {
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			padding: 20px;
			border-radius: 10px;
			width: 435px;
			height: 90px;
			border-radius: 6px;
			-webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
			box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
			border: solid 1px #f0f3f7;
			color: #4b5e6f;
			background-color: #fff;
			
			.tiws {
				    position: absolute;
				    right: 9px;
				    color: red;
				    font-size: 12px;
				    top: 65px;
				    line-height: 10px;
			}

			img {
				margin-right: 10px;
				width: 40px;
				height: 40px;
			}

			.Button {
				border: none;
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
				background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
				color: #fff;
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				transition: all .3s ease;
				cursor: pointer;

				&:hover {
					background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
					background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
					background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
					-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
					box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
				}
			}

			.title {
				margin-bottom: 10px;
				font-size: 14px;
				color: #4b5e6f;
			}


			.balance {
				font-size: 24px;
				color: #333;
				font-weight: bold;
				display: inline-block;
				max-width: 226px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				vertical-align: bottom;
			}

			.refresh {
				display: inline-block;
				margin-left: 10px;
				width: 16px;
				height: 16px;
				// background-image: url(https://static.odcqfh.com/main-consumer-web/assets-oss/kok/images/myCenter/shuaxin.0564eb1bcebf29704a2c0c1a71cb7165.svg);
				background-repeat: no-repeat;
				border: none;
				vertical-align: middle;
				cursor: pointer;
			}
		}
	}
</style>
