<template>
	<div class="header_div_1">
		<sports :showNav="navIndex == 1" :NewArrs="gameConfig['体育']"/>
		<person :showNav="navIndex == 2" :NewArrs="gameConfig['真人']"/>
		<chess :showNav="navIndex == 3" :NewArrs="gameConfig['棋牌']"/>
		<gaming :showNav="navIndex == 4" :NewArrs="gameConfig['电竞']"/>
		<lottery :showNav="navIndex == 5" :NewArrs="gameConfig['彩票']"/>
		<egame :showNav="navIndex == 6" :NewArrs="gameConfig['电子']"/>
		<by :showNav="navIndex == 7" :NewArrs="gameConfig['by']"></by>
	</div>
</template>

<script>
	import http from '@/http.js'
	// 体育
	import sports from '../BannerPage/sports/index.vue'
	// 真人
	import person from '../BannerPage/person/index.vue'
	// 棋牌
	import chess from '../BannerPage/chess/index.vue'
	// 电竞
	import gaming from '../BannerPage/gaming/index.vue'
	// 彩票
	import lottery from '../BannerPage/lottery/index.vue'
	// 电子
	import egame from '../BannerPage/egame/index.vue'
	// 不要
	import by from '../BannerPage/by/index.vue'
	import {
		mapGetters
	} from 'vuex';
	export default {
		props: ['navIndex'],
		components: {
			sports,
			person,
			chess,
			gaming,
			lottery,
			egame,
			by
		},
		data () {
			return {
				
			}
		},
		computed: {
			...mapGetters(['gameConfig']),
		},
		mounted() {
			
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
