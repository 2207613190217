<template>
	<a-modal title="添加虛擬幣取款地址" :footer="null" width="680px" :visible="show" 
	 @cancel="show = false">
		<div class="form forminut">
			<div>
				<!-- <div class="bind_bank_item " :class="{'error': formError.usdt_name}">
					<div class="bind_bank_item_left">别名：</div>
					<div class="bind_bank_item_right">
						<input type="text" placeholder="请输入别名" @input="isAddBank('usdt_name')" maxlength="24" class="Input" v-model="form.usdt_name">
						<span class="tips">别名能便于区分您名下的虚拟币地址</span>
						<span class="tips-err">请输入至少长度为2的别名</span>
						</div>
				</div> -->
				<!-- <div class="bind_bank_item ">
					<div class="bind_bank_item_left">虚拟币协议：</div>
					<div class="bind_bank_item_right">
						<ul class="Checkbox" style="width: auto; text-align: center; margin-left: -21px;">
							<div :class="{'Active': item.id == form.usdt_id}" style="width: 147px;height: 40px;margin: 0px 0px 20px 20px;" @click="selectUsdtId(item.id)" v-for="(item, index) in typeList" :key="index">
								<li class="payButtonSty1"><span>{{item.card_no}} </span><i></i></li>
							</div>
							
						</ul>
					</div>
				</div> -->
				<div class="bind_bank_item " :class="{'error': formError.bank_name}">
					<div class="bind_bank_item_left">虛擬幣協議：</div>
					<div class="bind_bank_item_right">
						<a-select v-model="form.pact" placeholder="請選擇虛擬幣協議" style="width: 486px;height:40px;margin-bottom: 20px;"
							>
							<a-select-option :value="item" v-for="(item, index) in typeList" :key="index">
								{{item}}
							</a-select-option>
						</a-select>
					</div>
				</div>
				<div class="bind_bank_item " :class="{'error': formError.usdt_address}">
					<div class="bind_bank_item_left">虛擬幣地址：</div>
					<div class="bind_bank_item_right">
						<input type="text" placeholder="請輸入虛擬幣地址" @input="isAddBank('usdt_address')" class="Input" v-model="form.usdt_address">
						<span class="tips">請認真校對虛擬幣地址，因地址和協議錯誤產生的損失由您自行承擔</span>
						<span class="tips-err">請輸入虛擬幣地址</span>
						</div>
					</div>
				</div>
				
				<div class="bind_bank_item" v-if="userInfo.is_bind_qk_pwd == 0" :class="{'error': formError.qk_pwd}">
					<div class="bind_bank_item_left">取款密碼：</div>
					<div class="bind_bank_item_right">
						<input-password v-model="form.qk_pwd" styleText="width:486px;height:40px"></input-password>
						<span class="tips">請認真校對取款密碼，取款密碼錯誤資金將無法取款</span>
						<span class="tips-err">請輸入4位數取款密碼</span>
					</div>
				</div>
				
				<div class="bind_bank_item "  :class="{'error': formError.phone}">
					<div class="bind_bank_item_left">手機號碼：</div>
					<div class="bind_bank_item_right">
						<p class="inputvalule">{{form.phone}}</p>
						<!-- <input type="number" placeholder="请输入手机号码"  :disabled="isCzPhone" @input="isAddBank('phone')" v-model.number="form.phone" class="Input"> -->
						<span class="tips">手機號碼將有助於您找回賬號，同時也是客服人員確認您身份的重要依據</span>
						<span class="tips-err">請輸入合法的手機號碼</span>
					</div>
				</div>

				<div class="bind_bank_item ">
					<div class="bind_bank_item_left">驗證碼：</div>
					<div class="bind_bank_item_right">
						<input :disabled="codeDisabled" @input="isAddBank('code')" type="number" placeholder="驗證碼" maxlength="6" class="Input " v-model.number="form.captcha">
						<div class="bindCount">
							<button class="" @click="getPhoneCode"><span class="countDown__verCode__2--mX ">{{codeText}}</span></button>
						</div>
					</div>
				</div>
				<div class="checktips" @click="linkCs">
					<my-img srcText="mycenter/withdrawals/icon6.svg" />
					有任何疑問請聯系<span style="cursor: pointer; margin-left: 5px;">在線客服</span>
				</div>
				<button class="Button333 " :class="{'disabled': addBtnDisabled}" @click="addBank" style="width: 300px; height: 50px; margin: 30px auto 0px; display: block;">確認提交</button>
			</div>
		</div>
	</a-modal>
</template>

<script>
	import http from '@/http.js'
	import {
		mapGetters,
		mapActions
	} from 'vuex';
	import InputPassword from '@/components/InputPassword.vue'
	export default {
		data() {
			return {
				show: false,
				form: {
					// usdt_name: '',
					usdt_address: '',
					pact: 'TRC',
					phone: '',
					captcha: '',
					qk_pwd: ''
				},
				formError: {
					usdt_name: false,
					usdt_address: false,
					pact: false,
					phone: false,
					captcha: false,
					qk_pwd: false
				},
				codeDisabled: true,
				addBtnDisabled: true,
				codeText: '獲取驗證碼',
				
				typeList: ['TRC', 'ERC'],
				isCzPhone: false
			};
		},
		computed: {
			...mapGetters(['config', 'userInfo']),
		},
		components: {
			InputPassword
		},
		mounted () {
			// http({
			// 	method: 'post',
			// 	url: '/member/paymentInfo',
			// 	data: {
			// 		payment_type: 18
			// 	}
			// }).then((res) => {
			// 	if (res.code == 200) {
			// 		this.typeList = res.data
			// 		if (this.typeList.length) {
			// 			this.form.usdt_id = this.typeList[0].id
			// 		}
			// 		console.log(this.form.usdt_id, 'this.form.usdt_id')
			// 	} else {
			// 		this.$message.error(res.message);
			// 	}
			// })
		},
		methods: {
			...mapActions('user', ['getUserInfo']),
			// selectUsdtId (id) {
			// 	this.form.usdt_id = id
			// },
			linkCs () {
				window.open(this.config.service_link)
			},
			showModel(phone) {
				this.show = true
				this.hideModel('no')
				if (phone) {
					this.isCzPhone = true
					this.form.phone = phone
					this.codeDisabled = false
				}
			},
			hideModel (type) {
				this.form = {
					// usdt_name: '',
					usdt_address: '',
					pact: 'TRC',
					phone: '',
					captcha: '',
					qk_pwd: ''
				}
				// if (this.typeList.length) {
				// 	this.form.usdt_id = this.typeList[0].id
				// }
				this.formError = {
					usdt_name: false,
					usdt_address: false,
					pact: false,
					phone: false,
					captcha: false,
					qk_pwd: false
				}
				this.codeDisabled = true
				this.addBtnDisabled = true
				this.codeText = '獲取驗證碼'
				if (type != 'no') {
					this.show = false
				}
			},
			
			// 
			bankNameChange () {
				
			},
			
			// 添加银行卡
			addBank () {
				if (this.addBtnDisabled) {
					return
				}
				
				if (this.userInfo.is_bind_qk_pwd == 0 && this.form.qk_pwd.length != 4) {
					this.formError.qk_pwd = true
					return
				} else {
					this.formError.qk_pwd = false
				}
				
				let data = {...this.form}
				if (this.userInfo.is_bind_qk_pwd == 1) {
					delete data.qk_pwd
				}
				
				let pact = ''
				if (this.form.pact === 'TRC') {
					pact = 'trc'
				} else if (this.form.pact === 'ERC') {
					pact = 'erc'
				} 
				http({
					method: 'post',
					url: '/member/bindUsdt',
					data: {
						...data,
						pact: pact,
						usdt_name: this.form.pact
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getUserInfo()
						this.show = false
						this.hideModel()
						this.$emit("getNewList")
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
					}
				})
			},
			
			// 验证是否可以添加
			isAddBank (type) {
				let isForm = true
				let isformError = true
				
				let dataFrom = {...this.form}
				delete dataFrom.qk_pwd
				Object.values(dataFrom).forEach((v)=> {
					if (v == '') {isForm = false}
				})
				
				Object.values(this.formError).forEach((v)=> {
					if (v == true) {isformError = false}
				})
				// if (type == 'usdt_name' || type == 'all') {
				// 	if (this.form.usdt_name.length < 2) {
				// 		this.formError.usdt_name = true
				// 	}else {
				// 		this.formError.usdt_name = false
				// 	}
				// }
				if (type == 'usdt_address' || type == 'all') {
					if (!this.form.usdt_address) {
						this.formError.usdt_address = true
					} else {
						this.formError.usdt_address = false
					}
				}
				if (type === 'usdt_address' && this.form.usdt_address) {
					this.form.usdt_address = this.form.usdt_address.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, "")
				}
				if (type == 'phone' || type == 'all') {
					var regex = /^(\+852\s)?[123456789]{1}\d{7}$/;
					if (!regex.test(this.form.phone)) {
						this.formError.phone = true
					} else {
						this.formError.phone = false 
					}
				}
				
				if (type == 'code') {
					if (this.form.captcha.length >= 4 && this.form.captcha.length <= 6) {
						this.formError.captcha = true
					} else {
						this.formError.captcha = false
					}
				}
				console.log(isForm , isformError)
				if (isForm && isformError) {
					this.addBtnDisabled = false
				} else {
					this.addBtnDisabled = true
				}
			},
			
			// 获取验证码
			getPhoneCode () {
				if (this.codeDisabled) {
					return
				}
				if (this.codeText != '獲取驗證碼') {
					return
				}
				let num = 60
				this.getCodeText = num + 's'
				let tim = setInterval((v) => {
					num = num - 1
					if (num == 0) {
						clearInterval(tim)
						this.codeText = '獲取驗證碼'
					} else {
						this.codeText = num  + 's'
					}
				}, 1000)
				http({
					method: 'post',
					url: '/member/sendSms',
					data: {
						phone: this.form.phone,
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message.success(res.message);
					} else {
						clearInterval(tim)
						this.codeText = '獲取驗證碼'
						this.$message.error(res.message);
					}
				})
			},
		}
	};
</script>

<style lang="less" scoped>
	.forminut .Input[disabled]{
		background: #f2f4f5 !important;
	}
	.form {

		padding: 0 26px;
		padding-top: 20px;
		padding-bottom: 20px;

		.Button333 {
			cursor: pointer;
			border: none;
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
			background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
			color: #fff;
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			transition: all .3s ease;
			font-size: 16px;
			border-radius: 6px;

			&:hover {
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
				background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			}

			&.disabled {
				opacity: .4;
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
				background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
			}
		}

		.checktips {
			color: #999;
			font-size: 12px;
			margin: 30px auto 0;
			text-align: center;
			display: block;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;

			span {
				color: #3678fe;
			}
		}

		.bind_bank_item {
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			line-height: 38px;
			
			&.error {
				.tips {
					display: none !important;
				}
			
				.tips-err {
					display: block !important;
				}
			
				.bind_bank_item_right>input {
					border-color: red;
			
					&:focus {
						-webkit-transition: background-color .2s ease, border .2s ease;
						transition: background-color .2s ease, border .2s ease;
						border: solid 1px red;
						-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
						box-shadow: 0 10px 20px 0 #dae8f2;
					}
				}
			}

			&:after {
				content: "";
				display: block;
				clear: both;
				overflow: hidden;
			}

			.bind_bank_item_left {
				font-size: 14px;
				color: #4b5e6f;
				min-width: 90px;
				text-align: right;
			}

			.bind_bank_item_right {
				text-align: left;
				margin-left: 10px;
				position: relative;

				.bindCount {
					position: absolute;
					right: 14px;
					top: 0;
					color: #94a6b7;

					&>button {
						border: none;
						outline: none;
						background: transparent;
						cursor: pointer;
					}
				}
				
				.inputvalule {
					font-size: 12px;
					width: 486px;
					height: 40px;
					border-radius: 6px;
					padding: 0 20px;
					border: solid 1px #d8dce7;
					color: #333;
					background-color: #fff;
					padding-left: 11px;
					background-color: rgba(216, 220, 231, .2);
					pointer-events: none;
				}

				&>input {
					font-size: 12px;
					width: 486px;
					height: 40px;
					border-radius: 6px;
					padding: 0 20px;
					border: solid 1px #d8dce7;
					color: #333;
					background-color: #fff;
					padding-left: 11px;
					
					&.disabled {
						background-color: rgba(216, 220, 231, .2);
						pointer-events: none;
					}

					&:focus {
						-webkit-transition: background-color .2s ease, border .2s ease;
						transition: background-color .2s ease, border .2s ease;
						border: solid 1px #3678fe;
						-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
						box-shadow: 0 10px 20px 0 #dae8f2;
					}


				}
				.tips-err {
					color: red;
					display: none;
					margin-bottom: 20px;
					font-size: 12px;
					margin-top: 7px;
					line-height: 1;
				}
				.tips {
					color: #94a6b7;
					margin-bottom: 20px;
					font-size: 12px;
					margin-top: 7px;
					display: block;
					line-height: 1;
				}
			}
		}
	}

	.Checkbox {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: 14px;

		&>div {
			border-radius: 6px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			cursor: pointer;
			position: relative;
			border: solid 2px transparent;
			color: #333;

			&:before {
				position: absolute;
				left: -2px;
				right: -2px;
				bottom: -2px;
				top: -2px;
				display: block;
				border-radius: 6px;
				cursor: pointer;
				pointer-events: none !important;
				content: " ";
				border-style: solid;
				border-color: #d8dce7;
				border-width: 1px;
				color: #333;
			}
		}

		.recommend {
			width: 54px;
			position: absolute;
			left: -7px;
			height: 54px;
			position: absolute;
			left: -7px;
			top: -7px;
			background-size: contain;
			z-index: 1;
		}

		&>div:hover {
			border-width: 2px;
			border-color: transparent;
			border-image-source: linear-gradient(296deg, #3678fe 96%, #75a3ff);
			border-image-slice: 1;
			background-image: -webkit-linear-gradient(top, #ffffff, #ffffff), -webkit-linear-gradient(154deg, #3678fe 96%, #75a3ff);
			background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(296deg, #3678fe 96%, #75a3ff);
			background-origin: border-box;
			background-clip: content-box, border-box;
			-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
			box-shadow: 0 10px 20px 0 #dae8f2;

			&:before {
				border: none;
			}
		}

		.Active {
			border-width: 2px;
			border-color: transparent;
			border-image-source: linear-gradient(296deg, #3678fe 96%, #75a3ff);
			border-image-slice: 1;
			background-image: -webkit-linear-gradient(top, #ffffff, #ffffff), -webkit-linear-gradient(154deg, #3678fe 96%, #75a3ff);
			background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(296deg, #3678fe 96%, #75a3ff);
			background-origin: border-box;
			background-clip: content-box, border-box;
			-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
			box-shadow: 0 10px 20px 0 #dae8f2;

			&:before {
				border: none;
			}
			&:after {
				position: absolute;
				right: -1px;
				bottom: -1px;
				content: "";
				display: block;
				// background-image: url(../../assets/mycenter/withdrawals/icon8.svg);
				background-image: url(/assets/mycenter/withdrawals/icon8.svg);
				background-size: cover;
				width: 24px;
				height: 24px;
				right: -2px !important;
				bottom: -2px !important;
				border: none;
			}
		}

		.act_percent {
			position: absolute;
			bottom: -10px;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			min-width: 50px;
			height: 20px;
			line-height: 14px;
			padding: 4px 5px;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			color: #fff;
			font-size: 12px;
			-webkit-box-shadow: inset 0 1px 3px 0 rgba(159, 0, 0, .44);
			box-shadow: inset 0 1px 3px 0 rgba(159, 0, 0, .44);
			background-color: red;
			border-radius: 10px;
			white-space: nowrap;
		}
	}
</style>
