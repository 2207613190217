<template>
	<div class="edete-box">
		<a-spin :spinning="pageLoading" tip="加載中...">
			
			<div class="edete">
				
				<div class="dddd">
					<div class="title">{{content.title}}</div>

					<div class="content" v-html="content.content"></div>
          <div class="layout_entryBtn1" v-if="content.is_apply == 0" @click="sq">立即申請</div>
				</div>
				
			</div>
		</a-spin>
		
	</div>
</template>

<script>
	import http from '../../http.js'
	export default {
		name: "Home",
		data() {
			return {
				pageLoading: true,
				content: {
					
				}
			};
		},
		activated() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			this.content = {}
			this.pageLoading = true
			this.getContent()
		},
		methods: {
			getContent() {
				http({
					method: 'get',
					url: '/activityInfo?id=' + this.$route.query.id,
					data: {
						
					}
				}).then((res) => {
					this.content = res.data
					this.pageLoading = false
				})
			},
			sq () {
				http({
					method: 'post',
					url: '/member/applyActivity',
					data: {
						id:this.content.id
					}
				}).then((res) => {
					if(res.code == 200){
						this.$message.success('申請成功');
						this.$router.push({
						  path: "/my-center/activity",
						});
					} else {
						this.$message.error(res.message);
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.edete-box {
		padding-top: 120px;
		padding-bottom: 50px;
	}
	.edete {
		width: 1200px;
		margin: 0 auto;
		min-height: 600px;
		border: 1px solid #e0e7fa;
		    -webkit-box-shadow: 0 8px 16px 0 rgba(51, 58, 218, .09);
		    box-shadow: 0 8px 16px 0 rgba(51, 58, 218, .09);
			border-radius: 20px;
			    padding: 70px 30px 30px;
				
				.dddd {
					
					position: relative;
					&:before {
						background-image: url(/assets/activity/common-border-top.png);
						content: '';
						position: absolute;
						    left: 0;
						    content: "";
						    width: 100%;
						    height: 50px;
						    background-position: 0 0;
						    background-repeat: no-repeat;
						    background-size: 100%;
					}
				}
				
		.title {
			font-size: 40px;
			font-weight: 700;
			height: 50px;
			position: absolute;
			top: -20px;
			background-color: #fff;
			padding: 0 20px;
			left: 50%;
			transform: translateX(-50%);
			white-space: nowrap; 
		}
		.content {
			 padding: 70px 30px 30px;
			padding-top: 80px;
			line-height: 24px;
			font-size: 16px;
		}
	}
	.layout_entryBtn1 {
		width: 160px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		margin-top: 47px;
		color: #fff;
		font-size: 18px;
		background-image: none !important;
		position: relative;
		z-index: 1;
		cursor: pointer;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		box-shadow: 0 2px 4px 0 #5da7eb;
		border-radius: 25px;
    margin: 0 auto;
	}
	.layout_entryBtn1:before {
		content: "";
		z-index: -1;
		top: 0;
		display: block;
		width: 160px;
		height: 50px;
		position: absolute;
		left: 50%;
		-webkit-transform: translate3d(-50%, 0, 0);
		transform: translate3d(-50%, 0, 0);
		// background-image: url(../../assets/sport/icon3.png);
		background-image: url(/assets/sport/icon3.png);
		background-size: 100% 100%
	}
	
	.layout_entryBtn1:hover {
		-webkit-transform: scale(1.05);
		transform: scale(1.05)
	}
</style>
