<template>
	<a-modal title="綁定手機號碼" :footer="null" width="520px" :visible="phoneShow" 
		@cancel="hideModel">
		<div class="form forminut" style="margin-top:-20px">
			<div>
				<span class="tipscc">手機號碼將有助於您找回賬號，同時也是客服人員確認您身份的重要依據</span>


				<div class="bind_bank_item ">
					<div class="bind_bank_item_left">手机号码：</div>
					<div class="bind_bank_item_right">
						<input type="text" @input="isAddBank('phone')" v-model="form.phone" placeholder="請輸入手機號碼"
							class="Input">
						<span v-if="formError.phone" class="tips-err">請輸入合法的手機號碼</span>
					</div>
				</div>

				<div class="bind_bank_item ">
					<div class="bind_bank_item_left">驗證碼：</div>
					<div class="bind_bank_item_right">
						<input  type="text" @input="inputCode" v-model="form.captcha"
							placeholder="請輸入手機驗證碼" maxlength="6" class="Input ">
						<div class="bindCount">
							<button data-label="獲取驗證碼"  @click="getPhoneCode" class="">
								<span class="countDown__verCode__2--mX ">{{codeText}}</span></button>
						</div>
					</div>
				</div>
				<button class="Button333 " :class="{'disabled': !isBtn}"
					style="width: 200px; height: 50px; margin: 30px auto 0px; display: block;"
					@click="bindPhone">提交綁定</button>
			</div>
		</div>
	</a-modal>
</template>

<script>
	import http from '@/http.js'
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {
				phoneShow: false,

				form: {
					phone: '',
					captcha: ''
				},
				formError: {
					phone: false
				},
				codeText: '獲取驗證碼',
				
				isCode: false,
				
				isBtn: false
			};
		},
		computed: {
			...mapGetters(['avatarImg']),
		},
		mounted() {

		},
		methods: {
			...mapActions('user', ['getUserInfo']),
			hideModel () {
				this.form = {
					phone: '',
					captcha: ''
				}
				this.formError = {
					phone: false
				}
				this.codeText = '獲取驗證碼'
				this.isCode = false
				this.isBtn = false
				this.phoneShow = false
			},
			showModel () {
				this.form = {
					phone: '',
					captcha: ''
				}
				this.formError = {
					phone: false
				}
				this.codeText = '獲取驗證碼'
				this.isCode = false
				this.isBtn = false
				this.phoneShow = true
			},
			bindPhone () {
				this.isAddBank('phone')
				if (this.formError.phone === true) {
					this.$message.error('請輸入合法的手機號碼');
					return
				}
				if (!this.form.captcha) {
					this.$message.error('請輸入手機驗證碼');
					return
				}
				http({
					method: 'post',
					url: '/member/bindPhone',
					data: {
						phone: this.form.phone,
						captcha: this.form.captcha,
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getUserInfo()
						this.$message.success('綁定手機號成功');
						this.phoneShow = false
					} else {
						this.$message.error(res.message);
					}
				})
			},
			inputCode () {
				this.isAddBank('phone')
				if (this.formError.phone === false && this.form.captcha) {
					this.isBtn = true	
				} else {
					this.isBtn = false
				}
			},
			// 验证是否可以添加
			isAddBank(type) {
				if (type == 'phone') {
					var regex = /^(\+852\s)?[123456789]{1}\d{7}$/;
					if (!regex.test(this.form.phone)) {
						this.formError.phone = true
					} else {
						this.formError.phone = false
					}
				}
				
				if (this.formError.phone === false && this.form.captcha) {
					this.isBtn = true	
				} else {
					this.isBtn = false
				}
			},
			// 获取验证码
			getPhoneCode () {
				this.isAddBank('phone')
				if (this.formError.phone === true) {
					this.$message.error('請輸入合法的手機號碼');
					return
				}
				
				let num = 60
				this.getCodeText = num + 's'
				let tim = setInterval((v) => {
					num = num - 1
					if (num == 0) {
						clearInterval(tim)
						this.codeText = '獲取驗證碼'
					} else {
						this.codeText = num  + 's'
					}
				}, 1000)
				http({
					method: 'post',
					url: '/member/sendSms',
					data: {
						phone: this.form.phone,
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message.success(res.message);
					} else {
						clearInterval(tim)
						this.codeText = '獲取驗證碼'
						this.$message.error(res.message);
					}
				})
			},
		}
	};
</script>

<style lang="less" scoped>
	.form {
		.tips-err {
			display: block !important;
		}
		.countDown__verCode__2--mX {
			cursor: pointer;
		}

		.tips-err {
			color: red;
			margin-bottom: 20px;
			font-size: 12px;
			margin-top: 7px;
			line-height: 1;
		}

		padding: 0 6px;
		padding-top: 10px;
		padding-bottom: 20px;
		margin-left: -6px;

		.Button333 {
			border: none;
			cursor: pointer;
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
			background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
			color: #fff;
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			transition: all .3s ease;
			font-size: 16px;
			border-radius: 6px;

			&:hover {
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
				background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			}

			&.disabled {
				opacity: .4;
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
				background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
			}
		}

		.checktips {
			color: #999;
			font-size: 12px;
			margin: 30px auto 0;
			text-align: center;
			display: block;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;

			span {
				color: #3678fe;
			}
		}

		.bind_bank_item {
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			line-height: 38px;
			flex-wrap: wrap;

			&:after {
				content: "";
				display: block;
				clear: both;
				overflow: hidden;
			}

			.bind_bank_item_left {
				font-size: 14px;
				color: #4b5e6f;
				min-width: 90px;
				text-align: left;
			}

			.bind_bank_item_right {
				text-align: left;
				position: relative;

				.bindCount {
					position: absolute;
					right: 14px;
					top: 0;
					color: #94a6b7;

					&>button {
						border: none;
						outline: none;
						background: transparent;
					}
				}

				&>input {
					font-size: 12px;
					width: 466px;
					height: 40px;
					border-radius: 6px;
					padding: 0 20px;
					border: solid 1px #d8dce7;
					color: #333;
					background-color: #fff;

					&.disabled {
						background-color: rgba(216, 220, 231, .2);
						pointer-events: none;
					}

					&:focus {
						-webkit-transition: background-color .2s ease, border .2s ease;
						transition: background-color .2s ease, border .2s ease;
						border: solid 1px #3678fe;
						-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
						box-shadow: 0 10px 20px 0 #dae8f2;
					}


				}

				.tips {}
			}
		}
	}
</style>
