<template>
	<div class="layout_venue__2ozWL lottery_bg__3vl-q">
		<div class="layout_container__2cEhE lottery_container__31-DR">
			<div class="lottery_leftWrap__1nmWc lottery_index0__2jqFy nopagafter" :class="{'lottery_fadeOut__3nY7g' : show == true}">
				<div class="lottery_top__7o3sw">
					<div class="image_imageContainer__2dKjX image_cover__3B5D- lottery_triangle__a4aPi"
						style="width: 350px; cursor: inherit;">
						<div>
							<my-img srcText="lottery/icon12.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
						</div>
					</div>
				</div>
				<div class="lottery_bottom__vhzJC ">
					<div class="image_imageContainer__2dKjX image_cover__3B5D- lottery_poster__20wC8"
						style="cursor: inherit;left:140px" :style="{'visibility': currentIndexValue == 0 ? 'inherit' : 'hidden'}">
						<div>
							<my-img srcText="lottery/page1/icon1.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
						</div>
					</div>
					<div class="image_imageContainer__2dKjX image_cover__3B5D- lottery_poster__20wC8"
						v-if="getGame('OBCP').on_line == 0" 
						style="cursor: inherit; left:140px" :style="{'visibility': currentIndexValue == 1 ? 'inherit' : 'hidden'}">
						<div>
							<my-img srcText="lottery/page2/icon1.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
						</div>
					</div>
					<div class="image_imageContainer__2dKjX image_cover__3B5D- lottery_poster__20wC8"
						style="cursor: inherit;left:140px" :style="{'visibility': currentIndexValue == 2 ? 'inherit' : 'hidden'}">
						<div>
							<my-img srcText="lottery/page3/icon1.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
						</div>
					</div>
					<div class="image_imageContainer__2dKjX image_cover__3B5D- lottery_poster__20wC8"
						v-if="getGame('BBIN').on_line == 0" 
						style="cursor: inherit; left:140px" :style="{'visibility': currentIndexValue == 3 ? 'inherit' : 'hidden'}">
						<div>
							<my-img srcText="lottery/page4/icon1.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
						</div>
					</div>
					
					<my-img srcText="lottery/icon13.png" classText="lottery_left__UrWJG"/>
					<my-img srcText="lottery/icon14.png" classText="lottery_right__2zkPz"/>
					
				</div>
			</div>
			<div class="layout_rightWrap__3idXF lottery_rightWrap__1W3AW">
				<div class="layout_section "  :class="{'layout_fadeOut' : show == true}">
					<div class="image_imageContainer__2dKjX image_contain__BN28f layout_title__3XxNu "
						style="width: 500px; height: 148px; cursor: inherit;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAwIiBoZWlnaHQ9IjE0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4="
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>
							<my-img :srcText="list[currentIndexValue].title" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
							
							</div>
					</div>
					<p class="layout_desc__2U81i ">{{list[currentIndexValue].content}}
					</p>
					<div class="image_imageContainer__2dKjX image_contain__BN28f  "
						style="width: auto; height: 80px; cursor: inherit;">
						
							<my-img :srcText="list[currentIndexValue].urlList"
								styleText="width: auto; height: 80px; filter: none; background-image: none;" />
								
							</div>
				</div>
				<div class="layout_tabs">
					<ul class="tabs_icons">
						<li class="game_maintain_icon"  v-if="getGame('OBCP').on_line == 0" :class="{'tabs_active' : currentIndex == 1 && getGame('OBCP').maintain != 1, 'game_maintain': getGame('OBCP').maintain == 1}" @click="setTab(1)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_2"></div>
							</div>
							<p>{{$t('main.game.cp.ob.name')}}</p>
						</li>
						<li class="game_maintain_icon"  v-if="getGame('BBIN').on_line == 0" :class="{'tabs_active' : currentIndex == 3, 'game_maintain': getGame('BBIN').maintain == 1}" @click="setTab(3)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_4"></div>
							</div>
							<p>{{$t('main.game.cp.bbin.name')}}</p>
						</li>
					</ul>
				</div>
				<div class="layout_entryBtn lottery_entryBtn" v-show="isOpen" @click="link(list[currentIndex].plat_type)">进入游戏</div>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: "appbox",
		data() {
			return {
				list: [
					{
						image: 'home/app/icon2.png',
						title: "lottery/page4/icon2.png",
						content: '双赢棋牌提供市面上热门游戏种类，选择全面多元，应有尽有，玩家能不断游戏不感无趣！',
						urlList: "lottery/page4/icon3.png",
						plat_type: "BBIN"
					},
					{
						image: 'home/app/icon2.png',
						title: "lottery/page2/icon2.png",
						content: this.$t('main.game.cp.ob.des'),
						urlList: "lottery/page2/icon3.png",
						plat_type: "OBCP"
					},
					{
						image: 'home/app/icon2.png',
						title: "lottery/page4/icon2.png",
						content: '双赢棋牌提供市面上热门游戏种类，选择全面多元，应有尽有，玩家能不断游戏不感无趣！',
						urlList: "lottery/page4/icon3.png",
						plat_type: "BBIN"
					},
					{
						image: 'home/app/icon2.png',
						title: "lottery/page2/icon2.png",
						content: this.$t('main.game.cp.bbin.des'),
						urlList: "lottery/page4/icon3.png",
						plat_type: "BBIN"
					},
	
				],
				currentIndex: 1,
				currentIndexValue: 1,
				currentIndexValue1:1,
				show: false,
				classList: ['realbet_yb__3zPHl', 'realbet_bg', 'realbet_wm__1FwMM', 'realbet_ag__3PYEY',
					'realbet_ebet__1AFNc', 'realbet_og__3a7j7'
				],
				
				NewArrs: []
			};
		},
		watch: {
			gameConfig () {
				this.NewArrs = this.gameConfig['彩票']
        if (this.NewArrs.length == 0 ) return
        if(this.NewArrs[0].plat_type == 'OBCP' ){
          this.currentIndex = 1
          this.currentIndexValue = 1
        }else if(this.NewArrs[0].plat_type == 'BBIN' ){
          this.currentIndex = 3
          this.currentIndexValue = 3
        }
        return;
				if (this.getGame('BBIN').on_line == 1 && this.getGame('TFG').on_line == 1) {
					this.currentIndex = -1
					this.currentIndexValue = -1
				}
			}
		},
		computed: {
			isOpen: function () {
				let is = true
				if (this.currentIndex == 1 && (this.getGame('OBCP').on_line == 1 || this.getGame('OBCP').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 3 && (this.getGame('BBIN').on_line == 1 || this.getGame('BBIN').maintain == 1)) {
					is = false
				}
				return is
			},
			...mapGetters(['gameConfig']),
		},
		methods: {
			getGame (type) {
				let data = {}
				this.NewArrs.forEach((v) => {
					if (v.plat_type == type) {
						data = v
					}
				})
				return data
			},
			setTab(idx) {
				this.show = false
				this.currentIndex = idx
				this.show = true
	
				setTimeout(() => {
					this.currentIndexValue = idx
				}, 600)
	
				setTimeout(() => {
					this.currentIndexValue1 = idx
				}, 400)
	
				setTimeout(() => {
					this.show = false
				}, 700)
			},
			link(type) {
				let self = this
				if (!localStorage.getItem('access_token')) {
					let wxts = this.$t('main.login_ts')
					this.$confirm({
						title: this.$t('main.wxts'),
						content: h => < div > {wxts} </div>,
						okText: this.$t('main.okText'),
						cancelText: this.$t('main.cancelText'),
						onOk() {
							self.$router.push({
							  path: "/login",
							});
						},
						onCancel() {
						},
						class: 'testbo',
					});
				} else {
					let data = {
						gameData: {}
					}
					this.NewArrs.forEach((v) => {
						if (v.plat_type == type) {
							data.gameData = v
						}
					})
					let href = window.location.origin + `/game?plat_type=${data.gameData.plat_type}&game_type=${data.gameData.game_type}&game_code=${data.gameData.game_code}`
					// window.open(href, "_blank");
					this.$store.dispatch('user/linkGamePage', {
						href: href,
						platType: data.gameData.plat_type,
						gameName: data.gameData.name
					})
				}
			}
		},
		activated() {
			this.NewArrs = this.gameConfig['彩票']
		},
		destroyed() {
	
		},
	};
</script>

<style lang="less" scoped>
	.tabs_icons li:hover {
		.tabs_logo_1 {
			// background-image: url(../../assets/lottery/icon4.png) !important;
			background-image: url(/assets/lottery/icon4.png) !important;
		}
	
		.tabs_logo_2 {
			// background-image: url(../../assets/lottery/icon6.png) !important;
			background-image: url(/assets/lottery/icon6.png) !important;
		}
	
		.tabs_logo_3 {
			// background-image: url(../../assets/lottery/icon8.png) !important;
			background-image: url(/assets/lottery/icon8.png) !important;
		}
	
		.tabs_logo_4 {
			// background-image: url(../../assets/lottery/icon10.png) !important;
			background-image: url(/assets/lottery/icon10.png) !important;
		}
	}
	
	.tabs_active {
		.tabs_logo_1 {
			// background-image: url(../../assets/lottery/icon4.png);
			background-image: url(/assets/lottery/icon4.png);
		}
	
		.tabs_logo_2 {
			// background-image: url(../../assets/lottery/icon6.png);
			background-image: url(/assets/lottery/icon6.png);
		}
	
		.tabs_logo_3 {
			// background-image: url(../../assets/lottery/icon8.png);
			background-image: url(/assets/lottery/icon8.png);
		}
	
		.tabs_logo_4 {
			// background-image: url(../../assets/lottery/icon10.png);
			background-image: url(/assets/lottery/icon10.png);
		}
	}
	
	.tabs_logo_1 {
		// background-image: url(../../assets/lottery/icon5.png);
		background-image: url(/assets/lottery/icon5.png);
	}
	
	.tabs_logo_2 {
		// background-image: url(../../assets/lottery/icon7.png);
		background-image: url(/assets/lottery/icon7.png);
	}
	
	.tabs_logo_3 {
		// background-image: url(../../assets/lottery/icon9.png);
		background-image: url(/assets/lottery/icon9.png);
	}
	
	.tabs_logo_4 {
		// background-image: url(../../assets/lottery/icon11.png);
		background-image: url(/assets/lottery/icon11.png);
	}
	.tabs_icons {
		display: -webkit-flex;
		display: flex
	}

	.tabs_icons>li {
		position: relative;
		text-align: center;
		cursor: pointer;
		margin-right: 16px
	}

	.tabs_icons>li .tabs_icon {
		width: 76px;
		height: 76px;
		margin-bottom: 16px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-content: center;
		align-content: center;
		position: relative;
		z-index: 1;
		box-shadow: 0 5px 10px 0 rgba(112, 146, 215, .14), inset 0 -4px 0 0 #fff;
		border-radius: 14px
	}

	.tabs_icons>li .tabs_icon:before {
		content: "";
		z-index: -1;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		// background-image: url(../../assets/lottery/icon1.png);
		background-image: url(/assets/lottery/icon1.png);
		background-size: 100% 100%
	}

	.tabs_icons>li .tabs_icon .tabs_logo {
		width: 50px;
		height: 50px;
		margin-top: 13px;
		display: inline-block;
		background-size: 50px 50px
	}

	.tabs_icons>li p {
		color: #6c7ba8;
		font-size: 16px
	}

	.tabs_icons>li:hover .tabs_icon {
		-webkit-animation: tabs_in__2YKAy .5s ease forwards;
		animation: tabs_in__2YKAy .5s ease forwards
	}

	.tabs_icons>li:hover .tabs_icon:before {
		// background-image: url(../../assets/lottery/icon2.png);
		background-image: url(/assets/lottery/icon2.png);
	}

	@-webkit-keyframes tabs_in__2YKAy {
		0% {
			-webkit-transform: scale(1.1);
			transform: scale(1.1)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	@keyframes tabs_in__2YKAy {
		0% {
			-webkit-transform: scale(1.1);
			transform: scale(1.1)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	.tabs_icons>li.tabs_active .tabs_icon,
	.tabs_icons>li:hover .tabs_icon {
		box-shadow: 0 7px 13px 0 #b6bfdb
	}

	.tabs_icons>li.tabs_active .tabs_icon:before,
	.tabs_icons>li:hover .tabs_icon:before {
		// background-image: url(../../assets/lottery/icon2.png);
		background-image: url(/assets/lottery/icon2.png);
	}

	.layout_venue__2ozWL {
		position: relative;
		margin: 0 auto;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		// -webkit-align-items: center;
		// align-items: center;
		background-size: 100% 100%;
		min-height: 960px;
		height: calc(100vh - 210px);
		padding-top: 170px
	}

	.layout_venue__2ozWL .layout_container__2cEhE {
		width: 1200px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-content: center;
		align-content: center;
		box-sizing: border-box;
		margin: 0 auto;
		position: relative
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF {
		width: 530px;
		margin-right: 33px;
		letter-spacing: .5px;
		text-align: justify
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_section {
		min-height: 393px;
		-webkit-animation: layout_animationRightIn__DIUpA 1s forwards;
		animation: layout_animationRightIn__DIUpA 1s forwards
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_section.layout_fadeOut {
		-webkit-animation: layout_animationRightOut__xFftF 1s forwards;
		animation: layout_animationRightOut__xFftF 1s forwards
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_title__3XxNu {
		max-width: 100%;
		height: 148px
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_desc__2U81i {
		line-height: 24px;
		margin: 20px 0 !important;
		font-size: 16px;
		color: #6c7ba8;
		letter-spacing: .5px;
		text-align: justify
	}

	@-webkit-keyframes layout_animationRightIn__DIUpA {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(50px, 0, 0);
			transform: translate3d(50px, 0, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@keyframes layout_animationRightIn__DIUpA {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(50px, 0, 0);
			transform: translate3d(50px, 0, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@-webkit-keyframes layout_animationRightOut__xFftF {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	@keyframes layout_animationRightOut__xFftF {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_tabs {
		width: 100%
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_entryBtn {
		width: 160px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		margin-top: 47px;
		color: #fff;
		font-size: 18px;
		background-image: none !important;
		position: relative;
		z-index: 1;
		cursor: pointer;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		box-shadow: 0 2px 4px 0 #5da7eb;
		border-radius: 25px
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_entryBtn:before {
		content: "";
		z-index: -1;
		top: 0;
		display: block;
		width: 160px;
		height: 50px;
		position: absolute;
		left: 50%;
		-webkit-transform: translate3d(-50%, 0, 0);
		transform: translate3d(-50%, 0, 0);
		// background-image: url(../../assets/lottery/icon3.png);
		background-image: url(/assets/lottery/icon3.png);
		background-size: 100% 100%
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_entryBtn:hover {
		-webkit-transform: scale(1.05);
		transform: scale(1.05)
	}

	.lottery_bg__3vl-q {
		background: #f0f6ff
	}

	.lottery_entryBtn {
		margin-top: 20px
	}

	.lottery_container__31-DR {
		height: 700px
	}

	.lottery_leftWrap__1nmWc {
		min-width: 750px;
		    height: 100%;
		    position: relative;
		    left: -100px;
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
		-webkit-animation: lottery_animationPostersIn__L-IcQ 1s forwards;
		animation: lottery_animationPostersIn__L-IcQ 1s forwards
	}

	.lottery_leftWrap__1nmWc.lottery_fadeOut__3nY7g {
		-webkit-animation: lottery_animationPostersOut__3RkOE .7s forwards;
		animation: lottery_animationPostersOut__3RkOE .7s forwards
	}

	.lottery_leftWrap__1nmWc .lottery_top__7o3sw {
		position: absolute;
		left: 50%;
		top: 0;
		-webkit-transform: translate(-30%);
		transform: translate(-30%);
		width: 350px
	}

	.lottery_leftWrap__1nmWc .lottery_top__7o3sw .lottery_triangle__a4aPi {
		height: 235px
	}

	.lottery_leftWrap__1nmWc.lottery_index0__2jqFy .lottery_top__7o3sw .lottery_triangle__a4aPi {
		height: 242px
	}

	.lottery_leftWrap__1nmWc .lottery_bottom__vhzJC {
		height: 674px;
		position: relative;
		-webkit-animation: lottery_animationGroundIn__1KRqm .7s forwards;
		animation: lottery_animationGroundIn__1KRqm .7s forwards
	}

	.lottery_leftWrap__1nmWc .lottery_bottom__vhzJC.lottery_fadeOut__3nY7g {
		-webkit-animation: lottery_animationGroundOut__LIOEd .7s forwards;
		animation: lottery_animationGroundOut__LIOEd .7s forwards
	}

	.lottery_leftWrap__1nmWc .lottery_bottom__vhzJC .lottery_poster__20wC8 {
		position: absolute;
		width: 600px;
		bottom: 0;
		left: 0
	}

	.lottery_leftWrap__1nmWc .lottery_bottom__vhzJC .lottery_left__UrWJG {
		position: absolute;
		width: 55px;
		height: 56px;
		bottom: 385px;
		right: 462px;
		-webkit-animation: lottery_upDownLeft__1KMQq 5s ease infinite;
		animation: lottery_upDownLeft__1KMQq 5s ease infinite
	}

	.lottery_leftWrap__1nmWc .lottery_bottom__vhzJC .lottery_right__2zkPz {
		position: absolute;
		width: 66px;
		height: 67px;
		bottom: 392px;
		right: 74px;
		-webkit-animation: lottery_upDownRight__ozp4v 5s ease infinite;
		animation: lottery_upDownRight__ozp4v 5s ease infinite
	}

	.lottery_leftWrap__1nmWc.lottery_index0__2jqFy .lottery_bottom__vhzJC .lottery_poster__20wC8 {
		width: 600px;
		height: 556px
	}

	.lottery_leftWrap__1nmWc.lottery_index1__vrIJm .lottery_bottom__vhzJC .lottery_poster__20wC8 {
		width: 600px;
		height: 601px
	}

	.lottery_leftWrap__1nmWc.lottery_index2__3WtAB .lottery_bottom__vhzJC .lottery_poster__20wC8,
	.lottery_leftWrap__1nmWc.lottery_index3__1V4F2 .lottery_bottom__vhzJC .lottery_poster__20wC8 {
		width: 600px;
		height: 579px
	}

	@-webkit-keyframes lottery_upDownRight__ozp4v {
		0% {
			-webkit-transform: translate(0);
			transform: translate(0)
		}

		50% {
			-webkit-transform: translateY(-20px);
			transform: translateY(-20px)
		}

		to {
			-webkit-transform: translate(0);
			transform: translate(0)
		}
	}

	@keyframes lottery_upDownRight__ozp4v {
		0% {
			-webkit-transform: translate(0);
			transform: translate(0)
		}

		50% {
			-webkit-transform: translateY(-20px);
			transform: translateY(-20px)
		}

		to {
			-webkit-transform: translate(0);
			transform: translate(0)
		}
	}

	@-webkit-keyframes lottery_upDownLeft__1KMQq {
		0% {
			-webkit-transform: translate(0);
			transform: translate(0)
		}

		50% {
			-webkit-transform: translateY(20px);
			transform: translateY(20px)
		}

		to {
			-webkit-transform: translate(0);
			transform: translate(0)
		}
	}

	@keyframes lottery_upDownLeft__1KMQq {
		0% {
			-webkit-transform: translate(0);
			transform: translate(0)
		}

		50% {
			-webkit-transform: translateY(20px);
			transform: translateY(20px)
		}

		to {
			-webkit-transform: translate(0);
			transform: translate(0)
		}
	}

	@-webkit-keyframes lottery_animationGroundIn__1KRqm {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(0, 50px, 0);
			transform: translate3d(0, 50px, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@keyframes lottery_animationGroundIn__1KRqm {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(0, 50px, 0);
			transform: translate3d(0, 50px, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@-webkit-keyframes lottery_animationGroundOut__LIOEd {
		0% {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		to {
			opacity: 0;
			-webkit-transform: translate3d(0, 50px, 0);
			transform: translate3d(0, 50px, 0)
		}
	}

	@keyframes lottery_animationGroundOut__LIOEd {
		0% {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}

		to {
			opacity: 0;
			-webkit-transform: translate3d(0, 50px, 0);
			transform: translate3d(0, 50px, 0)
		}
	}

	@-webkit-keyframes lottery_animationPostersIn__L-IcQ {
		0% {
			opacity: 0;
			-webkit-transform: rotateX(-20deg);
			transform: rotateX(-20deg)
		}

		to {
			opacity: 1;
			-webkit-transform: rotateX(0);
			transform: rotateX(0)
		}
	}

	@keyframes lottery_animationPostersIn__L-IcQ {
		0% {
			opacity: 0;
			-webkit-transform: rotateX(-20deg);
			transform: rotateX(-20deg)
		}

		to {
			opacity: 1;
			-webkit-transform: rotateX(0);
			transform: rotateX(0)
		}
	}

	@-webkit-keyframes lottery_animationPostersOut__3RkOE {
		0% {
			opacity: 1;
			-webkit-transform: rotateX(0);
			transform: rotateX(0)
		}

		to {
			opacity: 0;
			-webkit-transform: rotateX(-20deg);
			transform: rotateX(-20deg)
		}
	}

	@keyframes lottery_animationPostersOut__3RkOE {
		0% {
			opacity: 1;
			-webkit-transform: rotateX(0);
			transform: rotateX(0)
		}

		to {
			opacity: 0;
			-webkit-transform: rotateX(-20deg);
			transform: rotateX(-20deg)
		}
	}

	.lottery_rightWrap__1W3AW {
		position: relative;
		padding-top: 10px;
		    left: -84px;
	}
</style>
