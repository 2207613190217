<template>
	<div class="rightobbox">

		<div class="title-bo2">
			<div class="left">
				<p>交易記錄</p>
				<span>
					只顯示近一個月的交易記錄，如需更多信息請聯系客服查詢</span>
			</div>
		</div>

		<div class="serach-data">
			<div class="serach-item">
				<p style="margin-left: 0px;">交易类型：</p>
				<a-select v-model="selectValue" style="width: 257px" @change="handleChange">
					<a-select-option value="充值记录">
						存款記錄
					</a-select-option>
					<a-select-option value="提现记录">
						提款記錄
					</a-select-option>
					<a-select-option value="额度转换记录">
						額度轉換
					</a-select-option>
					<a-select-option value="红利记录">
						紅利記錄
					</a-select-option>
					
				</a-select>
			</div>
		</div>
		<div class="serach-data">
			<div class="serach-item">
				<p style="margin-left: 0px;">交易時間：</p>
				<a-radio-group v-model="selectDate" style="width: 280px" @change="onChangeDate">
					<a-radio-button value="0">
						今日
					</a-radio-button>
					<a-radio-button value="7">
						近7日
					</a-radio-button>
					<a-radio-button value="15">
						近15日
					</a-radio-button>
					<a-radio-button value="30">
						本月
					</a-radio-button>
				</a-radio-group>
			</div>
			<div class="serach-item" style="width: 420px;">
				<p style="margin-left: 0px;">時間篩選：</p>
				<a-range-picker :locale="DateLocale" :showTime="true" style="width: 400px;" format="YYYY-MM-DD HH:mm:ss" @ok="onChangeDate1" v-model="selectDateValue" :placeholder="['開始時間', '結束時間']"/>
			</div>
			<!--  <button data-label="交易记录-查询" class="Button333"
				style="width: 113px; height: 40px;margin-left: 20px;" >查询</button> -->
		</div>

		<a-table :columns="columns" rowKey="created_at" :locale="locale" :data-source="data" :pagination="false" :loading="tableLoading">
			<a slot="name" slot-scope="text">{{ text }}</a>
			<a slot="type" slot-scope="type">{{ gameType[type] }}</a>
			<div slot="transfer" slot-scope="text, record, index">
				{{record.transfer_out_account}} / {{record.transfer_in_account}}
			</div>
			<div slot="transfer_type" slot-scope="text, record, index">
				{{record.transfer_type == 0 ? '轉入遊戲' : '轉出遊戲'}}
			</div>
			<div slot="recharge_payment_type" slot-scope="payment_type">
				{{getRechargePaymentType(payment_type)}}
			</div>
			<div slot="drawing_payment_type" slot-scope="payment_type">
				{{getDrawingPaymentType(payment_type)}}
			</div>
			<div slot="drawing_money" slot-scope="money, data">
				<div v-if="data.type == 4" >{{money}} <span style="color:#4CAF50;font-weight: 700;">({{data.digital_money}})</span></div>
				<span v-else>{{money}}</span>
			</div>
			<div slot="recharge_money" slot-scope="money, data">
				<div v-if="data.payment_type == 18" >{{money}} <span style="color:#4CAF50;font-weight: 700;">({{data.digital_money}})</span></div>
				<span v-else>{{money}}</span>
			</div>
			<div slot="recharge_status" slot-scope="status">
				<span v-if="status == 1" style="color:#FF9800;font-weight: 700;">待確認</span>
				<span v-if="status == 2" style="color:#4CAF50;font-weight: 700;">充值成功</span>
				<span v-if="status == 3" style="color:red;font-weight: 700;">充值失敗</span>
			</div>
			<div slot="drawing_status" slot-scope="status">
				<span v-if="status == 1" style="color:#FF9800;font-weight: 700;">審核中</span>
				<span v-if="status == 2" style="color:#4CAF50;font-weight: 700;">成功</span>
				<span v-if="status == 3" style="color:red;font-weight: 700;">失敗</span>
			</div>
		</a-table>
		<div class="fotecs" v-if="selectValue == '充值记录' && data.length">
			總成功充值：<span>${{talNum}}</span>
		</div>
		<div class="fotecs" v-if="selectValue == '提现记录' && data.length">
			總成功提款：<span>${{talNum}}</span>
		</div>
		<div class="fotecs" v-if="selectValue == '红利记录' && data.length">
			總紅利：<span>${{talNum}}</span>
		</div>
		<div v-if="data.length" style="text-align: right;margin-top: 20px;">
			<a-pagination v-model="tablePage" :total="tableTotal" show-less-items @change="tablePageChange" />
		</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	import Empty from '@/components/Empty.vue'
	import moment from 'moment'
	function getDay(day, type) {
		var today = new Date();
		var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
		today.setTime(targetday_milliseconds); //注意，这行是关键代码
		var tYear = today.getFullYear();
		var tMonth = today.getMonth();
		var tDate = today.getDate();
		tMonth = doHandleMonth(tMonth + 1);
		tDate = doHandleMonth(tDate);
		if (type == 'start') {
			return tYear + "-" + tMonth + "-" + tDate + ' 00:00:00';
		} else if (type == 'end') {
			return tYear + "-" + tMonth + "-" + tDate + ' 23:59:59';
		}
	}

	function doHandleMonth(month) {
		var m = month;
		if (month.toString().length == 1) {
			m = "0" + month;
		}
		return m;
	}
	
	function getLastDay(){
	    var y = new Date().getFullYear(); //获取年份
	    var m = new Date().getMonth() + 1; //获取月份
	    var d = '01'
	    m = m < 10 ? '0' + m : m; //月份补 0
	    return [y,m,d].join("-") + ' 00:00:00'
	}
	import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
	import 'moment/locale/zh-cn';
	export default {
		name: "Home",

		data() { 
			return {
				DateLocale: locale,
				selectDate: '0',
				data: [],
				columns: [],
				locale: {
					emptyText: < Empty msg1 = "暫無交易記錄" / >
				},

				selectValue: '充值记录',
				start: getDay(0, 'start'),
				end: getDay(0, 'end'),
				
				selectDateValue: [
					moment(getDay(0, 'start'), 'YYYY-MM-DD HH:mm:ss'),
					moment(getDay(0, 'end'), 'YYYY-MM-DD HH:mm:ss')
				],
				
				gameType: [],
				
				tablePage: 1,
				tableTotal: 0,
				tableLoading: true,
				
				talNum: 0
			};
		},
		activated () {
			if (this.$route.query.type) {
				if (this.$route.query.type == 1) {
					this.selectValue = '充值记录'
				}
				if (this.$route.query.type == 2) {
					this.selectValue = '提现记录'
				}
				if (this.$route.query.type == 3) {
					this.selectValue = '额度转换记录'
				}
				if (this.$route.query.type == 4) {
					this.selectValue = '红利记录'
				}
				this.handleChange()
			} else {
				this.handleChange()
			}
		},
		mounted() {
			if (this.$route.query.type) {
				if (this.$route.query.type == 1) {
					this.selectValue = '充值记录'
				}
				if (this.$route.query.type == 2) {
					this.selectValue = '提现记录'
				}
				if (this.$route.query.type == 3) {
					this.selectValue = '额度转换记录'
				}
				if (this.$route.query.type == 4) {
					this.selectValue = '红利记录'
				}
				this.handleChange()
			} else {
				this.handleChange()
			}
			// http({
			// 	method: 'get',
			// 	url: '/game_type',
			// 	data: {
					
			// 	}
			// }).then((res) => {
			// 	if (res.code == 200) {
			// 		this.gameType = res.data
			// 	} else {
			// 		this.$message.error(res.message);
			// 	}
			// })
			this.gameType = ['', '充值彩金', '平臺紅利', '返水']
		},
		methods: {
			onChangeDate1 (date) {
				// .format('YYYY-MM-DD HH:mm:ss')
				this.start = moment(date[0],'YYYY-MM-DD HH:mm:ss')
				this.end = moment(date[1],'YYYY-MM-DD HH:mm:ss')
				this.selectDate = undefined
				this.selectDateValue = [this.start, this.end]
				this.handleChange()
			},
			onChangeDate () {
				if (this.selectDate == 0) {
					this.start = moment(getDay(0, 'start'), 'YYYY-MM-DD HH:mm:ss')
					this.end = moment(getDay(0, 'end'), 'YYYY-MM-DD HH:mm:ss')
				}
				if (this.selectDate == 7) {
					this.start = moment(getDay(-7, 'start'), 'YYYY-MM-DD HH:mm:ss')
					this.end = moment(getDay(0, 'end'), 'YYYY-MM-DD HH:mm:ss')
				}
				if (this.selectDate == 15) {
					this.start = moment(getDay(-15, 'start'), 'YYYY-MM-DD HH:mm:ss')
					this.end = moment(getDay(0, 'end'), 'YYYY-MM-DD HH:mm:ss')
				}
				if (this.selectDate == 30) {
					this.start = moment(getLastDay(), 'YYYY-MM-DD HH:mm:ss')
					this.end = moment(getDay(0, 'end'), 'YYYY-MM-DD HH:mm:ss')
				}
				this.selectDateValue = [this.start, this.end]
				this.handleChange()
			},
			// 红利记录
			dividendRecord () {
				this.tableLoading = true
				this.columns = [{
							title: '紅利類型',
							dataIndex: 'type',
							key: 'type',
							scopedSlots: {
								customRender: 'type'
							},
						},
						{
							title: '金額',
							dataIndex: 'money',
							key: 'money',
						},
						{
							title: '發放時間',
							dataIndex: 'created_at',
							key: 'created_at',
						},
					]
				
				http({
					method: 'post',
					url: '/member/dividendRecord',
					data: {
						page: this.tablePage,
						start: moment(this.start,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
						end: moment(this.end,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
					}
				}).then((res) => {
					if (res.code == 200) {
						this.data = res.data.data
						this.tableTotal = res.data.total
						this.talNum = res.data.all_money
					} else {
						this.$message.error(res.message);
					}
					this.tableLoading = false
				})
			},
			// 额度转换记录
			transferRecord () {
				this.tableLoading = true
				this.columns = [{
							title: '轉賬時間',
							dataIndex: 'created_at',
							key: 'created_at',
							width: 200,
						},
						{
							title: '金額',
							dataIndex: 'money',
							key: 'money',
						},
						{
							title: '轉出/轉入賬戶',
							dataIndex: 'transfer',
							key: 'transfer',
							scopedSlots: {
								customRender: 'transfer'
							},
							
						}
						,
						{
							title: '转换类型',
							dataIndex: 'transfer_type',
							key: 'transfer_type',
							scopedSlots: {
								customRender: 'transfer_type'
							},
						}
					]
				
				http({
					method: 'post',
					url: '/member/transferRecord',
					data: {
						page: this.tablePage,
						start: moment(this.start,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
						end: moment(this.end,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
					}
				}).then((res) => {
					if (res.code == 200) {
						this.data = res.data.list
						this.tableTotal = res.data.meta.pagination.total
					} else {
						this.$message.error(res.message);
					}
					this.tableLoading = false
				})
			},
			// 提现记录
			drawingRecord () {
				this.tableLoading = true
				this.columns = [{
							title: '提款時間',
							dataIndex: 'created_at',
							key: 'created_at',
							width: 170,
						},
						{
									title: '提款方式',
									dataIndex: 'type',
									key: 'type',
									scopedSlots: {
										customRender: 'drawing_payment_type'
									},
									width: 170,
								},
						{
							title: '金額',
							dataIndex: 'money',
							key: 'money',
							scopedSlots: {
								customRender: 'drawing_money'
							},
							width: 170,
						},
						{
							title: '原因',
							dataIndex: 'fail_reason',
							key: 'fail_reason',
							
						},
						{
							title: '狀態',
							dataIndex: 'status',
							key: 'status',
							width: 120,
							scopedSlots: {
								customRender: 'drawing_status'
							},
						}
					]
				
				http({
					method: 'post',
					url: '/member/drawingRecord',
					data: {
						page: this.tablePage,
						start: moment(this.start,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
						end: moment(this.end,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
					}
				}).then((res) => {
					if (res.code == 200) {
						this.data = res.data.data
						this.tableTotal = res.data.total
						this.talNum = res.data.all_money
					} else {
						this.$message.error(res.message);
					}
					this.tableLoading = false
				})
			},
			// 充值记录
			rechargeRecord() {
				this.tableLoading = true
				this.columns = [{
						title: '存款時間',
						dataIndex: 'created_at',
						key: 'created_at',
						width: 170,
					},
					{
						title: '存款方式',
						dataIndex: 'payment_type',
						key: 'payment_type',
						scopedSlots: {
							customRender: 'recharge_payment_type'
						},
						width: 200,
					},
					{
						title: '存款金額',
						dataIndex: 'money',
						key: 'money',
						scopedSlots: {
							customRender: 'recharge_money'
						},
						width: 170,
					},
					{
						title: '原因',
						dataIndex: 'fail_reason',
						key: 'fail_reason',
						
					},
					{
						title: '狀態',
						dataIndex: 'status',
						key: 'status',
						scopedSlots: {
							customRender: 'recharge_status'
						},
						width: 120,
					}
				]

				http({
					method: 'post',
					url: '/member/rechargeRecord',
					data: {
						page: this.tablePage,
						start: moment(this.start,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
						end: moment(this.end,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
					}
				}).then((res) => {
					if (res.code == 200) {
						this.data = res.data.data
						this.tableTotal = res.data.total
						this.talNum = res.data.all_money
					} else {
						this.$message.error(res.message);
					}
					this.tableLoading = false
				})
			},
			getRechargePaymentType (type) {
				let typeData = {
					'3': '銀行卡轉賬 / 轉數快',
					'11': '支付寶 (HK)',
					'12': '支付寶 (HK)',
					'12': 'Tap&Go (HK)',
					'14': '便利店增值',
					'15': '便利店增值',
					'16': '後臺人工充值',
					'17': '月度結余匯入',
					'18': '虛擬幣',
				}
				if (typeof type == 'number') {
					return typeData[JSON.stringify(type)]
				} else if (typeof type == 'string') {
					return typeData[type]
				} 
				return '无'
			},
			getDrawingPaymentType  (type) {
				let typeData = {
					'1': '銀行卡轉賬 / 轉數快',
					'2': '後臺人工取款',
					'3': '月度結余匯出',
					'4': '虛擬幣'
				}
				if (typeof type == 'number') {
					return typeData[JSON.stringify(type)]
				} else if (typeof type == 'string') {
					return typeData[type]
				} 
				return '无'
			},
			handleChange() {
				this.tablePage = 1
				this.tableTotal = 0
				this.data = []
				if (this.selectValue == '充值记录') {
					this.rechargeRecord()
				} else if (this.selectValue == '提现记录') {
					this.drawingRecord()
				} else if (this.selectValue == '额度转换记录') {
					this.transferRecord()
				} else if (this.selectValue == '红利记录') {
					this.dividendRecord()
				}
			},
			tablePageChange (index) {
				this.tablePage = index
				if (this.selectValue == '充值记录') {
					this.rechargeRecord()
				} else if (this.selectValue == '提现记录') {
					this.drawingRecord()
				} else if (this.selectValue == '额度转换记录') {
					this.transferRecord()
				} else if (this.selectValue == '红利记录') {
					this.dividendRecord()
				}
			},
			getSummaries(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '總價';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
						sums[index] += ' 元';
					} else {
						sums[index] = 'N/A';
					}
				});

				return sums;
			}
		}
	};
</script>

<style lang="less" scoped>
.fotecs {
	text-align: right;
	background: #fafafa;
	color: rgb(51, 51, 51) !important;
	    font-size: 14px !important;
	height: 54px;
	line-height: 54px;
	padding-right:20px;
	font-weight: 700;
	span {
		// margin-left:4px;
		color:red;
	}
}
	.serach-data {
		display: flex;
		margin-bottom: 20px;
	}

	.serach-item {
		display: flex;
		width: 380px;

		p {
			width: 70px;
			min-width: 70px;
			margin: 0 0 0 33px;
			color: rgb(75, 94, 111);
			font-size: 14px;
			line-height: 40px;
		}
	}

	.Button333 {
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;
		cursor: pointer;

		&:hover {
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
			background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		}
	}
</style>
