<template>
	<div class="layout_venue__2ozWL chess_bg__3w2Wz">
		<div class="layout_container__2cEhE chess_container__4yEck">
			<div class="chess_leftWrap__2e0uk nopagafter" :class="{'chess_fadeOut__2utv2': show == true}">
				<div class="image_imageContainer__2dKjX image_cover__3B5D- chess_poster__3MRpA" style="cursor: inherit;"
					v-if="getGame('OBQP').on_line == 0" 
					:style="{'visibility': currentIndexValue == 0 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="chess/page1/icon1.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer__2dKjX image_cover__3B5D- chess_poster__3MRpA" style="cursor: inherit;"
					:style="{'visibility': currentIndexValue == 1 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="chess/page2/icon1.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer__2dKjX image_cover__3B5D- chess_poster__3MRpA"
					style="cursor: inherit; " :style="{'visibility': currentIndexValue == 2 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="chess/page3/icon1.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer__2dKjX image_cover__3B5D- chess_poster__3MRpA"
					style="cursor: inherit; " :style="{'visibility': currentIndexValue == 3 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="chess/page4/icon1.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="image_imageContainer__2dKjX image_cover__3B5D- chess_poster__3MRpA" style="cursor: inherit;"
					:style="{'visibility': currentIndexValue == 4 ? 'inherit' : 'hidden'}">
					<div>
						<my-img srcText="chess/page1/icon1.png"
							styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
					</div>
				</div>
				<div class="chess_floatList__3CGa3"
					:style="{'visibility': currentIndexValue == 4 ? 'inherit' : 'hidden'}">
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 39px; height: 28px; cursor: inherit; top: 121px; left: 32px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzkiIGhlaWdodD0iMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>

							<my-img srcText="chess/page1/icon2.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
						</div>
					</div>
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 70px; height: 70px; cursor: inherit; top: -57px; left: 118px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzAiIGhlaWdodD0iNzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>
							<my-img srcText="chess/page1/icon3.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
						</div>
					</div>
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 45px; height: 58px; cursor: inherit; top: 24px; left: 518px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>

							<my-img srcText="chess/page1/icon4.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />

						</div>
					</div>
				</div>
				<div class="chess_floatList__3CGa3"
					:style="{'visibility': currentIndexValue == 3 ? 'inherit' : 'hidden'}">
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 78px; height: 111px; cursor: inherit; top: 42px; left: 36px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzgiIGhlaWdodD0iMTExIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIvPg=="
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>
							<my-img srcText="chess/page4/icon2.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
						</div>
					</div>
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 53px; height: 50px; cursor: inherit; top: 17px; left: 477px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTMiIGhlaWdodD0iNTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>
							<my-img srcText="chess/page4/icon3.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
						</div>
					</div>
				</div>

				<div class="chess_floatList__3CGa3"
					:style="{'visibility': currentIndexValue == 2 ? 'inherit' : 'hidden'}">
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 59px; height: 84px; cursor: inherit; top: 66px; left: 7px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTkiIGhlaWdodD0iODQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>
							<my-img srcText="chess/page3/icon2.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
						</div>
					</div>
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 50px; height: 63px; cursor: inherit; top: 7px; left: 500px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNjMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>
							<my-img srcText="chess/page3/icon3.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
						</div>
					</div>
				</div>

				<div class="chess_floatList__3CGa3"
					:style="{'visibility': currentIndexValue == 1 ? 'inherit' : 'hidden'}">
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 59px; height: 84px; cursor: inherit; top: 7px; left: 35px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTkiIGhlaWdodD0iODQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>

							<my-img srcText="chess/page2/icon2.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
						</div>
					</div>
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 50px; height: 63px; cursor: inherit; top: 7px; left: 500px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNjMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>

							<my-img srcText="chess/page2/icon3.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
						</div>
					</div>
				</div>

				<div class="chess_floatList__3CGa3"
					v-if="getGame('OBQP').on_line == 0 && getGame('OBQP').maintain == 0"
					:style="{'visibility': currentIndexValue == 0 ? 'inherit' : 'hidden'}">
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 39px; height: 28px; cursor: inherit; top: 121px; left: 32px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzkiIGhlaWdodD0iMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>

							<my-img srcText="chess/page1/icon2.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
						</div>
					</div>
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 70px; height: 70px; cursor: inherit; top: -57px; left: 118px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzAiIGhlaWdodD0iNzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>
							<my-img srcText="chess/page1/icon3.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
						</div>
					</div>
					<div class="image_imageContainer__2dKjX image_contain__BN28f chess_floatIcon__C2rGL"
						style="width: 45px; height: 58px; cursor: inherit; top: 24px; left: 518px;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>

							<my-img srcText="chess/page1/icon4.png"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />

						</div>
					</div>
				</div>
			</div>
			<div class="layout_rightWrap__3idXF chess_rightWrap__13-gh">
				<div class="layout_section__1y5F0 " :class="{'layout_fadeOut' : show == true}">
					<div class="image_imageContainer__2dKjX image_contain__BN28f layout_title__3XxNu "
						style="width: 500px; height: 148px; cursor: inherit;">
						<div
							style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
							<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
									aria-hidden="true"
									src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAwIiBoZWlnaHQ9IjE0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4="
									style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
							</div>

							<my-img :srcText="list[currentIndexValue1].title"
								styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; object-position: left center; filter: none; background-image: none;" />

						</div>
					</div>
					<p class="layout_desc__2U81i ">{{list[currentIndexValue].content}}</p>
					<div class="image_imageContainer image_contain  "
						style="width: auto; height: 40px; cursor: inherit;">
						<my-img :srcText="list[currentIndexValue].urlList"
							styleText="width: auto; height: 40px; filter: none; background-image: none;" />
					</div>
				</div>
				<div class="layout_tabs__3fwyR">
					<ul class="tabs_icons">
						<li class="game_maintain_icon" v-if="getGame('OBQP').on_line == 0" :class="{'tabs_active' : currentIndex == 0 && getGame('OBQP').maintain != 1, 'game_maintain': getGame('OBQP').maintain == 1}" @click="setTab(0)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_1"></div>
							</div>
							<p>{{$t('main.game.qp.ob.name')}}</p>
						</li>
            <li class="game_maintain_icon" v-if="getGame('MT').on_line == 0" :class="{'tabs_active' : currentIndex == 4, 'game_maintain': getGame('MT').maintain == 1}" @click="setTab(4)">
              <div class="tabs_icon">
                <div class="tabs_logo tabs_logo_5"></div>
              </div>
              <p>{{$t('main.game.qp.mt.name')}}</p>
            </li>
            <li class="game_maintain_icon" v-if="getGame('VG').on_line == 0" :class="{'tabs_active' : currentIndex == 3, 'game_maintain': getGame('VG').maintain == 1}" @click="setTab(3)">
              <div class="tabs_icon">
                <div class="tabs_logo tabs_logo_4"></div>
              </div>
              <p>{{$t('main.game.qp.vg.name')}}</p>
            </li>
						<li class="game_maintain_icon" v-if="getGame('KY').on_line == 0" :class="{'tabs_active' : currentIndex == 1, 'game_maintain': getGame('KY').maintain == 1}" @click="setTab(1)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_2"></div>
							</div>
							<p>{{$t('main.game.qp.ky.name')}}</p>
						</li>
						<li class="game_maintain_icon" v-if="getGame('LEG').on_line == 0" :class="{'tabs_active' : currentIndex == 2, 'game_maintain': getGame('LEG').maintain == 1}" @click="setTab(2)">
							<div class="tabs_icon">
								<div class="tabs_logo tabs_logo_3"></div>
							</div>
							<p>{{$t('main.game.qp.yl.name')}}</p>
						</li>


					</ul>
				</div>
				<div class="layout_entryBtn__btb7y chess_entryBtn__2oK0F" v-show="isOpen"  @click="link(list[currentIndex].plat_type)">进入游戏</div>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: "appbox",
		data() {
			return {
				list: [{
						image: 'home/app/icon2.png',
						title: "chess/page1/icon5.png",
						content: this.$t('main.game.qp.ob.des'),
						urlList: "person/page1/icon7.png",
						plat_type: "OBQP"
					},
					{
						image: 'home/app/icon2.png',
						title: "chess/page1/icon5.png",
						content: this.$t('main.game.qp.ky.des'),
						urlList: "person/page2/icon7.png",
						plat_type: "KY"
					},
					{
						image: 'home/app/icon2.png',
						title: "chess/page1/icon5.png",
						content: this.$t('main.game.qp.yl.des'),
						urlList: "person/page3/icon7.png",
						plat_type: "LEG"
					},
					{
						image: 'home/app/icon2.png',
						title: "chess/page1/icon5.png",
						content: this.$t('main.game.qp.vg.des'),
						urlList: "person/page4/icon7.png",
						plat_type: "VG"
					},
					{
						image: 'home/app/icon2.png',
						title: "chess/page1/icon5.png",
						content: this.$t('main.game.qp.mt.des'),
						urlList: "person/page1/icon7.png",
						plat_type: "MT"
					},
				],
				currentIndex: 0,
				currentIndexValue: 0,
				currentIndexValue1: 0,
				show: false,
				classList: ['realbet_yb__3zPHl', 'realbet_bg', 'realbet_wm__1FwMM', 'realbet_ag__3PYEY',
					'realbet_ebet__1AFNc', 'realbet_og__3a7j7'
				],
				
				NewArrs: []
			};
		},
		watch: {
			gameConfig () {
				this.NewArrs = this.gameConfig['棋牌']

        if (this.NewArrs.length == 0 ) return
        if(this.NewArrs[0].plat_type == 'OBQP' ){
          this.currentIndex = 0
          this.currentIndexValue = 0
        }else if(this.NewArrs[0].plat_type == 'KY' ){
          this.currentIndex = 1
          this.currentIndexValue = 1
        }else if(this.NewArrs[0].plat_type == 'LEG' ){
          this.currentIndex = 2
          this.currentIndexValue = 2
        }else if(this.NewArrs[0].plat_type == 'VG' ){
          this.currentIndex = 3
          this.currentIndexValue = 3
        }else if(this.NewArrs[0].plat_type == 'MT' ){
          this.currentIndex = 4
          this.currentIndexValue = 4
        }
        return;






				if (this.getGame('OBQP').on_line == 1) {
					this.currentIndex = 1
					this.currentIndexValue = 1
					this.currentIndexValue1 = 1
				}
				if (this.getGame('OBQP').on_line == 1 && this.getGame('KY').on_line == 1) {
					this.currentIndex = 2
					this.currentIndexValue = 2
					this.currentIndexValue1 = 2
				}
				if (this.getGame('OBQP').on_line == 1 && this.getGame('KY').on_line == 1 && this.getGame('LEG').on_line == 1) {
					this.currentIndex = 3
					this.currentIndexValue = 3
					this.currentIndexValue1 = 3
				}
				if (this.getGame('OBQP').on_line == 1 && this.getGame('KY').on_line == 1 && this.getGame('LEG').on_line == 1 && this.getGame('VG').on_line == 1) {
					this.currentIndex = 4
					this.currentIndexValue = 4
					this.currentIndexValue1 = 4
				}
				if (this.getGame('OBQP').on_line == 1 && this.getGame('KY').on_line == 1 && this.getGame('LEG').on_line == 1 && this.getGame('VG').on_line == 1 && this.getGame('MT').on_line == 1) {
					this.currentIndex = -1
					this.currentIndexValue = -1
					this.currentIndexValue1 = -1
				}
			}
		},
		computed: {
			isOpen: function () {
				let is = true
				if (this.currentIndex == 0 && (this.getGame('OBQP').on_line == 1 || this.getGame('OBQP').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 1 && (this.getGame('KY').on_line == 1 || this.getGame('KY').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 2 && (this.getGame('LEG').on_line == 1 || this.getGame('LEG').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 3 && (this.getGame('VG').on_line == 1 || this.getGame('VG').maintain == 1)) {
					is = false
				}
				if (this.currentIndex == 4 && (this.getGame('MT').on_line == 1 || this.getGame('MT').maintain == 1)) {
					is = false
				}
				return is
			},
			...mapGetters(['gameConfig']),
		},
		methods: {
			getGame (type) {
				let data = {}
				this.NewArrs.forEach((v) => {
					if (v.plat_type == type) {
						data = v
					}
				})
				return data
			},
			setTab(idx) {
				this.show = false
				this.currentIndex = idx
				this.show = true

				setTimeout(() => {
					this.currentIndexValue = idx
				}, 300)

				setTimeout(() => {
					this.currentIndexValue1 = idx
				}, 200)

				setTimeout(() => {
					this.show = false
				}, 400)
			},
			link(type) {
				let self = this
				if (!localStorage.getItem('access_token')) {
					let wxts = this.$t('main.login_ts')
					this.$confirm({
						title: this.$t('main.wxts'),
						content: h => < div > {wxts} </div>,
						okText: this.$t('main.okText'),
						cancelText: this.$t('main.cancelText'),
						onOk() {
							self.$router.push({
							  path: "/login",
							});
						},
						onCancel() {
						},
						class: 'testbo',
					});
				} else {
					let data = {
						gameData: {}
					}
					this.NewArrs.forEach((v) => {
						if (v.plat_type == type) {
							data.gameData = v
						}
					})
					let href = window.location.origin +
						`/game?plat_type=${data.gameData.plat_type}&game_type=${data.gameData.game_type}&game_code=${data.gameData.game_code}`
					// window.open(href, "_blank");
					this.$store.dispatch('user/linkGamePage', {
						href: href,
						platType: data.gameData.plat_type,
						gameName: data.gameData.name
					})
				}
			}
		},
		activated() {
			this.NewArrs = this.gameConfig['棋牌']
		},
		destroyed() {

		},
	};
</script>

<style lang="less" scoped>
	.tabs_icons li:hover {
		.tabs_logo_1 {
			// background-image: url(../../assets/chess/icon4.png) !important;
			background-image: url(/assets/chess/icon4.png) !important;
		}

		.tabs_logo_2 {
			// background-image: url(../../assets/chess/icon6.png) !important;
			background-image: url(/assets/chess/icon6.png) !important;
		}

		.tabs_logo_3 {
			// background-image: url(../../assets/chess/icon8.png) !important;
			background-image: url(/assets/chess/icon8.png) !important;
		}

		.tabs_logo_4 {
			// background-image: url(../../assets/chess/icon10.png) !important;
			background-image: url(/assets/chess/icon10.png) !important;
		}

		.tabs_logo_5 {
			// background-image: url(../../assets/chess/icon15.png) !important;
			background-image: url(/assets/chess/icon15.png) !important;
		}
	}

	.tabs_active {
		.tabs_logo_1 {
			// background-image: url(../../assets/chess/icon4.png);
			background-image: url(/assets/chess/icon4.png);
		}

		.tabs_logo_2 {
			// background-image: url(../../assets/chess/icon6.png);
			background-image: url(/assets/chess/icon6.png);
		}

		.tabs_logo_3 {
			// background-image: url(../../assets/chess/icon8.png);
			background-image: url(/assets/chess/icon8.png);
		}

		.tabs_logo_4 {
			// background-image: url(../../assets/chess/icon10.png);
			background-image: url(/assets/chess/icon10.png);
		}

		.tabs_logo_5 {
			// background-image: url(../../assets/chess/icon15.png);
			background-image: url(/assets/chess/icon15.png);
		}
	}

	.tabs_logo_1 {
		// background-image: url(../../assets/chess/icon5.png);
		background-image: url(/assets/chess/icon5.png);
	}

	.tabs_logo_2 {
		// background-image: url(../../assets/chess/icon7.png);
		background-image: url(/assets/chess/icon7.png);
	}

	.tabs_logo_3 {
		// background-image: url(../../assets/chess/icon9.png);
		background-image: url(/assets/chess/icon9.png);
	}

	.tabs_logo_4 {
		// background-image: url(../../assets/chess/icon11.png);
		background-image: url(/assets/chess/icon11.png);
	}

	.tabs_logo_5 {
		// background-image: url(../../assets/chess/icon14.png);
		background-image: url(/assets/chess/icon14.png);
	}

	.tabs_icons {
		display: -webkit-flex;
		display: flex
	}

	.tabs_icons>li {
		position: relative;
		text-align: center;
		cursor: pointer;
		margin-right: 16px
	}

	.tabs_icons>li .tabs_icon {
		width: 76px;
		height: 76px;
		margin-bottom: 16px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-content: center;
		align-content: center;
		position: relative;
		z-index: 1;
		box-shadow: 0 5px 10px 0 rgba(112, 146, 215, .14), inset 0 -4px 0 0 #fff;
		border-radius: 14px
	}

	.tabs_icons>li .tabs_icon:before {
		content: "";
		z-index: -1;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		// background-image: url(../../assets/chess/icon1.png);
		background-image: url(/assets/chess/icon1.png);
		background-size: 100% 100%
	}

	.tabs_icons>li .tabs_icon .tabs_logo {
		width: 50px;
		height: 50px;
		margin-top: 13px;
		display: inline-block;
		background-size: 50px 50px
	}

	.tabs_icons>li p {
		color: #6c7ba8;
		font-size: 16px
	}

	.tabs_icons>li:hover .tabs_icon {
		-webkit-animation: tabs_in__2YKAy .5s ease forwards;
		animation: tabs_in__2YKAy .5s ease forwards
	}

	.tabs_icons>li:hover .tabs_icon:before {
		// background-image: url(../../assets/chess/icon2.png)
		background-image: url(/assets/chess/icon2.png)
	}

	@-webkit-keyframes tabs_in__2YKAy {
		0% {
			-webkit-transform: scale(1.1);
			transform: scale(1.1)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	@keyframes tabs_in__2YKAy {
		0% {
			-webkit-transform: scale(1.1);
			transform: scale(1.1)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	.tabs_icons>li.tabs_active .tabs_icon,
	.tabs_icons>li:hover .tabs_icon {
		box-shadow: 0 7px 13px 0 #b6bfdb
	}

	.tabs_icons>li.tabs_active .tabs_icon:before,
	.tabs_icons>li:hover .tabs_icon:before {
		// background-image: url(../../assets/chess/icon2.png)
		background-image: url(/assets/chess/icon2.png)
	}

	.layout_venue__2ozWL {
		position: relative;
		margin: 0 auto;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		// -webkit-align-items: center;
		// align-items: center;
		background-size: 100% 100%;
		min-height: 960px;
		height: calc(100vh - 210px);
		padding-top: 170px
	}

	.layout_venue__2ozWL .layout_container__2cEhE {
		width: 1200px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-content: center;
		align-content: center;
		box-sizing: border-box;
		margin: 0 auto;
		position: relative
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF {
		width: 530px;
		margin-right: 33px;
		letter-spacing: .5px;
		text-align: justify
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_section__1y5F0 {
		min-height: 393px;
		-webkit-animation: layout_animationRightIn__DIUpA 1s forwards;
		animation: layout_animationRightIn__DIUpA 1s forwards
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_section__1y5F0.layout_fadeOut {
		-webkit-animation: layout_animationRightOut__xFftF 0.3s forwards;
		animation: layout_animationRightOut__xFftF 0.3s forwards
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_title__3XxNu {
		max-width: 100%;
		height: 148px
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_desc__2U81i {
		line-height: 24px;
		margin: 20px 0 !important;
		font-size: 16px;
		color: #6c7ba8;
		letter-spacing: .5px;
		text-align: justify
	}

	@-webkit-keyframes layout_animationRightIn__DIUpA {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(50px, 0, 0);
			transform: translate3d(50px, 0, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@keyframes layout_animationRightIn__DIUpA {
		0% {
			opacity: 0;
			-webkit-transform: translate3d(50px, 0, 0);
			transform: translate3d(50px, 0, 0)
		}

		to {
			opacity: 1;
			-webkit-transform: translateZ(0);
			transform: translateZ(0)
		}
	}

	@-webkit-keyframes layout_animationRightOut__xFftF {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	@keyframes layout_animationRightOut__xFftF {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_tabs__3fwyR {
		width: 100%
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_entryBtn__btb7y {
		width: 160px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		margin-top: 47px;
		color: #fff;
		font-size: 18px;
		background-image: none !important;
		position: relative;
		z-index: 1;
		cursor: pointer;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		box-shadow: 0 2px 4px 0 #5da7eb;
		border-radius: 25px
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_entryBtn__btb7y:before {
		content: "";
		z-index: -1;
		top: 0;
		display: block;
		width: 160px;
		height: 50px;
		position: absolute;
		left: 50%;
		-webkit-transform: translate3d(-50%, 0, 0);
		transform: translate3d(-50%, 0, 0);
		// background-image: url(../../assets/person/icon3.png);
		background-image: url(/assets/chess/icon3.png);
		background-size: 100% 100%
	}

	.layout_venue__2ozWL .layout_container__2cEhE .layout_rightWrap__3idXF .layout_entryBtn__btb7y:hover {
		-webkit-transform: scale(1.05);
		transform: scale(1.05)
	}

	.chess_bg__3w2Wz {
		background: #f0f6ff
	}

	.chess_container__4yEck {
		height: 700px;
		box-sizing: border-box
	}

	.chess_entryBtn__2oK0F {
		margin-top: 42px
	}

	.chess_leftWrap__2e0uk {
		min-width: 750px;
		    height: 100%;
		    position: relative;
		    left: -20px;
		-webkit-perspective: 600px;
		perspective: 600px;
		-webkit-animation: chess_animationPostersIn__1JbCE 1s forwards;
		animation: chess_animationPostersIn__1JbCE 1s forwards
	}

	.chess_leftWrap__2e0uk.chess_fadeOut__2utv2 {
		-webkit-animation: chess_animationPostersOut__3DGKE .3s forwards;
		animation: chess_animationPostersOut__3DGKE .3s forwards
	}

	@-webkit-keyframes chess_animationPostersIn__1JbCE {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-100px);
			transform: translateX(-100px)
		}

		to {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@keyframes chess_animationPostersIn__1JbCE {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-100px);
			transform: translateX(-100px)
		}

		to {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@-webkit-keyframes chess_animationPostersOut__3DGKE {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	@keyframes chess_animationPostersOut__3DGKE {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	.chess_leftWrap__2e0uk .chess_poster__3MRpA {
		position: absolute;
		left: 0;
		top: -38px;
		width: 614px;
		height: 750px
	}

	.chess_leftWrap__2e0uk .chess_floatList__3CGa3 {
		position: absolute;
		top: 53px;
		left: 0;
		width: 100%;
		height: 100%
	}

	.chess_leftWrap__2e0uk .chess_floatIcon__C2rGL {
		position: absolute
	}

	.chess_leftWrap__2e0uk .chess_floatIcon__C2rGL:nth-child(odd) {
		-webkit-animation: chess_upDown__NA-0q 5s ease 1s infinite;
		animation: chess_upDown__NA-0q 5s ease 1s infinite
	}

	.chess_leftWrap__2e0uk .chess_floatIcon__C2rGL:nth-child(2n) {
		-webkit-animation: chess_upDown2__9jNYS 5s ease 1s infinite;
		animation: chess_upDown2__9jNYS 5s ease 1s infinite
	}

	@-webkit-keyframes chess_upDown__NA-0q {
		0% {
			-webkit-transform: translate(0);
			transform: translate(0)
		}

		50% {
			-webkit-transform: translateY(20px);
			transform: translateY(20px)
		}

		to {
			-webkit-transform: translate(0);
			transform: translate(0)
		}
	}

	@keyframes chess_upDown__NA-0q {
		0% {
			-webkit-transform: translate(0);
			transform: translate(0)
		}

		50% {
			-webkit-transform: translateY(20px);
			transform: translateY(20px)
		}

		to {
			-webkit-transform: translate(0);
			transform: translate(0)
		}
	}

	@-webkit-keyframes chess_upDown2__9jNYS {
		0% {
			-webkit-transform: translate(0);
			transform: translate(0)
		}

		50% {
			-webkit-transform: translateY(-20px);
			transform: translateY(-20px)
		}

		to {
			-webkit-transform: translate(0);
			transform: translate(0)
		}
	}

	@keyframes chess_upDown2__9jNYS {
		0% {
			-webkit-transform: translate(0);
			transform: translate(0)
		}

		50% {
			-webkit-transform: translateY(-20px);
			transform: translateY(-20px)
		}

		to {
			-webkit-transform: translate(0);
			transform: translate(0)
		}
	}

	.chess_rightWrap__13-gh {
		position: relative;
		padding-top: 10px;
		
		    position: relative;
		    padding-top: 10px;
		    left: -84px;
	}
</style>
