<template>
	<a-modal title="選擇頭像" :footer="null" width="620px" :visible="show" 
	 @cancel="show = false">
		<a-spin :spinning="spinning" style="min-height: 200px;">
			<div class="list"  style="margin-top:-20px">
				<div class="item" @click="select(item)" :style="{'background-image': 'url(' + item.img_url + ')'}" :class="{'active': item.img_url == selectAvatarImg}" v-for="(item, index) in list" :key="index">
				</div>
			</div>
		</a-spin>
		<div class="Button3332">
			<button @click="hide">取消選擇</button>
			<button @click="save">確認提交</button>
		</div>
	</a-modal>
</template>

<script>
	import http from '@/http.js'
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {
				show: false,
				
				list: [],
				selectAvatarImg: '',
				_selectAvatarImg: '',
				
				spinning: true
			};
		},
		computed: {
			...mapGetters(['avatarImg']),
		},
		mounted () {
			this.spinning = true
		},
		methods: {
			...mapActions('user', ['getUserInfo']),
			// selectUsdtId (id) {
			// 	this.form.usdt_id = id
			// },
			select (data) {
				this.selectAvatarImg = data.img_url
				this._selectAvatarImg = data.img
			},
			showModel () {
				this.spinning = true
				http({
					method: 'post',
					url: '/member/avatar',
					data: {
						
					}
				}).then((res) => {
					this.spinning = false
					this.list = res.data
				})
				this.selectAvatarImg = this.avatarImg
				this._selectAvatarImg = this.avatarImg
				this.show = true
			},
			hide () {
				this.show = false
				this.selectAvatarImg = this.avatarImg
				this._selectAvatarImg = this.avatarImg
			},
			save () {
				if (this._selectAvatarImg.indexOf('http') >= 0) {
					this.show = false
					return
				}
				http({
					method: 'post',
					url: '/member/setAvatar',
					data: {
						avatar: this._selectAvatarImg
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message.success('更換頭像成功');
						this.show = false
						this.getUserInfo()
					} else {
						this.$message.error(res.message);
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.list {
		display: flex;
		flex-wrap: wrap;
		.item {
			margin: 10px;
			width: 90px;
			height: 90px;
			border-radius: 100px;
			box-sizing: content-box;
			cursor: pointer;
			    background-size: 102%;
			    background-position: center;
				border: 2px solid #e0dede;
			&.active {
				border: 2px solid #6c9dfe;
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .6);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .6);
			}
		}
	}
	.Button3332 {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100px;
		button:nth-child(1) {
			margin-right: 15px;
			background-color: transparent !important;
			    color: rgba(0,0,0,.65);
			    background-color: #fff;
			    border: 1px solid #d9d9d9;
				background-image: none !important;
			&:hover {
				background-color: transparent !important;
				background-image: none !important;
				color: #5fabff!important;
				   border: 1px solid #5fabff !important;
			}
		}
		button {
			width: 120px;
			height: 50px;
			cursor: pointer;
			border: none;
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
			background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
			color: #fff;
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			transition: all .3s ease;
			font-size: 16px;
			border-radius: 6px;
			
			&:hover {
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
				background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			}
		}
	}
</style>
