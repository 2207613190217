<template>
	<div class="navCard_secondNavbarView" :class="{'navCard_showNavView': showNav}">
		<div class="navCard_secondNavView">
			<div class="lottery_centerView">
				
				<div class="lottery_itemView game_maintainTY" v-if="getGame('OBCP').on_line == 0" :class="{'game_maintain': getGame('OBCP').maintain == 1}" @click="link('OBCP')">
					<div class="lottery_venuesName">
						<div class="logoBg_logoBgView">
							<my-img srcText="header/header-nav-5/icon3.png" classText="lottery_logoIcon" />
						</div>
						<div class="lottery_infoView">
							<div class="lottery_venueCn">{{$t('main.game.cp.ob.name')}}</div>
							<div class="lottery_venueEn">LOTTERY</div>
						</div>
					</div>
					<div class="image_imageContainer image_cover__3B5D- lottery_imgIcon__VQlBE"
						style="cursor: inherit;">
						<div>
							<my-img srcText="header/header-nav-5/icon4.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; filter: none; background-image: none;"/>
						</div>
					</div>
				</div>
				<div class="lottery_itemView game_maintainTY" v-if="getGame('BBIN').on_line == 0" :class="{'game_maintain': getGame('BBIN').maintain == 1}" @click="link('BBIN')">
					<div class="lottery_venuesName">
						<div class="logoBg_logoBgView">
							<my-img srcText="header/header-nav-5/icon7.png" classText="lottery_logoIcon" />
						</div>
						<div class="lottery_infoView">
							<div class="lottery_venueCn">{{$t('main.game.cp.bbin.name')}}</div>
							<div class="lottery_venueEn">LOTTERY</div>
						</div>
					</div>
					<div class="image_imageContainer image_cover__3B5D- lottery_imgIcon__VQlBE"
						style="cursor: inherit;">
						<div>
							<my-img srcText="header/header-nav-5/icon8.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; filter: none; background-image: none;"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	export default {
		props: ['showNav', 'NewArrs'],
		data() {
			return {
				
			}
		},
		methods: {
			getGame (type) {
				let data = {}
				this.NewArrs.forEach((v) => {
					if (v.plat_type == type) {
						data = v
					}
				})
				return data
			},
			link(type) {
				let self = this
				if (!localStorage.getItem('access_token')) {
					let wxts = this.$t('main.login_ts')
					this.$confirm({
						title: this.$t('main.wxts'),
						content: h => < div > {wxts} </div>,
						okText: this.$t('main.okText'),
						cancelText: this.$t('main.cancelText'),
						onOk() {
							self.$router.push({
							  path: "/login",
							});
						},
						onCancel() {
						},
						class: 'testbo',
					});
				} else {
					let data = {
						gameData: {}
					}
					this.NewArrs.forEach((v) => {
						if (v.plat_type == type) {
							data.gameData = v
						}
					})
					let href = window.location.origin +
						`/game?plat_type=${data.gameData.plat_type}&game_type=${data.gameData.game_type}&game_code=${data.gameData.game_code}`;
					// this.$store.dispatch('user/getNewGidBalanceTime')
					// window.open(href, "_blank");
					this.$store.dispatch('user/linkGamePage', {
						href: href,
						platType: data.gameData.plat_type,
						gameName: data.gameData.name
					})
				}
			}
		}
	};
</script>

<style scoped lang="less">
	.image_imageContainer {
		position: relative;
	}

	.navCard_secondNavbarView {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 390px;
		padding-top: 90px;
		background-color: #fff;
		overflow: hidden;
		-webkit-transition: opacity .8s ease, -webkit-transform .5s ease;
		transition: opacity .8s ease, -webkit-transform .5s ease;
		transition: transform .5s ease, opacity .8s ease;
		transition: transform .5s ease, opacity .8s ease, -webkit-transform .5s ease;
		z-index: 80;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		opacity: 0;
		// background-image: url(../../../assets/header/header-nav-1/icon10.png);
		background-image: url(/assets/header/header-nav-1/icon10.png);
		background-size: 100% 390px;
		background-position: top;
	}

	.navCard_showNavView {
		transform: translateY(0);
		opacity: 1;
		box-shadow: 0 6px 19px 0 hsla(0, 0%, 40%, .27);
	}

	.navCard_secondNavbarView.navCard_showNavView {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
		box-shadow: 0 6px 19px 0 hsla(0, 0%, 40%, .27);
	}

	.navCard_secondNavbarView .navCard_secondNavView {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		overflow: hidden;
	}

	.lottery_centerView {
		display: -webkit-flex;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.lottery_centerView .lottery_itemView {
		position: relative;
		width: 300px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center;
		cursor: pointer;
		-webkit-transition: all .2s ease;
		transition: all .2s ease
	}

	.lottery_centerView .lottery_itemView .lottery_imgIcon__VQlBE {
		width: 150px;
		height: 165px;
		-webkit-transition: all .3s;
		transition: all .3s;
		margin-top: 12px
	}

	.lottery_centerView .lottery_itemView:hover .lottery_imgIcon__VQlBE {
		-webkit-transform: scale(1.1);
		transform: scale(1.1)
	}

	.lottery_centerView .lottery_itemView .lottery_officialIcon__1kXWK {
		position: absolute;
		top: 29px;
		left: 145px
	}

	.lottery_centerView .lottery_itemView .lottery_venuesName {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		margin-top: 44px
	}

	.lottery_centerView .lottery_itemView .lottery_venuesName .lottery_infoView {
		width: 100%
	}

	.lottery_centerView .lottery_itemView .lottery_venuesName .lottery_infoView .lottery_venueCn {
		position: relative;
		line-height: 21px;
		margin-bottom: 2px;
		font-size: 16px;
		font-weight: 600;
		color: #6c7ba8
	}

	.lottery_centerView .lottery_itemView .lottery_venuesName .lottery_infoView .lottery_venueEn {
		line-height: 16px;
		font-size: 12px;
		color: #6c7ba8;
		white-space: nowrap
	}

	.lottery_centerView .lottery_itemView .lottery_venuesName .lottery_logoIcon {
		height: 50px;
		width: auto
	}

	.lottery_centerView .lottery_itemView .lottery_venuesName p {
		height: 20px;
		line-height: 20px;
		font-size: 16px;
		font-weight: 600;
		color: #6c7ba8
	}

	.judgeVenueStatus_isBanned__2cpSM {
		position: relative
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz h2 {
		color: #fff !important;
		font-size: 30px !important;
		line-height: 42px;
		font-family: PingFangSC;
		margin-bottom: 4px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO {
		position: absolute;
		width: 160px;
		height: 180px;
		border-radius: 8px;
		text-align: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-flow: column;
		flex-flow: column;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		z-index: 2
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE:after,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb:after,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W:after,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF:after,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO:after {
		content: "";
		background-color: #6c7ba8;
		width: 100%;
		height: 100%;
		opacity: .7;
		position: absolute;
		z-index: -1;
		border-radius: 8px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE h2,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb h2,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W h2,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF h2,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO h2 {
		color: #fff !important;
		font-size: 30px !important;
		line-height: 42px;
		font-family: PingFangSC;
		margin-bottom: 4px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE div,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb div,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W div,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF div,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO div {
		color: #fff !important
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE p,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb p,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W p,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF p,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO p {
		margin: 0 auto;
		color: #fff !important
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE .judgeVenueStatus_date__21MY9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb .judgeVenueStatus_date__21MY9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W .judgeVenueStatus_date__21MY9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF .judgeVenueStatus_date__21MY9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO .judgeVenueStatus_date__21MY9 {
		font-size: 14px;
		line-height: 16px;
		font-family: DINPro
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE .judgeVenueStatus_time__W_4q9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_maskContent__17vDb .judgeVenueStatus_time__W_4q9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W .judgeVenueStatus_time__W_4q9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF .judgeVenueStatus_time__W_4q9,
	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO .judgeVenueStatus_time__W_4q9 {
		font-size: 24px;
		line-height: 30px;
		font-weight: 700;
		font-family: DINPro
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE {
		width: 580px;
		height: 180px;
		top: 60px;
		left: 155px;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		padding: 30px 0
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE>section {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
		margin-top: 15px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE>section p {
		line-height: 20px;
		font-size: 18px;
		margin-left: 12px;
		margin-right: 12px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_bigMaskContent__2g2gE .judgeVenueStatus_time__W_4q9 {
		font-size: 20px;
		line-height: 24px;
		height: 24px;
		margin-top: 5px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W {
		width: 235px;
		height: 180px;
		top: 69px;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		padding: 30px 0
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W>section {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
		margin-top: 15px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W>section p {
		line-height: 46px;
		font-size: 18px;
		margin-left: 12px;
		margin-right: 12px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_middleMaskContent__23b9W .judgeVenueStatus_time__W_4q9 {
		font-size: 20px;
		line-height: 24px;
		height: 24px;
		margin-top: 5px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF {
		width: 162px;
		height: 204px;
		top: 48px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF>section p {
		line-height: 20px;
		font-size: 14px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_smallMaskContent__1ndlF .judgeVenueStatus_time__W_4q9 {
		font-size: 20px;
		line-height: 24px;
		height: 24px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO {
		width: 270px;
		height: 180px;
		top: 60px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO>section {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
		margin-top: 15px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO>section p {
		line-height: 20px;
		font-size: 18px;
		margin-left: 12px;
		margin-right: 12px
	}

	.judgeVenueStatus_isBanned__2cpSM .judgeVenueStatus_mask__1Csuz .judgeVenueStatus_twoContentMaskContent__2j8iO .judgeVenueStatus_time__W_4q9 {
		font-size: 20px;
		line-height: 24px;
		height: 24px;
		margin-top: 5px
	}

	.logoBg_logoBgView {
		width: 50px;
		height: 50px;
		-webkit-flex: none;
		flex: none;
		margin-right: 8px;
		border-radius: 10.9px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		box-shadow: 0 3px 7px 0 rgba(112, 146, 215, .14);
		background-size: contain;
		// background-image: url(../../../assets/header/header-nav-5/icon60.png)
		background-image: url(/assets/header/header-nav-5/icon60.png)
	}
	
	.officialLogo_officialLogoView {
		width: 51px;
		height: 16px;
		background-size: contain;
		position: relative;
		// background-image: url(../../../assets/header/header-nav-5/icon15.png)
		background-image: url(/assets/header/header-nav-5/icon15.png)
	}
	
	.officialLogo_officialLogoView span {
		position: absolute;
		top: 50%;
		left: 23px;
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		color: #fff;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%)
	}
</style>
