<template>
	<div class="agent swiperContent_layoutView__1QG4L" v-on:mousewheel="handleScroll">
		<!-- 头部  -->
		<header-page />
		<div class="page-box" :style="{ transform: ` translateY(${-100 * initialIndex}vh)` }">
			<div class="page1">
				<section class="swiperContent_screen_content__2i3Az ">
					<div class="swiperContent_screen_inner_wrap__3zFsQ">
						<div class="swiperContent_agent_left__2Kmdx">
							<div class="image_imageContainer__2dKjX image_contain__BN28f "
								style="width: 510px; height: 169px; cursor: inherit;">
								<div
									style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
									<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
											aria-hidden="true"
											src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEwIiBoZWlnaHQ9IjE2OSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4="
											style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
									</div>
									<my-img srcText="agent/icon2.png"
										styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-image: none;" />

								</div>
							</div>
							<div class="swiperContent_cc__1RZFi">
								<div class="image_imageContainer__2dKjX image_contain__BN28f "
									style="width: 510px; height: 41px; cursor: inherit;">
									<div
										style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
										<div style="box-sizing: border-box; display: block; max-width: 100%;"><img
												alt="" aria-hidden="true"
												src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEwIiBoZWlnaHQ9IjQxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIvPg=="
												style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
										</div>
										<my-img srcText="agent/icon3.png"
											styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-image: none;" />

									</div>
								</div>
							</div>
							<ul class="swiperContent_contact_list__1lNcA">
								<li>
									<div class="swiperContent_contact_item_inner__1Oxy2"></div>
									<div class="swiperContent_contact_item_content__Mq8ur">
										<my-img srcText="agent/icon5.png" styleText="width:30px;height:30px;" />
										<p class="swiperContent_title__14fxr">合營部Flygram</p>
										<p class="swiperContent_title__14fxr swiperContent_title_last__zrAOD">
											yaboaff33</p>
										<p class="swiperContent_groupBtnBox__1A8tS"><a>復製</a><a target="_blank"
												href="https://www.flygram2.im:20000/index"
												class="swiperContent_download__xXwFg">下載</a></p>
									</div>
								</li>
								<li @click="linkCs">
									<div class="swiperContent_contact_item_inner__1Oxy2"></div>
									<div class="swiperContent_contact_item_content__Mq8ur">
										<my-img srcText="agent/icon6.jpg" styleText="width:30px;height:30px;" />
										<p class="swiperContent_title__14fxr">合營咨詢</p>
										<p class="swiperContent_title__14fxr swiperContent_title_last__zrAOD">
											點擊進入在線咨詢</p>
										<p><a target="_blank"
												href="https://chat.fln8rs2n.com/chat/text/chat_1AxR5g.html"
												class="swiperContent_btn__7Ek5-">咨询</a></p>
									</div>
								</li>
							</ul>
							<div class="swiperContent_addBtn__1VBFk" @click="linkCs">加入我們</div>
						</div>
						<div>
							<div class="image_imageContainer__2dKjX image_contain__BN28f "
								style="width: 590px; height: 560px; cursor: inherit;">
								<div
									style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
									<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
											aria-hidden="true"
											src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTkwIiBoZWlnaHQ9IjU2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4="
											style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
									</div>
									<my-img srcText="agent/icon10.png"
										styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-image: none;" />
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<div class="page2">
				<div class="swiperContent_screen_inner_wrap__3zFsQ">
					<div class="swiperContent_left__zRvRb">
						<div class="image_imageContainer__2dKjX image_contain__BN28f "
							style="width: 320px; height: 600px; cursor: inherit;">
							<div
								style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
								<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
										aria-hidden="true"
										src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIwIiBoZWlnaHQ9IjYwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4="
										style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
								</div>
								<my-img srcText="agent/icon31.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-image: none;" />
								
							</div>
						</div>
					</div>
					<div class="swiperContent_right__1zSga">
						<div class="swiperContent_agent_title__20HNW">
							<div class="image_imageContainer__2dKjX image_contain__BN28f "
								style="width: 684px; height: 92px; cursor: inherit;">
								<div
									style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
									<div style="box-sizing: border-box; display: block; max-width: 100%;">
										<img alt=""
											aria-hidden="true"
											src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjg0IiBoZWlnaHQ9IjkyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIvPg=="
											style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
									</div>
									
									<my-img srcText="agent/icon32.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-image: none;" />
									
								</div>
							</div>
						</div>
						<div class="swiperContent_list_wrap__DKxeo">
							<div class="swiperContent_title_wrap__vvARr">
								<p class="swiperContent_list_wrap_title__yYcFr">業內最強的APP產品</p>
							</div>
							<ul class="swiperContent_list_content__2Gb1X">
								<li>
									<div class="image_imageContainer__2dKjX image_contain__BN28f swiperContent_title__14fxr"
										style="width: 60px; height: 60px; cursor: inherit;">
										<div
											style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
											<div style="box-sizing: border-box; display: block; max-width: 100%;"><img
													alt="" aria-hidden="true"
													src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
													style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
											</div>
											
											<my-img srcText="agent/icon33.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
					
										</div>
									</div>
									<p class="swiperContent_item_title__309yd">更豐富</p>
									<p class="swiperContent_item_sub_title__I2kjY">豐富的遊戲產品</p>
									<p class="swiperContent_item_sub_title__I2kjY">您想要的我們都有</p>
								</li>
								<li>
									<div class="image_imageContainer__2dKjX image_contain__BN28f swiperContent_title__14fxr"
										style="width: 60px; height: 60px; cursor: inherit;">
										<div
											style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
											<div style="box-sizing: border-box; display: block; max-width: 100%;"><img
													alt="" aria-hidden="true"
													src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
													style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
											</div>
											
											<my-img srcText="agent/icon34.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
																
										</div>
									</div>
									<p class="swiperContent_item_title__309yd">更穩定</p>
									<p class="swiperContent_item_sub_title__I2kjY">強大的技術團隊</p>
									<p class="swiperContent_item_sub_title__I2kjY">提供最穩定的產品</p>
								</li>
								<li>
									<div class="image_imageContainer__2dKjX image_contain__BN28f swiperContent_title__14fxr"
										style="width: 60px; height: 60px; cursor: inherit;">
										<div
											style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
											<div style="box-sizing: border-box; display: block; max-width: 100%;"><img
													alt="" aria-hidden="true"
													src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
													style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
											</div>
											
											<my-img srcText="agent/icon35.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
																
											</div>
									</div>
									<p class="swiperContent_item_title__309yd">更安全</p>
									<p class="swiperContent_item_sub_title__I2kjY">獨家網絡技術</p>
									<p class="swiperContent_item_sub_title__I2kjY">超強防劫持</p>
								</li>
								<li>
									<div class="image_imageContainer__2dKjX image_contain__BN28f swiperContent_title__14fxr"
										style="width: 60px; height: 60px; cursor: inherit;">
										<div
											style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
											<div style="box-sizing: border-box; display: block; max-width: 100%;"><img
													alt="" aria-hidden="true"
													src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
													style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
											</div>
											
											<my-img srcText="agent/icon36.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
																
											</div>
									</div>
									<p class="swiperContent_item_title__309yd">更私密</p>
									<p class="swiperContent_item_sub_title__I2kjY">三重數據加密</p>
									<p class="swiperContent_item_sub_title__I2kjY">保護您的資料安全</p>
								</li>
							</ul>
						</div>
						<div class="swiperContent_list_wrap__DKxeo">
							<div class="swiperContent_title_wrap__vvARr">
								<p class="swiperContent_list_wrap_title__yYcFr">贊助頂級賽事球隊</p>
							</div>
							<div>
								<div class="image_imageContainer__2dKjX image_contain__BN28f "
									style="width: 732px; height: 100px; cursor: inherit;">
									<div
										style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
										<div style="box-sizing: border-box; display: block; max-width: 100%;"><img
												alt="" aria-hidden="true"
												src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzMyIiBoZWlnaHQ9IjEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4="
												style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
										</div>
										
										
										<my-img srcText="agent/icon37.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
															
									
									</div>
								</div>
							</div>
						</div>
						<div class="swiperContent_list_wrap__DKxeo swiperContent_list_3wrap__1YqiB">
							<div class="swiperContent_title_wrap__vvARr">
								<p class="swiperContent_list_wrap_title__yYcFr">權威認證機構</p>
							</div>
							<div class="image_imageContainer__2dKjX image_contain__BN28f "
								style="width: 684px; height: 44px; cursor: inherit;">
								<div
									style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
									<div style="box-sizing: border-box; display: block; max-width: 100%;"><img alt=""
											aria-hidden="true"
											src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjg0IiBoZWlnaHQ9IjQ0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIvPg=="
											style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
									</div>
									
									<my-img srcText="agent/icon38.png" styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;" />
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="pagination">
			<div class="pageItem" @click="clickTo(0)" :class="{'selected': initialIndex == 0}"></div>
			<div class="pageItem " @click="clickTo(1)" :class="{'selected': initialIndex == 1}"></div>
		</div>
	</div>
</template>

<script>
	// 头部
	import HeaderPage from "@/components/HeaderPage";
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: "agent",
		components: {
			HeaderPage,
		},
		data() {
			return {
				initialIndex: 0,
				isScroll: true,
			};
		},
		computed: {
			...mapGetters(['config']),
		},
		mounted() {
			
		},
		methods: {
			linkCs () {
				window.open(this.config.service_link)
			},
			//判断滚动方向，因为此demo中只有四页，故边界处理为 0 与 3
			handleScroll(e) {
				console.log(e, 'eeeeee')
				if (!this.isScroll) {
					return;
				}
				let direction = e.deltaY > 0 ? "down" : "up"; //deltaY为正则滚轮向下，为负滚轮向上
				if (direction == "down" && e.deltaY >= 0) {
					//125为用户一次滚动鼠标的wheelDelta的值
					if (this.initialIndex >= 1) {
						this.initialIndex = 1;
						this.isScroll = false;
						this.setActiveItem(this.initialIndex);
					} else {
						this.initialIndex += 1;
						this.isScroll = false;
						this.setActiveItem(this.initialIndex);
					}
				}
				if (direction == "up" && e.deltaY <= 0) {
					if (this.initialIndex <= 0) {
						this.initialIndex = 0;
						this.isScroll = false;
						// this.setActiveItem(0)
						this.setActiveItem(this.initialIndex);
					} else {
						this.initialIndex -= 1;
						this.isScroll = false;
						this.setActiveItem(this.initialIndex);
					}
				}
			},
			clickTo(idx) {
				this.initialIndex = idx
				this.setActiveItem()
			},
			setActiveItem(index) {
				//index为走马灯当前页码
				setTimeout(() => {
					this.isScroll = true;
				}, 500);
			},
		},
		destroyed: function() {
			window.removeEventListener("mousewheel", this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.agent {
		height: 100vh;
		overflow: hidden;

		.join_us {}

		.pagination {
			width: 14px;
			height: 68px;
			position: fixed;
			top: 50vh;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			right: 237px;
			z-index: 3;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-pack: justify;
			-webkit-justify-content: space-between;
			-ms-flex-pack: justify;
			justify-content: space-between;

			.pageItem {
				width: 24px;
				height: 24px;
				border-radius: 12px;
				cursor: pointer;
				position: relative;

				&:after {
					content: "";
					display: block;
					position: absolute;
					width: 14px;
					height: 14px;
					left: 4px;
					top: 4px;
					border-radius: 7px;
					background: #8eb5d4;
				}

				&.selected {
					border: 1px solid #158bf4;

					&:after {
						-webkit-box-shadow: 0 -2px 3px 0 rgba(249, 250, 253, .62), inset 0 1px 3px 0 rgba(255, 255, 255, .32);
						box-shadow: 0 -2px 3px 0 rgba(249, 250, 253, .62), inset 0 1px 3px 0 rgba(255, 255, 255, .32);
						background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-12%, #04a5e4), to(#008df9));
						background-image: -webkit-linear-gradient(top, #04a5e4 -12%, #008df9);
						background-image: linear-gradient(to bottom, #04a5e4 -12%, #008df9);
						left: 4px;
						top: 4px;
					}
				}

			}
		}

		.page-box {
			height: 100vh;
			transition: all 1s;
			transform: translateY(-100vh);
		}

		.page1 {

			width: 100%;
			height: 100vh;
			display: flex;
			justify-content: center;
			overflow: hidden;
			// background-image: url(../../assets/agent/icon1.png);
			background-image: url(/assets/agent/icon1.png);
			width: 100%;
			height: 100%;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: 50%;
			position: relative;
		}

		.page2 {

			width: 100%;
			height: 100vh;
			display: flex;
			padding-top: 90px;
			justify-content: center;
			overflow: hidden;

			width: 100%;
			height: 100%;
			// background-image: url(../../assets/agent/icon30.png);
			background-image: url(/assets/agent/icon30.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: 50%;
			position: relative;

		}

		.page3 {

			width: 100%;
			height: 100vh;
			display: flex;
			padding-top: 90px;
			justify-content: center;
			overflow: hidden;


		}
	}



	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az {
		height: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		z-index: 1;
		background-size: cover;
		background-position: 50%;
		position: relative
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ {
		width: 1200px;
		height: 600px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%)
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_agent_left__2Kmdx {
		text-align: center;
		width: 510px;
		margin-right: 64px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_agent_left__2Kmdx .swiperContent_cc__1RZFi {
		width: "100%";
		margin-top: 40px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA {
		width: 510px;
		margin: 40px auto 0 0;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li {
		width: 162px;
		height: 140px;
		border-radius: 24px;
		position: relative;
		overflow: hidden;
		-webkit-flex-grow: 1;
		flex-grow: 1;
		// background-image: url(../../assets/agent/icon4.png);
		background-image: url(/assets/agent/icon4.png);
		background-size: 100% 100%;
		margin-right: 12px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li:last-child {
		margin-right: 0
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li .swiperContent_contact_item_inner__1Oxy2 {
		z-index: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		opacity: .6
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li .swiperContent_contact_item_content__Mq8ur {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		box-sizing: border-box;
		text-align: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		justify-content: space-around;
		-webkit-align-items: center;
		align-items: center;
		padding: 14px 0 16px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li .swiperContent_contact_item_content__Mq8ur .swiperContent_title__14fxr {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 100%;
		height: 20px;
		line-height: 20px;
		color: #6c7ba8;
		font-size: 12px;
		margin-top: 8px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li .swiperContent_contact_item_content__Mq8ur .swiperContent_title_last__zrAOD {
		margin-top: 0;
		font-size: 14px;
		padding: 0 3px;
		line-height: 20px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li .swiperContent_contact_item_content__Mq8ur .swiperContent_groupBtnBox__1A8tS {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li .swiperContent_contact_item_content__Mq8ur .swiperContent_groupBtnBox__1A8tS a {
		width: 60px;
		font-size: 12px;
		color: #fff;
		display: block;
		margin: 9px auto 0;
		height: 22px;
		line-height: 22px;
		border-radius: 13px;
		// background-image: url(../../assets/agent/icon7.png);
		background-image: url(/assets/agent/icon7.png);
		background-size: 100% 100%;
		box-shadow: 0 2px 4px 0 #5da7eb
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li .swiperContent_contact_item_content__Mq8ur .swiperContent_groupBtnBox__1A8tS a:hover {
		border: none;
		color: #fff;
		// background-image: url(../../assets/agent/icon7.png);
		background-image: url(/assets/agent/icon7.png);
		background-size: 100% 100%;
		box-shadow: 0 2px 4px 0 #5da7eb;
		opacity: .8
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li .swiperContent_contact_item_content__Mq8ur .swiperContent_groupBtnBox__1A8tS .swiperContent_download__xXwFg {
		margin-left: 8px;
		background-image: none;
		color: #6c7ba8;
		// background-image: url(../../assets/agent/icon8.png);
		background-image: url(/assets/agent/icon8.png);
		width: 60px;
		height: 22px;
		border-radius: 11px;
		background-size: 100% 100%;
		box-shadow: 0 4px 8px 0 rgba(194, 211, 222, .67)
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li .swiperContent_contact_item_content__Mq8ur .swiperContent_btn__7Ek5-,
	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li .swiperContent_contact_item_content__Mq8ur .swiperContent_consulting__1Iq_L {
		font-size: 12px;
		color: #fff;
		display: block;
		width: 80px;
		margin: 9px auto 0;
		height: 22px;
		line-height: 22px;
		border-radius: 13px;
		// background-image: url(../../assets/agent/icon20.png);
		background-image: url(/assets/agent/icon20.png);
		background-size: 100% 100%;
		box-shadow: 0 2px 4px 0 #5da7eb
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_contact_list__1lNcA li .swiperContent_contact_item_content__Mq8ur .swiperContent_btn__7Ek5-:hover {
		border: none;
		color: #fff;
		// background-image: url(../../assets/agent/icon20.png);
		background-image: url(/assets/agent/icon20.png);
		background-size: 100% 100%;
		box-shadow: 0 2px 4px 0 #5da7eb;
		opacity: .8
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_addBtn__1VBFk {
		width: 160px;
		height: 50px;
		outline: none;
		border: none;
		background-color: initial;
		box-shadow: 0 2px 4px 0 #5da7eb;
		line-height: 50px;
		text-align: center;
		margin: 42px auto 0;
		color: #fff;
		font-size: 18px;
		background-image: none !important;
		position: relative;
		z-index: 1;
		cursor: pointer;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		border-radius: 25px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_addBtn__1VBFk:before {
		content: "";
		z-index: -1;
		top: 0;
		display: block;
		width: 160px;
		height: 50px;
		position: absolute;
		left: 50%;
		-webkit-transform: translate3d(-50%, 0, 0);
		transform: translate3d(-50%, 0, 0);
		// background-image: url(../../assets/agent/icon9.png);
		background-image: url(/assets/agent/icon9.png);
		background-size: 100% 100%
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_content__2i3Az .swiperContent_screen_inner_wrap__3zFsQ .swiperContent_addBtn__1VBFk:hover {
		-webkit-transform: scale(1.05);
		transform: scale(1.05)
	}

	.swiperContent_layoutView__1QG4L .swiperContent_screen_inner_wrap__3zFsQ {
		width: 1200px;
		height: 600px;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%)
	}

	.swiperContent_layoutView__1QG4L .swiperContent_secondScreen__1DbxU .swiperContent_left__zRvRb {
		width: 630px;
		text-align: center;
		padding: 13px 0 1px;
		height: 100%;
		box-sizing: border-box;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center
	}

	.swiperContent_layoutView__1QG4L .swiperContent_secondScreen__1DbxU .swiperContent_right__1zSga {
		padding-right: 110px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_secondScreen__1DbxU .swiperContent_right__1zSga .swiperContent_title__14fxr {
		width: 100%;
		height: 92px;
		margin-bottom: 56px;
		margin-top: 34px;
		display: block
	}

	.swiperContent_layoutView__1QG4L .swiperContent_data_wrap__3AvyV {
		margin-top: 15px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-justify-content: space-between;
		justify-content: space-between
	}

	.swiperContent_layoutView__1QG4L .swiperContent_data_wrap__3AvyV li {
		background-color: #fff;
		position: relative;
		overflow: hidden;
		width: 300px;
		height: 230px;
		border-radius: 18px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_data_wrap__3AvyV li:first-child {
		margin-bottom: 15px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_data_wrap__3AvyV li .swiperContent_item_wrap__3GxbH {
		top: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center
	}

	.swiperContent_layoutView__1QG4L .swiperContent_data_wrap__3AvyV li .swiperContent_item_title__309yd {
		line-height: 24px;
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		color: #444c64
	}

	.swiperContent_layoutView__1QG4L .swiperContent_data_wrap__3AvyV li .swiperContent_item_sub_title__I2kjY {
		line-height: 24px;
		text-align: center;
		font-size: 14px;
		color: #6a7391;
		margin-bottom: 4px !important;
		font-family: MicrosoftYaHei;
		letter-spacing: -.47px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_list_wrap__DKxeo {
		width: 100%
	}

	.swiperContent_layoutView__1QG4L .swiperContent_list_wrap__DKxeo .swiperContent_title_wrap__vvARr {
		width: 100%;
		overflow: hidden;
		margin-bottom: 16px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_list_wrap__DKxeo .swiperContent_title_wrap__vvARr .swiperContent_list_wrap_title__yYcFr {
		position: relative;
		margin: 0 auto;
		width: 188px;
		line-height: 20px;
		font-size: 16px;
		font-weight: 700;
		color: #414655;
		text-align: center
	}

	.swiperContent_layoutView__1QG4L .swiperContent_list_wrap__DKxeo .swiperContent_title_wrap__vvARr .swiperContent_list_wrap_title__yYcFr:before {
		content: "";
		display: block;
		position: absolute;
		top: 9px;
		left: 196px;
		width: 240px;
		height: 1px;
		border-top: 1px solid #c0c4d0
	}

	.swiperContent_layoutView__1QG4L .swiperContent_list_wrap__DKxeo .swiperContent_title_wrap__vvARr .swiperContent_list_wrap_title__yYcFr:after {
		content: "";
		display: block;
		position: absolute;
		top: 9px;
		right: 196px;
		width: 240px;
		height: 1px;
		border-top: 1px solid #c0c4d0
	}

	.swiperContent_layoutView__1QG4L .swiperContent_list_wrap__DKxeo .swiperContent_list_content__2Gb1X {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		padding-bottom: 25px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_list_wrap__DKxeo .swiperContent_list_content__2Gb1X li {
		width: 25%;
		text-align: center
	}

	.swiperContent_layoutView__1QG4L .swiperContent_list_wrap__DKxeo .swiperContent_list_content__2Gb1X li .swiperContent_title__14fxr {
		margin: 0 auto
	}

	.swiperContent_layoutView__1QG4L .swiperContent_list_wrap__DKxeo .swiperContent_list_content__2Gb1X li p.swiperContent_item_title__309yd {
		line-height: 20px;
		font-family: MicrosoftYaHeiUI;
		font-weight: 700;
		color: #444c64;
		font-size: 16px;
		margin: 9px 0 4px !important
	}

	.swiperContent_layoutView__1QG4L .swiperContent_list_wrap__DKxeo .swiperContent_list_content__2Gb1X li p.swiperContent_item_sub_title__I2kjY {
		font-weight: 500;
		line-height: 1.43;
		height: 15px;
		letter-spacing: -.47px;
		text-align: center;
		color: #6c7ba8;
		font-family: MicrosoftYaHeiUI;
		font-size: 12px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_thirdScreen__WpLO2 .swiperContent_left__zRvRb {
		width: 320px;
		height: 600px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_thirdScreen__WpLO2 .swiperContent_right__1zSga {
		width: 684px;
		text-align: center;
		margin-right: 110px;
		height: 600px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_thirdScreen__WpLO2 .swiperContent_agent_title__20HNW {
		margin-bottom: 26px;
		margin-top: 34px;
		display: block
	}

	.swiperContent_layoutView__1QG4L .swiperContent_thirdScreen__WpLO2 .swiperContent_list_3wrap__1YqiB {
		margin-top: 30px
	}

	.swiperContent_layoutView__1QG4L .swiperContent_thirdScreen__WpLO2 .swiperContent_list_3wrap__1YqiB .swiperContent_title_wrap__vvARr {
		width: 100%;
		overflow: hidden;
		margin-bottom: 16px
	}

	.swiperContent_canvas__2JGpc {
		position: absolute;
		top: 0;
		z-index: 0
	}
</style>
