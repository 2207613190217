<template>
	<div class="no_list">
		<my-img srcText="mycenter/icon1.png" styleText="width:365px;height:165px;" />
		<div class="no_list_div"><span>{{msg1}}</span> <br></div>
		<div class="no_list_div" v-if="msg"><button class="Button333" style="width: 150px; height: 50px;">{{msg}}</button></div>
	</div>
</template>

<script>
	export default {
	  name: 'HelloWorld',
	  props: {
	    msg: String,
		msg1: String
	  }
	}
</script>

<style>
	.no_list {
		    width: 100%;
		    height: 350px;
		    padding-top: 100px;
		    text-align: center;
	}
	.no_list  img {
		margin-bottom: 30px;
	}
	.no_list_div {
		margin-bottom: 30px;
	}
	.no_list_div span {
		    color: #94a6b7;
	}
	.no_list_div .Button333 {
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;
		cursor: pointer;
	}
	.no_list_div .Button333:hover {
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
		background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
	}
	
</style>
