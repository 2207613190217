<template>
	<div class="rightobbox">

		<!-- <div class="title-bo2">
			<div class="left">
				<p>个人资料</p>
				<span>
				</span>
			</div>
		</div> -->

		<a-spin :spinning="pageLoading" tip="加載中...">

			<div class="topbox">
				<div class="lef">
					<div class="user">
						<div class="name">{{info.name}}</div>
						<div class="icons">
							<div :class="{'active': bankList.length != 0}" @click="linkCardPage('/my-center/banks')">
								<my-img srcText="mycenter/info/xm1.png" />
							</div>
							<div :class="{'active': userInfo.phone}" @click="showPhone">
								<my-img srcText="mycenter/info/sj1.png" />
							</div>
							<div :class="{'active': bankList.length != 0}" @click="linkCardPage('/my-center/banks')">
								<my-img srcText="mycenter/info/yh1.png" />
							</div>
						</div>
					</div>
					<div class="vipdj">
						<span class="dj">VIP {{info.level}}</span>
						<div class="stars">
							<span class="star"></span>
							<span class="star"></span>
							<span class="star"></span>
							<span class="star"></span>
							<span class="star"></span>
						</div>
						<span class="wh"></span>
					</div>
					<div class="cz">
						<div class="dh">
							<span class="dh1">成功充值即可</span>
							<span class="dh2">0/6</span>
						</div>
						<div class="kh"></div>
						<div class="ph">
							<span class="ph1">VIP {{info.level}}</span>
							<span class="ph2">VIP {{info.last_level}}</span>
						</div>
					</div>
				</div>

				<div class="right">
					<h5>賬戶余額</h5>
					<div class="price">
						<span class="p1">$ </span>
						<span class="p2">{{balanceText || '0.00'}}</span>
						<span class="p3" @click="oneTransferOut">{{oneTransferOutText}}</span>
					</div>
					<div class="btns">
						<div @click="linkPage('/my-center/deposit')">
							<my-img srcText="mycenter/info/qukuan1.png" />
							<span>存款</span>
						</div>
						<div @click="linkPage('/my-center/turnover')">
							<my-img srcText="mycenter/info/xima.png" />
							<span>洗碼</span>
						</div>
						<div @click="linkPage('/my-center/withdrawals')">
							<my-img srcText="mycenter/info/cunkuan1.png" />
							<span>提現</span>
						</div>
					</div>
				</div>
			</div>




			<div class="cnt3">
				<div class="cnt3-item">
					<div class="ti222">
						<div v-if="info.bank_card" :class="{'active': itab == 0 && info.bank_card && info.usdt_address}" @click="itab = 0">銀行卡</div>
						<span v-if="info.bank_card && info.usdt_address && config.drawing_usdt == 1"></span>
						<div v-if="info.usdt_address && config.drawing_usdt == 1" :class="{'active': itab == 1 && info.bank_card && info.usdt_address}" @click="itab = 1">USDT</div>
					</div>
					<div class="yhk" v-if="itab == 0 && info.bank_card">
						<div class="t1">
							<span>{{info.bank_name}}</span>
						</div>
						<div class="t2">{{dealCard(info.bank_card)}}</div>
						<div class="t3">{{dealName(info.bank_username)}}</div>
					</div>
					<div class="yhk" v-if="itab == 1 && info.usdt_address && config.drawing_usdt == 1">
						<div class="t1">
							<!-- <my-img srcText="mycenter/info/bank17.png" /> -->
							<span style="font-size: 16px;" v-if="info.pact === 'trc'">TRC</span>
							<span style="font-size: 16px;" v-else-if="info.pact === 'erc'">ERC</span>
							<span style="font-size: 16px;" v-else>{{info.pact}}</span>
						</div>
						<!-- <div class="t2">**** **** **** 1111</div> -->
						<div class="t2">{{dealUsdt(info.usdt_address)}}</div>
					</div>
					
					<div v-if="!info.bank_card && !info.usdt_address" style="font-size: 20px;color: #b9b9b9;text-align: center;line-height: 100px;">
						暫未綁定取款卡
					</div>
				</div>
				<div class="cnt3-item">
					<div class="ti2">
						<div>推薦活動</div>
						<span @click="linkPage('/discount')">更多 ></span>
					</div>
					<div class="listdt">
						<div class="item" v-for="(item, index) in actList" @click="linkAd(item)" :key="index">
							<h3>{{item.title}}</h3>
							<p>{{item.created_at}}</p>
						</div>
					</div>
					<div class="no-info" v-if="actList.length == 0">
						暫無消息

					</div>
				</div>
				<div class="cnt3-item">
					<div class="ti2">
						<div>我的消息</div>
						<span @click="linkPage('/my-center/mail')">更多 ></span>
					</div>
					<div class="listdt">
						<div class="item" v-for="(item, index) in msgList" @click="showContent(item)" :key="index">
							<h3>{{item.title}}</h3>
							<p>{{item.created_at}}</p>
						</div>
					</div>
					<div class="no-info" v-if="msgList.length == 0">
						暫無消息

					</div>
				</div>
			</div>


			<div class="jyjl">
				<div class="tile">
					<div>近期交易記錄</div>
					<span @click="linkPage('/my-center/transRecord')">報表 ></span>
				</div>
				<div class="box1">
					<div class="lt">
						<my-img srcText="mycenter/info/cunkuan.png" />
						<span>存款</span>
					</div>
					<div class="rt">
						<div v-if="rechargeList.length == 0" class="no-info">暫無記錄</div>
						<div class="table_css_div" v-if="rechargeList.length">
							<div style="font-weight: 700;">
								<p>存款時間</p>
								<p>存款方式</p>
								<p>存款金額</p>
								<p>狀態</p>
							</div>
							<div v-for="(item, index) in rechargeList" :key="index">
								<p>{{item.created_at}}</p>
								<p>{{item.recharge_type}}</p>
								<p>{{item.money}}</p>
								<p>
									<span v-if="item.status == 1" style="color:#FF9800;font-weight: 700;">待確認</span>
									<span v-if="item.status == 2" style="color:#4CAF50;font-weight: 700;">充值成功</span>
									<span v-if="item.status == 3" style="color:red;font-weight: 700;">充值失敗</span>
								</p>
								<!-- <p>{{['', '待確認', '充值成功', '充值失敗'][item.status]}}</p> -->
							</div>
						</div>
					</div>
				</div>
				<div class="box1">
					<div class="lt">
						<my-img srcText="mycenter/info/qukuan.png" />
						<span>提現</span>
					</div>
					<div class="rt">
						<div v-if="drawingList.length == 0" class="no-info">暫無記錄</div>
						<div class="table_css_div" v-if="drawingList.length">
							<div style="font-weight: 700;">
								<p>提款時間</p>
								<p>金額</p>
								<p>提款银行</p>
								<p>狀態</p>
							</div>
							<div v-for="(item, index) in drawingList" :key="index">
								<p>{{item.created_at}}</p>
								<p>{{item.money}}</p>
								<p>{{item.bank_name}}</p>
								<p>
									<span v-if="item.status == 1" style="color:#FF9800;font-weight: 700;">審核中</span>
									<span v-if="item.status == 2" style="color:#4CAF50;font-weight: 700;">成功</span>
									<span v-if="item.status == 3" style="color:red;font-weight: 700;">失敗</span>
								</p>
								<!-- <p>{{['', '審核中', '成功', '失敗'][item.status]}}</p> -->
							</div>
						</div>
					</div>
				</div>
			</div>

		</a-spin>



		<!-- <a-modal title="验证电子邮箱" :footer="null" width="560px" :visible="phoneShow1" 
			@ok="phoneShow1 = false" @cancel="phoneShow1 = false">
			<div class="form forminut">
				<div>
					<span class="tipscc">为了您的安全，信息在确认后将无法修改，如需帮助请联系客服</span>


					<div class="bind_bank_item ">
						<div class="bind_bank_item_left">邮箱账号：</div>
						<div class="bind_bank_item_right">
							<input type="text" placeholder="请输入邮箱账号" class="Input" value="">
						</div>
					</div>

					<div class="bind_bank_item ">
						<div class="bind_bank_item_left">验证码：</div>
						<div class="bind_bank_item_right">
							<input disabled="" type="text" placeholder="请输入邮箱验证码" maxlength="6" class="Input disabled"
								value="">
							<div class="bindCount">
								<button data-label="获取验证码" class="">
									<span class="countDown__verCode__2--mX ">获取验证码</span></button>
							</div>
						</div>
					</div>
					<button class="Button333 disabled"
						style="width: 100%; height: 50px; margin: 30px auto 0px; display: block;"
						@click="phoneShow1 = false">完成</button>
				</div>
			</div>
		</a-modal> -->

		<a-modal :title="'【' + getContent.title + '】'" :footer="null" width="680px" centered v-model="contentShow"
			@cancel="contentShow = false">
			<div class="text-model">
				<div class="detail" style="padding: 0 25px;">
					<div class="date">{{getContent.time}}</div>
					<div class="trext" v-html="getContent.content"></div>
				</div>
			</div>
		</a-modal>

		<bind-phone ref="phone" />
	</div>
</template>

<script>
	import http from '@/http.js'
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	const columns = [{
			title: '序號',
			dataIndex: 'name',
			key: 'name',
			scopedSlots: {
				customRender: 'name'
			},
		},
		{
			title: '时间',
			dataIndex: 'age',
			key: 'age',
			width: 80,
		},
		{
			title: '订单号',
			dataIndex: 'address',
			key: 'address 1',
			ellipsis: true,
		},
		{
			title: '提款账户',
			dataIndex: 'address',
			key: 'address 2',
			ellipsis: true,
		},
		{
			title: '交易类型',
			dataIndex: 'address',
			key: 'address 3',
			ellipsis: true,
		},
		{
			title: '金额',
			dataIndex: 'address',
			key: 'address 4',
			ellipsis: true,
		},
		{
			title: '金额',
			dataIndex: '状态',
			key: 'address 14',
			ellipsis: true,
		},
	];

	const data = [

	];
	import Empty from '@/components/Empty.vue'
	import BindPhone from '@/components/BindPhone.vue'
	export default {
		name: "Home",

		data() {
			return {
				value: 'b',
				data,
				columns,
				locale: {
					emptyText: < Empty msg = "立即存款"
					msg1 = "暫無交易記錄" / >
				},

				phoneShow1: false,

				actList: [],
				info: {},
				msgList: [],



				pageLoading: true,


				getContent: {},
				contentShow: false,

				rechargeList: [],
				drawingList: [],
				
				bankList: [],
				
				oneTransferOutText: '資金回收',
				
				itab: 0
			};
		},
		computed: {
			...mapGetters(['balance', 'userInfo', 'config']),
			
			balanceText () {
				if (this.balance) {
					if (typeof this.balance === 'number') {
						return this.balance.toFixed(2)
					} else {
						return this.balance
					}
				} else {
					return '0.00'
				}
			},
		},
		mounted() {
			this.pageLoading = true
			this.getInfo()
			this.getBankList()
		},
		components: {
			BindPhone
		},
		methods: {
			...mapActions('user', ['intervalGetBalance', 'setBalance']),
			// 获取最新余额
			getNewBalance () {
				this.setBalance()
				this.intervalGetBalance()
			},
			oneTransferOut () {
				if (this.oneTransferOutText === '回收中...') {
					return
				}
				this.oneTransferOutText = '回收中...'
				http({
					method: 'post',
					url: '/member/oneTransferOut',
					data: {
						
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getNewBalance()
						setTimeout(() => {
							this.$message.success('余額回收成功！');
						}, 500)
					} else {
						this.$message.error(res.message);
					}
					setTimeout(() => {
						this.oneTransferOutText = '資金回收'
					}, 500)
				})
			},
			getBankList () {
				http({
					method: 'post',
					url: '/member/drawingInfoList',
					data: {
						
					}
				}).then((res) => {
					if (res.code == 200) {
						if (res.data.bank && res.data.bank.bank_card) {
							this.bankList = [res.data.bank]
						}
						if (res.data.usdt && res.data.usdt.usdt_address) {
							this.bankList = [...this.bankList, res.data.usdt]
						}
					} else {
						this.$message.error(res.message);
					}
				})
			},
			linkCardPage(url) {
				if (!this.userInfo.phone) {
					let self = this
					this.$confirm({
						title: '手机號未綁定，請先綁定手機號！',
						cancelText: '取消',
						okText: '綁定手機號',
						onOk() {
							self.$refs.phone.showModel()
						},
						onCancel() {
							
						},
					});
				} else if (this.bankList.length) {
					
				} else {
					this.$router.push({
						path: url
					});
				}
			},
			showPhone() {
				if (!this.userInfo.phone) {
					this.$refs.phone.showModel()
				}
			},
			showContent(data) {
				http({
					method: 'post',
					url: '/member/msgInfo',
					data: {
						id: data.id
					}
				}).then((res) => {
					if (res.code == 200) {
						this.getContent = res.data
						this.contentShow = true
					} else {
						this.$message.error(res.message);
					}
				})
			},
			linkAd(data) {
				this.$router.push({
					path: '/activity_detail?id=' + data.id
				})
			},
			passw() {
				this.$router.push({
					path: '/my-center/password'
				})
			},
			getInfo() {
				http({
					method: 'post',
					url: '/member/info',
					data: {

					}
				}).then((res) => {
					if (res.code == 200) {
						this.actList = res.data.act?.slice(0, 3)
						this.msgList = res.data.msg?.slice(0, 3)
						if (res.data.recharge) {
							this.rechargeList = [res.data.recharge]
						}
						if (res.data.drawing) {
							this.drawingList = [res.data.drawing]
						}
						this.info = res.data
						// this.info.bank_card = null
						if (this.info.bank_card) {
							this.itab = 0
						} else if (this.info.usdt_address && this.config.drawing_usdt == 1) {
							this.itab = 1
						}
					} else {
						this.$message.error(res.message);
					}
					this.pageLoading = false
				})


			},
			dealCard(account) {
				if (account) {
					return "**** **** **** " + account.substring(account.length - 4);
				}
				return "**** **** **** ****"
			},
			dealUsdt(account) {
				let usdt = account.substring(0,4)
				for(let i = 0; i < 10; i++) {
					usdt += '*'
				}
				usdt += account.substring(account.length - 4);
				return usdt
			},
			dealName(name) {
				if (name) {
					return new Array(name.length).join('*') + name.substring(name.length - 1);
				}
				return ''
			},
			linkPage(url) {
				this.$router.push({
					path: url
				});
			}
		}
	};
</script>

<style lang="less" scoped>
	.table_css_div {
		&>div {
			display: flex;
			height: 58px;
			border-bottom: 1px solid #fff;
			align-items: center;

			p {
				width: 25%;
				line-height: 58px;
				height: 100%;
			}
		}
	}

	.jyjl {
		background-color: #fff;
		padding: 16px 24px 18px;
		box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, .05);
		border-radius: 10px;

		.tile {
			margin-bottom: 12px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			div {
				font-size: 20px;
				color: #666666;
				font-weight: 400;
			}

			span {
				font-size: 14px;
				color: #a9a6a6;
				line-height: 28px;
				cursor: pointer;
			}
		}

		.box1 {
			height: 117px;
			margin-bottom: 6px;
			background-color: #f0f3f7;
			display: flex;

			.lt {
				text-align: center;
				padding: 30px 0;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				width: 120px;
				background-color: #bac3cc;
				color: #FFF;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				img {
					width: 34px;
					height: 34px;
					margin-bottom: 3px;
				}

				span {
					font-size: 14px;
					line-height: 18px;
				}
			}

			.rt {
				text-align: center;
				line-height: 120px;
				color: #666666;
				font-size: 14px;
				width: calc(100% - 120px);

				.no-info {}
			}
		}
	}

	.cnt3 {
		margin-bottom: 12px;
		height: 260px;
		justify-content: space-between;
		display: flex;

		.cnt3-item {
			height: 260px;
			padding: 30px 24px 0;
			background-color: #fff;
			margin-bottom: 12px;
			box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, .05);
			border-radius: 10px;
			width: 310px;
			
			.ti222 {
				margin-bottom: 24px;
				line-height: 28px;
				height: 28px;
				font-size: 20px;
				color: #666666;
				font-weight: 400;
				display: flex;
				align-items: center;
				div {
					cursor: pointer;
					&.active {
						    color: #7096b5;
					}
				}
				
				span {
					height: 60%;
					width: 2px;
					background-color: #b9b9b9;
					display: block;
					margin: 0 20px;
				}
			}
			
			.ti2 {
				margin-bottom: 24px;
				line-height: 28px;
				height: 28px;
				font-size: 20px;
				color: #666666;
				font-weight: 400;
				display: flex;
				justify-content: space-between;

				div {}

				span {
					color: #a9a6a6;
					font-size: 14px;
					cursor: pointer;
					line-height: 28px;
				}
			}

			.yhk {
				padding: 18px 24px;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				background-color: #f7f9fb;
				width: 100%;
				height: 155px;
				overflow: hidden;

				.t1 {
					display: flex;
					justify-content: space-between;
					align-items: center;
					height: 32px;
					margin-bottom: 24px;

					img {
						width: 32px;
						height: 32px;
						background-size: cover;
						margin-right: 6px;
					}

					span {
						font-size: 14px;
						color: #666666;
						line-height: 32px;
					}
				}

				.t2 {
					color: #3b85bf;
					font-size: 20px;
					font-weight: bold;
					margin-bottom: 12px;
				}

				.t3 {
					color: #999999;
					font-size: 14px;
					padding-left: 12px;
				}
			}

			.listdt {
				max-height: 166px;
				overflow: auto;

				.item {
					margin-bottom: 16px;
					cursor: pointer;

					h3 {
						font-weight: 100;
						color: #000000;
						font-size: 14px;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						width: 92%;
						white-space: nowrap;

						&:hover {
							text-decoration: underline;
						}

					}

					p {
						color: #b9b9b9;
						font-size: 14px;
					}
				}
			}

			.no-info {
				text-align: center;
				line-height: 155px;
				color: #666666;
				font-size: 14px;
			}
		}
	}

	.topbox {
		height: 240px;
		padding: 36px 48px 48px;
		background-color: #fff;
		margin-bottom: 12px;
		box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, .05);
		border-radius: 10px;
		display: flex;

		.right {
			width: 442px;

			h5 {
				font-size: 14px;
				color: #444c64;
				margin-bottom: 18px;
			}

			.price {
				display: flex;
				align-items: center;

				.p1 {
					background-image: linear-gradient(135deg,#8cb5d9,#18466b);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFD96B', endColorstr='#F9AB10', GradientType=1);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					font-size: 30px;
					font-weight: bold;
					margin-right: 7px;
				}

				.p2 {
					background-image: linear-gradient(135deg,#8cb5d9,#18466b);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFD96B', endColorstr='#F9AB10', GradientType=1);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					font-size: 30px;
					font-weight: bold;
					margin-right: 17px;
				}

				.p3 {
					font-size: 14px;
					text-decoration: none;
					color: #fff;
					height: 30px;
					line-height: 30px;
					background-image: linear-gradient(90deg,#7d97cc 1%,#147ed2);
					color: #fff;
					box-shadow: 0 10px 20px 0 rgba(60, 142, 254, 0.2);

					padding: 0px 24px;
					-webkit-transition-property: all;
					transition-property: all;
					-webkit-transition-duration: .3s;
					transition-duration: .3s;
					box-sizing: border-box;
					border-radius: 50px;
					cursor: pointer;
					transition: all 0.3s ease;

					&:hover {
						background-image: linear-gradient(90deg,#147ed2 1%,#7d97cc);
						box-shadow: 0 10px 20px 0 rgba(60, 142, 254, 0.4);
					}
				}
			}

			.btns {
				margin-top: 36px;
				font-size: 16px;
				display: flex;

				div {
					width: 120px;
					height: 50px;
					background-image: linear-gradient(90deg,#1675c1 1%,#8aa2d2);
					box-shadow: 0 10px 20px 0 rgba(60, 142, 254, 0.2);
					color: #FFF;
					margin-right: 36px;
					line-height: 50px;
					cursor: pointer;
					border: 0;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					transition: all 0.3s ease;

					&:hover {
						background-image: linear-gradient(90deg,#8aa2d2 1%,#1675c1);
						box-shadow: 0 10px 20px 0 rgba(60, 142, 254, 0.4);
					}

					img {
						width: 26px;
						height: 26px;
						background-size: 100% 100%;
						margin-right: 10px;
						position: relative;
					}

					span {}
				}
			}
		}

		.lef {
			width: 401px;
			margin-right: 48px;

			.user {
				margin-bottom: 16px;
				height: 34px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.name {
					color: #7096b5;
					font-size: 20px;
					font-weight: 700;
					line-height: 34px;
				}

				.icons {
					display: flex;
					align-items: center;

					div {
						cursor: pointer;
						opacity: 0.6;

						&.active {
							opacity: 1;
						}
					}

					img {
						width: 34px;
						height: 34px;
						margin-left: 12px;
					}
				}
			}

			.vipdj {
				display: flex;
				margin-bottom: 36px;

				.dj {
					font-size: 12px;
					width: 48px;
					height: 20px;
					line-height: 20px;
					background-color: #6b7184;
					border-radius: 10px;
					color: #f9f7f7;
					text-align: center;
					float: left;
					margin-right: 12px;
				}

				.stars {
					display: flex;
					align-items: center;

					.star {
						width: 12px;
						height: 12px;
						display: inline-block;
						// background-image: url(../../../assets/mycenter/info/vip0.png);
						background-image: url(/assets/mycenter/info/vip0.png);
						background-size: 100% 100%;
						margin-right: 2px;
					}
				}

				.wh {
					width: 16px;
					height: 16px;
					background-size: 100% 100%;
					opacity: .4;
					-webkit-transition: .3s;
					transition: .3s;
					cursor: pointer;
					margin-top: 2px;
					position: relative;
					z-index: 2;
					margin-left: 7px;
					// background-image: url(../../../assets/mycenter/info/xunwen.png);
					background-image: url(/assets/mycenter/info/xunwen.png);
				}
			}

			.cz {
				.dh {
					.dh1 {
						color: #666666;
						margin-right: 6px;
						font-size: 12px;
					}

					.dh2 {
						font-size: 12px;
						color: #2196F3;
					}
				}

				.kh {
					position: relative;
					width: 100%;
					height: 6px;
					border-radius: 3px;
					background-color: #eaeaea;
					margin: 12px 0 6px;
					overflow: hidden;
				}

				.ph {
					font-size: 12px;
					display: flex;
					justify-content: space-between;

					.ph1 {
						color: #FB2464;
					}

					.ph2 {
						color: #666666;
					}
				}
			}
		}
	}

	.rightobbox {
		background-color: transparent !important;
		box-shadow: none !important;
		padding: 0px !important;
		border-radius: 0px !important;
	}

	.pte {
		display: flex;
		width: 380px;

		p {
			width: 230px !important;
			color: #333 !important;
			font-size: 14px !important;
			margin-left: 0px !important;
			text-align: left !important;
		}

		button {
			width: 120px;
			transition: all .3s ease;
			border-radius: 6px;
			height: 40px;
			right: 0px;
			display: block;
			font-size: 14px;
			border: solid 1px #3678fe;
			cursor: pointer;
			color: #3678fe;
			background-color: #fff;

			&:hover {
				-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
				box-shadow: 0 10px 20px 0 #dae8f2;
			}
		}
	}

	.topbo {
		position: relative;
		border-top: solid 1px rgba(210, 214, 226, .5);
	}


	.serach-data {
		display: flex;
		margin-bottom: 20px;
		flex-wrap: wrap;
	}

	.serach-item {
		display: flex;
		width: 100%;
		margin-bottom: 20px;

		p {
			width: 70px;
			min-width: 70px;
			margin: 0 0 0 33px;
			color: rgb(75, 94, 111);
			font-size: 14px;
			line-height: 40px;
			text-align: right;
		}
	}

	.Button333 {
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;
		cursor: pointer;

		&:hover {
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
			background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		}
	}

	.input {
		border-radius: 6px;
		padding: 0 20px;
		border: solid 1px #d8dce7;
		color: #333;
		background-color: #fff;

		&:hover {
			-webkit-transition: background-color .2s ease, border .2s ease;
			transition: background-color .2s ease, border .2s ease;
			border: solid 1px #3678fe;
			-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
			box-shadow: 0 10px 20px 0 #dae8f2;
		}
	}

	.title9 {
		font-size: 16px;
		line-height: 1;
		color: #333;
		margin-bottom: 30px;
		margin-top: 30px;
	}

	.Checkbox {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: 14px;

		&>div {
			border-radius: 6px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			cursor: pointer;
			position: relative;
			border: solid 2px transparent;
			color: #333;

			&:before {
				position: absolute;
				left: -2px;
				right: -2px;
				bottom: -2px;
				top: -2px;
				display: block;
				border-radius: 6px;
				cursor: pointer;
				pointer-events: none !important;
				content: " ";
				border-style: solid;
				border-color: #d8dce7;
				border-width: 1px;
				color: #333;
			}
		}

		.recommend {
			width: 54px;
			position: absolute;
			left: -7px;
			height: 54px;
			position: absolute;
			left: -7px;
			top: -7px;
			background-size: contain;
			z-index: 1;
		}

		&>div:hover {
			border-width: 2px;
			border-color: transparent;
			border-image-source: linear-gradient(296deg, #3678fe 96%, #75a3ff);
			border-image-slice: 1;
			background-image: -webkit-linear-gradient(top, #ffffff, #ffffff), -webkit-linear-gradient(154deg, #3678fe 96%, #75a3ff);
			background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(296deg, #3678fe 96%, #75a3ff);
			background-origin: border-box;
			background-clip: content-box, border-box;
			-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
			box-shadow: 0 10px 20px 0 #dae8f2;

			&:before {
				border: none;
			}
		}

		.Active {
			border-width: 2px;
			border-color: transparent;
			border-image-source: linear-gradient(296deg, #3678fe 96%, #75a3ff);
			border-image-slice: 1;
			background-image: -webkit-linear-gradient(top, #ffffff, #ffffff), -webkit-linear-gradient(154deg, #3678fe 96%, #75a3ff);
			background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(296deg, #3678fe 96%, #75a3ff);
			background-origin: border-box;
			background-clip: content-box, border-box;
			-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
			box-shadow: 0 10px 20px 0 #dae8f2;

			&:before {
				border: none;
			}

			&:after {
				position: absolute;
				right: -1px;
				bottom: -1px;
				content: "";
				display: block;
				// background-image: url(https://static.odcqfh.com/main-consumer-web/assets-oss/kok/images/myCenter/xuanzhong.663fd6fb75dcf95527fb34e34ce58309.svg);
				background-size: cover;
				width: 24px;
				height: 24px;
				right: -2px !important;
				bottom: -2px !important;
				border: none;
			}
		}

		.act_percent {
			position: absolute;
			bottom: -10px;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			min-width: 50px;
			height: 20px;
			line-height: 14px;
			padding: 4px 5px;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			color: #fff;
			font-size: 12px;
			-webkit-box-shadow: inset 0 1px 3px 0 rgba(159, 0, 0, .44);
			box-shadow: inset 0 1px 3px 0 rgba(159, 0, 0, .44);
			background-color: red;
			border-radius: 10px;
			white-space: nowrap;
		}
	}


	.form {

		padding: 0 26px;
		padding-top: 20px;
		padding-bottom: 20px;
		margin-left: -6px;

		.Button333 {
			border: none;
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
			background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
			color: #fff;
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
			transition: all .3s ease;
			font-size: 16px;
			border-radius: 6px;

			&:hover {
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
				background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			}

			&.disabled {
				opacity: .4;
				-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
				background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
				background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
				background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
			}
		}

		.checktips {
			color: #999;
			font-size: 12px;
			margin: 30px auto 0;
			text-align: center;
			display: block;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;

			span {
				color: #3678fe;
			}
		}

		.bind_bank_item {
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			line-height: 38px;
			flex-wrap: wrap;

			&:after {
				content: "";
				display: block;
				clear: both;
				overflow: hidden;
			}

			.bind_bank_item_left {
				font-size: 14px;
				color: #4b5e6f;
				min-width: 90px;
				text-align: left;
			}

			.bind_bank_item_right {
				text-align: left;
				position: relative;

				.bindCount {
					position: absolute;
					right: 14px;
					top: 0;
					color: #94a6b7;

					&>button {
						border: none;
						outline: none;
						background: transparent;
					}
				}

				&>input {
					font-size: 12px;
					width: 466px;
					height: 40px;
					border-radius: 6px;
					padding: 0 20px;
					border: solid 1px #d8dce7;
					color: #333;
					background-color: #fff;

					&.disabled {
						background-color: rgba(216, 220, 231, .2);
						pointer-events: none;
					}

					&:focus {
						-webkit-transition: background-color .2s ease, border .2s ease;
						transition: background-color .2s ease, border .2s ease;
						border: solid 1px #3678fe;
						-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
						box-shadow: 0 10px 20px 0 #dae8f2;
					}


				}

				.tips {}
			}
		}
	}

	.tipscc {
		color: #94a6b7;
		margin-bottom: 20px;
		font-size: 12px;
		margin-top: 7px;
		display: block;
		line-height: 1;
		margin-top: -30px;
	}
</style>
