<template>
	<div style="padding-bottom: 56px;">
		<div class="image_imageContainer image_contain homeSectionTransparent_title_en"
			style="width: 1200px; height: 110px; cursor: inherit;">
			<div
				style="display: block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px;">
				<div style="box-sizing: border-box; display: block; max-width: 100%;">

					<img alt="" aria-hidden="true"
						src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwMCIgaGVpZ2h0PSIxMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIi8+"
						style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;">
				</div>
				<my-img srcText="home/service/icon7.png"
					styleText="position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain; filter: none; background-size: contain; background-image: none; background-position: 0% 0%; background-repeat: no-repeat;" />
			</div>
		</div>

		<div class="homeSectionTransparent_homeSection_content" style="height: auto;">
			<div class="homeSectionTransparent_content_container">
				<div class="info_servertemplate_container">
					<div class="info_kind_warp">
						<ul class="info_kind_list">
							<li class="info_kind_item">
								<div class="info_count_warp">
									<div class="info_count_box">
										<p class="info_countTitle">CURRENT SPEED</p>
										<p class="info_countNum"><count-to :startVal='lastPersonNum' :endVal='nextPersonNum' :duration='2000'></count-to></p>
										<p class="info_countUnit">{{$t('main.home.service.m')}}</p>
									</div>
									<div class="info_progress_warp">
										<div class="circle_progress_wraper"><canvas id="05" class="circle_progress"
												width="153" height="153" style="width: 128px; height: 128px;"></canvas>
										</div>
									</div>
								</div>
								<ele-chart v-if="show" class="wi181" :option="ope1"></ele-chart>
								<p class="info_title">{{$t('main.home.service.t1')}}</p>
								<p class="info_subtitle">AVERAGE TIME OF DEPOSIT</p>
							</li>
							<li class="info_kind_item">
								<div class="info_count_warp">
									<div class="info_count_box">
										<p class="info_countTitle">TOTALLY AMOUNT</p>
										<p class="info_countNum"><count-to :startVal='lastPersonNum' :endVal='nextPersonNum1' :duration='2000'></count-to></p>
										<p class="info_countUnit">{{$t('main.home.service.j')}}</p>
									</div>
									<div class="info_progress_warp">
										<div class="circle_progress_wraper"><canvas id="15" class="circle_progress"
												width="153" height="153" style="width: 128px; height: 128px;"></canvas>
										</div>
									</div>
								</div>
								<ele-chart v-if="show" class="wi181" :option="ope1"></ele-chart>
								<p class="info_title">{{$t('main.home.service.t2')}}</p>
								<p class="info_subtitle">PAYMENT PLATFORM PARTNERS</p>
							</li>
							<li class="info_kind_item">
								<div class="info_count_warp">
									<div class="info_count_box">
										<p class="info_countTitle">CURRENT SPEED</p>
										<p class="info_countNum"><count-to :startVal='lastPersonNum' :endVal='nextPersonNum2' :duration='2000'></count-to></p>
										<p class="info_countUnit">{{$t('main.home.service.m')}}</p>
									</div>
									<div class="info_progress_warp">
										<div class="circle_progress_wraper"><canvas id="25" class="circle_progress"
												width="153" height="153" style="width: 128px; height: 128px;"></canvas>
										</div>
									</div>
								</div>
								<ele-chart v-if="show" class="wi181" :option="ope1"></ele-chart>
								<p class="info_title">{{$t('main.home.service.t3')}}</p>
								<p class="info_subtitle">AVERAGE TIME OF WITHDRAW</p>
							</li>
							<li class="info_kind_item">
								<div class="info_count_warp">
									<div class="info_count_box">
										<p class="info_countTitle">TOTALLY AMOUNT</p>
										<p class="info_countNum"><count-to :startVal='lastPersonNum' :endVal='nextPersonNum3' :duration='2000'></count-to></p>
										<p class="info_countUnit">{{$t('main.home.service.j')}}</p>
									</div>
									<div class="info_progress_warp">
										<div class="circle_progress_wraper"><canvas id="35" class="circle_progress"
												width="153" height="153" style="width: 128px; height: 128px;"></canvas>
										</div>
									</div>
								</div>
								<ele-chart v-if="show" class="wi181" :option="ope1"></ele-chart>
								<p class="info_title">{{$t('main.home.service.t4')}}</p>
								<p class="info_subtitle">GAMING PROVIDER PARTNERS</p>
							</li>
						</ul>
					</div>
					<div class="info_type_warp">
						<ul class="info_type_list">
							<li class="info_type_item">

								<my-img srcText="home/service/icon3.png" />
								<div class="info_content_warp">
									<p class="info_title">{{$t('main.home.service.de1.name')}}</p>
									<p class="info_desc">{{$t('main.home.service.de1.des')}}
									</p>
								</div>
							</li>
							<li class="info_type_item">
								<my-img srcText="home/service/icon4.png" />
								<div class="info_content_warp">
									<p class="info_title">{{$t('main.home.service.de2.name')}}</p>
									<p class="info_desc">{{$t('main.home.service.de2.des')}}</p>
								</div>
							</li>
							<li class="info_type_item">
								<my-img srcText="home/service/icon5.png" />
								<div class="info_content_warp">
									<p class="info_title">{{$t('main.home.service.de3.name')}}</p>
									<p class="info_desc">
										{{$t('main.home.service.de3.des')}}
									</p>
								</div>
							</li>
							<li class="info_type_item">
								<my-img srcText="home/service/icon6.png" />
								<div class="info_content_warp">
									<p class="info_title">{{$t('main.home.service.de4.name')}}</p>
									<p class="info_desc">{{$t('main.home.service.de4.des')}}
										</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

</script>

<script>
	import echarts from "echarts";
	import EleChart from "@/components/EleChart";
	import countTo from "vue-count-to"

	export default {
		name: "Home",
		components: {
			EleChart,
			countTo
		},
		data() {
			return {
				ope1: {
					angleAxis: {
						axisLine: {
							show: false,
						},
						axisLabel: {
							show: false,
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						min: 0,
						max: 120,
						// boundaryGap: ['0', '10'],
						startAngle: 225,
					},
					radiusAxis: {
						type: 'category',
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: {
							show: false,
						},
						data: ['a', 'b', 'c'],
						z: 10,
					},
					polar: {
						radius: '105%',
					},
					series: [{
						type: 'bar',
						data: [, , 40],
						z: 1,
						coordinateSystem: 'polar',
						barMaxWidth: 8.2,
						name: '警告事件',
						roundCap: true,
						color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
								offset: 0,
								color: '#468aff',
							},
							{
								offset: 1,
								color: '#78bbff',
							},
						]),
						barGap: '-100%',
						animationDuration: 3000,
					}, ],
					tooltip: {
						show: false,
					},
				}
				,
				show: false,
				
				lastPersonNum: 0,
				nextPersonNum: 0,
				nextPersonNum1: 0,
				nextPersonNum2: 0,
				nextPersonNum3: 0,
			};
		},
		methods: {

		},
		mounted() {
			let _this = this
			document.addEventListener('scroll', (e)=>{
			  if (document.documentElement.scrollTop > 2100) {
				_this.show = true
				_this.nextPersonNum = 60
				_this.nextPersonNum1 = 80
				_this.nextPersonNum2 = 90
				_this.nextPersonNum3 = 27
			  } 
			})
			if (document.documentElement.scrollTop > 2100) {
							_this.show = true
							_this.nextPersonNum = 60
							_this.nextPersonNum1 = 80
							_this.nextPersonNum2 = 90
							_this.nextPersonNum3 = 27
			} 
		},
		destroyed() {

		},
	};
</script>

<style lang="less" scoped>
	.wi181 {
		       width: 141px;
		       height: 141px;
		       position: absolute !important;
		       top: 21px;
		       left: 86px;
		       transform: rotate(6deg);
	}
	.homeSectionTransparent_homeSection_container__36mC1 {
		position: relative;
		width: 100%;
		max-width: 1200px
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_tab_margin2__Kb34p {
		margin-top: 48px
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_header_warp__FaXhu {
		text-align: center
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_header_warp__FaXhu .homeSectionTransparent_title_en {
		position: relative;
		top: -10px
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_header_box__3w8NV.homeSectionTransparent_spring_skin__3hcYn .homeSectionTransparent_header_warp__FaXhu .homeSectionTransparent_title_en {
		display: none
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_header_box__3w8NV.homeSectionTransparent_spring_skin__3hcYn .homeSectionTransparent_header_warp__FaXhu .homeSectionTransparent_spring_title_en__2jzJU {
		display: inline;
		display: initial
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_header_box__3w8NV.homeSectionTransparent_spring_skin__3hcYn .homeSectionTransparent_header_warp__FaXhu .homeSectionTransparent_title_ch__IaZtW {
		-webkit-justify-content: space-between;
		justify-content: space-between
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_header_box__3w8NV.homeSectionTransparent_spring_skin__3hcYn .homeSectionTransparent_header_warp__FaXhu .homeSectionTransparent_title_ch__IaZtW p {
		color: #d11311
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_header_box__3w8NV.homeSectionTransparent_spring_skin__3hcYn .homeSectionTransparent_header_warp__FaXhu .homeSectionTransparent_title_ch__IaZtW span {
		-webkit-flex-grow: 0;
		flex-grow: 0
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_header_box__3w8NV.homeSectionTransparent_spring_skin__3hcYn .homeSectionTransparent_header_warp__FaXhu .homeSectionTransparent_title_ch__IaZtW span:first-child {
		height: 36px;
		width: 471px;
		left: 0;
		top: 35px;
		// background: url(/_next/static/media/title-left.9e33bcf72a79be97666566e8966bc9f9.png) no-repeat
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_header_box__3w8NV.homeSectionTransparent_spring_skin__3hcYn .homeSectionTransparent_header_warp__FaXhu .homeSectionTransparent_title_ch__IaZtW span:nth-child(3) {
		height: 36px;
		width: 471px;
		right: 0;
		top: 35px;
		// background: url(/_next/static/media/title-right.1b0be7afc6ed380bd41546d8bc5bff6a.png) no-repeat
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_header_box__3w8NV.homeSectionTransparent_spring_skin__3hcYn .homeSectionTransparent_header_warp__FaXhu .homeSectionTransparent_title_desc__3FbdY {
		color: #d11311
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_spring_title_en__2jzJU {
		display: none
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_title_ch__IaZtW {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: flex-start;
		align-content: flex-start;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
		-webkit-align-items: center;
		align-items: center;
		height: 40px
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_title_ch__IaZtW span:nth-child(odd) {
		height: 21px;
		background-size: cover;
		-webkit-flex-grow: 1;
		flex-grow: 1
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_title_ch__IaZtW span:first-child {
		left: 0;
		// background: url(/_next/static/media/titleset-left@2x.d36f3a8f4e5149d1f8e4347ac6121ed2.png) no-repeat;
		background-position: 100%
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_title_ch__IaZtW span:nth-child(3) {
		right: 0;
		// background: url(/_next/static/media/titleset-right@2x.1eabbce4c789d786d5168193276ede13.png) no-repeat;
		background-position: 0
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_title_ch__IaZtW .homeSectionTransparent_appdown__DNfTZ {
		-webkit-flex-grow: 0;
		flex-grow: 0;
		font-size: 40px;
		color: #6a7391;
		background: -webkit-linear-gradient(top, #62b3ff 4%, #3d72fa 109%);
		background: linear-gradient(180deg, #62b3ff 4%, #3d72fa 109%);
		color: transparent;
		font-family: DinProBold, PingFang SC, SF Pro SC, SF Pro Text, Microsoft Yahei, Helvetica Neue, Helvetica, Arial, sans-serif !important;
		padding: 0 20px;
		font-weight: 700
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_title_desc__3FbdY {
		margin-top: 10px;
		color: #6c7ba8;
		font-size: 14px;
		font-family: MicrosoftYaHei
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_homeSection_content_wrap__2NQ6y {
		overflow: visible;
		position: relative
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_homeSection_content_wrap__2NQ6y .homeSectionTransparent_angelImg__2lqPG {
		position: absolute;
		width: 160px;
		height: 149px
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_homeSection_content_wrap__2NQ6y .homeSectionTransparent_left_top__2eR_v {
		top: 0;
		left: 0
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_homeSection_content_wrap__2NQ6y .homeSectionTransparent_right_top__3ptaf {
		top: 0;
		right: 0
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_homeSection_content_wrap__2NQ6y .homeSectionTransparent_left_bottom__1zbW4 {
		left: 0;
		bottom: 0
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_homeSection_content_wrap__2NQ6y .homeSectionTransparent_bottom_right__1dRXo {
		right: 0;
		bottom: 0
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_homeSection_content {
		position: relative;
		width: 100%;
		box-sizing: border-box
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_homeSection_content .homeSectionTransparent_active_title__3glLM {
		width: 560px;
		height: 44px;
		position: absolute;
		top: -89px;
		left: 126px;
		// background: url(/_next/static/media/title-box-bg@2x.55baae2af6695e519e18b86db766b8ed.png) no-repeat;
		background-size: 100% 100%;
		font-size: 20px;
		color: #fff;
		text-align: center;
		line-height: 44px;
		font-weight: 700;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
		padding: 0 30px
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_homeSection_content .homeSectionTransparent_content_container {
		position: relative;
		width: 100%;
		height: 100%
	}

	.homeSectionTransparent_homeSection_container__36mC1 .homeSectionTransparent_homeSection_content .homeSectionTransparent_sponsor_info_title__1MVhM {
		width: 190px;
		height: 68px;
		position: absolute;
		top: -38px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		font-size: 22px;
		text-align: center;
		line-height: 54px;
		font-weight: 700;
		color: #fff;
		// background: url(/_next/static/media/wodejiangli-bj.73f46461be277048560d72df0d410a2d.png) no-repeat;
		background-size: 100% 100%
	}

	.homeSectionTransparent_homeSection_container__36mC1.homeSectionTransparent_is_petty__2o9hH .homeSectionTransparent_title_en {
		position: relative;
		top: 0;
		width: auto;
		height: 28px;
		margin-bottom: 12px
	}

	.homeSectionTransparent_homeSection_container__36mC1.homeSectionTransparent_is_petty__2o9hH .homeSectionTransparent_title_ch__IaZtW {
		margin-bottom: 8px
	}

	.homeSectionTransparent_homeSection_container__36mC1.homeSectionTransparent_is_petty__2o9hH .homeSectionTransparent_title_ch__IaZtW span:nth-child(2) {
		padding: 0 20px
	}

	.homeSectionTransparent_homeSection_container__36mC1.homeSectionTransparent_is_petty__2o9hH .homeSectionTransparent_homeSection_content_wrap__2NQ6y {
		margin: 0
	}



	@-webkit-keyframes banner_fadeout__1HIly {
		0% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1
		}

		to {
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
			opacity: 0
		}
	}

	@keyframes banner_fadeout__1HIly {
		0% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1
		}

		to {
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
			opacity: 0
		}
	}

	@-webkit-keyframes banner_fadeIn__fUESK {
		0% {
			-webkit-transform: scale(.8);
			transform: scale(.8);
			opacity: 0
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1
		}
	}

	@keyframes banner_fadeIn__fUESK {
		0% {
			-webkit-transform: scale(.8);
			transform: scale(.8);
			opacity: 0
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1
		}
	}

	.marguee_YBMarquee__m84nr {
		width: 100%;
		height: 100%;
		position: relative
	}

	.marguee_YBMarquee__m84nr .marguee_wrapper__1q--K {
		width: 1030px;
		margin-left: 56px;
		height: 100%;
		position: absolute;
		top: 0;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center
	}

	.marguee_YBMarquee__m84nr .marguee_marqueeIcon__2A6fG {
		height: 100%;
		position: relative;
		z-index: 1;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center
	}

	.marguee_YBMarquee__m84nr .marguee_marqueeIcon__2A6fG img {
		margin-top: 5px !important
	}

	.marguee_YBMarquee__m84nr .marguee_content__1OjsN {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden
	}

	.marguee_YBMarquee__m84nr .marguee_item__1irq- {
		float: left;
		height: 100%;
		line-height: 40px;
		white-space: nowrap
	}

	.marguee_YBMarquee__m84nr .marguee_txt__2s3Oy {
		padding-right: 150px;
		font-family: FZLTHK-GBK1-0;
		white-space: nowrap;
		display: inline-block;
		height: 100%;
		letter-spacing: -.35px;
		color: #9da5bb;
		cursor: pointer;
		font-size: 16px;
		letter-spacing: -.4px !important;
		color: #6c7ba8 !important
	}

	.marguee_YBMarquee__m84nr .marguee_txt__2s3Oy div {
		padding-right: 150px
	}

	.notice_homepageMgWarp__3Lngq {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		width: 100%;
		height: 40px;
		padding: 0 10px 0 18px;
		margin-top: 31px;
		border-radius: 20px;
		background-image: -webkit-linear-gradient(top, #fff 14%, #ecefff 74%, #fefeff 98%);
		background-image: linear-gradient(180deg, #fff 14%, #ecefff 74%, #fefeff 98%);
		box-shadow: 0 6px 12px 0 rgba(216, 226, 255, .9)
	}

	.notice_homepageMgWarp__3Lngq .notice_noticeIcon__38l6G {
		position: relative;
		width: 53px;
		height: 63px;
		top: 4px
	}

	.notice_homepageMgWarp__3Lngq .notice_maskBarBox__2JTuo {
		width: 100%;
		box-sizing: border-box;
		margin: auto;
		text-align: left;
		display: -webkit-flex;
		display: flex
	}

	.notice_homepageMgWarp__3Lngq .notice_maskBarBox__2JTuo>img {
		width: 16px;
		height: 16px;
		margin: auto 14px auto 0
	}

	.notice_homepageMgWarp__3Lngq .notice_maskBarBox__2JTuo .notice_inner__14_eb {
		width: 100%;
		overflow: hidden
	}

	.notice_homepageMgWarp__3Lngq .notice_maskBarBox__2JTuo .notice_inner__14_eb:hover p {
		-webkit-animation-play-state: paused;
		animation-play-state: paused
	}

	.notice_homepageMgWarp__3Lngq .notice_maskBarBox__2JTuo .notice_inner__14_eb>p {
		width: 100%;
		display: inline-block;
		-webkit-animation-name: notice_marquee__cegcT;
		animation-name: notice_marquee__cegcT;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		height: 40px;
		white-space: nowrap
	}

	@-webkit-keyframes notice_marquee__cegcT {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		to {
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%)
		}
	}

	@keyframes notice_marquee__cegcT {
		0% {
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		to {
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%)
		}
	}

	.notice_homepageMgWarp__3Lngq .notice_maskBarBox__2JTuo .notice_inner__14_eb>p span {
		color: #9da5bb;
		font-size: 14px;
		margin-right: 100px;
		-webkit-user-select: none;
		user-select: none;
		cursor: pointer
	}

	.notice_homepageMgWarp__3Lngq .notice_wrapHomepageMore__2Kz9r .notice_homepageMore__UyqJX {
		width: 75px;
		height: 26px;
		line-height: 26px;
		margin-left: 10px;
		font-size: 14px;
		text-align: center;
		border: 1px solid #4176fa;
		border-radius: 13px;
		color: #4176fa;
		cursor: pointer;
		-webkit-transition: all .2s;
		transition: all .2s
	}

	.notice_homepageMgWarp__3Lngq .notice_wrapHomepageMore__2Kz9r .notice_homepageMore__UyqJX:hover {
		background: #4176fa;
		color: #fff
	}

	.sponsor_sponsor_container__3Qaxw {
		position: relative;
		margin-top: 40px;
		margin-bottom: 45px
	}

	.sponsor_sponsor_container__3Qaxw .sponsor_sponsor_list__vf9eV {
		position: relative;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between
	}

	.sponsor_sponsor_container__3Qaxw .sponsor_sponsor_list__vf9eV .sponsor_sponsor_item__3OmHO {
		position: relative;
		width: 384px;
		height: 136px;
		margin-top: 18px;
		overflow: visible;
		text-align: center;
		-webkit-transition: all .3s;
		transition: all .3s;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
		cursor: pointer;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		box-shadow: 0 20px 30px -10px #cdd2dd;
		border-radius: 12px
	}

	.sponsor_sponsor_container__3Qaxw .sponsor_sponsor_list__vf9eV .sponsor_sponsor_item__3OmHO:hover {
		-webkit-transform: scale(1.05) translateZ(0);
		transform: scale(1.05) translateZ(0);
		-webkit-transition: all .1s;
		transition: all .1s
	}

	.sponsor_sponsor_container__3Qaxw .sponsor_sponsor_list__vf9eV .sponsor_sponsor_item__3OmHO .sponsor_sponsorLogo__2O9ts {
		width: 50px;
		height: 50px;
		top: 16px;
		left: 40px
	}

	.sponsor_sponsor_container__3Qaxw .sponsor_sponsor_list__vf9eV .sponsor_sponsor_item__3OmHO .sponsor_sponsorInfo__2SN7V {
		width: auto;
		height: 46px;
		font-size: 16px;
		text-align: left;
		position: absolute;
		left: 40px;
		top: 74px
	}

	.sponsor_sponsor_container__3Qaxw .sponsor_sponsor_list__vf9eV .sponsor_sponsor_item__3OmHO .sponsor_sponsorInfo__2SN7V h2 {
		height: 22px;
		line-height: 22px;
		color: #444c64;
		margin-bottom: 0
	}

	.sponsor_sponsor_container__3Qaxw .sponsor_sponsor_list__vf9eV .sponsor_sponsor_item__3OmHO .sponsor_sponsorInfo__2SN7V p {
		height: 22px;
		font-size: 14px;
		line-height: 22px;
		color: #6c7ba8;
		margin-top: 4px
	}

	.sponsor_sponsor_container__3Qaxw .sponsor_sponsor_list__vf9eV .sponsor_sponsor_item__3OmHO .sponsor_sponsorModel__18PMQ {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 240px;
		height: 156px
	}

	.appLinker_container__22MR8 {
		-webkit-flex-shrink: 0;
		flex-shrink: 0;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center
	}

	.appLinker_container__22MR8:last-child {
		margin-right: 0
	}

	.appLinker_container__22MR8 .appLinker_frameClassName__3NWV_ {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		width: 168px;
		height: 168px;
		border-radius: 12px;
		background: -webkit-linear-gradient(224.91deg, #dbe7ff, #ebf0f5 31.82%, #eef4ff 63.9%, #ebf0f5 97.47%);
		background: linear-gradient(225.09deg, #dbe7ff, #ebf0f5 31.82%, #eef4ff 63.9%, #ebf0f5 97.47%);
		border: 2px solid #b4d3fd;
		padding: 12px 23px
	}

	.appLinker_container__22MR8 .appLinker_frameClassName__3NWV_ ul {
		height: 100%;
		width: 100%;
		box-sizing: border-box;
		overflow-y: auto;
		overflow: hidden
	}

	.appLinker_container__22MR8 .appLinker_frameClassName__3NWV_ ul li {
		text-align: center;
		height: 28px
	}

	.appLinker_container__22MR8 .appLinker_frameClassName__3NWV_ ul li span {
		max-width: 100%;
		display: inline-block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		height: 28px;
		text-align: center;
		line-height: 28px;
		text-decoration: underline;
		color: #4b98ee;
		cursor: pointer
	}

	.appLinker_container__22MR8 .appLinker_frameClassName__3NWV_ ul li span:hover {
		-webkit-transition: all .2s;
		transition: all .2s;
		color: #6a7391
	}

	.appLinker_container__22MR8 .appLinker_fir_text__3LqMO {
		color: #6c7ba8;
		margin-top: 10px;
		font-size: 18px;
		font-weight: 700;
		line-height: 25px
	}

	.appLinker_container__22MR8 .appLinker_sen_text__1LPhk {
		color: #6c7ba8;
		margin-top: 1px;
		font-size: 14px;
		line-height: 22px
	}

	.homeTabs_hometabs_container__G37AZ {
		position: relative;
		display: -webkit-flex;
		display: flex
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY {
		position: relative;
		font-size: 18px
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY:after {
		content: "";
		display: block;
		clear: both
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY li {
		float: left;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		width: 120px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background-size: cover;
		border-radius: 30.6px;
		box-shadow: 0 4px 8px 0 rgba(194, 211, 222, .5);
		cursor: pointer;
		margin-right: 12px
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY li:last-child {
		margin-right: 0
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY li .homeTabs_icon__25UZ9 {
		display: block;
		width: 28px;
		height: 28px;
		background-size: 606px 44px;
		margin-right: 14px
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY li span {
		font-size: 16px;
		font-weight: 500
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_item__1iDmv {
		color: #6c7ba8;
		// background: url(/_next/static/media/home-button-normal-bg@2x.27b016ffd73180a8fcffe44362660b7c.png) no-repeat;
		background-size: 100% 100%;
		position: relative
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_hover_item__jGvGe {
		position: relative
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_hover_item__jGvGe:after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 2;
		border-radius: 30.6px;
		background-color: hsla(0, 0%, 100%, .2)
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_active_item__1Iucm {
		background-image: none !important;
		position: relative;
		z-index: 1;
		color: #fff
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_active_item__1Iucm:before {
		content: "";
		z-index: -1;
		top: 0;
		display: block;
		width: 120px;
		height: 40px;
		position: absolute;
		left: 50%;
		-webkit-transform: translate3d(-50%, 0, 0);
		transform: translate3d(-50%, 0, 0);
		// background: url(/_next/static/media/home-button-selected-bg@2x.e7e20a3c631500cc0fbb948f992c1c1f.png) no-repeat 0 0/cover;
		box-shadow: 0 2px 4px 0 rgba(93, 167, 235, .8);
		border-radius: 30.6px;
		-webkit-animation: homeTabs_diamondBtnAnimation__x8T3M .35s ease-out forwards;
		animation: homeTabs_diamondBtnAnimation__x8T3M .35s ease-out forwards
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_active_item__1Iucm * {
		position: relative
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_active_item__1Iucm .homeTabs_icon__25UZ9 {
		width: 44px;
		height: 44px;
		margin-right: 4px;
		position: relative;
		top: 4px
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_active_item__1Iucm span {
		font-weight: 700
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_leave_out__FWV13 .homeTabs_icon__25UZ9 {
		width: 44px;
		height: 44px;
		margin-right: 4px;
		position: relative;
		top: 4px
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_active_item__1Iucm {
		box-shadow: 0 2px 4px 0 rgba(93, 167, 235, .8)
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_active_item__1Iucm.homeTabs_icon_animation_in__3nE7N .homeTabs_icon__25UZ9 {
		-webkit-animation: homeTabs_iconAnimationIn__2n8u1 .35s ease-out;
		animation: homeTabs_iconAnimationIn__2n8u1 .35s ease-out
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_btnHoverStatus__3LCAR {
		-webkit-transition: all .2s;
		transition: all .2s
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_icon_animation_out__1sZ4h {
		// background: url(/_next/static/media/home-button-normal-bg@2x.27b016ffd73180a8fcffe44362660b7c.png) no-repeat;
		background-size: 100% 100%;
		position: relative;
		z-index: 1;
		color: #fff
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_icon_animation_out__1sZ4h * {
		position: relative
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_icon_animation_out__1sZ4h:before {
		content: "";
		top: 0;
		display: block;
		width: 120px;
		height: 40px;
		position: absolute;
		left: 50%;
		-webkit-transform: translate3d(-50%, 0, 0);
		transform: translate3d(-50%, 0, 0);
		// background: url(/_next/static/media/home-button-selected-bg@2x.e7e20a3c631500cc0fbb948f992c1c1f.png) no-repeat 0 0/cover;
		box-shadow: 0 2px 4px 0 rgba(93, 167, 235, .8);
		border-radius: 30.6px;
		z-index: -1;
		-webkit-animation: homeTabs_diamondBtnAnimationLe__1grkp .35s ease-out forwards;
		animation: homeTabs_diamondBtnAnimationLe__1grkp .35s ease-out forwards
	}

	.homeTabs_hometabs_container__G37AZ .homeTabs_list__2eqlY .homeTabs_icon_animation_out__1sZ4h .homeTabs_icon__25UZ9 {
		-webkit-animation: homeTabs_iconAnimationOut__aoF7z .35s ease-out forwards;
		animation: homeTabs_iconAnimationOut__aoF7z .35s ease-out forwards
	}

	.homeTabs_hometabs_container_righth__1rf8a {
		margin-top: 26px;
		margin-bottom: 54px;
		-webkit-justify-content: flex-end;
		justify-content: flex-end
	}

	.homeTabs_hometabs_container_center__3xZWy {
		-webkit-justify-content: center;
		justify-content: center;
		margin: 35px 4px 0 16px
	}

	@-webkit-keyframes homeTabs_diamondBtnAnimation__x8T3M {
		0% {
			opacity: .25
		}

		to {
			opacity: 1
		}
	}

	@keyframes homeTabs_diamondBtnAnimation__x8T3M {
		0% {
			opacity: .25
		}

		to {
			opacity: 1
		}
	}

	@-webkit-keyframes homeTabs_diamondBtnAnimationLe__1grkp {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	@keyframes homeTabs_diamondBtnAnimationLe__1grkp {
		0% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	@-webkit-keyframes homeTabs_iconAnimationIn__2n8u1 {
		0% {
			-webkit-transform: scale(1.25);
			transform: scale(1.25)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	@keyframes homeTabs_iconAnimationIn__2n8u1 {
		0% {
			-webkit-transform: scale(1.25);
			transform: scale(1.25)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	@-webkit-keyframes homeTabs_iconAnimationOut__aoF7z {
		0% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1
		}

		to {
			-webkit-transform: scale(.75);
			transform: scale(.75);
			opacity: 0
		}
	}

	@keyframes homeTabs_iconAnimationOut__aoF7z {
		0% {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1
		}

		to {
			-webkit-transform: scale(.75);
			transform: scale(.75);
			opacity: 0
		}
	}

	.app-download_downtemplate_container__2Yd53 {
		position: relative;
		margin-top: 32px
	}

	.app-download_downtemplate_container__2Yd53 .app-download_item_wrap__36YdT {
		width: 100%;
		height: 100%
	}

	.app-download_downtemplate_container__2Yd53 .app-download_tabs__2HnX0 {
		margin: 36px 36px 0;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-around;
		justify-content: space-around;
		-webkit-align-items: center;
		align-items: center
	}

	.app-download_downtemplate_container__2Yd53 .app-download_left_warp__39p8J {
		position: relative;
		width: 880px;
		height: 620px;
		z-index: 0
	}

	.app-download_downtemplate_container__2Yd53 .app-download_left_warp__39p8J>div {
		width: 100%;
		margin-left: -85px;
		pointer-events: none
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_card__1QrDB {
		width: 456px;
		min-width: 456px;
		height: 580px;
		position: absolute;
		z-index: 1;
		background-size: cover;
		top: 0;
		right: 0;
		border-radius: 24px;
		box-shadow: 0 20px 30px -10px #cdd2dd
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_warp__10Ttp {
		width: 456px;
		min-width: 456px;
		height: 504px;
		position: absolute;
		z-index: 2;
		top: 76px;
		right: 0;
		overflow: hidden
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_warp__10Ttp .app-download_appInfo__1ArvB.app-download_animationIn__2FiV1 {
		-webkit-animation: app-download_animationIn__2FiV1 .3s ease-in forwards;
		animation: app-download_animationIn__2FiV1 .3s ease-in forwards
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_warp__10Ttp .app-download_appInfo__1ArvB .app-download_title__2T3fI {
		height: 40px;
		line-height: 40px;
		margin-top: 44px;
		margin-left: 36px;
		margin-bottom: 0;
		font-size: 40px;
		color: #6c7ba8;
		font-weight: 700;
		font-family: Microsoft Yahei !important
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_warp__10Ttp .app-download_appInfo__1ArvB .app-download_desciption__1C-uO {
		line-height: 1.56;
		margin-top: 12px;
		font-size: 18px;
		color: #6c7ba8;
		text-align: left;
		width: 384px;
		margin-left: 33px;
		min-height: 112px
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_warp__10Ttp .app-download_bottom_warp__2Bstt {
		position: relative;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		margin-top: 40px;
		padding-right: 40px
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_warp__10Ttp .app-download_bottom_warp__2Bstt .app-download_qrcode_warp__3xw0M {
		text-align: center;
		margin-left: 33px;
		margin-right: 47px
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_warp__10Ttp .app-download_bottom_warp__2Bstt .app-download_qrcode_warp__3xw0M .app-download_qrcode_box__oonH7 {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		width: 168px;
		height: 168px;
		border-radius: 12px;
		border: 2px solid #fff;
		background-image: -webkit-linear-gradient(top, #f7fafc 1%, #ebf0f5);
		background-image: linear-gradient(180deg, #f7fafc 1%, #ebf0f5)
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_warp__10Ttp .app-download_bottom_warp__2Bstt .app-download_qrcode_warp__3xw0M .app-download_qrcode_box__oonH7 .app-download_qrcode__2ttzs {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		width: 128px;
		height: 130px;
		// background: url(/_next/static/media/image-image-frame-qrcode-9@2x.be59edc07c453bd87bccfa166a60a90a.png) no-repeat;
		background-size: cover
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_warp__10Ttp .app-download_bottom_warp__2Bstt .app-download_qrcode_warp__3xw0M p {
		color: #6c7ba8
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_warp__10Ttp .app-download_bottom_warp__2Bstt .app-download_qrcode_warp__3xw0M .app-download_fir_text__37P0c {
		margin-top: 10px;
		font-size: 18px;
		font-weight: 700;
		line-height: 25px
	}

	.app-download_downtemplate_container__2Yd53 .app-download_right_warp__10Ttp .app-download_bottom_warp__2Bstt .app-download_qrcode_warp__3xw0M .app-download_sen_text__2l-8j {
		margin-top: 1px;
		font-size: 14px;
		line-height: 22px
	}

	.app-download_downtemplate_container__2Yd53 .app-download_mainAnimation__3WA3t {
		-webkit-animation: app-download_animationMain__2YnNL .48s cubic-bezier(.17, .17, .67, 1);
		animation: app-download_animationMain__2YnNL .48s cubic-bezier(.17, .17, .67, 1)
	}

	.app-download_downtemplate_container__2Yd53 .app-download_infoAnimation__2g4UT {
		-webkit-animation: app-download_animationInfo__Cc_7b .48s ease-in-out;
		animation: app-download_animationInfo__Cc_7b .48s ease-in-out
	}

	.app-download_downtemplate_container__2Yd53 .app-download_qrAnimation__3giCI {
		-webkit-animation: app-download_animationQr__2t8mC .6s cubic-bezier(.2, .2, .1, .8);
		animation: app-download_animationQr__2t8mC .6s cubic-bezier(.2, .2, .1, .8)
	}

	@-webkit-keyframes app-download_animationMain__2YnNL {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		50% {
			opacity: 0;
			-webkit-transform: translateX(-60px);
			transform: translateX(-60px)
		}

		55% {
			-webkit-transform: translateX(70px);
			transform: translateX(70px)
		}

		to {
			opacity: 1
		}
	}

	@keyframes app-download_animationMain__2YnNL {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		50% {
			opacity: 0;
			-webkit-transform: translateX(-60px);
			transform: translateX(-60px)
		}

		55% {
			-webkit-transform: translateX(70px);
			transform: translateX(70px)
		}

		to {
			opacity: 1
		}
	}

	@-webkit-keyframes app-download_animationInfo__Cc_7b {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		40% {
			opacity: 0;
			-webkit-transform: translateX(-30px);
			transform: translateX(-30px)
		}

		50% {
			-webkit-transform: translateX(50px);
			transform: translateX(50px)
		}

		to {
			opacity: 1
		}
	}

	@keyframes app-download_animationInfo__Cc_7b {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		40% {
			opacity: 0;
			-webkit-transform: translateX(-30px);
			transform: translateX(-30px)
		}

		50% {
			-webkit-transform: translateX(50px);
			transform: translateX(50px)
		}

		to {
			opacity: 1
		}
	}

	@-webkit-keyframes app-download_animationQr__2t8mC {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		31% {
			opacity: 0;
			-webkit-transform: translateX(-20px);
			transform: translateX(-20px)
		}

		60% {
			opacity: 0;
			-webkit-transform: translateX(40px);
			transform: translateX(40px)
		}

		to {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@keyframes app-download_animationQr__2t8mC {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		31% {
			opacity: 0;
			-webkit-transform: translateX(-20px);
			transform: translateX(-20px)
		}

		60% {
			opacity: 0;
			-webkit-transform: translateX(40px);
			transform: translateX(40px)
		}

		to {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	.hotTemplate_hottemplate_container__Cw-fe {
		position: relative;
		box-sizing: border-box;
		padding-top: 48px
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_tabs__8riUK {
		margin: 0
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_item_wrap__2byHT {
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		margin-top: 8px;
		margin-bottom: 20px
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_item_wrap__2byHT .hotTemplate_left_warp__1lWTH {
		position: relative;
		z-index: 3
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_item_wrap__2byHT .hotTemplate_left_warp__1lWTH .hotTemplate_left_warp_img__o8iQx {
		margin-left: -30px
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_item_wrap__2byHT .hotTemplate_right_warp__2qJcF {
		width: 700px;
		height: 460px;
		position: relative;
		margin-top: 35px;
		margin-left: -220px;
		font-family: MicrosoftYaHei !important;
		// background: url(/_next/static/media/common-card-background-game@2x.2b98fbda5e246f9b65d82cce708ea0bb.png);
		background-size: cover;
		z-index: 2;
		border-radius: 24px;
		box-shadow: 0 20px 30px -10px #cdd2dd
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 {
		position: absolute;
		left: 705px;
		bottom: 191px;
		z-index: 2
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_title_wrap__2u5i_ {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: flex-start;
		justify-content: flex-start
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_title_wrap__2u5i_ img {
		height: 116px;
		display: block
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_desciption__1sXt5 {
		width: 400px;
		min-height: 72px;
		line-height: 1.5;
		margin-top: 11px;
		font-size: 16px;
		color: #6c7ba8;
		text-align: justify;
		font-family: MicrosoftYaHei !important
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_bottom_warp__3vuZ- {
		margin-top: 21px
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_bottom_warp__3vuZ- .hotTemplate_electronic_list__3jBBf {
		position: relative;
		display: -webkit-flex;
		display: flex
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_bottom_warp__3vuZ- .hotTemplate_electronic_list__3jBBf .hotTemplate_electronic_item__KE-QJ {
		position: relative;
		cursor: pointer
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_bottom_warp__3vuZ- .hotTemplate_electronic_list__3jBBf .hotTemplate_electronic_item__KE-QJ .hotTemplate_img_box__3Dhzd {
		width: 58px;
		height: 58px;
		margin: 0 4px;
		// background: url(/_next/static/media/slotgame-tab-normal-bg@2x.dfd00f3b58c59576833124903f306c2d.png) no-repeat 0 0/cover;
		border-radius: 12.7px;
		box-shadow: 0 4px 8px 0 rgba(112, 146, 215, .14);
		position: relative
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_bottom_warp__3vuZ- .hotTemplate_electronic_list__3jBBf .hotTemplate_electronic_item__KE-QJ .hotTemplate_img_box__3Dhzd:hover {
		// background-image: url(/_next/static/media/slotgame-tab-selected-bg@2x.ad1946c3007f336a9926505b37b473f6.png);
		-webkit-animation: hotTemplate_iconAnimationIn__3fttE .5s ease forwards;
		animation: hotTemplate_iconAnimationIn__3fttE .5s ease forwards;
		box-shadow: 0 4px 8px 0 #b6bfdb
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_bottom_warp__3vuZ- .hotTemplate_electronic_list__3jBBf .hotTemplate_electronic_item__KE-QJ .hotTemplate_img_box__3Dhzd:hover .hotTemplate_iconActive__zCuLH {
		opacity: 1
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_bottom_warp__3vuZ- .hotTemplate_electronic_list__3jBBf .hotTemplate_electronic_item__KE-QJ .hotTemplate_img_box__3Dhzd .hotTemplate_icon__2fMkP,
	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_bottom_warp__3vuZ- .hotTemplate_electronic_list__3jBBf .hotTemplate_electronic_item__KE-QJ .hotTemplate_img_box__3Dhzd .hotTemplate_iconActive__zCuLH {
		width: 50px;
		height: 50px;
		display: inline-block;
		background-size: cover;
		position: absolute;
		left: 4px;
		top: 4px;
		opacity: 1;
		z-index: 1
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_bottom_warp__3vuZ- .hotTemplate_electronic_list__3jBBf .hotTemplate_electronic_item__KE-QJ .hotTemplate_img_box__3Dhzd .hotTemplate_iconActive__zCuLH {
		opacity: 0;
		z-index: 2
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_card_content__3R-W5 .hotTemplate_bottom_warp__3vuZ- .hotTemplate_electronic_list__3jBBf .hotTemplate_electronic_item__KE-QJ .hotTemplate_name__1nqUh {
		margin-top: 8px;
		height: 14px;
		font-size: 14px;
		font-weight: 500;
		color: #6c7ba8;
		text-align: center
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_mainAnimation__xDkoQ {
		-webkit-animation: hotTemplate_animationMain__2Z9Ms .48s cubic-bezier(.17, .17, .67, 1);
		animation: hotTemplate_animationMain__2Z9Ms .48s cubic-bezier(.17, .17, .67, 1)
	}

	.hotTemplate_hottemplate_container__Cw-fe .hotTemplate_cardAnimation__2VbkW {
		-webkit-animation: hotTemplate_animationCard__3N3NN .76s cubic-bezier(0, 0, .2, 1);
		animation: hotTemplate_animationCard__3N3NN .76s cubic-bezier(0, 0, .2, 1)
	}

	@-webkit-keyframes hotTemplate_iconAnimationIn__3fttE {
		0% {
			-webkit-transform: scale(1.1);
			transform: scale(1.1)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	@keyframes hotTemplate_iconAnimationIn__3fttE {
		0% {
			-webkit-transform: scale(1.1);
			transform: scale(1.1)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	@-webkit-keyframes hotTemplate_animationMain__2Z9Ms {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		50% {
			opacity: 0;
			-webkit-transform: translateX(-40px);
			transform: translateX(-40px)
		}

		51% {
			-webkit-transform: translateX(40px);
			transform: translateX(40px)
		}

		to {
			opacity: 1
		}
	}

	@keyframes hotTemplate_animationMain__2Z9Ms {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		50% {
			opacity: 0;
			-webkit-transform: translateX(-40px);
			transform: translateX(-40px)
		}

		51% {
			-webkit-transform: translateX(40px);
			transform: translateX(40px)
		}

		to {
			opacity: 1
		}
	}

	@-webkit-keyframes hotTemplate_animationCard__3N3NN {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		31% {
			opacity: 0;
			-webkit-transform: translateX(-40px);
			transform: translateX(-40px)
		}

		47% {
			opacity: 0;
			-webkit-transform: translateX(40px);
			transform: translateX(40px)
		}

		to {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	@keyframes hotTemplate_animationCard__3N3NN {
		0% {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}

		31% {
			opacity: 0;
			-webkit-transform: translateX(-40px);
			transform: translateX(-40px)
		}

		47% {
			opacity: 0;
			-webkit-transform: translateX(40px);
			transform: translateX(40px)
		}

		to {
			opacity: 1;
			-webkit-transform: translateX(0);
			transform: translateX(0)
		}
	}

	.info_servertemplate_container {
		position: relative;
		padding-top: 48px
	}

	.info_servertemplate_container .info_kind_warp {
		position: relative
	}

	.info_servertemplate_container .info_kind_warp .info_kind_list {
		display: -webkit-flex;
		display: flex
	}

	.info_servertemplate_container .info_kind_warp .info_kind_list .info_kind_item {
		width: 300px;
		height: 230px;
		text-align: center;
		position: relative;
	}

	.info_servertemplate_container .info_kind_warp .info_kind_list .info_kind_item .info_count_warp {
		position: relative;
		width: 160px;
		height: 137px;
		margin: 7px auto 0;
		// background: url(../../../../assets/home/service/icon1.png) no-repeat;
		background: url(/assets/home/service/icon1.png) no-repeat;
		background-size: cover
	}

	.info_servertemplate_container .info_kind_warp .info_kind_list .info_kind_item .info_count_warp .info_count_box {
		position: relative;
		text-align: center;
		padding-top: 48px
	}

	.info_servertemplate_container .info_kind_warp .info_kind_list .info_kind_item .info_count_warp .info_count_box>p {
		line-height: 1
	}

	.info_servertemplate_container .info_kind_warp .info_kind_list .info_kind_item .info_count_warp .info_count_box .info_countTitle {
		opacity: .5;
		font-family: DINPro;
		font-size: 12px;
		color: #6c7ba8;
		-webkit-transform: scale(.666);
		transform: scale(.666)
	}

	.info_servertemplate_container .info_kind_warp .info_kind_list .info_kind_item .info_count_warp .info_count_box .info_countNum {
		font-family: DINPro;
		font-size: 64px;
		font-weight: 700;
		color: #468aff;
		margin-top: -6px;
		margin-bottom: -2px
	}

	.info_servertemplate_container .info_kind_warp .info_kind_list .info_kind_item .info_count_warp .info_count_box .info_countUnit {
		font-size: 12px;
		font-weight: 700;
		color: #6c7ba8
	}

	.info_servertemplate_container .info_kind_warp .info_kind_list .info_kind_item .info_count_warp .info_progress_warp {
		position: absolute;
		top: 16px;
		left: 15px;
		-webkit-transform: rotate(46deg);
		transform: rotate(46deg)
	}

	.info_servertemplate_container .info_kind_warp .info_kind_list .info_kind_item .info_title {
		margin-top: 28px;
		margin-bottom: 2px;
		font-size: 18px;
		font-weight: 700;
		color: #444c64;
		line-height: 25px
	}

	.info_servertemplate_container .info_kind_warp .info_kind_list .info_kind_item .info_subtitle {
		line-height: 15px;
		font-size: 12px;
		color: #6c7ba8
	}

	.info_servertemplate_container .info_type_warp {
		position: relative;
		margin-top: 48px
	}

	.info_servertemplate_container .info_type_warp .info_type_list {
		position: relative;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-justify-content: space-between;
		justify-content: space-between
	}

	.info_servertemplate_container .info_type_warp .info_type_list .info_type_item {
		position: relative;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		width: 580px;
		height: 140px;
		border-radius: 12px;
		box-shadow: 0 20px 30px -10px #cdd2dd;
		// background: url(../../../../assets/home/service/icon2.png) no-repeat;
		background: url(/assets/home/service/icon2.png) no-repeat;
		background-size: cover;
		margin-bottom: 24px;
		padding: 26px 24px 0
	}

	.info_servertemplate_container .info_type_warp .info_type_list .info_type_item img {
		width: 88px;
		height: 88px;
		display: block;
		margin-top: 2px
	}

	.info_servertemplate_container .info_type_warp .info_type_list .info_type_item .info_content_warp {
		position: relative;
		width: 424px
	}

	.info_servertemplate_container .info_type_warp .info_type_list .info_type_item .info_content_warp .info_title {
		font-weight: 700;
		font-size: 18px;
		color: #444c64;
		line-height: 25px
	}

	.info_servertemplate_container .info_type_warp .info_type_list .info_type_item .info_content_warp .info_desc {
		line-height: 1.38;
		margin-top: 4px;
		font-size: 16px;
		color: #6c7ba8
	}

	.homeSectionWarpper_homeBg__1W7yX {
		width: 100%;
		min-width: 1200px;
		padding-bottom: 56px;
		background: #f0f6ff;
		overflow: hidden
	}

	.homeSectionWarpper_homeBg__1W7yX .homeSectionWarpper_homeContaner__ihe7a {
		position: relative;
		width: 1200px;
		margin: 0 auto
	}

	.homeSectionWarpper_homeBg__1W7yX .homeSectionWarpper_contentPublic__37khQ {
		position: relative;
		margin-top: 50px
	}

	.home_kokHomeBg__1BJ9a {
		background-color: #f0f6ff;
		overflow: hidden
	}

	.home_kokHomeBg__1BJ9a .home_bgContainer__3MTwF {
		background-repeat: repeat;
		background-position: top;
		background-size: 1920px 1772px
	}

	.home_modal__2Cf1r {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .3);
		position: fixed;
		left: 0;
		top: 0;
		overflow: hidden;
		z-index: 1300
	}

	.home_modal__2Cf1r .home_modal_main__1Nt1t {
		position: relative;
		width: 360px;
		min-height: 179px;
		border-radius: 4px;
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		z-index: 999;
		top: 50%;
		margin: -117px auto 0;
		background: #fff
	}

	.home_header__3vFZg {
		position: relative;
		width: 100%;
		height: 40px;
		line-height: 40px;
		border-radius: 4px 4px 0 0;
		background: -webkit-linear-gradient(left, #d2b496, #dccab8);
		background: linear-gradient(90deg, #d2b496, #dccab8);
		border-bottom: 1px solid #fff
	}

	.home_header__3vFZg .home_text__2GlVB {
		height: 40px;
		line-height: 40px;
		padding: 0 40px 0 16px;
		font-size: 18px;
		color: #fff
	}

	.home_header__3vFZg .home_close_icon__1I9tc {
		position: absolute;
		right: 16px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 16px;
		z-index: 1000;
		height: 16px;
		// background-image: url(/_next/static/media/icon_closed.b4ca8bb39ee6465dec25a35bfbdb1654.png);
		background-position: 50%;
		background-size: contain;
		cursor: pointer
	}

	.home_modal_content__HG8re {
		width: 100%;
		padding: 27px 15px 32px;
		box-sizing: border-box;
		background-color: #f0f6ff
	}

	.home_modal_content__HG8re .home_modal_statusIcon__3xFGd {
		width: 36px;
		height: 36px;
		margin: 0 auto 22px
	}

	.home_modal_content__HG8re .home_modal_statusIcon__3xFGd.home_statusSuccess__8v2Vp {
		// background-image: url(/_next/static/media/success_icon.e553accc13c9cdf40e3701126aac0efc.svg);
		background-position: 50%;
		background-size: contain
	}

	.home_modal_content__HG8re .home_modal_statusIcon__3xFGd.home_statusError__2vZm- {
		// background-image: url(/_next/static/media/icon_fail_modal.4dde7c52b6d598e5b4b30a7daa8a19ee.png);
		background-position: 50%;
		background-size: contain
	}

	.home_modal_content__HG8re .home_modal_statusIcon__3xFGd.home_statusInfo__u5g4- {
		// background-image: url(/_next/static/media/icon_info_modal.1342520c619dc807eabf1dbf373ce3da.png);
		background-position: 50%;
		background-size: contain
	}

	.home_modal_content__HG8re .home_messageBox__2GNke {
		text-align: center;
		color: #666;
		font-size: 14px;
		line-height: 18px;
		word-break: break-word
	}

	.home_modal_content__HG8re .home_messageBox__2GNke p {
		max-height: 150px;
		overflow-y: auto;
		text-align: left
	}

	.home_modal-content__ybkKj::-webkit-scrollbar {
		display: none
	}

	.home_modal_foot__1Xqz7 {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		width: 100%;
		padding-bottom: 24px;
		box-sizing: border-box;
		margin: 0 auto
	}

	.home_modal_foot__1Xqz7 button {
		width: 136px;
		height: 38px;
		line-height: 38px;
		border: none;
		border-radius: 20px;
		text-align: center;
		font-size: 14px;
		color: #fff
	}

	.home_modal_foot__1Xqz7 .home_btn1__28nrU {
		float: left;
		background-size: 100%;
		background: #fff;
		color: #666;
		border: 1px solid #e0e0e0
	}

	.home_modal_foot__1Xqz7 .home_btn2__1GlKL {
		float: right;
		background-size: 100%;
		margin-left: 16px;
		box-shadow: 0 4px 6px 4px rgba(219, 198, 177, .24)
	}

	.home_modal_foot__1Xqz7 .home_btn2__1GlKL,
	.home_modal_foot__1Xqz7 .home_btn2__1GlKL:hover {
		background: -webkit-linear-gradient(right, #d2b496, #dccab8);
		background: linear-gradient(270deg, #d2b496, #dccab8);
		color: #fff !important
	}

	.home_modal_foot__1Xqz7 .home_btn3__2tLDv {
		box-shadow: 0 4px 6px 4px rgba(219, 198, 177, .24)
	}

	.home_modal_foot__1Xqz7 .home_btn3__2tLDv,
	.home_modal_foot__1Xqz7 .home_btn3__2tLDv:hover {
		background: -webkit-linear-gradient(right, #d2b496, #dccab8);
		background: linear-gradient(270deg, #d2b496, #dccab8);
		color: #fff !important;
		color: #fff
	}

	.home_success_bgColor__1OJyk {
		background-color: #33abff
	}

	.home_error_bgColor__3t6NV {
		background-color: #ee5a5b
	}

	.home_modal_main__1Nt1t {
		-webkit-animation: home_Tada__3zA7C .2s both;
		animation: home_Tada__3zA7C .2s both;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
		-webkit-animation-direction: 0;
		animation-direction: 0
	}

	@-webkit-keyframes home_Tada__3zA7C {
		0% {
			-webkit-transform: scale(.7);
			transform: scale(.7)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	@keyframes home_Tada__3zA7C {
		0% {
			-webkit-transform: scale(.7);
			transform: scale(.7)
		}

		to {
			-webkit-transform: scale(1);
			transform: scale(1)
		}
	}

	.home_messageBox__2GNke {
		-webkit-flex: 1 1;
		flex: 1 1
	}

	.home_modal_HomeAreat__1XoVk {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 0;
		width: 612px;
		height: 370px;
		border-radius: 4px;
		border-radius: .16rem;
		background-size: 100% auto
	}

	.home_modal_HomeAreat__1XoVk,
	.home_modal_HomeAreat_model__1YjyA {
		box-sizing: border-box;
		position: relative;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		z-index: 1000;
		top: 40%;
		margin: -184px auto 0
	}

	.home_modal_HomeAreat_model__1YjyA {
		width: 490px;
		height: 209px;
		border-radius: 8px
	}

	.home_alert_dialog__1b-bn {
		width: 612px;
		height: 370px;
		border: none;
		position: absolute;
		box-shadow: none
	}

	.home_alert_dialog__1b-bn ::-webkit-scrollbar {
		width: 6px !important
	}

	.home_alert_dialog__1b-bn ::-webkit-scrollbar-track {
		border-radius: 3px
	}

	.home_alert_dialog__1b-bn ::-webkit-scrollbar-thumb {
		border-radius: 3px;
		background: #eee
	}

	.home_alert_dialog__1b-bn ::-webkit-scrollbar-thumb:window-inactive {
		background: #eee
	}

	.home_alert_dialog__1b-bn .home_el-dialog__close__3LvXi {
		display: none
	}

	.home_alert_dialog__1b-bn .home_el-dialog__body__IgJDK {
		padding: 15px 37px
	}

	.home_alert_dialog__1b-bn .home_el-dialog__header__2e-wr {
		text-align: center
	}

	.home_alert_dialog__1b-bn .home_el-dialog__header__2e-wr .home_el-dialog__title__3f_Id {
		font-size: 18px;
		color: #fff;
		display: block
	}

	.home_alert_dialog__1b-bn .home_button__1w5v8 {
		width: 106px;
		height: 30px;
		line-height: 5px;
		margin-top: 10px;
		margin-left: 220px;
		color: #fff;
		background-color: #409eff;
		border: #409eff;
		-webkit-transition: .1s;
		transition: .1s;
		padding: 12px 20px;
		float: left;
		font-size: 14px;
		border-radius: 4px
	}

	.home_alert_dialog__1b-bn .home_button__1w5v8:hover {
		background-color: #5dc2ff
	}

	.home_dialog_header__rvYbq {
		text-align: center;
		margin-top: 18px;
		position: relative;
		height: 46px;
		line-height: 46px;
		top: 0
	}

	.home_dialog_header__rvYbq span {
		font-size: 18px;
		color: #fff;
		display: block;
		margin-top: 8px
	}

	.home_bottom__1vpWv {
		margin-left: 40px;
		float: left;
		height: 50px;
		cursor: pointer;
		line-height: 50px
	}

	.home_bottom__1vpWv #home_checkBox__2KQ-E {
		width: 19px;
		height: 19px;
		border: #3db5ff;
		float: left
	}

	.home_bottom__1vpWv div {
		font-size: 14px;
		color: #565454;
		float: right
	}

	.home_bottom__1vpWv .home_closes__MEz3P {
		margin-left: 8px;
		width: 186px
	}

	.home_redomain__3NP7K {
		color: #606266;
		font-size: 16px;
		line-height: 30px
	}

	.home_dialogContent__1ndXn {
		color: #333;
		padding: 0 75px;
		height: 238px;
		display: -webkit-flex;
		display: flex
	}

	.home_dialogContent__1ndXn p {
		padding: 20px 12px 0
	}

	.home_dialogContent__1ndXn p a {
		color: #33abff
	}

	.home_dialog_footer__2XpuT {
		height: 50px;
		display: -webkit-flex;
		display: flex;
		line-height: 50px
	}

	.home_home_alert_dialog_model__1fc8Y {
		height: auto;
		border-radius: 8px;
		background: #fff
	}

	.home_notice_header__1XbYL {
		position: relative;
		width: 100%;
		height: 40px;
		line-height: 40px;
		border-radius: 4px 4px 0 0;
		background: -webkit-linear-gradient(left, #d2b496, #dccab8);
		background: linear-gradient(90deg, #d2b496, #dccab8);
		margin-bottom: 23px
	}

	.home_notice_header__1XbYL .home_text__2GlVB {
		height: 40px;
		line-height: 40px;
		padding: 0 40px 0 16px;
		font-size: 18px;
		color: #fff
	}

	.home_notice_header__1XbYL .home_close_icon__1I9tc {
		position: absolute;
		right: 16px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 16px;
		z-index: 1000;
		height: 16px;
		// background-image: url(/_next/static/media/icon_closed.b4ca8bb39ee6465dec25a35bfbdb1654.png);
		background-position: 50%;
		background-size: contain;
		cursor: pointer
	}

	.home_notice_header__1XbYL .home_text__2GlVB {
		display: block;
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis
	}

	.home_notice_content__18wUY {
		min-height: 170px;
		max-height: 374px
	}

	.home_notice_content__18wUY .home_no_more_tips__kTJEq {
		height: 64px;
		line-height: 64px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center
	}

	.home_notice_content__18wUY .home_no_more_tips__kTJEq .home_btn_wrap__LWbJ4 {
		height: 36px;
		margin: 0 auto
	}

	.home_notice_content__18wUY .home_no_more_tips__kTJEq .home_btn_wrap__LWbJ4 button {
		font-size: 14px;
		color: #666;
		width: 130px;
		height: 36px;
		line-height: 34px;
		border-radius: 200px;
		vertical-align: top
	}

	.home_notice_content__18wUY .home_no_more_tips__kTJEq .home_btn_wrap__LWbJ4 .home_close__3mmQj {
		border: 1px solid #e3e3e3;
		background-color: initial
	}

	.home_notice_content__18wUY .home_no_more_tips__kTJEq .home_btn_wrap__LWbJ4 .home_confirm__1knmg {
		border: none;
		margin-left: 20px;
		color: #fff;
		box-shadow: 0 4px 6px 4px rgba(219, 198, 177, .24)
	}

	.home_notice_content__18wUY .home_no_more_tips__kTJEq .home_btn_wrap__LWbJ4 .home_confirm__1knmg,
	.home_notice_content__18wUY .home_no_more_tips__kTJEq .home_btn_wrap__LWbJ4 .home_confirm__1knmg:hover {
		background: -webkit-linear-gradient(right, #d2b496, #dccab8);
		background: linear-gradient(270deg, #d2b496, #dccab8);
		color: #fff !important
	}

	.home_notice_content__18wUY .home_notice_content__18wUY {
		position: relative;
		min-height: 101px;
		max-height: 209px;
		padding: 20px 8px 0 16px;
		border-bottom: 1px solid #ededed;
		box-sizing: border-box;
		overflow-y: auto
	}

	.home_notice_content__18wUY .home_notice_content__18wUY ::-webkit-scrollbar {
		width: 6px !important
	}

	.home_notice_content__18wUY .home_notice_content__18wUY ::-webkit-scrollbar-thumb {
		border-radius: 3px;
		background: #eee
	}

	.home_notice_content__18wUY .home_notice_content__18wUY ::-webkit-scrollbar-thumb:window-inactive {
		background: #eee
	}

	.home_notice_content__18wUY div ul {
		width: 100%;
		height: 100%;
		overflow-y: auto;
		scrollbar-color: transparent transparent;
		scrollbar-track-color: transparent;
		-ms-scrollbar-track-color: transparent
	}

	.home_notice_content__18wUY ul li {
		position: relative;
		margin-bottom: 20px;
		padding: 0 13px;
		box-sizing: border-box
	}

	.home_notice_content__18wUY ul li:after {
		content: "";
		position: absolute;
		left: 0;
		top: 5px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: rgba(65, 118, 250, .5)
	}

	.home_notice_content__18wUY ul li .home_title__3WM4z {
		line-height: 18px;
		font-size: 14px;
		color: #4176fa;
		margin-bottom: 2px
	}

	.home_notice_content__18wUY ul li .home_content__2f4AU {
		line-height: 18px;
		font-size: 14px;
		color: #666
	}

	.home_line__3dZc2 {
		width: 100%;
		height: 1px;
		background: #ededed;
		margin-bottom: 9px
	}

	.home_notice_content__18wUY div ul ::-webkit-scrollbar {
		width: 6px !important
	}

	.home_notice_content__18wUY div ul ::-webkit-scrollbar-track {
		border-radius: 3px
	}

	.home_notice_content__18wUY div ul ::-webkit-scrollbar-thumb {
		border-radius: 3px;
		background: #eee
	}

	.home_notice_content__18wUY div ul ::-webkit-scrollbar-thumb:window-inactive {
		background: #eee
	}

	.home_modal_CuorsterAreat_model__7w2j9 {
		width: 490px;
		height: auto;
		background-color: #fff
	}

	.home_modal_CuorsterAreat_model__7w2j9 .home_notice_content__18wUY {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column
	}

	.home_modal_CuorsterAreat_model__7w2j9 .home_flex__2y3Kq {
		white-space: pre-wrap;
		position: relative;
		margin-bottom: 23px;
		padding: 0 16px;
		box-sizing: border-box;
		line-height: 22px;
		min-height: 65px;
		max-height: 273px;
		font-size: 14px
	}

	.home_modal_CuorsterAreat_model__7w2j9 .home_footer__3m_Ro {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		height: 82px;
		border-top: 1px solid #efeff4
	}

	.home_modal_CuorsterAreat_model__7w2j9 button {
		width: 136px;
		height: 38px;
		line-height: 38px;
		border: none;
		border-radius: 20px;
		text-align: center;
		font-size: 14px;
		color: #666;
		box-sizing: border-box
	}

	.home_modal_CuorsterAreat_model__7w2j9 button.home_confirm__1knmg {
		color: #fff
	}

	.home_modal_CuorsterAreat_model__7w2j9 button.home_confirm__1knmg,
	.home_modal_CuorsterAreat_model__7w2j9 button.home_confirm__1knmg:hover {
		background: -webkit-linear-gradient(right, #d2b496, #dccab8);
		background: linear-gradient(270deg, #d2b496, #dccab8);
		color: #fff !important
	}

	.home_modal_CuorsterAreat_model__7w2j9 button.home_close__3mmQj {
		margin-right: 32px;
		background: #fff;
		color: #666;
		border: 1px solid #e0e0e0
	}

	.home_modalWrapper__1Uoc2 .ant-modal-mask .ant-modal-wrap .ant-modal {
		background: red
	}
</style>
